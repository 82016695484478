import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  NativeSelect,
  Switch,
  IconButton,
  Badge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Container } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

import { useStyles } from './AddProduct.styles';
import {
  selectCategories,
  selectProductStates,
  selectProductVariants,
  setOrderNewVariants,
  setProductData,
  selectProductData,
  setClearProductData,
} from '../../../../Redux/Slices/Products.Slices';
import * as colors from '../../../../assets/GlobalColors';
import FormikTextField from '../../../../Components/Generals/FormikTextField';
import { setSnackBar, setBackDropState } from '../../../../Redux/Slices/Navigation.Slices';
import HeaderButton from '../../../../Components/TsAdmin/HeaderButton';
import HeaderButtonUnestiled from '../../../../Components/TsAdmin/HeaderButtonUnestiled';
import Upload from '../../../../assets/Icons/Upload.png';
import Font from '../../../../Components/Generals/Fonts/Font';
import Products from '../../../../entities/Products';

const products = new Products();

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.TOOLTIP,
      border: `1px solid ${colors.TOOLTIP}`,
    },
  })
);

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [categoryName, setCategoryName] = React.useState('');
  const [subCategory, setSubCategory] = React.useState('');
  const [label, setLabel] = React.useState('');
  const [state, setState] = React.useState('');
  const [checked, setChecked] = React.useState(true);
  const [inputCode, setInputCode] = React.useState('');
  const [codes, setCodes] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [price, setPrice] = React.useState('');
  const [quantity, setQuantity] = React.useState('');
  const [subCategoriesList, setSubCategoriesList] = React.useState([]);
  const [labelsList, setLabelsList] = React.useState([]);
  const [variants, setVariants] = React.useState([]);
  const [disableButton, setDisableButton] = React.useState(false);
  const [errorsToDisplay, setErrorsToDisplay] = React.useState([]);
  const [weight, setWeight] = React.useState('');
  const [width, setWidth] = React.useState('');
  const [height, setHeight] = React.useState('');
  const [depth, setDepth] = React.useState('');
  const [deliveryPrice, setDeliveryPrice] = React.useState('');
  const [deliveryPriceAux, setDeliveryPriceAux] = React.useState('');
  const [isDeliveryFree, setIsDeliveryFree] = React.useState(false);
  const [disableDeliveryPrice, setDisableDeliveryPrice] = React.useState(false);
  const [disableDimensions, setDisableDimensions] = React.useState(false);

  const { t } = useTranslation('global');

  const categories = useSelector(selectCategories);
  const productStates = useSelector(selectProductStates);
  const productVariants = useSelector(selectProductVariants);
  const productData = useSelector(selectProductData);

  const classes = useStyles();

  const handleSelectChange = (event) => {
    setCategoryName(event.target.value);
    const category = categories.find((item) => item.category === event.target.value);
    const subcategories = category.subcategories.map((item) => item.subCategory);
    setSubCategoriesList(subcategories);
    formik.setFieldValue('categoryName', event.target.value);
    setSubCategory('');
    setLabel('');
  };

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
    setLabel('');
    const category = categories.find((item) => item.category === categoryName);
    const subcategory = category.subcategories.find((item) => item.subCategory === event.target.value);
    setLabelsList(subcategory.labels);
    formik.setFieldValue('subCategory', event.target.value);
  };

  const handleLabelChange = (event) => {
    setLabel(event.target.value);
    formik.setFieldValue('label', event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
    formik.setFieldValue('state', event.target.value);
  };

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
    formik.setFieldValue('observable', event.target.checked);
  };

  const handleCancelButton = () => {
    formik.resetForm();
    formik.setFieldValue('name', '');
    formik.setFieldValue('category', '');
    formik.setFieldValue('codes', '');
    formik.setFieldValue('price', '');
    formik.setFieldValue('quantity', '');
    formik.setFieldValue('state', '');
    formik.setFieldValue('observable', false);
    formik.setFieldValue('description', '');
    formik.setFieldValue('file', '');
    setCategoryName('');
    setSubCategory('');
    setLabel('');
    setState('');
    setChecked(false);
    setCodes([]);
    setFile(null);
    navigate('/ts-admin/products/view');
  };

  const handleSaveChangesButton = (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const handleInputCode = (event) => {
    setInputCode(event.target.value);
  };

  const handleAddCode = () => {
    setCodes([...codes, inputCode]);
    setInputCode('');
    formik.setFieldValue('codes', [...codes, inputCode]);
  };

  const handleChange = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFile(reader.result);
      formik.setFieldValue('file', reader.result);
    };
  };

  const handleDelete = () => {
    setFile(null);
  };

  const handleOnlyNumbers = (event, setState, formikName) => {
    const re = /^[0-9\b]+$/;

    if (event.target.value === '' || re.test(event.target.value)) {
      setState(event.target.value);
      formik.setFieldValue(formikName, parseFloat(event.target.value));
    }
  };

  const handleDeleteCode = (code) => {
    const newCodes = codes.filter((item) => item !== code);
    setCodes(newCodes);
    formik.setFieldValue('codes', newCodes);
  };

  const hadleClickAddVariants = (e) => {
    e.preventDefault();
    dispatch(setProductData(formik.values));
    navigate('/ts-admin/product/new-variants');
  };

  const hadleClickEditVariants = (e) => {
    e.preventDefault();
    dispatch(
      setOrderNewVariants({
        updatedItems: productVariants,
      })
    );
    hadleClickAddVariants(e);
  };

  const handleCurrencyFormat = (event, setState, setStateAux) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(inputValue)) {
      setState(inputValue);
      setStateAux(inputValue);
    } else {
      setState(inputValue.slice(0, -1));
      setStateAux(inputValue.slice(0, -1));
    }
  };

  const handleDeliveryPrice = (event) => {
    if (event.target.checked) {
      setDeliveryPrice('');
      setIsDeliveryFree(true);
      formik.setFieldValue('deliveryPrice', '');
      formik.setFieldValue('isDeliveryFree', true);
      setDisableDeliveryPrice(true);
    } else {
      setDeliveryPrice(deliveryPriceAux);
      setIsDeliveryFree(false);
      formik.setFieldValue('isDeliveryFree', false);
      setDisableDeliveryPrice(false);
    }
  };

  const handlePriceFormat = (event, setState, formikName) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(inputValue)) {
      setState(inputValue);
      formik.setFieldValue(formikName, inputValue);
    } else {
      setState(inputValue.slice(0, -1));
      formik.setFieldValue(formikName, inputValue.slice(0, -1));
    }
  };

  const schema = yup.object().shape({
    categoryName: yup.string().required(`${t('admin.CreateProduct.formik-errors.categoryName')}`),
    subCategory: yup.string().required(`${t('admin.CreateProduct.formik-errors.subCategory')}`),
    name: yup.string().required(`${t('admin.CreateProduct.formik-errors.name')}`),
    codes: yup
      .array()
      .min(1, `${t('admin.CreateProduct.formik-errors.codes')}`)
      .required(`${t('admin.CreateProduct.formik-errors.codes')}`),
    price: yup.string().required(`${t('admin.CreateProduct.formik-errors.price')}`),
    quantity: yup.number().required(`${t('admin.CreateProduct.formik-errors.quantity')}`),
    state: yup.string().required(`${t('admin.CreateProduct.formik-errors.state')}`),
    observable: yup.boolean().required(`${t('admin.CreateProduct.formik-errors.observable')}`),
    description: yup.string().required(`${t('admin.CreateProduct.formik-errors.description')}`),
    file: yup.string().required(`${t('admin.CreateProduct.formik-errors.file')}`),
  });

  const formik = useFormik({
    initialValues:
      Object.keys(productData).length === 0
        ? {
            _id: null,
            name: '',
            categoryName: '',
            codes: [],
            price: '',
            quantity: 0,
            state: '',
            observable: true,
            description: '',
            file: '',
            subCategory: '',
            label: '',
            productVariants: [],
            weight: '',
            width: '',
            height: '',
            depth: '',
            deliveryPrice: '',
            isDeliveryFree: false,
          }
        : {
            _id: productData._id,
            name: productData.name,
            categoryName: productData.categoryName,
            codes: productData.codes,
            price: productData.price,
            quantity: productData.quantity,
            state: productData.state,
            observable: productData.observable,
            description: productData.description,
            file: productData.file || productData.image,
            subCategory: productData.subCategory,
            label: productData.label,
            productVariants: productData.productVariants,
            weight: productData.weight,
            width: productData.width,
            height: productData.height,
            depth: productData.depth,
            deliveryPrice: productData.deliveryPrice,
            isDeliveryFree: productData.isDeliveryFree,
          },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      dispatch(setBackDropState(true));
      setDisableButton(true);
      if (values._id !== null) {
        const productBody = {
          _id: productData._id,
          name: values.name,
          categoryName: values.categoryName,
          subCategory: values.subCategory,
          label: values.label,
          codes: values.codes,
          price: values.price,
          quantity: values.quantity,
          state: values.state,
          observable: values.observable,
          description: values.description,
          file: values.file,
          isFileUpdated: values.file === productData.image ? false : true,
          productVariants: values.productVariants,
          weight: values.weight,
          width: values.width,
          height: values.height,
          depth: values.depth,
          deliveryPrice: values.deliveryPrice,
          isDeliveryFree: values.isDeliveryFree,
        };
        const response = await products.editProduct(productBody);
        if (response.data.statusCode === 200) {
          dispatch(setBackDropState(false));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'success',
              snackBarMessage: 'Producto editado con éxito',
            })
          );
          dispatch(setClearProductData());
          formik.resetForm();
          formik.setFieldValue('name', '');
          formik.setFieldValue('category', '');
          formik.setFieldValue('codes', []);
          formik.setFieldValue('price', '');
          formik.setFieldValue('quantity', 0);
          formik.setFieldValue('state', '');
          formik.setFieldValue('observable', false);
          formik.setFieldValue('description', '');
          formik.setFieldValue('file', '');
          setState('');
          setChecked(false);
          setCodes([]);
          setFile(null);
          setCategoryName('');
          setSubCategory('');
          setLabel('');
          setTimeout(() => {
            dispatch(
              setSnackBar({
                snackBarStatus: false,
                snackBarSeverity: 'success',
                snackBarMessage: '',
              })
            );
            navigate('/ts-admin/products/view');
            setDisableButton(false);
          }, 1000);
        } else {
          dispatch(setBackDropState(false));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: 'Error al crear el producto',
            })
          );
          setTimeout(() => {
            dispatch(
              setSnackBar({
                snackBarStatus: false,
                snackBarSeverity: 'error',
                snackBarMessage: '',
              })
            );
            setDisableButton(false);
          }, 1000);
        }
      } else {
        const productBody = {
          name: values.name,
          categoryName: values.categoryName,
          subcategory: values.subCategory,
          label: values.label,
          codes: values.codes,
          price: values.price,
          quantity: values.quantity,
          state: values.state,
          observable: values.observable,
          description: values.description,
          file: values.file,
          productVariants: values.productVariants,
          weight: values.weight,
          width: values.width,
          height: values.height,
          depth: values.depth,
          deliveryPrice: values.deliveryPrice === '' ? (values.weight === '' ? 0 : '') : values.deliveryPrice,
          isDeliveryFree: values.isDeliveryFree,
        };

        const response = await products.addNewProducts(productBody);

        if (response.statusCode === 200) {
          dispatch(setBackDropState(false));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'success',
              snackBarMessage: 'Producto creado con éxito',
            })
          );
          dispatch(setClearProductData());
          formik.resetForm();
          formik.setFieldValue('name', '');
          formik.setFieldValue('category', '');
          formik.setFieldValue('codes', []);
          formik.setFieldValue('price', '');
          formik.setFieldValue('quantity', 0);
          formik.setFieldValue('state', '');
          formik.setFieldValue('observable', false);
          formik.setFieldValue('description', '');
          formik.setFieldValue('file', '');
          setState('');
          setChecked(false);
          setCodes([]);
          setFile(null);
          setCategoryName('');
          setSubCategory('');
          setLabel('');
          setTimeout(() => {
            dispatch(
              setSnackBar({
                snackBarStatus: false,
                snackBarSeverity: 'success',
                snackBarMessage: '',
              })
            );
            navigate('/ts-admin/products/view');
            setDisableButton(false);
          }, 1000);
        } else {
          dispatch(setBackDropState(false));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: 'Error al crear el producto',
            })
          );
          setTimeout(() => {
            dispatch(
              setSnackBar({
                snackBarStatus: false,
                snackBarSeverity: 'error',
                snackBarMessage: '',
              })
            );
            setDisableButton(false);
          }, 1000);
        }
      }
    },
  });

  React.useEffect(() => {
    setChecked(true);
    formik.setFieldValue('observable', true);
  }, [formik.values]);

  React.useEffect(() => {
    if (Object.keys(formik.errors).length !== 0) {
      setErrorsToDisplay(Object.values(formik.errors));
    }
  }, [formik.errors]);

  React.useEffect(() => {
    if (errorsToDisplay.length > 0) {
      dispatch(
        setSnackBar({
          snackBarStatus: true,
          snackBarSeverity: 'warning',
          snackBarMessage: (
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              {errorsToDisplay.map((error, idx) => {
                return <Font key={idx} text={error} color={colors.WHITE} size={14} lineHeight="22px" isHead={false} />;
              })}
            </Box>
          ),
        })
      );
    }
  }, [errorsToDisplay]);

  React.useEffect(() => {
    if (Object.keys(productVariants).length !== 0) {
      setVariants(productVariants);
      formik.setFieldValue('productVariants', productVariants);
    }
  }, [productVariants]);

  React.useEffect(() => {
    if (Object.keys(productData).length !== 0) {
      setPrice(productData.price);
      setChecked(productData.observable);
      setFile(productData.file || productData.image);
      setCodes(productData.codes);
      setState(productData.state);
      setCategoryName(productData.categoryName);
      setSubCategory(productData.subCategory);
      setLabel(productData.label);
      if (productData.categoryName !== '') {
        const category = categories.find((item) => item.category === productData.categoryName);
        const subcategories = category.subcategories.map((item) => item.subCategory);
        setSubCategoriesList(subcategories);
        const subcategory = category.subcategories.find((item) => item.subCategory === productData.subCategory);
        setLabelsList(subcategory.labels);
      }
      setWeight(productData.weight);
      setWidth(productData.width);
      setHeight(productData.height);
      setDepth(productData.depth);
      setDeliveryPrice(productData.deliveryPrice);
      setIsDeliveryFree(productData.isDeliveryFree);
    }
  }, [productData]);

  React.useEffect(() => {
    if (typeof deliveryPrice === 'number') {
      formik.setFieldValue('deliveryPrice', deliveryPrice);
    } else {
      if (deliveryPrice !== '') {
        if (isDeliveryFree === false) {
          const deliveryPriceWithoutCommas = deliveryPrice.replace(/,/g, '');
          const deliveryPriceNumber = parseFloat(deliveryPriceWithoutCommas);
          formik.setFieldValue('deliveryPrice', deliveryPriceNumber);
        }
      }
    }
  }, [deliveryPrice]);

  React.useEffect(() => {
    if (isDeliveryFree === true) {
      setDisableDimensions(true);
      formik.setFieldValue('weight', 0);
      setWeight('');
      formik.setFieldValue('width', 0);
      setWidth('');
      formik.setFieldValue('height', 0);
      setHeight('');
      formik.setFieldValue('depth', 0);
      setDepth('');
    } else {
      setDisableDimensions(false);
    }
  }, [isDeliveryFree]);

  React.useEffect(() => {
    if (deliveryPrice !== '') {
      setDisableDimensions(true);
      formik.setFieldValue('weight', 0);
      setWeight('');
      formik.setFieldValue('width', 0);
      setWidth('');
      formik.setFieldValue('height', 0);
      setHeight('');
      formik.setFieldValue('depth', 0);
      setDepth('');
    } else {
      setDisableDimensions(false);
    }
  }, [deliveryPrice]);

  return (
    <Container maxWidth="lg">
      <Box component="div" className={classes.mainContainer}>
        <Box component="div" className={classes.header}>
          <Box component="div" className={classes.headerContent}>
            <Font
              text={t('admin.CreateProduct.text-welcome')}
              color={colors.BLACK}
              size={24}
              lineHeight="32px"
              isHead={true}
            />

            <Box component="div" className={classes.buttonsContainer}>
              <HeaderButtonUnestiled
                text={t('admin.CreateProduct.label-button-cancel')}
                handleOnClick={handleCancelButton}
              />

              <HeaderButton
                text={t('admin.CreateProduct.label-button')}
                handleOnClick={handleSaveChangesButton}
                disableButton={disableButton}
              />
            </Box>
          </Box>
        </Box>

        <Box component="div" className={classes.container}>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              width: '100%',
            }}
          >
            <Box
              component="div"
              className={classes.formContainer}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-evenly',
              }}
            >
              <Box component="div" className={classes.formContent}>
                <Box
                  component="div"
                  className={classes.formLeftContainer}
                  style={{
                    width: '520px',
                  }}
                >
                  <Box component="div" className={classes.formItemsContainer}>
                    <Font
                      text={t('admin.CreateProduct.label-name')}
                      color={colors.BLACK_06}
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <FormikTextField
                      itemValue={formik.values.name}
                      itemSize="medium"
                      itemId="name"
                      itemName="name"
                      itemVariant="outlined"
                      itemOnChange={(e) => {
                        formik.handleChange(e);
                      }}
                      itemStyle={{
                        border: formik.errors.name ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                        borderRadius: '5px',
                        width: '480px',
                        backgroundColor: colors.GRAY_BG_04,
                      }}
                      itemType="text"
                      itemPlaceHolderColor={colors.BLACK}
                      itemPlaceHolderSize="13px"
                      itemPlaceholder={t('admin.CreateProduct.placeholder-name')}
                    />
                  </Box>

                  <Box component="div" className={classes.formItemsContainer}>
                    <Font
                      text={t('admin.CreateProduct.label-description')}
                      color={colors.BLACK_06}
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <FormikTextField
                      itemValue={formik.values.description}
                      itemSize="medium"
                      itemId="description"
                      itemName="description"
                      itemVariant="outlined"
                      itemOnChange={(e) => {
                        formik.handleChange(e);
                      }}
                      itemStyle={{
                        border: formik.errors.price ? '0.4px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                        borderRadius: '5px',
                        width: '480px',
                        backgroundColor: colors.GRAY_BG_04,
                      }}
                      itemType="text"
                      itemPlaceHolderColor={colors.BLACK}
                      itemPlaceHolderSize="13px"
                      itemPlaceholder={t('admin.CreateProduct.placeholder-description')}
                      itemMultiline={true}
                      itemRows={2}
                    />
                  </Box>

                  <Box component="div" className={classes.formItemsContainer}>
                    <Font
                      text={t('admin.CreateProduct.label-price')}
                      color={colors.BLACK_06}
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <FormikTextField
                      itemValue={price}
                      itemSize="medium"
                      itemId="price"
                      itemName="price"
                      itemVariant="outlined"
                      itemOnChange={(e) => {
                        handlePriceFormat(e, setPrice, 'price');
                      }}
                      itemStyle={{
                        border: formik.errors.price ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                        borderRadius: '5px',
                        width: '480px',
                        backgroundColor: colors.GRAY_BG_04,
                      }}
                      itemType="text"
                      itemPlaceHolderColor={colors.BLACK}
                      itemPlaceHolderSize="13px"
                      itemPlaceholder={t('admin.CreateProduct.placeholder-price')}
                      itemMoney={true}
                      itemDisabled={false}
                    />
                  </Box>

                  <Box component="div" className={classes.formItemsContainer}>
                    <Box
                      component="div"
                      style={{
                        width: '480px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Switch
                        checked={checked}
                        onChange={handleCheckedChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />

                      {checked === true ? <VisibilityIcon /> : <VisibilityOffIcon />}

                      {checked === true ? (
                        <Font
                          text={t('admin.CreateProduct.label-observable-true')}
                          color={colors.BLACK_06}
                          size={14}
                          lineHeight="22px"
                          isHead={false}
                        />
                      ) : (
                        <Font
                          text={t('admin.CreateProduct.label-observable-false')}
                          color={colors.BLACK_06}
                          size={14}
                          lineHeight="22px"
                          isHead={false}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  component="div"
                  className={classes.formLeftContainer}
                  style={{
                    width: '520px',
                  }}
                >
                  <Box component="div" className={classes.imageContainer}>
                    <Box component="div" className={classes.imageContainerHeader}>
                      <Font
                        text={t('admin.CreateProduct.label-image')}
                        color={colors.BLACK_06}
                        size={14}
                        lineHeight="22px"
                        isHead={false}
                      />

                      {file !== null ? (
                        <IconButton onClick={handleDelete}>
                          <DeleteIcon className={classes.DeleteIcon} />
                        </IconButton>
                      ) : null}
                    </Box>

                    {file === null ? (
                      <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={['png', 'jpeg', 'jpg']}
                        label={t('admin.CreateProduct.label-image')}
                        className={classes.FileUploader}
                        children={
                          <Box
                            component="div"
                            className={classes.FileUploader}
                            style={{
                              border: formik.errors.file
                                ? `1px dashed ${colors.TSADMIN_RED_DELETE}`
                                : `1px dashed ${colors.GRAY_196_1}`,
                            }}
                          >
                            <img src={Upload} alt={t('admin.CreateProduct.label-image')} />

                            <Box
                              component="div"
                              style={{
                                gap: '5px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Font
                                text={t('admin.CreateProduct.label-image-upload')}
                                color={colors.BLACK}
                                size={14}
                                lineHeight="22px"
                                isHead={true}
                              />

                              <Font
                                text={t('admin.CreateProduct.label-image-upload2')}
                                color={colors.TSADMIN_BLUE}
                                size={14}
                                lineHeight="22px"
                                isHead={true}
                              />
                            </Box>
                          </Box>
                        }
                      />
                    ) : (
                      <img src={file} alt={t('admin.CreateProduct.label-image')} className={classes.image} />
                    )}
                  </Box>
                </Box>
              </Box>

              <Box component="div" className={classes.formContent}>
                <Box
                  component="div"
                  className={classes.formLeftContainer}
                  style={{
                    width: '320px',
                  }}
                >
                  <Box component="div" className={classes.formItemsContainer}>
                    <Font
                      text={t('admin.CreateProduct.label-category')}
                      color="rgba(0, 0, 0, 0.4)"
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <FormControl fullWidth>
                      <NativeSelect
                        value={categoryName}
                        inputProps={{
                          name: 'categoryName',
                          id: 'controlled-native',
                        }}
                        onChange={(e) => handleSelectChange(e)}
                        variant="outlined"
                        style={{
                          width: '280px',
                          border: formik.errors.categoryName ? '1.5px solid red' : `1.5px solid ${colors.GRAY_214_1}`,
                          borderRadius: '5px',
                        }}
                      >
                        <option value={null} className="text-04 text-13">
                          {t('admin.CreateProduct.placeholder-category')}
                        </option>
                        {categories.map((category, idx) => {
                          return (
                            <option key={idx} value={category.category}>
                              {category.category}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </Box>

                  {categoryName !== '' ? (
                    <Box component="div" className={classes.formItemsContainer}>
                      <Font
                        text={t('admin.CreateProduct.label-subcategory')}
                        color="rgba(0, 0, 0, 0.4)"
                        size={14}
                        lineHeight="22px"
                        isHead={false}
                      />

                      <FormControl fullWidth>
                        <NativeSelect
                          value={subCategory}
                          inputProps={{
                            name: 'subCategory',
                            id: 'controlled-native',
                          }}
                          onChange={(e) => handleSubCategoryChange(e)}
                          variant="outlined"
                          style={{
                            width: '280px',
                            border: formik.errors.subCategory ? '1.5px solid red' : `1.5px solid ${colors.GRAY_214_1}`,
                            borderRadius: '5px',
                          }}
                        >
                          <option value={''} className="text-04 text-13">
                            {t('admin.CreateProduct.placeholder-subcategory')}
                          </option>
                          {subCategoriesList.map((subCategory, idx) => {
                            return (
                              <option key={idx} value={subCategory}>
                                {subCategory}
                              </option>
                            );
                          })}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  ) : null}

                  {subCategory !== '' ? (
                    <Box component="div" className={classes.formItemsContainer}>
                      <Font
                        text={t('admin.CreateProduct.label-labels')}
                        color="rgba(0, 0, 0, 0.4)"
                        size={14}
                        lineHeight="22px"
                        isHead={false}
                      />

                      <FormControl fullWidth>
                        <NativeSelect
                          value={label}
                          inputProps={{
                            name: 'label',
                            id: 'controlled-native',
                          }}
                          onChange={(e) => handleLabelChange(e)}
                          variant="outlined"
                          style={{
                            width: '280px',
                            border: `1.5px solid ${colors.GRAY_214_1}`,
                            borderRadius: '5px',
                          }}
                        >
                          <option value={''} className="text-04 text-13">
                            {t('admin.CreateProduct.placeholder-labels')}
                          </option>
                          {labelsList.map((label, idx) => {
                            return (
                              <option key={idx} value={label}>
                                {label}
                              </option>
                            );
                          })}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  ) : null}

                  <Box component="div" className={classes.formItemsContainer}>
                    <Font
                      text={t('admin.CreateProduct.label-state')}
                      color="rgba(0, 0, 0, 0.4)"
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <FormControl fullWidth>
                      <NativeSelect
                        value={state}
                        inputProps={{
                          name: 'category',
                          id: 'controlled-native',
                        }}
                        onChange={(e) => handleStateChange(e)}
                        variant="outlined"
                        style={{
                          width: '280px',
                          border: formik.errors.state ? '1.5px solid red' : `1.5px solid ${colors.GRAY_214_1}`,
                          borderRadius: '5px',
                        }}
                      >
                        <option value={null}>{t('admin.CreateProduct.placeholder-state')}</option>
                        {productStates.map((item, idx) => {
                          return (
                            <option key={idx} value={item.state}>
                              {t(`admin.CreateProduct.product-state-${item.state}`)}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </Box>

                  <Box component="div" className={classes.formItemsContainer}>
                    <Font
                      text={t('admin.CreateProduct.label-quantity')}
                      color="rgba(0, 0, 0, 0.4)"
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <FormikTextField
                      itemValue={quantity}
                      itemSize="medium"
                      itemId="quantity"
                      itemName="quantity"
                      itemVariant="outlined"
                      itemOnChange={(e) => {
                        handleOnlyNumbers(e, setQuantity, 'quantity');
                      }}
                      itemStyle={{
                        border: formik.errors.price ? '0.4px solid red' : `0.4px solid ${colors.BLACK}`,
                        borderRadius: '5px',
                        width: '280px',
                        backgroundColor: colors.GRAY_BG_04,
                      }}
                      itemType="text"
                      itemPlaceHolderColor={colors.BLACK}
                      itemPlaceHolderSize="13px"
                      itemPlaceholder={formik.values.quantity}
                    />
                  </Box>

                  <Box component="div" className={classes.formItemsContainer}>
                    <Font
                      text={t('admin.CreateProduct.label-codes')}
                      color="rgba(0, 0, 0, 0.4)"
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <Box
                      component="div"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '280px',
                      }}
                    >
                      <FormikTextField
                        itemSize="medium"
                        itemId="codes"
                        itemName="codes"
                        itemVariant="outlined"
                        itemOnChange={handleInputCode}
                        itemStyle={{
                          border: formik.errors.codes ? '0.4px solid red' : `0.4px solid ${colors.BLACK}`,
                          borderRadius: '5px',
                          width: '230px',
                          backgroundColor: colors.GRAY_BG_04,
                        }}
                        itemType="text"
                        itemPlaceHolderColor={colors.BLACK}
                        itemPlaceHolderSize="13px"
                        itemPlaceholder={t('admin.CreateProduct.placeholder-codes')}
                        itemValue={inputCode}
                      />

                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddCode();
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box component="div" className={classes.codesContainer}>
                    {codes.map((code, idx) => {
                      return (
                        <Badge
                          key={idx}
                          badgeContent={
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteCode(code);
                              }}
                            >
                              <DeleteIcon className={classes.DeleteBadgeIcon} />
                            </IconButton>
                          }
                        >
                          <Box component="div" className={classes.codeItem}>
                            <Font text={code} color="rgba(0, 0, 0, 0.4)" size={14} lineHeight="22px" isHead={false} />
                          </Box>
                        </Badge>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>

          <Box component="div" className={classes.variantsBox}>
            <Font text="Logística" color={colors.BLACK_06} size={18} lineHeight="26px" isHead={true} />

            <Font
              text="Configure los datos de envío e inventario de este producto"
              color={colors.BLACK_06}
              size={18}
              lineHeight="26px"
              isHead={false}
            />

            <Box component="div" className={classes.variantsEmptyBox}>
              <Font text="Dimensiones y peso" color={colors.BLACK_06} size={18} lineHeight="26px" isHead={true} />

              <Font
                text="Introduzca las dimensiones y peso de este producto para facilitar el cálculo de la tarifa de envío. Estas medidas son pertinentes para saber el tamaño del contenedor de envío para el producto. Se utilizan para facilitar el cálculo del precio del envío y no se muestran en su tienda en línea."
                color={colors.BLACK_06}
                size={18}
                lineHeight="26px"
                isHead={false}
              />

              <Box component="div" className={classes.logisticBox}>
                <Box component="div" className={classes.formItemsContainer}>
                  <Box component="div" className={classes.logisticHeaderBox}>
                    <Font
                      text={t('admin.CreateProduct.label-weight')}
                      color={colors.BLACK_06}
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <Font text={t('Generals.kg')} color={colors.BLACK_06} size={14} lineHeight="22px" isHead={false} />

                    <HtmlTooltip
                      placement="right"
                      title={
                        <React.Fragment>
                          <Font
                            text={t('admin.CreateProduct.tooltip-weight')}
                            color={colors.WHITE}
                            size={14}
                            lineHeight="22px"
                            isHead={false}
                          />
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <HelpIcon
                          sx={{
                            color: colors.BLACK_06,
                          }}
                        />
                      </IconButton>
                    </HtmlTooltip>
                  </Box>

                  <FormikTextField
                    itemValue={weight}
                    itemSize="medium"
                    itemId="weight"
                    itemName="weight"
                    itemVariant="outlined"
                    itemOnChange={(e) => {
                      handleOnlyNumbers(e, setWeight, 'weight');
                    }}
                    itemStyle={{
                      border: formik.errors.weight ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                      borderRadius: '5px',
                      width: '450px',
                      backgroundColor: colors.GRAY_BG_04,
                    }}
                    itemType="text"
                    itemPlaceHolderColor={colors.BLACK}
                    itemPlaceHolderSize="13px"
                    itemPlaceholder={formik.values.weight}
                    itemDisabled={disableDimensions}
                  />
                </Box>

                <Box component="div" className={classes.formItemsContainer}>
                  <Box component="div" className={classes.logisticHeaderBox}>
                    <Font
                      text={t('admin.CreateProduct.label-width')}
                      color={colors.BLACK_06}
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <Font text={t('Generals.cm')} color={colors.BLACK_06} size={14} lineHeight="22px" isHead={false} />

                    <HtmlTooltip
                      placement="right"
                      title={
                        <React.Fragment>
                          <Font
                            text={t('admin.CreateProduct.tooltip-width')}
                            color={colors.WHITE}
                            size={14}
                            lineHeight="22px"
                            isHead={false}
                          />
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <HelpIcon
                          sx={{
                            color: colors.BLACK_06,
                          }}
                        />
                      </IconButton>
                    </HtmlTooltip>
                  </Box>

                  <FormikTextField
                    itemValue={width}
                    itemSize="medium"
                    itemId="width"
                    itemName="width"
                    itemVariant="outlined"
                    itemOnChange={(e) => {
                      handleOnlyNumbers(e, setWidth, 'width');
                    }}
                    itemStyle={{
                      border: formik.errors.width ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                      borderRadius: '5px',
                      width: '450px',
                      backgroundColor: colors.GRAY_BG_04,
                    }}
                    itemType="text"
                    itemPlaceHolderColor={colors.BLACK}
                    itemPlaceHolderSize="13px"
                    itemPlaceholder={formik.values.width}
                    itemDisabled={disableDimensions}
                  />
                </Box>
              </Box>

              <Box component="div" className={classes.logisticBox}>
                <Box component="div" className={classes.formItemsContainer}>
                  <Box component="div" className={classes.logisticHeaderBox}>
                    <Font
                      text={t('admin.CreateProduct.label-height')}
                      color={colors.BLACK_06}
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <Font text={t('Generals.cm')} color={colors.BLACK_06} size={14} lineHeight="22px" isHead={false} />

                    <HtmlTooltip
                      placement="right"
                      title={
                        <React.Fragment>
                          <Font
                            text={t('admin.CreateProduct.tooltip-height')}
                            color={colors.WHITE}
                            size={14}
                            lineHeight="22px"
                            isHead={false}
                          />
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <HelpIcon
                          sx={{
                            color: colors.BLACK_06,
                          }}
                        />
                      </IconButton>
                    </HtmlTooltip>
                  </Box>

                  <FormikTextField
                    itemValue={height}
                    itemSize="medium"
                    itemId="height"
                    itemName="height"
                    itemVariant="outlined"
                    itemOnChange={(e) => {
                      handleOnlyNumbers(e, setHeight, 'height');
                    }}
                    itemStyle={{
                      border: formik.errors.height ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                      borderRadius: '5px',
                      width: '450px',
                      backgroundColor: colors.GRAY_BG_04,
                    }}
                    itemType="text"
                    itemPlaceHolderColor={colors.BLACK}
                    itemPlaceHolderSize="13px"
                    itemPlaceholder={formik.values.height}
                    itemDisabled={disableDimensions}
                  />
                </Box>

                <Box component="div" className={classes.formItemsContainer}>
                  <Box component="div" className={classes.logisticHeaderBox}>
                    <Font
                      text={t('admin.CreateProduct.label-depth')}
                      color={colors.BLACK_06}
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <Font text={t('Generals.cm')} color={colors.BLACK_06} size={14} lineHeight="22px" isHead={false} />

                    <HtmlTooltip
                      placement="right"
                      title={
                        <React.Fragment>
                          <Font
                            text={t('admin.CreateProduct.tooltip-depth')}
                            color={colors.WHITE}
                            size={14}
                            lineHeight="22px"
                            isHead={false}
                          />
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <HelpIcon
                          sx={{
                            color: colors.BLACK_06,
                          }}
                        />
                      </IconButton>
                    </HtmlTooltip>
                  </Box>

                  <FormikTextField
                    itemValue={depth}
                    itemSize="medium"
                    itemId="depth"
                    itemName="depth"
                    itemVariant="outlined"
                    itemOnChange={(e) => {
                      handleOnlyNumbers(e, setDepth, 'depth');
                    }}
                    itemStyle={{
                      border: formik.errors.depth ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                      borderRadius: '5px',
                      width: '450px',
                      backgroundColor: colors.GRAY_BG_04,
                    }}
                    itemType="text"
                    itemPlaceHolderColor={colors.BLACK}
                    itemPlaceHolderSize="13px"
                    itemPlaceholder={formik.values.depth}
                    itemDisabled={disableDimensions}
                  />
                </Box>
              </Box>

              <Box component="div" className={classes.logisticBox}>
                <Box component="div" className={classes.formItemsContainer}>
                  <Box component="div" className={classes.logisticHeaderBox}>
                    <Font
                      text={t('admin.CreateProduct.label-deliveryPrice')}
                      color={colors.BLACK_06}
                      size={14}
                      lineHeight="22px"
                      isHead={false}
                    />

                    <HtmlTooltip
                      placement="right"
                      title={
                        <React.Fragment>
                          <Font
                            text={t('admin.CreateProduct.tooltip-deliveryPrice')}
                            color={colors.WHITE}
                            size={14}
                            lineHeight="22px"
                            isHead={false}
                          />
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <HelpIcon
                          sx={{
                            color: colors.BLACK_06,
                          }}
                        />
                      </IconButton>
                    </HtmlTooltip>
                  </Box>

                  <Box component="div" className={classes.logisticHeaderBox}>
                    <FormikTextField
                      itemValue={deliveryPrice}
                      itemSize="medium"
                      itemId="deliveryPrice"
                      itemName="deliveryPrice"
                      itemVariant="outlined"
                      itemOnChange={(e) => {
                        handleCurrencyFormat(e, setDeliveryPrice, setDeliveryPriceAux);
                      }}
                      itemStyle={{
                        border: `0.4px solid ${colors.GRAY_214_1}`,
                        borderRadius: '5px',
                        width: '450px',
                        backgroundColor: colors.GRAY_BG_04,
                      }}
                      itemType="text"
                      itemPlaceHolderColor={colors.BLACK}
                      itemPlaceHolderSize="13px"
                      itemPlaceholder={t('admin.CreateProduct.placeholder-deliveryPrice')}
                      itemMoney={true}
                      itemDisabled={disableDeliveryPrice}
                    />

                    <Box component="div" className={classes.logisticHeaderBox}>
                      <Checkbox checked={isDeliveryFree} onChange={handleDeliveryPrice} />
                      <Font text="Envío gratis" color={colors.BLACK_06} size={14} lineHeight="22px" isHead={false} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box component="div" className={classes.variantsBox}>
            <Font text="Opciones de productos" color={colors.BLACK_06} size={18} lineHeight="26px" isHead={true} />

            <Font
              text="Cree opciones de variación y perzonalicación del producto."
              color={colors.BLACK_06}
              size={18}
              lineHeight="26px"
              isHead={false}
            />

            <Box component="div" className={classes.variantsEmptyBox}>
              <Font text="Variaciones" color={colors.BLACK_06} size={18} lineHeight="26px" isHead={true} />

              <Font
                text="Añada opciones de variantes, como tamaño y color para crear variantes de este producto."
                color={colors.BLACK_06}
                size={18}
                lineHeight="26px"
                isHead={false}
              />

              <Box component="div" className={classes.variantsHeader}>
                <Font text="Opciones de variantes" color={colors.BLACK_06} size={18} lineHeight="26px" isHead={true} />

                {variants.length !== 0 ? (
                  <Button onClick={hadleClickEditVariants} variant="contained" className={classes.editVariantButton}>
                    <EditIcon
                      sx={{
                        color: colors.TSADMIN_BLUE,
                      }}
                    />

                    <Font
                      text="Configurar opciones"
                      color={colors.TSADMIN_BLUE}
                      size={14}
                      lineHeight="22px"
                      isHead={true}
                    />
                  </Button>
                ) : null}
              </Box>

              {variants.length === 0 ? (
                <Box component="div" className={classes.variantsBox}>
                  <Font
                    text="Aún no se han añadido opciones."
                    color={colors.BLACK_06}
                    size={14}
                    lineHeight="22px"
                    isHead={false}
                  />

                  <Button onClick={hadleClickAddVariants} variant="contained" className={classes.addVariantButton}>
                    <AddIcon className={classes.AddIcon} />

                    <Font text="Añadir variante" color={colors.BLACK_06} size={14} lineHeight="22px" isHead={true} />
                  </Button>
                </Box>
              ) : (
                <TableContainer
                  component={Paper}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                >
                  <Table
                    style={{
                      border: 'none',
                      boxShadow: 'none',
                    }}
                  >
                    <TableHead
                      style={{
                        border: 'none',
                        boxShadow: 'none',
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Font text="Nombre de la opción" color={colors.BLACK_06} size={14} lineHeight="22px" />
                        </TableCell>

                        <TableCell>
                          <Font text="Tipo" color={colors.BLACK_06} size={14} lineHeight="22px" />
                        </TableCell>

                        <TableCell>
                          <Font text="Valores" color={colors.BLACK_06} size={14} lineHeight="22px" />
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {productVariants.map((row, idx) => (
                        <TableRow key={idx}>
                          <TableCell
                            component="th"
                            padding="normal"
                            sx={{
                              width: '250px',
                            }}
                          >
                            <Font text={row.name} color={colors.BLACK_06} size={14} lineHeight="22px" />
                          </TableCell>

                          <TableCell
                            align="right"
                            padding="normal"
                            sx={{
                              width: '250px',
                            }}
                          >
                            <Font text={row.type} color={colors.BLACK_06} size={14} lineHeight="22px" />
                          </TableCell>

                          <TableCell
                            align="right"
                            padding="normal"
                            sx={{
                              width: '250px',
                            }}
                          >
                            <Box
                              component="div"
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: '5px',
                              }}
                            >
                              {row.values.map((item, idx) => {
                                return (
                                  <Font
                                    key={idx}
                                    text={`${item.name},`}
                                    color={colors.BLACK_06}
                                    size={14}
                                    lineHeight="22px"
                                  />
                                );
                              })}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AddProduct;
