import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colors.GREEN_MAIN}`,
    backgroundColor: colors.GREEN_MAIN,
  },
});
