import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Checkbox } from '@mui/material';

import Font from '../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../utils/Generals';
import { getAddressText } from '../../../utils/FormatData';
import {
  setDeleteAddressModalState,
  setAddressIdToDelete,
  setAddressToEdit,
  setEditAddressModalState,
  setEditAddressModalAction,
} from '../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../assets/GlobalColors';

const OptionButton = ({ text, isDelete, classes, handleClick }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Button
      onClick={handleClick}
      variant="text"
      className={classes.optionButtonRoot}
      sx={{
        backgroundColor: colors.WHITE,
        border: `1px solid ${isDelete ? colors.TSADMIN_RED_DELETE : colors.GRAY_BG}`,
        color: colors.BLACK,

        '&:hover': {
          backgroundColor: isDelete ? colors.TSADMIN_RED_DELETE : colors.GRAY_BG,
          border: `1px solid ${colors.GRAY_BG}`,
          color: colors.WHITE,
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Font
        text={text}
        color={isHovered ? colors.WHITE : isDelete ? colors.TSADMIN_RED_DELETE : colors.GRAY_BG}
        size={14}
        lineHeight="24px"
        isHead={false}
      />
    </Button>
  );
};

const Address = ({ address, classes, t, isCheckout, handleSelectAddress, isAddressSelected }) => {
  const dispatch = useDispatch();

  const [localAddressCity, setLocalAddressCity] = React.useState(generalsEnums.emptyString);
  const [localAddressStreet, setLocalAddressStreet] = React.useState(generalsEnums.emptyString);
  const [localAddressUserData, setLocaladdressUserData] = React.useState(generalsEnums.emptyString);

  const handleOpenEditAddressModal = (e) => {
    e.preventDefault();
    dispatch(setAddressToEdit(address));
    dispatch(setEditAddressModalAction('edit'));
    dispatch(setEditAddressModalState(true));
  };

  const handleOpenDeleteAddressModal = (e) => {
    e.preventDefault();
    dispatch(setAddressIdToDelete(address._id));
    dispatch(setDeleteAddressModalState(true));
  };

  React.useEffect(() => {
    const { addressCity, addressStreet, addressUserData } = getAddressText(address);
    setLocalAddressCity(addressCity);
    setLocalAddressStreet(addressStreet);
    setLocaladdressUserData(addressUserData);
  }, [address]);

  return (
    <Box
      component="div"
      className={classes.addressRoot}
      sx={{
        border: isCheckout ? `1px solid ${colors.GREEN_MAIN}` : 'none',
        padding: isCheckout ? '10px' : '0px',
        justifyContent: 'space-between',
        borderRadius: '8px',
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        {isCheckout ? (
          <Checkbox
            checked={isAddressSelected}
            onChange={() => handleSelectAddress(address)}
            sx={{
              color: colors.GREEN_MAIN,
              '&.Mui-checked': {
                color: colors.GREEN_MAIN,
              },
            }}
          />
        ) : null}

        <Box component="div" className={classes.addressInfoBox}>
          <Font text={localAddressUserData} color={colors.BLACK} size={16} lineHeight="26px" isHead={true} />

          <Font text={localAddressStreet} color={colors.BLACK} size={16} lineHeight="26px" isHead={true} />

          <Font text={localAddressCity} color={colors.BLACK_06} size={16} lineHeight="26px" isHead={false} />
        </Box>
      </Box>

      <Box component="div" className={classes.addressButtonsBox}>
        <OptionButton
          text={t('Generals.edit')}
          isDelete={false}
          classes={classes}
          handleClick={handleOpenEditAddressModal}
        />

        <OptionButton
          text={t('Generals.delete')}
          isDelete={true}
          classes={classes}
          handleClick={handleOpenDeleteAddressModal}
        />
      </Box>
    </Box>
  );
};

export default Address;
