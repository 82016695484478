import React from 'react';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Box } from '@mui/material';

import ResponsiveComponent from '../../Hooks/ResponsiveComponent';
import AddressForm from '../../Components/Generals/Forms/AddressForm';
import generalsEnums from '../../utils/Generals';
import Font from '../../Components/Generals/Fonts/Font';
import Addresses from '../../entities/Addresses';
import Form from '../../Screens/Client/ConfirmOrder/Form';
import { getResponsiveValues } from '../../utils/FormatData';
import { selectPortalLanguage } from '../../Redux/Slices/Portal.Slices';
import { useStyles } from '../../Screens/Client/ConfirmOrder/Checkout.styles';
import { selectUserIdAddress } from '../../Redux/Slices/Portal.Slices';
import { selectUserId, isLogged } from '../../Redux/Slices/User.Slices';
import {
  selectEditAddressModalState,
  setEditAddressModalState,
  selectAddressToEdit,
  selectEditAddressModalAction,
  setEditAddressModalAction,
  setSnackBar,
  setBackDropState,
  setAddressesUpdated,
  setAddressToEdit,
  selectNewClientId,
  selectClientInfo,
  setToastSuccessMessage,
  setIsToastSuccess,
} from '../../Redux/Slices/Navigation.Slices';
import * as colors from '../../assets/GlobalColors';

const BlurryDialog = styled(({ displayModal, ...other }) => <Dialog {...other} />)(({ displayModal }) => ({
  backdropFilter: displayModal ? 'blur(5px)' : 'none',
}));

const EditAddressModal = ({ addressSize, addressLineHeight }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('global');
  const addressesAPI = new Addresses();

  const [userIdSelected, setUserIdSelected] = React.useState(generalsEnums.emptyString);
  const [displayModal, setDisplayModal] = React.useState(generalsEnums.trueBoolean);

  const EditAddressModal = useSelector(selectEditAddressModalState);
  const addressToEdit = useSelector(selectAddressToEdit);
  const editAddressModalAction = useSelector(selectEditAddressModalAction);
  const userId = useSelector(selectUserId);
  const clientInfo = useSelector(selectClientInfo);
  const newClientId = useSelector(selectNewClientId);
  const language = useSelector(selectPortalLanguage);
  const logged = useSelector(isLogged);
  const userIdAddress = useSelector(selectUserIdAddress);

  const handleCloseModal = () => {
    dispatch(setAddressToEdit(generalsEnums.emptyObject));
    dispatch(setEditAddressModalAction(generalsEnums.emptyString));
    dispatch(setEditAddressModalState(generalsEnums.falseBoolean));
  };

  const handleSaveChanges = async (body, sameAsBillingAddress, saveUserAddress) => {
    const userIdToUse = userIdAddress ? userIdAddress : userIdSelected;

    dispatch(setBackDropState(generalsEnums.trueBoolean));
    setDisplayModal(generalsEnums.falseBoolean);
    if (editAddressModalAction === 'add') {
      const createUsersAddressesHandlerBody = {
        userId: userIdToUse,
        name: body.name,
        lastName: body.lastName,
        workEnterprise: body.workEnterprise,
        phone: body.phone,
        countryCallingCode: body.countryCallingCode,
        street: body.street,
        apartment: body.apartment,
        city: body.city,
        country: body.country,
        state: body.state,
        zipCode: body.zipCode,
        adititionalInfo: body.adititionalInfo,
        stateCode: body.stateCode,
      };
      const createUsersAddressesHandlerRes = await addressesAPI.createUsersAddressesHandler(
        createUsersAddressesHandlerBody
      );
      const { statusCode, messages } = createUsersAddressesHandlerRes;
      if (statusCode === generalsEnums.statusCodes.ok) {
        dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
        dispatch(setToastSuccessMessage(language === generalsEnums.languages.spanish ? messages.es : messages.en));
        dispatch(setAddressesUpdated(generalsEnums.trueBoolean));
        handleCloseModal();
      }
    } else if (editAddressModalAction === 'edit') {
      const editAddressHandlerBody = {
        _id: addressToEdit._id,
        name: body.name,
        lastName: body.lastName,
        workEnterprise: body.workEnterprise,
        phone: body.phone,
        countryCallingCode: body.countryCallingCode,
        street: body.street,
        apartment: body.apartment,
        city: body.city,
        country: body.country,
        state: body.state,
        zipCode: body.zipCode,
        adititionalInfo: body.adititionalInfo,
        stateCode: body.stateCode,
      };
      const editAddressHandlerRes = await addressesAPI.editAddressHandler(editAddressHandlerBody);
      const { statusCode, messages } = editAddressHandlerRes;
      if (statusCode === generalsEnums.statusCodes.ok) {
        dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
        dispatch(setToastSuccessMessage(language === generalsEnums.languages.spanish ? messages.es : messages.en));
        dispatch(setAddressesUpdated(generalsEnums.trueBoolean));
        handleCloseModal();
      }
    }
  };

  React.useEffect(() => {
    if (EditAddressModal) {
      setDisplayModal(generalsEnums.trueBoolean);
      if (Object.keys(clientInfo).length > 0) {
        setUserIdSelected(clientInfo.userId);
      } else {
        setUserIdSelected(userId);
      }
    }
  }, [clientInfo, EditAddressModal]);

  return (
    <BlurryDialog onClose={handleCloseModal} open={EditAddressModal} id="cart-modal" displayModal={displayModal}>
      <Box
        component="div"
        sx={{
          width: 'auto',
          minWidth: '100px',
          height: 'auto',
          display: displayModal ? 'flex' : 'none',
          borderRadius: '6px',
          padding: '22px',
          border: `1px solid ${colors.WHITE}`,
          backgroundColor: colors.WHITE,
        }}
      >
        <AddressForm
          t={t}
          handleCancelAddressForm={handleCloseModal}
          addressObject={addressToEdit}
          addressSize={addressSize}
          addressLineHeight={addressLineHeight}
          showCustomerAdititionalInfo={generalsEnums.falseBoolean}
          handleSaveAddress={handleSaveChanges}
          logged={logged}
          saveAdrsCheckbox={generalsEnums.falseBoolean}
        />
      </Box>
    </BlurryDialog>
  );
};

const Main = () => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size22.size,
      generalsEnums.fontSizes.size26.size,
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size28.size,
    ],
    [
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size22.lineHeight,
      generalsEnums.fontSizes.size26.lineHeight,
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size28.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];

  const [addressSize, addressLineHeight] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  return <EditAddressModal addressSize={addressSize} addressLineHeight={addressLineHeight} />;
};

export default Main;
