import { makeStyles } from '@mui/styles';

import * as colors from '../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  logo: {
    width: 'auto',
    height: 'auto',
  },
  aboutUsBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  linkBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderLeft: `2px solid ${colors.GRAY_228_05}`,
    paddingLeft: '20px',
  },
  textButton: {
    width: '200px',
    height: 'auto',
    color: colors.BLACK,
    backgroundColor: colors.WHITE,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

    '&:hover': {
      color: colors.BLACK,
      opacity: '0.8',
    },

    '&:disabled': {
      color: colors.BLACK,
    },
  },
  informationBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderLeft: `2px solid ${colors.GRAY_228_05}`,
    paddingLeft: '20px',
  },
  socialMediaBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    gap: '40px',
    borderLeft: `2px solid ${colors.GRAY_228_05}`,
    paddingLeft: '20px',
  },
  socialMediaIcon: {
    width: '24px',
    height: '24px',
  },
  stripe: {
    width: '110px',
    height: '32px',
  },
});
