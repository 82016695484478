import React from 'react';
import { FormControl, Radio, FormControlLabel } from '@mui/material';

import * as colors from '../../../../assets/GlobalColors';

const RadioButtonAttom = ({ isChecked, isDisabled, handleChange }) => {
  return (
    <FormControl component="fieldset" sx={{ padding: 0, textAlign: 'center' }}>
      <FormControlLabel
        checked={isChecked}
        disabled={isDisabled}
        onChange={handleChange}
        control={
          <Radio
            sx={{
              color: colors.GREEN_MAIN,
              padding: 0,
              '&.Mui-checked': {
                color: colors.GREEN_MAIN,
              },
            }}
          />
        }
      />
    </FormControl>
  );
};

export default RadioButtonAttom;
