import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Checkbox, Button } from '@mui/material';
import * as yup from 'yup';

import GoBackButton from '../../../Components/Generals/GoBackButton';
import Font from '../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../utils/Generals';
import FormikTextField from '../../../Components/Generals/FormikTextField';
import Users from '../../../entities/Users';
import { useStyles } from './MyPersonalInfo.styles';
import {
  selectUserId,
  selectName,
  selectLastName,
  selectEmail,
  selectPhoneNumber,
  selectWorkEnterprise,
  selectAllowMarketingEmails,
  selectSub,
  setUserUpdated,
} from '../../../Redux/Slices/User.Slices';
import { setBackDropState, setSnackBar } from '../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../assets/GlobalColors';

const MyPersonalInfo = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('global');
  const usersApi = new Users();

  const userId = useSelector(selectUserId);
  const name = useSelector(selectName);
  const lastName = useSelector(selectLastName);
  const email = useSelector(selectEmail);
  const phoneNumber = useSelector(selectPhoneNumber);
  const workEnterprise = useSelector(selectWorkEnterprise);
  const allowMarketingEmails = useSelector(selectAllowMarketingEmails);
  const sub = useSelector(selectSub);

  const [emailHasChanged, setEmailHasChange] = React.useState(false);

  const schema = yup.object().shape({
    userId: yup.string().required(),
    name: yup.string().required(),
    lastName: yup.string().required(),
    phoneNumber: yup.string().required(),
    workEnterprise: yup.string().required(),
    allowMarketingEmails: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      userId: generalsEnums.emptyString,
      name: generalsEnums.emptyString,
      lastName: generalsEnums.emptyString,
      phoneNumber: generalsEnums.emptyString,
      workEnterprise: generalsEnums.emptyString,
      allowMarketingEmails: generalsEnums.falseBoolean,
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      const editClientUserBody = {
        userId: values.userId,
        name: values.name,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        workEnterprise: values.workEnterprise,
        allowMarketingEmails: values.allowMarketingEmails,
      };
      dispatch(setBackDropState(true));
      const editClientUserResponse = await usersApi.editClientUser(editClientUserBody);
      const { statusCode } = editClientUserResponse;
      if (statusCode === generalsEnums.statusCodes.ok) {
        dispatch(
          setUserUpdated({
            name: values.name,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            workEnterprise: values.workEnterprise,
            allowMarketingEmails: values.allowMarketingEmails,
          })
        );
        dispatch(setBackDropState(false));
      }
    },
  });

  const handleCheckboxChange = (event) => {
    formik.setFieldValue('allowMarketingEmails', event.target.checked);
    setEmailHasChange(event.target.checked);
  };

  const handlePhoneNumberInput = (e) => {
    const { value } = e.target;
    const phone = value.replace(/[^0-9]+/g, '');

    if (phone.length <= 10) {
      formik.setFieldValue('phoneNumber', phone);
    }
  };

  React.useEffect(() => {
    formik.setFieldValue('userId', userId);
    formik.setFieldValue('name', name);
    formik.setFieldValue('lastName', lastName);
    formik.setFieldValue('email', email);
    formik.setFieldValue('phoneNumber', phoneNumber);
    formik.setFieldValue('workEnterprise', workEnterprise);
    formik.setFieldValue('allowMarketingEmails', allowMarketingEmails);
    formik.setFieldValue('sub', sub);

    setEmailHasChange(allowMarketingEmails);
  }, []);

  return (
    <Box
      component="div"
      className={classes.root}
      sx={{
        gap: '5px',
      }}
    >
      <Font text={t('client.MyPersonalInfo.title')} color={colors.BLACK} size={24} lineHeight="32px" isHead={true} />

      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Box component="div" className={classes.formItem}>
          <Font
            text={`${t('client.MyPersonalInfo.name')} *`}
            color={colors.BLACK_06}
            size={14}
            lineHeight="22px"
            isHead={true}
          />

          <FormikTextField
            itemValue={formik.values.name}
            itemSize="medium"
            itemId="name"
            itemName="name"
            itemVariant="outlined"
            itemOnChange={(e) => {
              formik.handleChange(e);
            }}
            itemStyle={{
              border: formik.errors.name ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
              borderRadius: '5px',
              width: '100%',
              backgroundColor: colors.WHITE,
            }}
            itemType="text"
            itemPlaceHolderColor={colors.BLACK}
            itemPlaceHolderSize="13px"
            itemPlaceholder={t('client.MyPersonalInfo.name')}
          />
        </Box>

        <Box component="div" className={classes.formItem}>
          <Font
            text={`${t('client.MyPersonalInfo.lastName')} *`}
            color={colors.BLACK_06}
            size={14}
            lineHeight="22px"
            isHead={true}
          />

          <FormikTextField
            itemValue={formik.values.lastName}
            itemSize="medium"
            itemId="lastName"
            itemName="lastName"
            itemVariant="outlined"
            itemOnChange={(e) => {
              formik.handleChange(e);
            }}
            itemStyle={{
              border: formik.errors.lastName ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
              borderRadius: '5px',
              width: '100%',
              backgroundColor: colors.WHITE,
            }}
            itemType="text"
            itemPlaceHolderColor={colors.BLACK}
            itemPlaceHolderSize="13px"
            itemPlaceholder={t('client.MyPersonalInfo.lastName')}
          />
        </Box>

        <Box component="div" className={classes.formItem}>
          <Font
            text={`${t('client.MyPersonalInfo.phoneNumber')} *`}
            color={colors.BLACK_06}
            size={14}
            lineHeight="22px"
            isHead={true}
          />

          <FormikTextField
            itemValue={formik.values.phoneNumber}
            itemSize="medium"
            itemId="phoneNumber"
            itemName="phoneNumber"
            itemVariant="outlined"
            itemOnChange={(e) => {
              handlePhoneNumberInput(e);
            }}
            itemStyle={{
              border: formik.errors.phoneNumber ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
              borderRadius: '5px',
              width: '100%',
              backgroundColor: colors.WHITE,
            }}
            itemType="text"
            itemPlaceHolderColor={colors.BLACK}
            itemPlaceHolderSize="13px"
            itemPlaceholder={t('client.MyPersonalInfo.phoneNumber')}
          />
        </Box>

        <Box component="div" className={classes.formItem}>
          <Font
            text={`${t('client.MyPersonalInfo.workEnterprise')} *`}
            color={colors.BLACK_06}
            size={14}
            lineHeight="22px"
            isHead={true}
          />

          <FormikTextField
            itemValue={formik.values.workEnterprise}
            itemSize="medium"
            itemId="workEnterprise"
            itemName="workEnterprise"
            itemVariant="outlined"
            itemOnChange={(e) => {
              formik.handleChange(e);
            }}
            itemStyle={{
              border: formik.errors.workEnterprise ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
              borderRadius: '5px',
              width: '100%',
              backgroundColor: colors.WHITE,
            }}
            itemType="text"
            itemPlaceHolderColor={colors.BLACK}
            itemPlaceHolderSize="13px"
            itemPlaceholder={t('client.MyPersonalInfo.workEnterprise')}
          />
        </Box>

        <Box component="div" className={classes.formItem}>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={emailHasChanged}
              onChange={handleCheckboxChange}
              sx={{
                '&.Mui-checked': {
                  color: colors.GREEN_MAIN,
                },
              }}
            />

            <Font
              text={`${t('client.MyPersonalInfo.allowMarketingEmails')} *`}
              color={colors.BLACK_06}
              size={14}
              lineHeight="22px"
              isHead={true}
            />
          </Box>
        </Box>

        <Button onClick={formik.handleSubmit} variant="contained" className={classes.continueButton}>
          <Font text={t('Generals.saveChanges')} color={colors.BLACK} size={14} lineHeight="26px" isHead={true} />
        </Button>
      </form>
    </Box>
  );
};

export default MyPersonalInfo;
