import React from 'react';
import { Box, TextField } from '@mui/material';

import generalsEnums from '../../../../utils/Generals';
import * as colors from '../../../../assets/GlobalColors';

const min = 1;
const max = 999;

const ProductQtyInput = ({ qty, handleAddQuantityInput }) => {
  const [quantity, setQuantity] = React.useState(1);
  const [timerId, setTimerId] = React.useState(null);

  const handleChange = (value) => {
    setQuantity(value);

    if (value !== '') {
      var value = parseInt(value, 10);
      if (value > max) value = max;
      if (value < min) value = min;
      setQuantity(value);
    }
  };

  const handleBlur = () => {
    if (!quantity) {
      setQuantity(qty);
    }
  };

  const handleCallAction = () => {
    if (qty !== quantity) {
      if (quantity) {
        handleAddQuantityInput(quantity);
      }
    }
  };

  React.useEffect(() => {
    setQuantity(qty);
  }, [qty]);

  React.useEffect(() => {
    clearTimeout(timerId);
    setTimerId(setTimeout(handleCallAction, 500));
  }, [quantity]);

  return (
    <Box
      component={generalsEnums.BoxDivComponent}
      sx={{
        width: '80px',
      }}
    >
      <TextField
        fullWidth
        type="number"
        size="small"
        value={quantity}
        onChange={(e) => {
          const value = e.target.value;
          handleChange(value);
        }}
        onBlur={handleBlur}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          color: colors.BLACK,
          '& label.Mui-focused': {
            color: colors.BLACK_06,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: colors.BLACK_06,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: colors.BLACK_06,
            },
            '&:hover fieldset': {
              borderColor: colors.BLACK_06,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.BLACK_06,
            },
          },
        }}
      />
    </Box>
  );
};

export default ProductQtyInput;
