import React from 'react';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Box } from '@mui/material';

import Orders from '../../entities/Orders';
import Font from '../../Components/Generals/Fonts/Font';
import Icons from '../../Components/Generals/Figma/Icons/Icons';
import TsiButton from '../../Components/Generals/Figma/Atoms/TsiButton';
import generalsEnums from '../../utils/Generals';
import {
  selectTvhCreateOrderState,
  setTvhCreateOrderState,
  selectOrderNumber,
  setUpdateOrder,
  setBackDropState,
  setPreventReload,
} from '../../Redux/Slices/Navigation.Slices';
import * as colors from '../../assets/GlobalColors';

const BlurryDialog = styled(Dialog)(({}) => ({
  backdropFilter: 'blur(5px)',
}));

const TvhCreaeteOrder = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');
  const ordersAPI = new Orders();

  const [hideModal, setHideModal] = React.useState(generalsEnums.falseBoolean);
  const [disableButtons, setDisableButtons] = React.useState(generalsEnums.falseBoolean);

  const TvhCreaeteOrderState = useSelector(selectTvhCreateOrderState);
  const orderNumber = useSelector(selectOrderNumber);

  const handleCloseModal = () => {
    if (disableButtons) return;
    dispatch(setTvhCreateOrderState(false));
  };

  const handleSave = async () => {
    setDisableButtons(generalsEnums.trueBoolean);
    setHideModal(generalsEnums.trueBoolean);
    dispatch(setBackDropState(true));
    try {
      const body = {
        orderNumber: orderNumber,
      };
      await ordersAPI.tvhCreateOrder(body);
      dispatch(setTvhCreateOrderState(generalsEnums.falseBoolean));
      dispatch(setUpdateOrder(generalsEnums.trueBoolean));
      setHideModal(generalsEnums.falseBoolean);
      dispatch(setBackDropState(generalsEnums.falseBoolean));
    } catch (error) {
      setHideModal(generalsEnums.falseBoolean);
      dispatch(setTvhCreateOrderState(generalsEnums.falseBoolean));
      dispatch(setBackDropState(generalsEnums.falseBoolean));
    }
  };

  React.useEffect(() => {
    if (TvhCreaeteOrderState) {
      dispatch(setPreventReload(generalsEnums.trueBoolean));
    } else {
      dispatch(setPreventReload(generalsEnums.falseBoolean));
    }
  }, [TvhCreaeteOrderState]);

  return (
    <BlurryDialog onClose={handleCloseModal} open={TvhCreaeteOrderState} id="cart-modal">
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '360px',
          height: '355px',
          borderRadius: '12px',
          padding: '32px',
          display: hideModal ? 'none' : 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <Icons kind={generalsEnums.icons.kind.Package} c={null} w="72px" h="72px" />

        <Font
          text={t('TvhCreaeteOrder.title')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size18.size}
          lineHeight={generalsEnums.fontSizes.size18.lineHeight}
          isHead={true}
          isCenter={true}
        />

        <Font
          text={t('TvhCreaeteOrder.ask')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size14.size}
          lineHeight={generalsEnums.fontSizes.size14.lineHeight}
          isHead={false}
          isCenter={true}
        />

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: 'auto',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.black}
              handleAction={handleCloseModal}
              text={t('Generals.no')}
              isDisabled={disableButtons}
              size={generalsEnums.fontSizes.size14.size}
              lineHeight={generalsEnums.fontSizes.size14.lineHeight}
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: 'auto',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.save}
              handleAction={handleSave}
              text={t('Generals.yes')}
              isDisabled={disableButtons}
              size={generalsEnums.fontSizes.size14.size}
              lineHeight={generalsEnums.fontSizes.size14.lineHeight}
            />
          </Box>
        </Box>
      </Box>
    </BlurryDialog>
  );
};

export default TvhCreaeteOrder;
