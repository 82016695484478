import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  snackBarStatus: false,
  snackBarSeverity: 'success',
  snackBarMessage: '',
  backDropState: false,
  CartModalState: false,
  clientOrderNotesModalState: false,
  orderNumber: '',
  orderNotes: '',
  updateData: true,
  DeleteAddressModalState: false,
  addressIdToDelete: '',
  addressesUpdated: false,
  EditAddressModalState: false,
  addressToEdit: {},
  EditAddressModalAction: '',
  ClientInfoModalState: false,
  ClientInfo: {},
  ClientInfoOnlyView: false,
  newClientId: '',
  clearShoppingCart: false,
  textLogoObject: {},
  imageLogoObject: {},
  logoIsUpdated: false,
  faviconObject: {},
  storeProfilesModalState: false,
  errorId: '',
  errorMessage: '',
  emailTemplate: {},
  TvhCreaeteOrderState: false,
  orderNumber: '',
  updateOrder: false,
  preventReload: false,
  isToastSuccess: false,
  toastSuccessMessage: '',
  isToastError: false,
  ViewMailTemplateModalState: false,
  ViewMailTemplateModalObject: {},
};

export const NavigationSlices = createSlice({
  name: 'Navigation',
  initialState,
  reducers: {
    setViewMailTemplateModalObject: (state, action) => {
      state.ViewMailTemplateModalObject = action.payload;
    },
    setViewMailTemplateModalState: (state, action) => {
      state.ViewMailTemplateModalState = action.payload;
    },
    setToastError: (state, action) => {
      state.isToastError = action.payload;
    },
    setToastSuccessMessage: (state, action) => {
      state.toastSuccessMessage = action.payload;
    },
    setIsToastSuccess: (state, action) => {
      state.isToastSuccess = action.payload;
    },
    setPreventReload: (state, action) => {
      state.preventReload = action.payload;
    },
    setUpdateOrder: (state, action) => {
      state.updateOrder = action.payload;
    },
    setOrderNumber: (state, action) => {
      state.orderNumber = action.payload;
    },
    setTvhCreateOrderState: (state, action) => {
      state.TvhCreaeteOrderState = action.payload;
    },
    setEmailTemplate: (state, action) => {
      state.emailTemplate = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setErrorId: (state, action) => {
      state.errorId = action.payload;
    },
    setStoreProfilesModalState: (state, action) => {
      state.storeProfilesModalState = action.payload;
    },
    setFaviconObject: (state, action) => {
      state.faviconObject._id = action.payload._id;
      state.faviconObject.text = action.payload.text;
      state.faviconObject.fontSize = action.payload.fontSize;
      state.faviconObject.fontColor = action.payload.fontColor;
      state.faviconObject.newImage = false;
      state.faviconObject.isDefault = action.payload.isDefault;
      state.faviconObject.image = action.payload.image;
    },
    setFaviconImage: (state, action) => {
      state.faviconObject.image = action.payload;
      state.faviconObject.newImage = true;
    },
    setTextLogoObject: (state, action) => {
      state.textLogoObject._id = action.payload._id;
      state.textLogoObject.text = action.payload.text;
      state.textLogoObject.fontSize = action.payload.fontSize;
      state.textLogoObject.fontColor = action.payload.fontColor;
      state.textLogoObject.newImage = false;
      state.textLogoObject.isDefault = action.payload.isDefault;
      state.textLogoObject.image = action.payload.image;
    },
    setTextLogoText: (state, action) => {
      state.textLogoObject.text = action.payload;
    },
    setTextLogoColor: (state, action) => {
      state.textLogoObject.fontColor = action.payload;
    },
    setTextLogoFontSize: (state, action) => {
      state.textLogoObject.fontSize = action.payload;
    },
    setTextLogoIsUpdated: (state, action) => {
      state.logoIsUpdated = action.payload;
    },
    setTextLogoIsDefault: (state, action) => {
      state.textLogoObject.isDefault = action.payload;
    },
    setImageLogoObject: (state, action) => {
      state.imageLogoObject._id = action.payload._id;
      state.imageLogoObject.text = action.payload.text;
      state.imageLogoObject.fontSize = action.payload.fontSize;
      state.imageLogoObject.fontColor = action.payload.fontColor;
      state.imageLogoObject.newImage = false;
      state.imageLogoObject.isDefault = action.payload.isDefault;
      state.imageLogoObject.image = action.payload.image;
    },
    setImageLogoImage: (state, action) => {
      state.imageLogoObject.image = action.payload;
      state.imageLogoObject.newImage = true;
    },
    setImageLogoIsDefault: (state, action) => {
      state.imageLogoObject.isDefault = action.payload;
    },
    setSnackBar: (state, action) => {
      state.snackBarStatus = action.payload.snackBarStatus;
      state.snackBarSeverity = action.payload.snackBarSeverity;
      state.snackBarMessage = action.payload.snackBarMessage;
    },
    setBackDropState: (state, action) => {
      state.backDropState = action.payload;
    },
    setCartModalState: (state, action) => {
      state.CartModalState = action.payload;
    },
    setClientOrderNotesModalState: (state, action) => {
      const { notesModalState, orderNumber, orderNotes } = action.payload;
      state.clientOrderNotesModalState = notesModalState;
      state.orderNumber = orderNumber;
      state.orderNotes = orderNotes;
    },
    setUpdateData: (state, action) => {
      state.updateData = action.payload;
    },
    setDeleteAddressModalState: (state, action) => {
      state.DeleteAddressModalState = action.payload;
    },
    setAddressIdToDelete: (state, action) => {
      state.addressIdToDelete = action.payload;
    },
    setAddressesUpdated: (state, action) => {
      state.addressesUpdated = action.payload;
    },
    setEditAddressModalState: (state, action) => {
      state.EditAddressModalState = action.payload;
    },
    setAddressToEdit: (state, action) => {
      state.addressToEdit = action.payload;
    },
    setEditAddressModalAction: (state, action) => {
      state.EditAddressModalAction = action.payload;
    },
    setClientInfoModalState: (state, action) => {
      state.ClientInfoModalState = action.payload;
    },
    setClientInfo: (state, action) => {
      state.ClientInfo = action.payload;
    },
    setClientInfoOnlyView: (state, action) => {
      state.ClientInfoOnlyView = action.payload;
    },
    setNewClientId: (state, action) => {
      state.newClientId = action.payload;
    },
    setClearShoppingCart: (state, action) => {
      state.clearShoppingCart = action.payload;
    },
  },
});

export const {
  setViewMailTemplateModalObject,
  setViewMailTemplateModalState,
  setToastError,
  setToastSuccessMessage,
  setIsToastSuccess,
  setPreventReload,
  setUpdateOrder,
  setOrderNumber,
  setTvhCreateOrderState,
  setEmailTemplate,
  setErrorMessage,
  setErrorId,
  setStoreProfilesModalState,
  setFaviconObject,
  setFaviconImage,
  setTextLogoObject,
  setTextLogoText,
  setTextLogoColor,
  setTextLogoFontSize,
  setTextLogoIsUpdated,
  setTextLogoIsDefault,
  setImageLogoObject,
  setImageLogoImage,
  setImageLogoIsDefault,
  setSnackBar,
  setBackDropState,
  setCartModalState,
  setClientOrderNotesModalState,
  setUpdateData,
  setDeleteAddressModalState,
  setAddressIdToDelete,
  setAddressesUpdated,
  setEditAddressModalState,
  setAddressToEdit,
  setEditAddressModalAction,
  setClientInfoModalState,
  setClientInfo,
  setClientInfoOnlyView,
  setNewClientId,
  setClearShoppingCart,
} = NavigationSlices.actions;

export const selectSnackBarStatus = (state) => state.Navigation.snackBarStatus;
export const selectSnackBarSeverity = (state) => state.Navigation.snackBarSeverity;
export const selectSnackBarMessage = (state) => state.Navigation.snackBarMessage;
export const selectBackDropState = (state) => state.Navigation.backDropState;
export const selectCartModalState = (state) => state.Navigation.CartModalState;
export const selectClientOrderNotesModalState = (state) => {
  return {
    notesModalState: state.Navigation.clientOrderNotesModalState,
    orderNumber: state.Navigation.orderNumber,
    orderNotes: state.Navigation.orderNotes,
  };
};
export const selectUpdateData = (state) => state.Navigation.updateData;
export const selectDeleteAddressModalState = (state) => state.Navigation.DeleteAddressModalState;
export const selectAddressIdToDelete = (state) => state.Navigation.addressIdToDelete;
export const selectAddressesUpdated = (state) => state.Navigation.addressesUpdated;
export const selectEditAddressModalState = (state) => state.Navigation.EditAddressModalState;
export const selectAddressToEdit = (state) => state.Navigation.addressToEdit;
export const selectEditAddressModalAction = (state) => state.Navigation.EditAddressModalAction;
export const selectClientInfoModalState = (state) => state.Navigation.ClientInfoModalState;
export const selectClientInfo = (state) => state.Navigation.ClientInfo;
export const selectClientInfoOnlyView = (state) => state.Navigation.ClientInfoOnlyView;
export const selectNewClientId = (state) => state.Navigation.newClientId;
export const selectClearShoppingCart = (state) => state.Navigation.clearShoppingCart;
export const selectTextLogoObject = (state) => state.Navigation.textLogoObject;
export const selectImageLogoObject = (state) => state.Navigation.imageLogoObject;
export const selectLogoIsUpdated = (state) => state.Navigation.logoIsUpdated;
export const selectFaviconObject = (state) => state.Navigation.faviconObject;
export const selectStoreProfilesModalState = (state) => state.Navigation.storeProfilesModalState;
export const selectErrorId = (state) => state.Navigation.errorId;
export const selectErrorMessage = (state) => state.Navigation.errorMessage;
export const selectTemplate = (state) => state.Navigation.emailTemplate;
export const selectTvhCreateOrderState = (state) => state.Navigation.TvhCreaeteOrderState;
export const selectOrderNumber = (state) => state.Navigation.orderNumber;
export const selectUpdateOrder = (state) => state.Navigation.updateOrder;
export const selectPreventReload = (state) => state.Navigation.preventReload;
export const selectIsToastSuccess = (state) => state.Navigation.isToastSuccess;
export const selectToastSuccessMessage = (state) => state.Navigation.toastSuccessMessage;
export const selectIsToastError = (state) => state.Navigation.isToastError;
export const selectViewMailTemplateModalState = (state) => state.Navigation.ViewMailTemplateModalState;
export const selectViewMailTemplateModalObject = (state) => state.Navigation.ViewMailTemplateModalObject;

export default NavigationSlices.reducer;
