import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';

import Font from '../../../../Components/Generals/Fonts/Font';
import Item from '../Freight/Item';
import { getLisWeightsRulesQty, getLisWeightsRulesCondition } from '../../../../utils/FormatData';

const LisWeightsRules = ({
  t,
  colors,
  generalsEnums,
  classes,
  handleAddNewMarkup,
  descEmptySize,
  descEmptyLineHeight,
  listCurrencySize,
  listCurrencyLineHeight,
  markupArray,
  listTableSize,
  listTableLineHeight,
}) => {
  const boxArray = [
    {
      width: '100px',
      title: t('WeightsRules.list.boxArray.0.title'),
    },
    {
      width: '200px',
      title: t('WeightsRules.list.boxArray.1.title'),
    },
    {
      width: '100px',
      title: t('WeightsRules.list.boxArray.2.title'),
    },
    {
      width: '150px',
      title: t('WeightsRules.list.boxArray.3.title'),
    },
  ];

  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.columnBox}
      sx={{
        gap: '12px',
      }}
    >
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: 'auto',
          minWidth: '100px',
          height: 'auto',
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          className={classes.columnBox}
          sx={{
            padding: '16px',
            borderRadius: '8px 8px 0px 0px',
            gap: '8px',
            border: `1px solid ${colors.GREEN_MAIN}`,
            backgroundColor: colors.GREEN_MAIN_1,
          }}
        >
          <Font
            text={t('WeightsRules.list.title')}
            color={colors.BLACK}
            size={listCurrencySize}
            lineHeight={listCurrencyLineHeight}
            isHead={generalsEnums.trueBoolean}
            ml="0px"
          />

          <Font
            text={t('WeightsRules.list.desc')}
            color={colors.BLACK}
            size={descEmptySize}
            lineHeight={descEmptyLineHeight}
            isHead={generalsEnums.falseBoolean}
            ml="0px"
          />
        </Box>

        <Item
          generalsEnums={generalsEnums}
          classes={classes}
          colors={colors}
          isWhite={generalsEnums.falseBoolean}
          listToRender={
            <>
              {boxArray.map((box) => {
                const { width, title } = box;
                return (
                  <Box
                    key={uuidv4()}
                    component={generalsEnums.BoxDiv}
                    sx={{
                      width: width,
                      height: 'auto',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '8px',
                    }}
                  >
                    <Font
                      text={title}
                      color={colors.BLACK}
                      size={listTableSize}
                      lineHeight={listTableLineHeight}
                      isHead={generalsEnums.trueBoolean}
                      ml="0px"
                    />
                  </Box>
                );
              })}
            </>
          }
        />

        {markupArray.map((markup) => {
          const { quantity, condition, maxWeight, measurementUnit } = markup;

          const childBoxArray = [
            {
              width: boxArray[0].width,
              title: getLisWeightsRulesQty(quantity, t('WeightsRules.list.all')),
            },
            {
              width: boxArray[1].width,
              title: getLisWeightsRulesCondition(
                condition,
                t('WeightsRules.list.greaterOrEqual'),
                t('WeightsRules.list.lessOrEqual')
              ),
            },
            {
              width: boxArray[2].width,
              title: maxWeight,
            },
            {
              width: boxArray[3].width,
              title: measurementUnit,
            },
          ];
          return (
            <Item
              key={uuidv4()}
              generalsEnums={generalsEnums}
              classes={classes}
              colors={colors}
              isWhite={generalsEnums.trueBoolean}
              listToRender={
                <>
                  {childBoxArray.map((box) => {
                    const { width, title } = box;
                    return (
                      <Box
                        key={uuidv4()}
                        component={generalsEnums.BoxDiv}
                        sx={{
                          width: width,
                          height: 'auto',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                        }}
                      >
                        <Font
                          text={title}
                          color={colors.BLACK}
                          size={listTableSize}
                          lineHeight={listTableLineHeight}
                          isHead={generalsEnums.falseBoolean}
                          ml="0px"
                        />
                      </Box>
                    );
                  })}
                </>
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default LisWeightsRules;
