import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import portalSettings from '../../../entities/portalSettings';
import DatePicker from '../../../Components/Generals/Figma/Molecules/DatePicker';
import RequestsTable from './RequestsTable.jsx';
import AdminScreenTemplate from '../../../utils/templates/AdminScreen.Template.jsx';
import generalsEnums from '../../../utils/Generals';
import Font from '../../../Components/Generals/Fonts/Font';
import ResponsiveComponent from '../../../Hooks/ResponsiveComponent';
import { getResponsiveValues } from '../../../utils/FormatData';
import { setBackDropState } from '../../../Redux/Slices/Navigation.Slices.js';
import {
  selectAllRequests,
  selectAllMxRequests,
  selectAllUsRequests,
  selectAllBlockedRequests,
  selectAllBlockedBots,
  selectMinDate,
  selectMaxDate,
} from '../../../Redux/Slices/SecurityDashboard.Slices.js';
import * as colors from '../../../assets/GlobalColors.js';

const ListDetails = ({
  titleSize,
  titleLineHeight,
  textTableSize,
  textTableLineHeight,
  headerItemTitleSize,
  headerItemTitleLineHeight,
}) => {
  const { type } = useParams();
  const { t } = useTranslation('global');
  const portalSettingsAPI = new portalSettings();
  const dispatch = useDispatch();

  const [data, setData] = React.useState(generalsEnums.emptyArray);
  const [title, setTitle] = React.useState(generalsEnums.emptyString);
  const [currentMaxDate, setCurrentMaxDate] = React.useState();
  const [localMinDate, setLocalMinDate] = React.useState();
  const [localMaxDate, setLocalMaxDate] = React.useState();
  const [isBots, setIsBots] = React.useState(generalsEnums.falseBoolean);

  const allRequests = useSelector(selectAllRequests);
  const allMxRequests = useSelector(selectAllMxRequests);
  const allUsRequests = useSelector(selectAllUsRequests);
  const allBlockedRequests = useSelector(selectAllBlockedRequests);
  const allBlockedBots = useSelector(selectAllBlockedBots);
  const minDate = useSelector(selectMinDate);
  const maxDate = useSelector(selectMaxDate);

  const handleChangeMinDate = (newValue) => {
    const date = new Date(newValue);
    date.setHours(0, 0, 0, 0);
    setLocalMinDate(date.toISOString());
  };

  const handleChangeMaxDate = (newValue) => {
    const date = new Date(newValue);
    date.setHours(23, 59, 59, 999);
    setLocalMaxDate(date.toISOString());
  };

  React.useEffect(() => {
    switch (type) {
      case generalsEnums.SecurityListDetails.allRequests:
        setData(allRequests);
        setTitle(t('SecurityDashboard.ListDetails.allRequests'));
        break;
      case generalsEnums.SecurityListDetails.allMxRequests:
        setData(allMxRequests);
        setTitle(t('SecurityDashboard.ListDetails.allMxRequests'));
        break;
      case generalsEnums.SecurityListDetails.allUsRequests:
        setData(allUsRequests);
        setTitle(t('SecurityDashboard.ListDetails.allUsRequests'));
        break;
      case generalsEnums.SecurityListDetails.allBlockedRequests:
        setData(allBlockedRequests);
        setTitle(t('SecurityDashboard.ListDetails.allBlockedRequests'));
        break;
      case generalsEnums.SecurityListDetails.allBlockedBots:
        setData(allBlockedBots);
        setTitle(t('SecurityDashboard.ListDetails.allBlockedBots'));
        setIsBots(generalsEnums.trueBoolean);
        break;
      default:
        break;
    }
  }, [type]);

  React.useEffect(() => {
    setLocalMinDate(minDate);
    setLocalMaxDate(maxDate);
    setCurrentMaxDate(new Date(maxDate));
  }, [minDate, maxDate]);

  React.useEffect(async () => {
    if (localMinDate && localMaxDate) {
      if (localMinDate !== minDate || localMaxDate !== maxDate) {
        dispatch(setBackDropState(generalsEnums.trueBoolean));
        setData(generalsEnums.emptyArray);
        const getSecurityDashboardResponse = await portalSettingsAPI.getSecurityDashboard({
          endTime: localMaxDate,
          startTime: localMinDate,
        });
        const { data } = getSecurityDashboardResponse;
        const { allRequests, allMxRequests, allUsRequests, allBlockedRequests, allBlockedBots } = data.data;

        const dataMap = {
          [generalsEnums.SecurityListDetails.allRequests]: allRequests,
          [generalsEnums.SecurityListDetails.allMxRequests]: allMxRequests,
          [generalsEnums.SecurityListDetails.allUsRequests]: allUsRequests,
          [generalsEnums.SecurityListDetails.allBlockedRequests]: allBlockedRequests,
          [generalsEnums.SecurityListDetails.allBlockedBots]: allBlockedBots,
        };

        setData(dataMap[type] || allRequests);
        dispatch(setBackDropState(generalsEnums.falseBoolean));
      }
    }
  }, [localMinDate, localMaxDate]);

  return (
    <AdminScreenTemplate gap={'12px'}>
      <Font
        text={title}
        color={colors.BLACK}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
        }}
      >
        <DatePicker
          initialValue={localMinDate}
          currentMaxDate={currentMaxDate}
          handleChangeDate={handleChangeMinDate}
          text={
            <Font
              text={t('AdminPortalHome.chart.minDate')}
              color={colors.BLACK_06}
              size={headerItemTitleSize}
              lineHeight={headerItemTitleLineHeight}
              isHead={generalsEnums.trueBoolean}
              ml="0px"
            />
          }
        />

        <DatePicker
          initialValue={localMaxDate}
          currentMaxDate={new Date()}
          handleChangeDate={handleChangeMaxDate}
          text={
            <Font
              text={t('AdminPortalHome.chart.maxDate')}
              color={colors.BLACK_06}
              size={headerItemTitleSize}
              lineHeight={headerItemTitleLineHeight}
              isHead={generalsEnums.trueBoolean}
              ml="0px"
            />
          }
        />
      </Box>

      <RequestsTable
        rows={data}
        textTableSize={textTableSize}
        textTableLineHeight={textTableLineHeight}
        colors={colors}
        isBots={isBots}
      />
    </AdminScreenTemplate>
  );
};

const Main = () => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size30.size,
      generalsEnums.fontSizes.size34.size,
      generalsEnums.fontSizes.size36.size,
      generalsEnums.fontSizes.size36.size,
    ],
    [
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size30.lineHeight,
      generalsEnums.fontSizes.size34.lineHeight,
      generalsEnums.fontSizes.size36.lineHeight,
      generalsEnums.fontSizes.size36.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size10.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size10.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size8.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size18.size,
    ],
    [
      generalsEnums.fontSizes.size8.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
    ],
  ];

  const [
    titleSize,
    titleLineHeight,
    textTableSize,
    textTableLineHeight,
    headerItemTitleSize,
    headerItemTitleLineHeight,
  ] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  return (
    <ListDetails
      titleSize={titleSize}
      titleLineHeight={titleLineHeight}
      textTableSize={textTableSize}
      textTableLineHeight={textTableLineHeight}
      headerItemTitleSize={headerItemTitleSize}
      headerItemTitleLineHeight={headerItemTitleLineHeight}
    />
  );
};

export default Main;
