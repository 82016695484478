import React from 'react';
import { Button } from '@mui/material';

import * as color from '../../assets/GlobalColors';

const FilterButton = ({ text, value, handleOnClick, filterValue }) => {
  return (
    <Button
      onClick={() => handleOnClick(value)}
      variant="contained"
      style={{
        textTransform: 'none',
        width: 'auto',
        height: '40px',
        borderRadius: '0px',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        fontSize: '14px',
        lineHeight: '14px',
        color: color.BLACK_1,
        borderBottom: filterValue === value ? `4px solid ${color.BLACK_1}` : 'none',
      }}
    >
      {text}
    </Button>
  );
};

export default FilterButton;
