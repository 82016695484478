import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

import * as colors from '../../../assets/GlobalColors';

const LineCharts = ({ dataArray }) => {
  const [nameArr, setNameArr] = React.useState([]);
  const [usdArr, setUsdArr] = React.useState([]);

  React.useEffect(() => {
    const updatedNameArr = [];
    const updatedUsdArr = [];

    dataArray.forEach((item) => {
      const index = updatedNameArr.indexOf(item.country);

      if (index === -1) {
        updatedNameArr.push(item.country);
        updatedUsdArr.push(item.totalRequests);
      } else {
        updatedUsdArr[index] += item.totalRequests;
      }
    });

    setNameArr(updatedNameArr);
    setUsdArr(updatedUsdArr);
  }, [dataArray]);

  return (
    <LineChart
      width={600}
      height={400}
      series={[
        {
          curve: 'linear',
          data: usdArr,
          label: 'totalRequests',
        },
      ]}
      xAxis={[{ scaleType: 'point', data: nameArr }]}
      sx={{
        '.MuiLineElement-root': {
          stroke: colors.ORANGE_207,
          padding: '0px',
        },
        '.MuiMarkElement-root': {
          stroke: colors.ORANGE_235,
          fill: colors.ORANGE_235,
        },
        '.MuiLineElement-highlighted': {
          stroke: colors.ORANGE_235,
          fill: colors.ORANGE_235,
        },
      }}
    />
  );
};

export default LineCharts;
