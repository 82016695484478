import uuid from 'react-uuid';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Products from '../../entities/Products';

const products = new Products();

const initialState = {
  allProducts: [],
  allActiveProducts: [],
  allInactiveProducts: [],
  productToEdit: {},
  productStates: [
    {
      state: 'active',
    },
    {
      state: 'inactive',
    },
  ],
  productVariants: [],
  newVariants: [],
  prevNewVariants: [],
  productData: {},
  clientProducts: [],
  productsFiltersSelected: [],
  categoriesFilter: [],
  productSelected: {},
  mainCategoriesFilter: [],
  categories: [],
  searchPagination: [],
};

export const fetchAllProductsFromApi = createAsyncThunk('Products/fetchAllProductsFromApi', async () => {
  const response = await products.listAllProducts();
  return response;
});

export const ProductsSlices = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    setSearchPagination: (state, action) => {
      state.searchPagination = action.payload;
    },
    setProductToEdit: (state, action) => {
      state.productData = action.payload;
    },
    setCategoryFilter: (state, action) => {
      const { uuid, isSelected } = action.payload;
      state.categoriesFilter = state.categoriesFilter.map((category) => {
        if (category.category_uuid === uuid) {
          category.checked = isSelected;
        }
        return category;
      });
    },
    setSubcategoryFilter: (state, action) => {
      const { uuid, isSelected } = action.payload;
      state.categoriesFilter = state.categoriesFilter.map((category) => {
        category.subcategories = category.subcategories.map((subcategory) => {
          if (subcategory.subcategory_uuid === uuid) {
            subcategory.checked = isSelected;
          }
          return subcategory;
        });
        return category;
      });
    },
    setLabelFilter: (state, action) => {
      const { uuid, isSelected } = action.payload;
      state.categoriesFilter = state.categoriesFilter.map((category) => {
        category.subcategories = category.subcategories.map((subcategory) => {
          subcategory.labels = subcategory.labels.map((label) => {
            if (label.label_uuid === uuid) {
              label.checked = isSelected;
            }
            return label;
          });
          return subcategory;
        });
        return category;
      });
    },
    setProductSelected: (state, action) => {
      state.productSelected = action.payload;
    },
    setAddNewVariant: (state, action) => {
      state.newVariants.push({
        id: `item-${state.newVariants.length + 1}`,
        sku: uuid(),
        name: '',
        type: '',
        values: [
          {
            id: uuid(),
            valueSku: uuid(),
            name: '',
            isDefault: false,
          },
        ],
      });
    },
    setClearNewVariants: (state, action) => {
      state.newVariants = state.productVariants;
    },
    setPrevNewVariants: (state, action) => {
      state.prevNewVariants = action.payload;
    },
    setDeleteNewVariant: (state, action) => {
      const { sku } = action.payload;
      state.newVariants = state.newVariants.filter((variant) => variant.sku !== sku);
    },
    setNameNewVariant: (state, action) => {
      const { sku, name } = action.payload;
      state.newVariants = state.newVariants.map((variant) => {
        if (variant.sku === sku) {
          variant.name = name;
        }
        return variant;
      });
    },
    setTypesNewVariant: (state, action) => {
      const { sku, type } = action.payload;
      state.newVariants = state.newVariants.map((variant) => {
        if (variant.sku === sku) {
          variant.type = type;
        }
        return variant;
      });
    },
    setValuesNewVariantValue: (state, action) => {
      const { sku, value, valueSku } = action.payload;
      state.newVariants = state.newVariants.map((variant) => {
        if (variant.sku === sku) {
          variant.values = variant.values.map((item) => {
            if (item.valueSku === valueSku) {
              item.name = value;
            }
            return item;
          });
        }
        return variant;
      });
    },
    setDeleteValuesNewVariantValue: (state, action) => {
      const { sku, valueSku } = action.payload;
      state.newVariants = state.newVariants.map((variant) => {
        if (variant.sku === sku) {
          variant.values = variant.values.filter((item) => item.valueSku !== valueSku);
        }
        return variant;
      });
    },
    setAddValuesNewVariantValue: (state, action) => {
      const { sku } = action.payload;
      state.newVariants = state.newVariants.map((variant) => {
        if (variant.sku === sku) {
          variant.values.push({
            id: uuid(),
            valueSku: uuid(),
            name: '',
            isDefault: false,
          });
        }
        return variant;
      });
    },
    setUpdatePositionsNewVariantValue: (state, action) => {
      const { sku, updatedItems } = action.payload;
      state.newVariants = state.newVariants.map((variant) => {
        if (variant.sku === sku) {
          variant.values = updatedItems;
        }
        return variant;
      });
    },
    setDefaultNewVariantValue: (state, action) => {
      const { sku, index } = action.payload;

      state.newVariants = state.newVariants.map((variant) => {
        if (variant.sku === sku) {
          variant.values = variant.values.map((item, i) => {
            if (i === index) {
              item.isDefault = true;
            } else {
              item.isDefault = false;
            }
            return item;
          });
        }
        return variant;
      });

      state.newVariants = state.newVariants.map((variant) => {
        if (variant.sku === sku) {
          variant.values.sort((a, b) => {
            if (a.isDefault === true) {
              return -1;
            }
            if (b.isDefault === true) {
              return 1;
            }
            return 0;
          });
        }
        return variant;
      });
    },
    setProductVariants: (state, action) => {
      state.productVariants = state.newVariants;
      state.newVariants = [];
    },
    setOrderNewVariants: (state, action) => {
      const { updatedItems } = action.payload;

      state.newVariants = updatedItems;
    },
    setProductData: (state, action) => {
      state.productData = action.payload;
    },
    setClearProductData: (state, action) => {
      state.productData = {};
      state.productVariants = [];
      state.newVariants = [];
    },
    setProductVariantsFromApi: (state, action) => {
      state.productVariants = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProductsFromApi.fulfilled, (state, action) => {
      const { allProducts, allActiveProducts, allInactiveProducts } = action.payload;
      state.allProducts = allProducts ? allProducts : [];
      state.allActiveProducts = allActiveProducts ? allActiveProducts : [];

      state.allInactiveProducts = allInactiveProducts ? allInactiveProducts : [];
    });
  },
});

export const {
  setSearchPagination,
  setProductToEdit,
  setCategoryFilter,
  setSubcategoryFilter,
  setLabelFilter,
  setProductSelected,
  setAddNewVariant,
  setClearNewVariants,
  setPrevNewVariants,
  setDeleteNewVariant,
  setNameNewVariant,
  setTypesNewVariant,
  setValuesNewVariantValue,
  setDeleteValuesNewVariantValue,
  setAddValuesNewVariantValue,
  setUpdatePositionsNewVariantValue,
  setDefaultNewVariantValue,
  setProductVariants,
  setOrderNewVariants,
  setProductData,
  setClearProductData,
  setProductVariantsFromApi,
} = ProductsSlices.actions;

export const selectCategories = (state) => state.Products.categories;
export const selectProductStates = (state) => state.Products.productStates;
export const selectIsCategoriesEmpty = (state) => {
  if (state.Products.categories.length === 0) {
    return true;
  }
  return false;
};
export const selectAllProducts = (state) => state.Products.allProducts;
export const selectAllActiveProducts = (state) => state.Products.allActiveProducts;
export const selectAllInactiveProducts = (state) => state.Products.allInactiveProducts;
export const selectProductToEdit = (state) => state.Products.productToEdit;
export const selectClientProducts = (state) => state.Products.clientProducts;
export const selectIsClientProductsEmpty = (state) => {
  if (state.Products.clientProducts.length === 0) {
    return true;
  }
  return false;
};
export const selectProductsFiltersSelected = (state) => state.Products.productsFiltersSelected;
export const selectCategoriesFilter = (state) => state.Products.categoriesFilter;
export const selectProductsFiltered = (state) => {
  const { categoriesFilter } = state.Products;
  const all = [];

  categoriesFilter.forEach((category) => {
    let productsIdsFiltered = [];

    if (category.checked === true) {
      const subcategoriesChecked = category.subcategories.filter((subcategory) => subcategory.checked === true);

      if (subcategoriesChecked.length === 0) {
        category.products.forEach((product) => {
          productsIdsFiltered.push(product);
        });
      } else {
        subcategoriesChecked.forEach((subcategory) => {
          const labelsChecked = subcategory.labels.filter((label) => label.checked === true);

          if (labelsChecked.length === 0) {
            subcategory.products.forEach((product) => {
              productsIdsFiltered.push(product);
            });
          } else {
            labelsChecked.forEach((label) => {
              label.products.forEach((product) => {
                productsIdsFiltered.push(product);
              });
            });
          }
        });
      }
    }

    all.push(...productsIdsFiltered);
  });

  let produtsToReturn = [];

  if (all.length === 0) {
    produtsToReturn = state.Products.clientProducts;
  } else {
    all.forEach((_id) => {
      const product = state.Products.clientProducts.find((product) => product._id === _id);
      if (product) {
        produtsToReturn.push(product);
      }
    });
  }

  return produtsToReturn;
};
export const selectMainCategoriesFilter = (state) => state.Products.mainCategoriesFilter;
export const selectNewVariants = (state) => state.Products.newVariants;
export const selectProductVariants = (state) => state.Products.productVariants;
export const selectProductData = (state) => state.Products.productData;
export const selectSearchPagination = (state) => state.Products.searchPagination;

export default ProductsSlices.reducer;
