export const API_ROUTES = {
  users: {
    getStripeCustomerId: process.env.REACT_APP_ROOT_API + '/users/getStripeCustomerId',
    getClients: process.env.REACT_APP_ROOT_API + '/users/getClients',
    deleteClientsByIds: process.env.REACT_APP_ROOT_API + '/users/deleteClientsByIds',
    editClientUser: process.env.REACT_APP_ROOT_API + '/users/editClientUser',
    editAdminUser: process.env.REACT_APP_ROOT_API + '/users/editAdminUser',
    changeAdminUserPassword: process.env.REACT_APP_ROOT_API + '/users/changeAdminUserPassword',
    get2faQR: process.env.REACT_APP_ROOT_API + '/users/get2faQR',
    checkUserMFA: process.env.REACT_APP_ROOT_API + '/users/checkUserMFA',
    enableUserMFA: process.env.REACT_APP_ROOT_API + '/users/enableUserMFA',
    validateUserMFA: process.env.REACT_APP_ROOT_API + '/users/validateUserMFA',
  },
  userAuth: {
    signup: process.env.REACT_APP_ROOT_API + '/userAuth/signup',
    signin: process.env.REACT_APP_ROOT_API + '/userAuth/signin',
    verify: process.env.REACT_APP_ROOT_API + '/userAuth/verify',
    signout: process.env.REACT_APP_ROOT_API + '/userAuth/signout',
    refreshSession: process.env.REACT_APP_ROOT_API + '/userAuth/refreshSession',
    signInWithMFA: process.env.REACT_APP_ROOT_API + '/userAuth/signInWithMFA',
    restoreUserPasswordGenerateCode: process.env.REACT_APP_ROOT_API + '/userAuth/restoreUserPasswordGenerateCode',
    restoreUserPassword: process.env.REACT_APP_ROOT_API + '/userAuth/restoreUserPassword',
  },
  products: {
    listProducts: process.env.REACT_APP_ROOT_API + '/products/listProducts',
    addNewProducts: process.env.REACT_APP_ROOT_API + '/products/addNewProducts',
    listAllProducts: process.env.REACT_APP_ROOT_API + '/products/listAllProducts',
    editProduct: process.env.REACT_APP_ROOT_API + '/products/editProduct',
    deleteProduct: process.env.REACT_APP_ROOT_API + '/products/deleteProduct',
    searchProductByNameAndCodes: process.env.REACT_APP_ROOT_API + '/products/searchProductByNameAndCodes',
    searchProductById: process.env.REACT_APP_ROOT_API + '/products/searchProductById/:productId/:country',
    getDeliveryOptions: process.env.REACT_APP_ROOT_API + '/products/getDeliveryOptions/:productId/:quantity/:zipCode',
    tvhImportProducts: process.env.REACT_APP_ROOT_API + '/products/tvhImportProducts',
    productsPagination: process.env.REACT_APP_ROOT_API + '/products/productsPagination',
    productsSearchEngine: process.env.REACT_APP_ROOT_API + '/products/productsSearchEngine',
    getFreightCost: process.env.REACT_APP_ROOT_API + '/products/getFreightCost',
    getTopSellingProducts: process.env.REACT_APP_ROOT_API + '/products/getTopSellingProducts',
  },
  orders: {
    createOrder: process.env.REACT_APP_ROOT_API + '/orders/createOrder',
    getOrder: process.env.REACT_APP_ROOT_API + '/orders/getOrder/:orderNumber',
    getAllOrderByUserId: process.env.REACT_APP_ROOT_API + '/orders/getAllOrderByUserId/:userId',
    getAllOrders: process.env.REACT_APP_ROOT_API + '/orders/getAllOrders',
    editOrderStatusByOrderId: process.env.REACT_APP_ROOT_API + '/orders/editOrderStatusByOrderId',
    createOrderTicket: process.env.REACT_APP_ROOT_API + '/orders/createOrderTicket',
    tvhCreateOrder: process.env.REACT_APP_ROOT_API + '/orders/tvhCreateOrder',
    getDashboardOrders: process.env.REACT_APP_ROOT_API + '/orders/getDashboardOrders/:minDate/:maxDate',
    createOrderClaim: process.env.REACT_APP_ROOT_API + '/orders/createOrderClaim',
    adminViewOrders: process.env.REACT_APP_ROOT_API + '/orders/adminViewOrders/:orderStatus',
    adminUpadteOrderStatus: process.env.REACT_APP_ROOT_API + '/orders/adminUpadteOrderStatus',
  },
  addresses: {
    getAllAddressesByUserId: process.env.REACT_APP_ROOT_API + '/addresses/getAllAddressesByUserId/:userId',
    createUsersAddressesHandler: process.env.REACT_APP_ROOT_API + '/addresses/createUsersAddressesHandler',
    getAllCountries: process.env.REACT_APP_ROOT_API + '/addresses/getAllCountries',
    deleteUserAddressHandler: process.env.REACT_APP_ROOT_API + '/addresses/deleteUserAddressHandler',
    editAddressHandler: process.env.REACT_APP_ROOT_API + '/addresses/editAddressHandler',
  },
  stripeIntegration: {
    createPaymentIntent: process.env.REACT_APP_ROOT_API + '/stripeIntegration/createPaymentIntent',
    cancelPaymentIntent: process.env.REACT_APP_ROOT_API + '/stripeIntegration/cancelPaymentIntent',
  },
  status: {
    getAllOrderStatus: process.env.REACT_APP_ROOT_API + '/status/getAllOrderStatus',
    editOrderStatus: process.env.REACT_APP_ROOT_API + '/status/editStatus',
  },
  UserCredit: {
    editCreditUser: process.env.REACT_APP_ROOT_API + '/users/editCreditUser',
    setCreditToUser: process.env.REACT_APP_ROOT_API + '/users/setCreditToUser',
  },
  MailShots: {
    contactTSI: process.env.REACT_APP_ROOT_API + '/mailshots/contactTSI',
    getMailTemplates: process.env.REACT_APP_ROOT_API + '/mailshots/getMailTemplates',
    updateMailTemplateAvailable: process.env.REACT_APP_ROOT_API + '/mailshots/updateMailTemplateAvailable',
    abandonedCart: process.env.REACT_APP_ROOT_API + '/mailshots/abandonedCart',
    shippingUpdate: process.env.REACT_APP_ROOT_API + '/mailshots/shippingUpdate',
    paymentDeclined: process.env.REACT_APP_ROOT_API + '/mailshots/paymentDeclined',
    complaintResponse: process.env.REACT_APP_ROOT_API + '/mailshots/complaintResponse',
    complaintSolution: process.env.REACT_APP_ROOT_API + '/mailshots/complaintSolution',
    complaintConfirm: process.env.REACT_APP_ROOT_API + '/mailshots/complaintConfirm',
    claimOnOrder: process.env.REACT_APP_ROOT_API + '/mailshots/claimOnOrder',
    deliveryConfirmation: process.env.REACT_APP_ROOT_API + '/mailshots/deliveryConfirmation',
    trackingUpdate: process.env.REACT_APP_ROOT_API + '/mailshots/trackingUpdate',
    shippingConfirmation: process.env.REACT_APP_ROOT_API + '/mailshots/shippingConfirmation',
    purchaseConfirmation: process.env.REACT_APP_ROOT_API + '/mailshots/purchaseConfirmation',
    contactTSI: process.env.REACT_APP_ROOT_API + '/mailshots/contactTSI',
  },
  portalSettings: {
    getPortalLogos: process.env.REACT_APP_ROOT_API + '/portalSettings/getPortalLogos',
    editPortalLogo: process.env.REACT_APP_ROOT_API + '/portalSettings/editPortalLogo',
    getPortalDefaultLogo: process.env.REACT_APP_ROOT_API + '/portalSettings/getPortalDefaultLogo',
    getPortalFavicon: process.env.REACT_APP_ROOT_API + '/portalSettings/getPortalFavicon',
    getProfile: process.env.REACT_APP_ROOT_API + '/portalSettings/getProfile/mx-1',
    editProfile: process.env.REACT_APP_ROOT_API + '/portalSettings/editProfile',
    getProfileForClient: process.env.REACT_APP_ROOT_API + '/portalSettings/getProfileForClient/mx-1',
    editLanguageProfile: process.env.REACT_APP_ROOT_API + '/portalSettings/editLanguageProfile',
    signedUrlFile: process.env.REACT_APP_ROOT_API + '/portalSettings/signedUrlFile',
    getExchangeRate: process.env.REACT_APP_ROOT_API + '/portalSettings/getExchangeRate',
    getStoreProfileAddress: process.env.REACT_APP_ROOT_API + '/portalSettings/getStoreProfileAddress/mx-1',
    getAllActiveMarkup: process.env.REACT_APP_ROOT_API + '/portalSettings/getAllActiveMarkup',
    editActiveMarkup: process.env.REACT_APP_ROOT_API + '/portalSettings/editActiveMarkup',
    getAllActiveFreight: process.env.REACT_APP_ROOT_API + '/portalSettings/getAllActiveFreight',
    getAllActiveWeight: process.env.REACT_APP_ROOT_API + '/portalSettings/getAllActiveWeight',
    getSecurityDashboard: process.env.REACT_APP_ROOT_API + '/portalSettings/getSecurityDashboard',
    getApiCalls: process.env.REACT_APP_ROOT_API + '/portalSettings/getApiCalls',
    blockApiCalls: process.env.REACT_APP_ROOT_API + '/portalSettings/blockApiCalls',
  },
};
