import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal, IconButton } from '@mui/material';

import Font from '../../Components/Generals/Fonts/Font';
import CloseIcon from '@mui/icons-material/Close';
import { setClientOrderNotesModalState, selectClientOrderNotesModalState } from '../../Redux/Slices/Navigation.Slices';
import * as colors from '../../assets/GlobalColors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: colors.WHITE,
  border: 'none',
  boxShadow: 24,
  borderRadius: 4,
  pt: 2,
  px: 4,
  pb: 3,
};

const OrderNotes = () => {
  const dispatch = useDispatch();

  const clientOrderNotesModalState = useSelector(selectClientOrderNotesModalState);

  const handleClose = () => {
    dispatch(
      setClientOrderNotesModalState({
        notesModalState: false,
        orderNumber: '',
        orderNotes: '',
      })
    );
  };

  return (
    <Modal open={clientOrderNotesModalState.notesModalState} onClose={handleClose}>
      <Box
        component="div"
        sx={{
          ...style,
          width: 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Font
            text={`Notas de la orden ${clientOrderNotesModalState.orderNumber}`}
            color={colors.BLACK}
            size={24}
            lineHeight="32px"
            isHead={true}
          />

          <IconButton onClick={handleClose}>
            <CloseIcon
              sx={{
                color: colors.BLACK,
              }}
            />
          </IconButton>
        </Box>

        <Box
          component="div"
          sx={{
            width: '100%',
            height: clientOrderNotesModalState.orderNotes !== '' ? '100px' : 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            textAlign: 'left',
          }}
        >
          <Font
            text={clientOrderNotesModalState.orderNotes !== '' ? clientOrderNotesModalState.orderNotes : 'Sin notas...'}
            color={colors.BLACK}
            size={18}
            lineHeight="28px"
            isHead={false}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderNotes;
