import { makeStyles } from '@mui/styles';

import generalsEnums from '../../../../utils/Generals';
import * as colors from '../../../../assets/GlobalColors';

const useStyles = makeStyles({
  Container: {},
  ContainerIsNotContactUs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  root: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: generalsEnums.paddings.containerRoot,
  },
  formRow: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formRowContent: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '5px',
  },
});

export { useStyles, colors };
