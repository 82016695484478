import React from 'react';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _debounce from 'lodash/debounce';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
  IconButton,
  Checkbox,
  Button,
  FormControl,
  NativeSelect,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import { setUserIdAddress } from '../../../../Redux/Slices/Portal.Slices';
import {
  setClientOrderNotesModalState,
  setClientInfoModalState,
  setClientInfo,
  setClientInfoOnlyView,
} from '../../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../../assets/GlobalColors';

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const OrderInfo = ({ order, classes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orderCreateDate, setOrderCreateDate] = React.useState(generalsEnums.emptyString);

  const handleViewOrderNotes = (e) => {
    e.stopPropagation();
    dispatch(
      setClientOrderNotesModalState({
        notesModalState: true,
        orderNumber: order.orderNumber,
        orderNotes: '',
      })
    );
  };

  const handleViewOrderInfo = (e) => {
    e.stopPropagation();
    navigate(`/ts-admin/order/${order.orderNumber}`);
  };

  React.useEffect(() => {
    const date = new Date(order.orderCreateDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const orderDate = `${day}/${month}/${year}`;
    setOrderCreateDate(orderDate);
  }, [order]);

  return (
    <Box component="div" className={classes.OrderInfoBox}>
      <Box component="div" className={classes.OrderInfoTextBox}>
        <Font text="Pedido" color={colors.BLACK} size={16} lineHeight="26px" isHead={false} />

        <Button variant="text" onClick={handleViewOrderInfo}>
          <Font text={`#${order.orderNumber}`} color={colors.TSADMIN_BLUE} size={16} lineHeight="26px" isHead={false} />
        </Button>
      </Box>

      <Box component="div" className={classes.OrderInfoTextBox}>
        <Font text="Estado: " color={colors.BLACK} size={14} lineHeight="18px" isHead={false} />

        <Font text={order.orderStatus.status} color={colors.GRAY_1_1} size={14} lineHeight="18px" isHead={false} />
      </Box>

      <Box component="div" className={classes.OrderInfoTextBox}>
        <Font text="Total del pedido: " color={colors.BLACK} size={14} lineHeight="18px" isHead={false} />

        <Font text={order.orderTotal} color={colors.GRAY_1_1} size={14} lineHeight="18px" isHead={false} />
      </Box>

      <Box component="div" className={classes.OrderInfoTextBox}>
        <Font text="Fecha del pedido: " color={colors.BLACK} size={14} lineHeight="18px" isHead={false} />

        <Font text={orderCreateDate} color={colors.GRAY_1_1} size={14} lineHeight="18px" isHead={false} />
      </Box>

      <Box
        component="div"
        className={classes.OrderInfoTextBox}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleViewOrderNotes} variant="contained" className={classes.ViewNotesButton}>
          <Font text="Ver notas" color={colors.BLACK} size={14} lineHeight="18px" isHead={false} />
        </Button>
      </Box>
    </Box>
  );
};

const ClientAccordion = ({
  row,
  classes,
  handleCheckOneClient,
  language,
  clientsSelected,
  handleClickName,
  handleClickEmail,
  mediaQuery1200,
  t,
  handleClickOrders,
}) => {
  const [expand, setExpand] = React.useState(generalsEnums.falseBoolean);
  const [anchorEl, setAnchorEl] = React.useState(generalsEnums.nullData);

  const open = Boolean(anchorEl);
  const ID = open ? 'simple-popover' : undefined;

  const toggleAcordion = () => {
    setExpand(!expand);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Accordion
      expanded={expand}
      style={{
        width: '100%',
      }}
    >
      <AccordionSummary
        style={{
          width: '100%',
          boxShadow: 'none',
          flexDirection: 'row-reverse',
        }}
      >
        <Box
          component="div"
          className={classes.AccordionSummaryBox}
          sx={{
            width: '100%',
            justifyContent: mediaQuery1200 ? 'flex-start' : 'space-between',
            alignItems: 'center',
            padding: '0px',
            gap: '1px',
          }}
        >
          <IconButton onClick={toggleAcordion}>
            <ExpandMoreIcon
              sx={{
                rotate: expand ? '180deg' : '0deg',
                transition: '0.3s',
                color: colors.BLACK,
              }}
            />
          </IconButton>

          <Checkbox
            color="secondary"
            onChange={(e) => {
              handleCheckOneClient(e, row._id);
            }}
            checked={clientsSelected.includes(row._id)}
          />

          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              width: '200px',
            }}
          >
            <Button
              onClick={(e) => handleClickName(e, row, !generalsEnums.falseBoolean)}
              variant="contained"
              className={classes.allButton}
              sx={{
                display: 'block',
              }}
            >
              <Font text={row.name} color={colors.TSADMIN_BLUE} size={14} lineHeight="22px" isHead={false} />
            </Button>
          </Box>

          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              width: '250px',
            }}
          >
            <Button
              onClick={(e) => handleClickEmail(e, row.email)}
              variant="contained"
              className={classes.allButton}
              sx={{
                display: 'block',
              }}
            >
              <Font text={row.email} color={colors.TSADMIN_BLUE} size={14} lineHeight="22px" isHead={false} />
            </Button>
          </Box>

          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              width: '100px',
            }}
          >
            <Font text={row.client.phoneNumber} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />
          </Box>

          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              width: '150px',
            }}
          >
            <FormControl fullWidth>
              <NativeSelect
                defaultValue={1}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native',
                }}
              >
                <option value={1}>- Ningun Grupo -</option>
              </NativeSelect>
            </FormControl>
          </Box>

          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              width: '75px',
            }}
          >
            <Font text={row.orders.length} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />
          </Box>

          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              width: '120px',
            }}
          >
            <Font
              text={language === 'es' ? row.since.es : row.since.en}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={false}
            />
          </Box>

          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              width: '40px',
            }}
          >
            <IconButton aria-describedby={ID} onClick={handlePopoverOpen}>
              <MoreVertIcon />
            </IconButton>

            <Popover
              id={ID}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1px',
                  padding: '10px',
                }}
              >
                <Button onClick={(e) => handleClickName(e, row, generalsEnums.falseBoolean)} variant="text">
                  <Font
                    text={t('admin.ClientsView.actions.edit')}
                    color={colors.BLACK}
                    size={14}
                    lineHeight="22px"
                    isHead={false}
                  />
                </Button>

                <Button onClick={(e) => handleClickName(e, row, generalsEnums.falseBoolean)} variant="text">
                  <Font
                    text={t('admin.ClientsView.actions.notes')}
                    color={colors.BLACK}
                    size={14}
                    lineHeight="22px"
                    isHead={false}
                  />
                </Button>

                <Button onClick={(e) => handleClickOrders(e)} variant="text">
                  <Font
                    text={t('admin.ClientsView.actions.orders')}
                    color={colors.BLACK}
                    size={14}
                    lineHeight="22px"
                    isHead={false}
                  />
                </Button>
              </Box>
            </Popover>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box
          component="div"
          className={classes.AccordionSummaryBox}
          sx={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '100px',
          }}
        >
          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Box component="div" className={classes.rowDetailsBox}>
              <Font text="Pedidos actuales" color={colors.BLACK} size={22} lineHeight="28px" isHead={true} />
            </Box>

            <Box
              component="div"
              className={classes.rowDetailsBox}
              sx={{
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {row.orders.map((order, idx) => {
                return (
                  <OrderInfo key={idx} order={order} classes={classes} handleCheckOneClient={handleCheckOneClient} />
                );
              })}
            </Box>
          </Box>

          <Box
            component="div"
            className={classes.rowBox}
            sx={{
              flexDirection: 'column',
            }}
          >
            <Box
              component="div"
              className={classes.rowDetailsBox}
              sx={{
                borderRight: `1px solid ${colors.GRAY_214_1}`,
              }}
            >
              <Font text="Pedidos anteriores" color={colors.BLACK} size={22} lineHeight="28px" isHead={true} />
            </Box>

            <Box
              component="div"
              className={classes.rowDetailsBox}
              sx={{
                flexDirection: 'column',
                gap: '10px',
              }}
            ></Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const ClientsTable = ({
  rows,
  classes,
  t,
  handleCheckOneClient,
  clientsSelected,
  handleSelectAllClients,
  selectedAll,
  setUpdate,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(generalsEnums.zeroNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(generalsEnums.TableRowsPerPage.ten);

  const language = localStorage.getItem('language');
  const mediaQuery1200 = useMediaQuery('(max-width:1200px)');

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickName = (event, row, state) => {
    event.preventDefault();
    const combinedObject = Object.assign({}, row.client, row.creditInfo);
    dispatch(setClientInfoModalState(true));
    dispatch(setClientInfo(combinedObject));
    dispatch(setClientInfoOnlyView(state));
    dispatch(setUserIdAddress(row._id));
  };

  const handleClickEmail = (event, email) => {
    event.preventDefault();
    window.open(`mailto:${email}`);
  };

  const handleClickOrders = (event) => {
    event.preventDefault();
    navigate('/ts-admin/orders/view');
  };

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        style={{
          width: '100%',
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '5px',
          marginTop: '30px',
        }}
      >
        <Table>
          <Box
            component="div"
            className={classes.AccordionSummaryBox}
            sx={{
              width: '100%',
              justifyContent: mediaQuery1200 ? 'flex-start' : 'space-between',
              alignItems: 'center',
              padding: '0px',
              gap: '1px',
              padding: '10px 0px',
            }}
          >
            <Box
              component="div"
              className={classes.rowBox}
              sx={{
                width: '3px',
              }}
            />

            <Checkbox
              color="secondary"
              onChange={handleSelectAllClients}
              checked={selectedAll}
              sx={{
                marginLeft: '55px',
              }}
            />

            <Box
              component="div"
              className={classes.rowBox}
              sx={{
                width: '200px',
              }}
            >
              <Font
                text={t('admin.ClientsView.tableHeaders.name')}
                color={colors.TSADMIN_BLUE}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Box>

            <Box
              component="div"
              className={classes.rowBox}
              sx={{
                width: '250px',
              }}
            >
              <Font
                text={t('admin.ClientsView.tableHeaders.email')}
                color={colors.TSADMIN_BLUE}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Box>

            <Box
              component="div"
              className={classes.rowBox}
              sx={{
                width: '100px',
              }}
            >
              <Font
                text={t('admin.ClientsView.tableHeaders.phone')}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Box>

            <Box
              component="div"
              className={classes.rowBox}
              sx={{
                width: '150px',
              }}
            >
              <Font
                text={t('admin.ClientsView.tableHeaders.group')}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Box>

            <Box
              component="div"
              className={classes.rowBox}
              sx={{
                width: '75px',
              }}
            >
              <Font
                text={t('admin.ClientsView.tableHeaders.orders')}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Box>

            <Box
              component="div"
              className={classes.rowBox}
              sx={{
                width: '120px',
              }}
            >
              <Font
                text={t('admin.ClientsView.tableHeaders.since')}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Box>

            <Box
              component="div"
              className={classes.rowBox}
              sx={{
                width: '40px',
              }}
            />
          </Box>

          <TableBody
            style={{
              width: '100%',
              boxShadow: 'none',
            }}
          >
            {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
              (row, idx) => (
                <ClientAccordion
                  key={idx}
                  row={row}
                  classes={classes}
                  handleCheckOneClient={handleCheckOneClient}
                  language={language}
                  clientsSelected={clientsSelected}
                  handleClickName={handleClickName}
                  handleClickEmail={handleClickEmail}
                  setUpdate={setUpdate}
                  mediaQuery1200={mediaQuery1200}
                  t={t}
                  handleClickOrders={handleClickOrders}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          marginTop: '30px',
        }}
      >
        <TablePagination
          component="div"
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: t('admin.AdminProducts.table-pagination-label-rows-per-page-all'), value: -1 },
          ]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage={
            <Box component="div" style={{ marginTop: '15px' }}>
              {t('admin.ClientsView.tableFooter')}
            </Box>
          }
          labelDisplayedRows={({ from, to, count }) => (
            <Box component="div" style={{ marginTop: '15px' }}>
              {`${from}-${to} ${t('admin.AdminProducts.table-pagination-label-rows-per-page-of')} ${count}`}
            </Box>
          )}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default ClientsTable;
