import { makeStyles } from '@mui/styles';

import * as colors from '../../../../assets/GlobalColors';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'auto',
    backgroundColor: colors.TSADMIN_BG,
    marginBottom: '50px',
  },
  container: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '20px',
    gap: '20px',
  },
  header: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.WHITE,
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  headerContent: {
    width: '95%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.WHITE,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  formContainer: {
    width: '100%',
    height: 'auto',
    gap: '20px',
  },
  formItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 'auto',
    height: 'auto',
    gap: '10px',
  },
  formContent: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '10px',
  },
  formLeftContainer: {
    height: 'auto',
    borderRadius: '12px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.GRAY_214_1}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '25px',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  codesContainer: {
    width: '90%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '5px',
  },
  codeItem: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    border: `1px solid ${colors.GRAY_214_1}`,
    padding: '10px',
    overflow: 'hidden',
  },
  imageContainer: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  imageContainerHeader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  FileUploader: {
    width: '480px',
    height: '88px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    cursor: 'pointer',
  },
  image: {
    width: '480px',
    height: 'auto',
    borderRadius: '6px',
  },
  DeleteIcon: {
    width: '20px',
    height: '20px',
    color: colors.TSADMIN_RED_DELETE,
  },
  DeleteBadgeIcon: {
    width: '15px',
    height: '15px',
    color: colors.TSADMIN_RED_DELETE,
  },
  variantsBox: {
    height: 'auto',
    width: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '20px',
  },
  variantsEmptyBox: {
    height: 'auto',
    width: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '10px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.WHITE}`,
  },
  addVariantButton: {
    width: 200,
    height: 40,
    borderRadius: '8px',
    backgroundColor: colors.WHITE,
    border: `2px solid ${colors.GREEN_MAIN}`,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',

    '&:hover': {
      backgroundColor: colors.WHITE,
      border: `2px solid ${colors.GREEN_MAIN}`,
      boxShadow: 'none',
      opacity: 0.8,
    },
  },
  AddIcon: {
    color: colors.BLACK_06,
  },
  CurrencyTextField: {
    borderRadius: '5px',
    width: '480px',
    height: '60px',
    backgroundColor: colors.GRAY_BG_04,
  },
  variantsHeader: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editVariantButton: {
    width: 220,
    height: 40,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.WHITE}`,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.WHITE,
      border: `1px solid ${colors.WHITE}`,
      boxShadow: 'none',
      opacity: 0.8,
    },
  },
  logisticBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  logisticHeaderBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px',
  },
});
