import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '12px',
  },
  CarouselBox: {
    width: 550,
    minWidth: 500,
    height: 'auto',
  },
  productDetailContainer: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: colors.WHITE,
    gap: '8px',
  },
  productNameBox: {
    width: '100%',
    borderBottom: `1px solid ${colors.BLACK}`,
    paddingBottom: '5px',
    textAlign: 'left',
  },
  productCodeBox: {
    width: '100%',
    textAlign: 'left',
  },
  productPriceBox: {
    width: '100%',
    textAlign: 'left',
  },
  productVariantsBox: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '5px',
  },
  FormControl: {
    width: 200,
  },
  productZipCodeBox: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '25px',
  },
  addToCartButton: {
    width: 250,
    height: 40,
    borderRadius: '6px',
    border: `1px solid ${colors.GREEN_MAIN}`,
    backgroundColor: colors.GREEN_MAIN,
    display: 'flex',
    gap: '10px',
    padding: '10px',

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      opacity: '0.8',
    },
  },
  textBox: {
    width: 200,
    textAlign: 'left',
  },
  addToCartIcon: {
    color: colors.BLACK,
  },
  productDescriptionBox: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  isAvailableChip: {
    width: 100,
    height: 40,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  isNotAvailableChip: {
    width: 100,
    height: 40,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
