import React from 'react';
import { Image } from 'react-bootstrap';
import { Box } from '@mui/material';

import NoImageItem from '../../../../assets/Images/NoImageItem.svg';
import generalsEnums from '../../../../utils/Generals';

const ItemImage = ({ image, name, size, handleClick }) => {
  if (image !== generalsEnums.emptyString) {
    return (
      <Image
        src={image}
        alt={name}
        fluid={true}
        onClick={handleClick}
        style={{
          width: size,
          height: size,
          cursor: handleClick ? 'pointer' : 'default',
        }}
      />
    );
  }

  return (
    <Image
      src={NoImageItem}
      fluid={true}
      alt={generalsEnums.itemNoImageAlt}
      onClick={handleClick}
      style={{
        width: size,
        height: size,
        cursor: handleClick ? 'pointer' : 'default',
      }}
    />
  );
};

export default ItemImage;
