import { makeStyles } from '@mui/styles';

import * as colors from '../../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  headerRoot: {
    width: '100%',
    height: 'auto',
    backgroundColor: colors.WHITE,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `2px solid ${colors.GRAY_229_1}`,
  },
  header: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: colors.WHITE,
  },
  headerTitle: {
    color: colors.BLACK_1,
    fontSize: '20px',
    lineHeight: '24px',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    height: '100%',
    gap: '10px',
  },
  closeButton: {
    color: colors.BLACK_1,
  },
  filterRoot: {
    width: '100%',
    height: '50px',
    borderBottom: `2px solid ${colors.GRAY_229_1}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterBox: {
    width: '95%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  TableListProducts: {
    width: '95%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  headerTableCell: {
    color: colors.BLACK_06,
    fontSize: '12px',
    lineHeight: '12px',
  },
  text: {
    color: colors.BLACK_1,
    fontSize: '14px',
    lineHeight: '20px',
  },
  image: {
    width: '45px',
    height: '45px',
  },
});
