import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, TextField, IconButton } from '@mui/material';

import Icons from '../Icons/Icons';
import generalsEnums from '../../../../utils/Generals';
import * as colors from '../../../../assets/GlobalColors';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.BORDER_CCC,
      },
      '&:hover fieldset': {
        borderColor: colors.GREEN_MAIN,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.GREEN_MAIN,
      },
    },
    '& .MuiInputBase-input': {
      color: colors.TEXT_GRAY_666,
      '&:focus': {
        color: colors.BLACK,
      },
      '&[disabled]': {
        color: colors.BORDER_CCC,
      },
    },
    '& .MuiInputBase-root': {
      backgroundColor: colors.WHITE,
    },
  },
});

const InputAttom = ({ isSearcher, value, setValue, handleAction, isButtonDisabled, placeholder }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (isButtonDisabled === generalsEnums.falseBoolean) handleAction();
    }
  };

  return (
    <Box
      component={generalsEnums.BoxDiv}
      display="flex"
      alignItems="center"
      sx={{
        height: 'auto',
      }}
    >
      <TextField
        className={classes.root}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        onClick={handleAction}
        placeholder={placeholder}
        fullWidth
        variant="outlined"
        size="small"
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: isSearcher ? '8px 0 0 8px' : '8px',
          },
        }}
      />

      {isSearcher ? (
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            height: '100%',
            backgroundColor: colors.GREEN_MAIN,
            borderRadius: '0px 8px 8px 0px',
          }}
        >
          <IconButton onClick={handleAction} disabled={isButtonDisabled}>
            <Icons kind={generalsEnums.icons.kind.Search} c={null} w={'24px'} h={'24px'} />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default InputAttom;
