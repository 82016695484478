export  const brands = 
[
    {
      value:"FT",label:"FIAT"
    },
    {
      value:"YX",label:"INTELLA"
    },
    {
      value:"DA",label:"DALIAN"
    },
    {
      value:"EW",label:"EPW"
    },
    {
      value:"EX",label:"EP FORKLIFT"
    },
    {
      value:"GH",label:"HI-GEAR"
    },
    {
      value:"GZ",label:"G POWER"
    },
    {
      value:"KK",label:"KARCHER"
    },
    {
      value:"XN",label:"NEXEN"
    },
    {
      value:"XT",label:"TFN"
    },
    {
      value:"XX",label:"MAXIMAL"
    },
    {
      value:"ZG",label:"HANGCHA"
    },
    {
      value:"ZI",label:"BAOLI"
    },
    {
      value:"OM",label:"OMEGA"
    },
    {
      value:"KW",label:"KAWASAKI"
    },
    {
      value:"VD",label:"BANDO"
    },
    {
      value:"ED",label:"ERGODYNE"
    },
    {
      value:"XY",label:"CARLISLE"
    },
    {
      value:"GY",label:"GOODYEAR"
    },
    {
      value:"JN",label:"JASON"
    },
    {
      value:"OB",label:"OPTIBELT"
    },
    {
      value:"XV",label:"HAVIS SHIELD"
    },
    {
      value:"XZ",label:"NATIONAL SIGNAL"
    },
    {
      value:"YG",label:"SOUNDOFF"
    },
    {
      value:"YJ",label:"UNITY"
    },
    {
      value:"WF",label:"WHELEN"
    },
    {
      value:"XI",label:"MAXXIMA"
    },
    {
      value:"VZ",label:"TRAFCON"
    },
    {
      value:"VH",label:"HOIST"
    },
    {
      value:"AB",label:"AC DELCO FILTERS"
    },
    {
      value:"AC",label:"ALLIS CHALMERS/TUSK"
    },
    {
      value:"AD",label:"ADVANCE"
    },
    {
      value:"AE",label:"ATLET"
    },
    {
      value:"AF",label:"ADVANCE FORKLIFT"
    },
    {
      value:"AG",label:"ALGAS"
    },
    {
      value:"AH",label:"ATHEY STREET SWEEPER"
    },
    {
      value:"AK",label:"AMETEK LAMB MOTORS"
    },
    {
      value:"AL",label:"ALBRIGHT"
    },
    {
      value:"AM",label:"AMERICAN LINCOLN"
    },
    {
      value:"AN",label:"ANDERSON"
    },
    {
      value:"AO",label:"AUTOLITE"
    },
    {
      value:"AR",label:"ARMSTRONG"
    },
    {
      value:"AS",label:"ATLAS-OLD"
    },
    {
      value:"AT",label:"ATLAS"
    },
    {
      value:"AU",label:"AUSA"
    },
    {
      value:"AV",label:"ADVANCED DC MOTORS"
    },
    {
      value:"BB",label:"BCA"
    },
    {
      value:"BC",label:"BOBCAT"
    },
    {
      value:"BD",label:"BOWER"
    },
    {
      value:"BE",label:"BETCO"
    },
    {
      value:"BF",label:"BORG WARNER"
    },
    {
      value:"BG",label:"BLUE GIANT"
    },
    {
      value:"BH",label:"BRAD HARRIS"
    },
    {
      value:"BI",label:"BENDI"
    },
    {
      value:"BJ",label:"BIG JOE"
    },
    {
      value:"BK",label:"BAKER"
    },
    {
      value:"BL",label:"BLASTRAC"
    },
    {
      value:"BM",label:"BEAM"
    },
    {
      value:"BN",label:"BALKANCAR"
    },
    {
      value:"BO",label:"BOMAN"
    },
    {
      value:"BP",label:"BRIGGS"
    },
    {
      value:"BQ",label:"BOSCH"
    },
    {
      value:"BR",label:"BARRETT"
    },
    {
      value:"BS",label:"BISHAMON"
    },
    {
      value:"BT",label:"BT"
    },
    {
      value:"BU",label:"BREUER TORNADO"
    },
    {
      value:"BV",label:"BARKO"
    },
    {
      value:"BW",label:"BALDWIN"
    },
    {
      value:"BX",label:"BENDIX"
    },
    {
      value:"BZ",label:"BOLZONI"
    },
    {
      value:"CA",label:"CASCADE"
    },
    {
      value:"CB",label:"COMBI"
    },
    {
      value:"CC",label:"CLUB CAR"
    },
    {
      value:"CD",label:"CARDINAL"
    },
    {
      value:"CE",label:"CENTURY"
    },
    {
      value:"CF",label:"CABELFORM"
    },
    {
      value:"CG",label:"CONDOR / TIME MFG"
    },
    {
      value:"CH",label:"CHAMP / LUBERFINER"
    },
    {
      value:"CI",label:"C-TECH"
    },
    {
      value:"CJ",label:"CART-PARTS "
    },
    {
      value:"CK",label:"CLARKE SWEEPERS"
    },
    {
      value:"CL",label:"CLARK"
    },
    {
      value:"CM",label:"CUMMINS"
    },
    {
      value:"CN",label:"CONTINENTAL"
    },
    {
      value:"CO",label:"COLUMBIA"
    },
    {
      value:"CP",label:"CHINA PALLET TRUCK"
    },
    {
      value:"CQ",label:"CAT CONSTRUCTION"
    },
    {
      value:"CR",label:"CROWN"
    },
    {
      value:"CS",label:"CASE"
    },
    {
      value:"CT",label:"CATERPILLAR/TOWMOTOR"
    },
    {
      value:"CU",label:"CUSHMAN"
    },
    {
      value:"CV",label:"CALAVAR"
    },
    {
      value:"CW",label:"CC POWER (DDC CONVERTERS)"
    },
    {
      value:"CX",label:"CASTEX"
    },
    {
      value:"CY",label:"CHRYSLER"
    },
    {
      value:"CZ",label:"CHAMPION"
    },
    {
      value:"DN",label:"DONALDSON"
    },
    {
      value:"DP",label:"DYNA PAC"
    },
    {
      value:"DR",label:"DELCO-REMY"
    },
    {
      value:"DS",label:"DOCKSTOCKER"
    },
    {
      value:"DT",label:"DITCH WITCH"
    },
    {
      value:"DU",label:"DEUTZ"
    },
    {
      value:"DV",label:"DAVIS"
    },
    {
      value:"DW",label:"DAEWOO"
    },
    {
      value:"DX",label:"DREXEL"
    },
    {
      value:"DY",label:"DYNATEX"
    },
    {
      value:"EA",label:"EATON"
    },
    {
      value:"EC",label:"ECOA"
    },
    {
      value:"EE",label:"ECCO"
    },
    {
      value:"EF",label:"EFFER CRANES"
    },
    {
      value:"EG",label:"EAGLEMAN"
    },
    {
      value:"EH",label:"ELLIOT HI-REACH"
    },
    {
      value:"EJ",label:"ESCO"
    },
    {
      value:"EK",label:"ETC HENDERSON"
    },
    {
      value:"EL",label:"ELECTRONICS (SMH)"
    },
    {
      value:"EM",label:"EIMCO"
    },
    {
      value:"EN",label:"ENGINE PARTS "
    },
    {
      value:"EO",label:"ECOLIFT"
    },
    {
      value:"EP",label:"ELWELL-PARKER"
    },
    {
      value:"ES",label:"ELGIN SWEEPER"
    },
    {
      value:"ET",label:"E-PARTS"
    },
    {
      value:"EU",label:"EUROLIFTER"
    },
    {
      value:"EZ",label:"E-Z-GO"
    },
    {
      value:"FA",label:"FAG"
    },
    {
      value:"FB",label:"FABTEC/HYSTER"
    },
    {
      value:"FC",label:"FACTORY CAT"
    },
    {
      value:"FD",label:"FORD"
    },
    {
      value:"FE",label:"FASSE CRANES"
    },
    {
      value:"FG",label:"FLEETGUARD"
    },
    {
      value:"FH",label:"FAIRCHILD INTERNATIONAL"
    },
    {
      value:"FK",label:"FRANKLIN"
    },
    {
      value:"FL",label:"FLIGHT SYSTEMS"
    },
    {
      value:"FM",label:"FMC"
    },
    {
      value:"FP",label:"FLOPAC"
    },
    {
      value:"FR",label:"FRAM"
    },
    {
      value:"FW",label:"FENWICK"
    },
    {
      value:"FZ",label:"FERRAZ"
    },
    {
      value:"GA",label:"GARRETSON"
    },
    {
      value:"GB",label:"GEHL"
    },
    {
      value:"GC",label:"GOLF CART"
    },
    {
      value:"GD",label:"GRADALL"
    },
    {
      value:"GE",label:"GENERAL ELECTRIC"
    },
    {
      value:"GJ",label:"GO-JO"
    },
    {
      value:"GL",label:"GROVE / MANLIFT"
    },
    {
      value:"GM",label:"GENERAL MOTORS"
    },
    {
      value:"GN",label:"GENIE"
    },
    {
      value:"GO",label:"GRACO"
    },
    {
      value:"GP",label:"GENERIC PARTS"
    },
    {
      value:"GR",label:"GRAY FORKLIFT JACK"
    },
    {
      value:"GW",label:"GATEWAY"
    },
    {
      value:"HA",label:"HALLA"
    },
    {
      value:"HB",label:"HONDA"
    },
    {
      value:"HC",label:"HITACHI"
    },
    {
      value:"HE",label:"HERCULES"
    },
    {
      value:"HF",label:"HOOF"
    },
    {
      value:"HG",label:"HOYT ELECTRIC"
    },
    {
      value:"HH",label:"HUL LIFT"
    },
    {
      value:"HI",label:"HELI"
    },
    {
      value:"HL",label:"HAULOTTE"
    },
    {
      value:"HM",label:"HELMAR"
    },
    {
      value:"HN",label:"HYUNDAI"
    },
    {
      value:"HQ",label:"HUSKY BRUTE"
    },
    {
      value:"HR",label:"HI RANGER"
    },
    {
      value:"HS",label:"HOOVER"
    },
    {
      value:"HT",label:"HUNTER LIFT"
    },
    {
      value:"HU",label:"HYDRAULIC UNIT REBILT PALLET TRUCK"
    },
    {
      value:"HV",label:"HAVILAND"
    },
    {
      value:"HX",label:"HYDRO-AX"
    },
    {
      value:"HY",label:"HYSTER"
    },
    {
      value:"HZ",label:"HIAB"
    },
    {
      value:"IA",label:"INA"
    },
    {
      value:"IB",label:"INTERNATIONAL HARVESTER"
    },
    {
      value:"ID",label:"INGERSOLL-RAND"
    },
    {
      value:"IE",label:"ISKRA"
    },
    {
      value:"IK",label:"IKO"
    },
    {
      value:"IMP",label:"IMPCO"
    },
    {
      value:"IN",label:"INTRUPA"
    },
    {
      value:"IR",label:"INTERNATIONAL RECTIFIER"
    },
    {
      value:"IS",label:"ISUZU"
    },
    {
      value:"IT",label:"INTERTHOR"
    },
    {
      value:"JA",label:"JACOBSON"
    },
    {
      value:"JC",label:"JCB"
    },
    {
      value:"JD",label:"JOHN DEERE"
    },
    {
      value:"JL",label:"JLG"
    },
    {
      value:"JS",label:"J&S CARBURETION"
    },
    {
      value:"JT",label:"JET"
    },
    {
      value:"JU",label:"JUNGHEINRICH"
    },
    {
      value:"JY",label:"JOY MANUFACTURING"
    },
    {
      value:"KA",label:"KARRIOR"
    },
    {
      value:"KB",label:"KUBOTA"
    },
    {
      value:"KC",label:"KHD"
    },
    {
      value:"KD",label:"KAYABA"
    },
    {
      value:"KF",label:"KOEHRING"
    },
    {
      value:"KG",label:"KATO EXCAVATORS"
    },
    {
      value:"KH",label:"KOHLER"
    },
    {
      value:"KI",label:"KING"
    },
    {
      value:"KL",label:"KOBELCO"
    },
    {
      value:"KM",label:"KALMAR LMV"
    },
    {
      value:"KN",label:"KENHAR FORKS"
    },
    {
      value:"KO",label:"KOMATSU"
    },
    {
      value:"KT",label:"KENT"
    },
    {
      value:"KY",label:"KOYO"
    },
    {
      value:"KZ",label:"KALAMAZOO"
    },
    {
      value:"LA",label:"LANSING"
    },
    {
      value:"LB",label:"LANCER BOSS"
    },
    {
      value:"LC",label:"LUCAS"
    },
    {
      value:"LE",label:"LEGEND"
    },
    {
      value:"LF",label:"LAFIS"
    },
    {
      value:"LG",label:"LONG AIRDOX"
    },
    {
      value:"LI",label:"LINDE"
    },
    {
      value:"LJ",label:"LO-JO"
    },
    {
      value:"LK",label:"LIFT-KING"
    },
    {
      value:"LL",label:"LIFT-A-LOFT"
    },
    {
      value:"LM",label:"LIEBHERR"
    },
    {
      value:"LN",label:"LANSING BAGNALL"
    },
    {
      value:"LO",label:"LO-LIFT"
    },
    {
      value:"LP",label:"LPM"
    },
    {
      value:"LQ",label:"LINK BELT"
    },
    {
      value:"LR",label:"LIFT RITE"
    },
    {
      value:"LS",label:"LESTER"
    },
    {
      value:"LU",label:"LULL"
    },
    {
      value:"LV",label:"LOG HOG"
    },
    {
      value:"LW",label:"LAWLOR"
    },
    {
      value:"LX",label:"LORAIN"
    },
    {
      value:"LZ",label:"LESTER PETTER"
    },
    {
      value:"MA",label:"MALISH"
    },
    {
      value:"MB",label:"MITSUBISHI"
    },
    {
      value:"MD",label:"MOTREC PBC"
    },
    {
      value:"ME",label:"MERCURY-PETTIBONE"
    },
    {
      value:"MF",label:"MASSEY FERGUSON"
    },
    {
      value:"MG",label:"MAGNUM"
    },
    {
      value:"MH",label:"MINUTEMAN SWEEPER"
    },
    {
      value:"MJ",label:"MACHINE ELECTRIC"
    },
    {
      value:"MK",label:"MARKLIFT"
    },
    {
      value:"ML",label:"MOBILE"
    },
    {
      value:"MM",label:"MAGNETI MARELLI"
    },
    {
      value:"MN",label:"MANITOU"
    },
    {
      value:"MO",label:"MOTORCRAFT"
    },
    {
      value:"MP",label:"MICO"
    },
    {
      value:"MQ",label:"MUSTANG"
    },
    {
      value:"MR",label:"MARCO TIGER"
    },
    {
      value:"MS",label:"MICRO SWITCH"
    },
    {
      value:"MT",label:"MULTITON"
    },
    {
      value:"MU",label:"MULTI-CLEAN"
    },
    {
      value:"MW",label:"MITE-E-LIFT"
    },
    {
      value:"MX",label:"MELEX"
    },
    {
      value:"MY",label:"MEC (MAYVILLE ENG)"
    },
    {
      value:"MZ",label:"MAZDA"
    },
    {
      value:"NA",label:"NAMCO"
    },
    {
      value:"NB",label:"NOBLES"
    },
    {
      value:"NC",label:"NIKKO"
    },
    {
      value:"ND",label:"NIPPONDENSO"
    },
    {
      value:"NG",label:"NGK"
    },
    {
      value:"NH",label:"NEW HOLLAND"
    },
    {
      value:"NI",label:"NISSAN"
    },
    {
      value:"NN",label:"NTN"
    },
    {
      value:"NO",label:"NOLAND"
    },
    {
      value:"NP",label:"NAPA"
    },
    {
      value:"NR",label:"NORDSKOG"
    },
    {
      value:"NT",label:"NATIONAL SUPER SERVICE"
    },
    {
      value:"NU",label:"NUTTING"
    },
    {
      value:"NV",label:"NIVEL"
    },
    {
      value:"NY",label:"NYK"
    },
    {
      value:"OE",label:"OEM CONTROLS"
    },
    {
      value:"OH",label:"OHIO MOTOR"
    },
    {
      value:"ON",label:"ONAN"
    },
    {
      value:"OT",label:"OTIS"
    },
    {
      value:"PA",label:"PARGO"
    },
    {
      value:"PB",label:"POWER BOSS"
    },
    {
      value:"PC",label:"PRAMAC"
    },
    {
      value:"PD",label:"PULLMAN HOLT"
    },
    {
      value:"PE",label:"PREMIER PALLET TRUCK"
    },
    {
      value:"PG",label:"PEUGEOT"
    },
    {
      value:"PH",label:"P&H CRANES"
    },
    {
      value:"PI",label:"PIONEER ECLIPSE"
    },
    {
      value:"PJ",label:"PETTIBONE"
    },
    {
      value:"PK",label:"PERKINS"
    },
    {
      value:"PL",label:"PRESTOLITE"
    },
    {
      value:"PM",label:"PRIME MOVER"
    },
    {
      value:"PO",label:"PRECO"
    },
    {
      value:"PQ",label:"PQ CONTROLS"
    },
    {
      value:"PR",label:"PARKER HOSE/FITTING"
    },
    {
      value:"PS",label:"PALLETMASTER"
    },
    {
      value:"PT",label:"PALLET MULE"
    },
    {
      value:"PU",label:"PUROLATOR"
    },
    {
      value:"PX",label:"PERTRONIX"
    },
    {
      value:"PY",label:"PENRAY"
    },
    {
      value:"PZ",label:"PRENTICE"
    },
    {
      value:"RA",label:"RAYMOND"
    },
    {
      value:"RC",label:"REACH ALL"
    },
    {
      value:"RE",label:"REGO"
    },
    {
      value:"RL",label:"ROL-LIFT"
    },
    {
      value:"RS",label:"REYNOLDS SCRAPER"
    },
    {
      value:"SA",label:"SKY TRAK"
    },
    {
      value:"SB",label:"STEINBOCK"
    },
    {
      value:"SC",label:"SCHRECK"
    },
    {
      value:"SD",label:"SISU"
    },
    {
      value:"SE",label:"SVE"
    },
    {
      value:"SF",label:"SKF"
    },
    {
      value:"SG",label:"SAMSUNG"
    },
    {
      value:"SH",label:"STAR-HYDRODYNE"
    },
    {
      value:"SI",label:"SIMON"
    },
    {
      value:"SJ",label:"SKYJACK"
    },
    {
      value:"SK",label:"SELLICK"
    },
    {
      value:"SL",label:"STILL"
    },
    {
      value:"SM",label:"SMV"
    },
    {
      value:"SN",label:"SNORKEL"
    },
    {
      value:"SO",label:"SRE CONTROLS"
    },
    {
      value:"SQ",label:"SQUARE D"
    },
    {
      value:"SR",label:"SUPERIOR SIGNALS"
    },
    {
      value:"SS",label:"SUPER SIGHT"
    },
    {
      value:"STA",label:"SAFE-T-ALERT"
    },
    {
      value:"STC",label:"SAFE-T-CONNECT"
    },
    {
      value:"STL",label:"SAFE-T-LOCK"
    },
    {
      value:"SU",label:"SUPERIOR TIRES & WHEELS"
    },
    {
      value:"SV",label:"SEVCON"
    },
    {
      value:"SW",label:"SKY WORHER"
    },
    {
      value:"SX",label:"SNYFLEX HOSE"
    },
    {
      value:"SY",label:"SMH"
    },
    {
      value:"SZ",label:"SIGNALIER SKYWITCH"
    },
    {
      value:"TA",label:"TAYLOR DUNN"
    },
    {
      value:"TB",label:"TIPCON"
    },
    {
      value:"TC",label:"TCM"
    },
    {
      value:"TE",label:"TELEDYNE PRINCETON"
    },
    {
      value:"TF",label:"TAILIFT"
    },
    {
      value:"TG",label:"TORRINGTON"
    },
    {
      value:"TH",label:"THOMBERT TIRES AND WHEELS"
    },
    {
      value:"TI",label:"TASKI"
    },
    {
      value:"TJ",label:"TERMINAL SUPPLY"
    },
    {
      value:"TK",label:"TAKEUCHI"
    },
    {
      value:"TL",label:"TAYLOR"
    },
    {
      value:"TM",label:"TIMKEN"
    },
    {
      value:"TN",label:"TENNANT"
    },
    {
      value:"TO",label:"TALLY-HO"
    },
    {
      value:"TP",label:"TIMBERJACK"
    },
    {
      value:"TQ",label:"TELEMECANIQUE"
    },
    {
      value:"TR",label:"TORO"
    },
    {
      value:"TS",label:"TOTALSOURCE ACTIVEWEAR"
    },
    {
      value:"TU",label:"TUG"
    },
    {
      value:"TW",label:"TECUMSEH"
    },
    {
      value:"TX",label:"TEREX"
    },
    {
      value:"TY",label:"TOYOTA"
    },
    {
      value:"TZ",label:"TRACKMOBILE"
    },
    {
      value:"UI",label:"UNIPOINT"
    },
    {
      value:"UL",label:"ULTRA PALLET JACKS & SWEEPERS"
    },
    {
      value:"UN",label:"UNIROYAL (BELTS)"
    },
    {
      value:"UP",label:"UPRIGHT"
    },
    {
      value:"US",label:"US ELECTRICAR"
    },
    {
      value:"VA",label:"VALEO"
    },
    {
      value:"VI",label:"VICKERS"
    },
    {
      value:"VJ",label:"VALUE JACK"
    },
    {
      value:"VM",label:"VERMEER"
    },
    {
      value:"VO",label:"VOLVO"
    },
    {
      value:"VW",label:"VOLKSWAGEN"
    },
    {
      value:"WA",label:"WACKER"
    },
    {
      value:"WC",label:"WESCO"
    },
    {
      value:"WD",label:"WINDSOR INDUSTRIES"
    },
    {
      value:"WE",label:"WESTINGHOUSE"
    },
    {
      value:"WG",label:"WAGNER"
    },
    {
      value:"WH",label:"WHITE RODGERS"
    },
    {
      value:"WI",label:"WISCONSIN"
    },
    {
      value:"WK",label:"WAUKESHA"
    },
    {
      value:"WL",label:"WILSON"
    },
    {
      value:"WM",label:"WHITE MOBILIFT"
    },
    {
      value:"WN",label:"WESTLING"
    },
    {
      value:"WO",label:"WORLD-LIFT"
    },
    {
      value:"WR",label:"WRANGLER"
    },
    {
      value:"WS",label:"WISE"
    },
    {
      value:"WT",label:"WEATHERHEAD"
    },
    {
      value:"WW",label:"WIRE WORKS"
    },
    {
      value:"WX",label:"WIX / AIR REFINER"
    },
    {
      value:"YM",label:"YAMAHA"
    },
    {
      value:"YN",label:"YANG"
    },
    {
      value:"YT",label:"YALE"
    },
    {
      value:"YU",label:"YUTANI"
    },
    {
      value:"ZH",label:"HYTSU"
    },
    {
      value:"ZC",label:"SKY CLIMBER"
    },
    {
      value:"ZF",label:"ZF TRANSMISSION PARTS"
    },
    {
      value:"ZN",label:"ZENITH"
    },
    {
      value:"ZZ",label:"SOLID DEAL TIRES"
    },
    {
      value:"DB",label:"DISCOVER BATTERY"
    },
    {
      value:"HJ",label:"HENDLEY"
    },
    {
      value:"ZX",label:"MINNPAR"
    },
    {
      value:"OW",label:"OTTAWA YARD MULE"
    },
    {
      value:"GG",label:"GRAMMER"
    },
    {
      value:"FY",label:"FLOW EZY"
    },
    {
      value:"IL",label:"INTERCOASTAL MFG"
    },
    {
      value:"ZM",label:"Moffett"
    },
    {
      value:"ZU",label:"SUPERIOR UNLIMITED"
    },
    {
      value:"ZO",label:"H.O. BOSTROM"
    },
    {
      value:"JX",label:"HALDEX"
    },
    {
      value:"AZ",label:"ALLIANZ"
    },
    {
      value:"BA",label:"BRUDI"
    },
    {
      value:"DH",label:"DANAHER MOTION"
    },
    {
      value:"JH",label:"JOHNSTON"
    },
    {
      value:"KR",label:"KRALINATOR"
    },
    {
      value:"NW",label:"NIGHT HAWK"
    },
    {
      value:"OC",label:"BROCE"
    },
    {
      value:"RI",label:"RIGHTLINE"
    },
    {
      value:"RM",label:"REMA"
    },
    {
      value:"RO",label:"ROCKWELL"
    },
    {
      value:"RP",label:"ROSCO"
    },
    {
      value:"ST",label:"STRATO-LIFT"
    },
    {
      value:"YC",label:"TYMCO"
    },
    {
      value:"YR",label:"LAY-MOR"
    },
    {
      value:"ZA",label:"ZAPI CONTROLS"
    },
    {
      value:"ZJ",label:"BIL JAX"
    },
    {
      value:"ZT",label:"TERRAMITE"
    },
    {
      value:"ZW",label:"WALDON"
    },
    {
      value:"ZY",label:"CURTIS INSTRUMENT"
    },
    {
      value:"LY",label:"LIBERTY BRUSH"
    },
    {
      value:"XF",label:"FEELER"
    },
    {
      value:"XH",label:"M&H"
    },
    {
      value:"XS",label:"SAMUK"
    },
    {
      value:"MV",label:"MANN FILTERS"
    },
    {
      value:"PF",label:"PACIFIC FLOOR CARE"
    },
    {
      value:"QB",label:"QUICK CABLE"
    },
    {
      value:"RN",label:"RENAULT"
    },
    {
      value:"TD",label:"TOMAR"
    },
    {
      value:"UC",label:"UNITED CONTACTS"
    },
    {
      value:"UT",label:"UNITED TRACTOR"
    },
    {
      value:"UY",label:"UNITED ROTARY"
    },
    {
      value:"VT",label:"VACTOR"
    },
    {
      value:"WY",label:"WAYNE"
    },
    {
      value:"FF",label:"FAHEL"
    },
    {
      value:"XL",label:"CARLISLE TIRE"
    },
    {
      value:"VC",label:"VAC/ALL"
    },
    {
      value:"VR",label:"VICTOR"
    },
    {
      value:"YA",label:"YANMAR DIESEL"
    },
    {
      value:"ZE",label:"MASCO"
    },
    {
      value:"ZL",label:"CALLENGER"
    },
    {
      value:"XC",label:"XINCHAI"
    },
    {
      value:"ZD",label:"SHANGLI"
    },
    {
      value:"ZV",label:"STAM"
    },
    {
      value:"VE",label:"THERMOID"
    },
    {
      value:"WU",label:"BROWNING"
    },
    {
      value:"XA",label:"ABLE 2"
    },
    {
      value:"YE",label:"PETERSON"
    },
    {
      value:"YF",label:"STAR"
    },
    {
      value:"YK",label:"WANCO"
    },
    {
      value:"PV",label:"TAPE SWITCH"
    },
    {
      value:"YZ",label:"EDISON"
    },
    {
      value:"GK",label:"GATES"
    },
    {
      value:"DD",label:"DODGE"
    },
    {
      value:"WZ",label:"TB WOODS"
    },
    {
      value:"XB",label:"ARROW SAFETY DEVICES"
    },
    {
      value:"XD",label:"BACKTRACK"
    },
    {
      value:"XE",label:"BETTS"
    },
    {
      value:"XJ",label:"PSE / CODE 3"
    },
    {
      value:"XK",label:"FEDERAL SIGNAL"
    },
    {
      value:"XR",label:"GOLIGHT"
    },
    {
      value:"XU",label:"GROTE"
    },
    {
      value:"YB",label:"NORTH AMERICAN SIGNA"
    },
    {
      value:"YD",label:"NOVA"
    },
    {
      value:"YH",label:"TRUCK-LITE"
    },
    {
      value:"YP",label:"WELDON"
    },
    {
      value:"QK",label:"KIA"
    },
    {
      value:"QR",label:"MERCEDES"
    },
    {
      value:"QZ",label:"HATZ"
    },
    {
      value:"WQ",label:"WANFENG/BAYIANG"
    },
    {
      value:"YW",label:"MWM"
    },
    {
      value:"LD",label:"LANDINI"
    },
    {
      value:"MC",label:"MC CORMICK"
    },
    {
      value:"VN",label:"MAHINDRA"
    },
    {
      value:"QD",label:"DEUTZ FAHR"
    },
    {
      value:"QF",label:"FARMTRAC"
    },
    {
      value:"QL",label:"SONALIKA"
    },
    {
      value:"VB",label:"VALTRA"
    },
    {
      value:"VF",label:"FENDT"
    },
    {
      value:"AJ",label:"AISAN"
    },
    {
      value:"DL",label:"DELPHI"
    },
    {
      value:"EB",label:"CESAB"
    },
    {
      value:"IC",label:"MIC SA"
    },
    {
      value:"JG",label:"FUJI"
    },
    {
      value:"QX",label:"LOCTITE"
    },
    {
      value:"LT",label:"LIFT-TEK ELECAR"
    },
    {
      value:"NK",label:"NOBLELIFT"
    },
    {
      value:"PW",label:"STELLANA"
    },
    {
      value:"QE",label:"DESTA"
    },
    {
      value:"QN",label:"KNOTT"
    },
    {
      value:"QQ",label:"SCHALTBAU"
    },
    {
      value:"QS",label:"STABILUS"
    },
    {
      value:"RB",label:"BRIGADE"
    },
    {
      value:"RV",label:"REALS"
    },
    {
      value:"RX",label:"REXNORD"
    },
    {
      value:"SP",label:"SPE"
    },
    {
      value:"TT",label:"ATIB"
    },
    {
      value:"UF",label:"FLUKE"
    },
    {
      value:"VG",label:"SIGNET SYSTEMS"
    },
    {
      value:"VQ",label:"SCHÄFFER"
    },
    {
      value:"WB",label:"WABCO"
    },
    {
      value:"WJ",label:"WICKE"
    },
    {
      value:"WP",label:"WARNERELECTRIC"
    },
    {
      value:"WV",label:"WOODWARD"
    },
    {
      value:"YQ",label:"SCHABMULLER"
    },
    {
      value:"UQ",label:"SINOBOOM"
    },
    {
      value:"VX",label:"BRAVI"
    },
    {
      value:"QG",label:"LGMG"
    },
    {
      value:"QO",label:"ZOOMLION"
    },
    {
      value:"UM",label:"KIMBALL-MIDWEST"
    },
    {
      value:"OR",label:"CHAR-LYNN"
    },
    {
      value:"OF",label:"CHICAGO RAWHIDE"
    },
    {
      value:"IO",label:"DAYCO"
    },
    {
      value:"HOBBS",label:"HOBBS"
    },
    {
      value:"IV",label:"VDO"
    },
    {
      value:"GS",label:"SAUER-SUNSTRAND"
    },
    {
      value:"DC",label:"CLIMAX (KALMAR-UK)"
    },
    {
      value:"VS",label:"SALEV"
    },
    {
      value:"UH",label:"HURTH"
    },
    {
      value:"QJ",label:"HONEYWELL"
    },
    {
      value:"IF",label:"COLE HERSEE"
    },
    {
      value:"AQ",label:"KAUP"
    },
    {
      value:"ZK",label:"SPICER"
    },
    {
      value:"OD",label:"OMRON"
    },
    {
      value:"AY",label:"KOKONOE"
    },
    {
      value:"UO",label:"ALLISON"
    },
    {
      value:"ZB",label:"SIEMENS"
    },
    {
      value:"JQ",label:"REXROTH"
    },
    {
      value:"IH",label:"INT HARVESTER"
    },
    {
      value:"UE",label:"HARLAN"
    },
    {
      value:"RK",label:"TRACPIECES"
    },
    {
      value:"DK",label:"ELME"
    },
    {
      value:"IU",label:"UNIVERSAL"
    },
    {
      value:"RR",label:"CARRARO"
    },
    {
      value:"RF",label:"MERLO"
    },
    {
      value:"ZQ",label:"BAUMANN"
    },
    {
      value:"JJ",label:"JAMSONS"
    },
    {
      value:"JV",label:"TROMBETTA"
    },
    {
      value:"JI",label:"NIFTYLIFT"
    },
    {
      value:"KS",label:"STANLEY"
    },
    {
      value:"FN",label:"FANTUZZI"
    },
    {
      value:"IZ",label:"TYCO"
    },
    {
      value:"UR",label:"LONG REACH BRUDI"
    },
    {
      value:"RZ",label:"BEPCO"
    },
    {
      value:"FJ",label:"LUNA"
    },
    {
      value:"QI",label:"GM-PGL"
    },
    {
      value:"NS",label:"SCHMERSAL"
    },
    {
      value:"NM",label:"MP FILTRI"
    },
    {
      value:"JR",label:"RACOR"
    },
    {
      value:"IW",label:"WAGO"
    },
    {
      value:"AX",label:"AXLE TECH"
    },
    {
      value:"OJ",label:"CUTLER-HAMMER"
    },
    {
      value:"QC",label:"CAM"
    },
    {
      value:"DO",label:"TERBERG"
    },
    {
      value:"FI",label:"VANGENT"
    },
    {
      value:"JB",label:"BARKSDALE"
    },
    {
      value:"AA",label:"PARTSLIFT"
    },
    {
      value:"EY",label:"EUROYEN"
    },
    {
      value:"YS",label:"HYTSU"
    },
    {
      value:"NZ",label:"AUSCO"
    },
    {
      value:"GF",label:"GLEASON REEL"
    },
    {
      value:"KX",label:"TELEFLEX"
    },
    {
      value:"AI",label:"BUCCANEER"
    },
    {
      value:"OS",label:"DATCON"
    },
    {
      value:"QW",label:"WATERMAN-GOULD"
    },
    {
      value:"JF",label:"FAIRFIELD"
    },
    {
      value:"JE",label:"EBERHARD"
    },
    {
      value:"OG",label:"CARELIFT"
    },
    {
      value:"OY",label:"MAC"
    },
    {
      value:"GQ",label:"QUCK CHARGE"
    },
    {
      value:"DQ",label:"DELTA-Q"
    },
    {
      value:"OV",label:"DELTA POWER"
    },
    {
      value:"DI",label:"STINIS"
    },
    {
      value:"NL",label:"ALLEN-BRADLEY"
    },
    {
      value:"DG",label:"BULGA"
    },
    {
      value:"FO",label:"PPM"
    },
    {
      value:"JK",label:"HYDAC"
    },
    {
      value:"UA",label:"TEUPEN"
    },
    {
      value:"RH",label:"CHARLATTE"
    },
    {
      value:"NE",label:"ETA"
    },
    {
      value:"OK",label:"HYDRAFORCE"
    },
    {
      value:"OU",label:"SUN"
    },
    {
      value:"FV",label:"HANSELIFTER"
    },
    {
      value:"KQ",label:"SOUTHCO"
    },
    {
      value:"JM",label:"MOLEX"
    },
    {
      value:"II",label:"IDEC"
    },
    {
      value:"QM",label:"OMNI"
    },
    {
      value:"XQ",label:"BRODERSON CRANE"
    },
    {
      value:"QT",label:"TRAVERSE"
    },
    {
      value:"KV",label:"TIME MANUFACTURING"
    },
    {
      value:"UZ",label:"TIGER TRACTOR"
    },
    {
      value:"EI",label:"EXIDE"
    },
    {
      value:"OO",label:"OTTO"
    },
    {
      value:"JW",label:"SPEAKER"
    },
    {
      value:"NX",label:"ANPAT"
    },
    {
      value:"FU",label:"FURNAS"
    },
    {
      value:"FQ",label:"SANY"
    },
    {
      value:"OP",label:"SUPERTRAPP"
    },
    {
      value:"OX",label:"DETROIT"
    },
    {
      value:"TV",label:"TSS PRODUCT"
    },
    {
      value:"NJ",label:"HEYCO"
    },
    {
      value:"JO",label:"NASON"
    },
    {
      value:"KE",label:"KESSLER"
    },
    {
      value:"AP",label:"MANNS"
    },
    {
      value:"FX",label:"BELOTTI"
    },
    {
      value:"RT",label:"ROLOTEC"
    },
    {
      value:"ZP",label:"ZPMC"
    },
    {
      value:"GU",label:"LIUJONG"
    },
    {
      value:"DF",label:"NOELL"
    },
    {
      value:"OQ",label:"SYNCHRO-START"
    },
    {
      value:"HK",label:"HAMECH"
    },
    {
      value:"AW",label:"PACECO"
    },
    {
      value:"QV",label:"HUSQVARNA"
    },
    {
      value:"GV",label:"GOVERNMENT"
    },
    {
      value:"PN",label:"PANACEA"
    },
    {
      value:"FS",label:"CVS FERRARI"
    },
    {
      value:"FAC",label:"FIAT-ALLIS"
    },
    {
      value:"EQ",label:"SWE FRAME"
    },
    {
      value:"ER",label:"BBS"
    },
    {
      value:"BY",label:"BROMMA"
    },
    {
      value:"QH",label:"DEUTSCH"
    },
    {
      value:"VL",label:"DULEVO"
    },
    {
      value:"ZR",label:"ECONTROLS"
    },
    {
      value:"LH",label:"LIFT ALL"
    },
    {
      value:"QP",label:"TRIONIC"
    },
    {
      value:"DJ",label:"EUROGATE"
    },
    {
      value:"UV",label:"UTILEV"
    },
    {
      value:"DM",label:"HELID"
    },
    {
      value:"VP",label:"MVP"
    },
    {
      value:"KU",label:"WHITE ROLLER STATOR"
    },
    {
      value:"DE",label:"DEKA BATTERY"
    },
    {
      value:"IX",label:"INTERSTATE BATTERY"
    },
    {
      value:"YI",label:"HY-BRID LIFTS"
    },
    {
      value:"UK",label:"UNICARRIER"
    },
    {
      value:"DZ",label:"DONKEY"
    },
    {
      value:"EV",label:"EOSLIFT"
    },
    {
      value:"OI",label:"KIOTI"
    },
    {
      value:"XW",label:"MASTER CRAFT"
    },
    {
      value:"GI",label:"INTEGRATED HYDRAULICS"
    },
    {
      value:"JZ",label:"ZETTLER"
    },
    {
      value:"RD",label:"RAM MOUNTS"
    },
    {
      value:"GX",label:"GENERAC"
    },
    {
      value:"GT",label:"TE CONNECTIVITY"
    },
    {
      value:"KJ",label:"ACCES PLATFORM"
    },
    {
      value:"NF",label:"FULTON"
    },
    {
      value:"IJ",label:"DELTA TECH CONTROLS"
    },
    {
      value:"OL",label:"LEVITON"
    },
    {
      value:"IY",label:"WOODHEAD"
    },
    {
      value:"OZ",label:"ZINGA"
    },
    {
      value:"JP",label:"PASS & SEYMOUR"
    },
    {
      value:"KP",label:"SPEP"
    },
    {
      value:"NQ",label:"NATIONAL SEAL"
    },
    {
      value:"XM",label:"MARTIN CAB"
    },
    {
      value:"XG",label:"PACK MULE"
    },
    {
      value:"XO",label:"XTREME"
    },
    {
      value:"OA",label:"OHMITE"
    },
    {
      value:"RJ",label:"TROJAN"
    },
    {
      value:"UB",label:"LOAD LIFTER"
    },
    {
      value:"IG",label:"COMATROL"
    },
    {
      value:"UD",label:"DIECI"
    }
  ]