import React from 'react';
import { Box, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import generalsEnums from '../../../../utils/Generals';
import ItemImage from '../Atoms/ItemImage';
import Font from '../../Fonts/Font';
import CurrencyFont from '../../Fonts/CurrencyFont';
import Icons from '../Icons/Icons';
import * as colors from '../../../../assets/GlobalColors';

const ProductCart = ({ product, t, handleClickSubQuantity, handleClickAddQuantity, handleClickDeleteProduct }) => {
  const [name, setName] = React.useState(generalsEnums.emptyString);
  const [image, setImage] = React.useState(generalsEnums.emptyString);
  const [categories, setCategories] = React.useState(generalsEnums.emptyString);
  const [codes, setCodes] = React.useState(generalsEnums.emptyString);

  React.useEffect(() => {
    if (product.name) setName(product.name);

    if (product.categoryName) setCategories(`${product.categoryName} / ${product.subCategory} / ${product.label}`);

    if (Array.isArray(product.codes)) {
      setCodes(product.codes[0]);
    } else {
      setCodes(product.codes);
    }

    if (product.image !== generalsEnums.nullData) setImage(product.image);

    return () => {
      setName(generalsEnums.emptyString);
      setImage(generalsEnums.emptyString);
      setCodes(generalsEnums.emptyString);
      setCategories(generalsEnums.emptyString);
    };
  }, [product]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: '100%',
        height: 'auto',
        marginBottom: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <ItemImage image={image} name={name} size="76px" />

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Font
            text={name}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size12.size}
            lineHeight={generalsEnums.fontSizes.size12.lineHeight}
            isHead={true}
          />

          <CurrencyFont
            price={product.quantityPrice}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size12.size}
            lineHeight={generalsEnums.fontSizes.size12.lineHeight}
            isHead={true}
          />
        </Box>

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Font
            text={t('ProductCart.unitPrice')}
            color={colors.TEXT_GRAY_34}
            size={generalsEnums.fontSizes.size12.size}
            lineHeight={generalsEnums.fontSizes.size12.lineHeight}
            isHead={false}
          />

          <CurrencyFont
            price={product.price}
            color={colors.TEXT_GRAY_34}
            size={generalsEnums.fontSizes.size12.size}
            lineHeight={generalsEnums.fontSizes.size12.lineHeight}
            isHead={false}
          />
        </Box>

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Font
            text={categories}
            color={colors.TEXT_GRAY_34}
            size={generalsEnums.fontSizes.size10.size}
            lineHeight={generalsEnums.fontSizes.size10.lineHeight}
            isHead={false}
          />

          <Font
            text={codes}
            color={colors.TEXT_GRAY_34}
            size={generalsEnums.fontSizes.size10.size}
            lineHeight={generalsEnums.fontSizes.size10.lineHeight}
            isHead={false}
          />
        </Box>

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: 'auto',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '8px',
            }}
          >
            <Font
              text={t('Generals.quantity')}
              color={colors.TEXT_GRAY_34}
              size={generalsEnums.fontSizes.size14.size}
              lineHeight={generalsEnums.fontSizes.size14.lineHeight}
              isHead={false}
            />

            <IconButton
              onClick={(e) => {
                handleClickSubQuantity(e, product._id);
              }}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '4px',
                border: `1px solid ${colors.BLACK}`,
              }}
            >
              <RemoveIcon
                style={{
                  width: '20px',
                  height: '20px',
                  color: '#000',
                }}
              />
            </IconButton>

            <Font
              text={product.quantitySelected}
              color={colors.TEXT_GRAY_34}
              size={generalsEnums.fontSizes.size12.size}
              lineHeight={generalsEnums.fontSizes.size12.lineHeight}
              isHead={false}
              ml="0px"
            />

            <IconButton
              onClick={(e) => {
                handleClickAddQuantity(e, product._id);
              }}
              style={{
                width: '25px',
                height: '25px',
                borderRadius: '4px',
                border: `1px solid ${colors.BLACK}`,
              }}
            >
              <AddIcon
                style={{
                  width: '20px',
                  height: '20px',
                  color: '#000',
                }}
              />
            </IconButton>
          </Box>

          <IconButton
            onClick={(e) => {
              handleClickDeleteProduct(e, product._id);
            }}
          >
            <Icons kind={generalsEnums.icons.kind.Delete} c={null} w="24px" h="24px" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCart;
