import generalsEnums from './Generals';

export const getSize = (size) => {
  let fontSize = 0;
  let lineHeight = '0px';

  switch (size) {
    case generalsEnums.fontSizes.size10.size:
      fontSize = generalsEnums.fontSizes.size10.size;
      lineHeight = generalsEnums.fontSizes.size10.lineHeight;
      break;
    case generalsEnums.fontSizes.size12.size:
      fontSize = generalsEnums.fontSizes.size12.size;
      lineHeight = generalsEnums.fontSizes.size12.lineHeight;
      break;
    case generalsEnums.fontSizes.size14.size:
      fontSize = generalsEnums.fontSizes.size14.size;
      lineHeight = generalsEnums.fontSizes.size14.lineHeight;
      break;
    case generalsEnums.fontSizes.size16.size:
      fontSize = generalsEnums.fontSizes.size16.size;
      lineHeight = generalsEnums.fontSizes.size16.lineHeight;
      break;
    case generalsEnums.fontSizes.size18.size:
      fontSize = generalsEnums.fontSizes.size18.size;
      lineHeight = generalsEnums.fontSizes.size18.lineHeight;
      break;
    case generalsEnums.fontSizes.size20.size:
      fontSize = generalsEnums.fontSizes.size20.size;
      lineHeight = generalsEnums.fontSizes.size20.lineHeight;
      break;
    case generalsEnums.fontSizes.size22.size:
      fontSize = generalsEnums.fontSizes.size22.size;
      lineHeight = generalsEnums.fontSizes.size22.lineHeight;
      break;
    case generalsEnums.fontSizes.size24.size:
      fontSize = generalsEnums.fontSizes.size24.size;
      lineHeight = generalsEnums.fontSizes.size24.lineHeight;
      break;
    case generalsEnums.fontSizes.size26.size:
      fontSize = generalsEnums.fontSizes.size26.size;
      lineHeight = generalsEnums.fontSizes.size26.lineHeight;
      break;
    case generalsEnums.fontSizes.size28.size:
      fontSize = generalsEnums.fontSizes.size28.size;
      lineHeight = generalsEnums.fontSizes.size28.lineHeight;
      break;
    case generalsEnums.fontSizes.size30.size:
      fontSize = generalsEnums.fontSizes.size30.size;
      lineHeight = generalsEnums.fontSizes.size30.lineHeight;
      break;
    case generalsEnums.fontSizes.size32.size:
      fontSize = generalsEnums.fontSizes.size32.size;
      lineHeight = generalsEnums.fontSizes.size32.lineHeight;
      break;
    case generalsEnums.fontSizes.size34.size:
      fontSize = generalsEnums.fontSizes.size34.size;
      lineHeight = generalsEnums.fontSizes.size34.lineHeight;
      break;
    case generalsEnums.fontSizes.size36.size:
      fontSize = generalsEnums.fontSizes.size36.size;
      lineHeight = generalsEnums.fontSizes.size36.lineHeight;
      break;
    case generalsEnums.fontSizes.size38.size:
      fontSize = generalsEnums.fontSizes.size38.size;
      lineHeight = generalsEnums.fontSizes.size38.lineHeight;
      break;
    case generalsEnums.fontSizes.size40.size:
      fontSize = generalsEnums.fontSizes.size40.size;
      lineHeight = generalsEnums.fontSizes.size40.lineHeight;
      break;
    default:
      fontSize = generalsEnums.fontSizes.size14.size;
      lineHeight = generalsEnums.fontSizes.size14.lineHeight;
      break;
  }

  return { fontSize, lineHeight };
};
