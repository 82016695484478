import React from 'react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, TextField, Select, MenuItem } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import generalsEnums from '../../../utils/Generals';
import Products from '../../../entities/Products';
import CurrencyFont from '../../../Components/Generals/Fonts/CurrencyFont';
import BadgeAttom from '../../../Components/Generals/Figma/Atoms/BadgeAttom';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import ItemImage from '../../../Components/Generals/Figma/Atoms/ItemImage';
import { useStyles } from './ProductDetail.styles';
import { selectPortalCountry, setIsCountryCBDisabled } from '../../../Redux/Slices/Portal.Slices';
import { addProduct, selectAllProductsIds } from '../../../Redux/Slices/ShoppingCart.Slices';
import { setBackDropState, setIsToastSuccess, setToastSuccessMessage } from '../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../assets/GlobalColors';

import Font from '../../../Components/Generals/Fonts/Font';

const products = new Products();

const ValuesFormControl = ({ values, variantId, classes }) => {
  const [valueSelectedId, setValueSelectedId] = React.useState('');

  const handleSetNewValueDefault = (ValueId) => {
    setValueSelectedId(ValueId);
  };

  React.useEffect(() => {
    values.map((value) => {
      if (value.isDefault) {
        setValueSelectedId(value.id);
      }
    });
  }, [values]);

  return (
    <FormControl className={classes.FormControl}>
      <Select
        name=""
        value={valueSelectedId}
        onChange={(e) => {
          e.preventDefault();
          handleSetNewValueDefault(e.target.value);
        }}
        fullWidth
        size="small"
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Font text="Referencia Cruzada" color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />;
          } else {
            const name = values.find((value) => value.id === selected).name;
            return <Font text={name} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />;
          }
        }}
        displayEmpty
        sx={{
          color: colors.BLACK,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.BLACK_06,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.BLACK_06,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.BLACK_06,
          },
          '.MuiSvgIcon-root ': {
            fill: colors.BLACK_06,
          },
        }}
      >
        {values.map((value, idx) => {
          return (
            <MenuItem key={idx} value={value.id}>
              <Font text={value.name} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const ProductDetail = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { productId } = useParams();
  const { t } = useTranslation('global');

  const [product, setProduct] = React.useState(generalsEnums.emptyObject);
  const [quantity, setQuantity] = React.useState(generalsEnums.oneNumber);
  const [productVariants, setProductVariants] = React.useState(generalsEnums.emptyArray);
  const [productName, setProductName] = React.useState(generalsEnums.emptyString);
  const [productCode, setProductCode] = React.useState(generalsEnums.emptyString);
  const [productPrice, setProductPrice] = React.useState(generalsEnums.emptyString);
  const [productIsAvailable, setProductIsAvailable] = React.useState(generalsEnums.falseBoolean);
  const [productCategories, setProductCategories] = React.useState(generalsEnums.emptyString);
  const [productDescription, setProductDescription] = React.useState(generalsEnums.emptyString);
  const [productImage, setProductImage] = React.useState(generalsEnums.emptyString);
  const [productBrand, setProductBrand] = React.useState(generalsEnums.emptyString);
  const [weight, setWeight] = React.useState(generalsEnums.emptyString);

  const min = 1;
  const max = 999;
  const allProductsIds = useSelector(selectAllProductsIds);
  const portalCountry = useSelector(selectPortalCountry);
  const isXs = useMediaQuery(generalsEnums.mediaQueries.isXs);
  const isSm = useMediaQuery(generalsEnums.mediaQueries.isSm);
  const isMd = useMediaQuery(generalsEnums.mediaQueries.isMd);
  const isLg = useMediaQuery(generalsEnums.mediaQueries.isLg);

  const getResponsiveValue = (values) => {
    return isXs ? values[0] : isSm ? values[1] : isMd ? values[2] : isLg ? values[3] : values[4];
  };

  const responsiveBoxWidth = getResponsiveValue(['150px', '350px', '450px', '500px', '700px']);
  const responsiveDoubleBoxWidth = getResponsiveValue(['300px', '700px', '900px', '1000px', '1400px']);
  const responsive18to10Size = getResponsiveValue([
    generalsEnums.fontSizes.size10.size,
    generalsEnums.fontSizes.size12.size,
    generalsEnums.fontSizes.size14.size,
    generalsEnums.fontSizes.size16.size,
    generalsEnums.fontSizes.size18.size,
  ]);
  const responsive18to10LineHeight = getResponsiveValue([
    generalsEnums.fontSizes.size10.lineHeight,
    generalsEnums.fontSizes.size12.lineHeight,
    generalsEnums.fontSizes.size14.lineHeight,
    generalsEnums.fontSizes.size16.lineHeight,
    generalsEnums.fontSizes.size18.lineHeight,
  ]);
  const responsiveProductNameSize = getResponsiveValue([
    generalsEnums.fontSizes.size16.size,
    generalsEnums.fontSizes.size24.size,
    generalsEnums.fontSizes.size36.size,
    generalsEnums.fontSizes.size38.size,
    generalsEnums.fontSizes.size40.size,
  ]);
  const responsiveProductNameLineHeight = getResponsiveValue([
    generalsEnums.fontSizes.size16.lineHeight,
    generalsEnums.fontSizes.size24.lineHeight,
    generalsEnums.fontSizes.size36.lineHeight,
    generalsEnums.fontSizes.size38.lineHeight,
    generalsEnums.fontSizes.size40.lineHeight,
  ]);
  const responsiveProductCodeSize = getResponsiveValue([
    generalsEnums.fontSizes.size12.size,
    generalsEnums.fontSizes.size14.size,
    generalsEnums.fontSizes.size18.size,
    generalsEnums.fontSizes.size18.size,
    generalsEnums.fontSizes.size24.size,
  ]);
  const responsiveProductCodeLineHeight = getResponsiveValue([
    generalsEnums.fontSizes.size12.lineHeight,
    generalsEnums.fontSizes.size14.lineHeight,
    generalsEnums.fontSizes.size18.lineHeight,
    generalsEnums.fontSizes.size18.lineHeight,
    generalsEnums.fontSizes.size24.lineHeight,
  ]);
  const responsiveProductPriceSize = getResponsiveValue([
    generalsEnums.fontSizes.size14.size,
    generalsEnums.fontSizes.size22.size,
    generalsEnums.fontSizes.size28.size,
    generalsEnums.fontSizes.size32.size,
    generalsEnums.fontSizes.size38.size,
  ]);
  const responsiveProductPriceLineHeight = getResponsiveValue([
    generalsEnums.fontSizes.size14.lineHeight,
    generalsEnums.fontSizes.size22.lineHeight,
    generalsEnums.fontSizes.size28.lineHeight,
    generalsEnums.fontSizes.size32.lineHeight,
    generalsEnums.fontSizes.size38.lineHeight,
  ]);

  const handleClickAddToCart = (e) => {
    e.preventDefault();

    dispatch(setBackDropState(generalsEnums.trueBoolean));
    const index = allProductsIds.findIndex((id) => id === product._id);
    if (index === -1) {
      dispatch(setBackDropState(generalsEnums.falseBoolean));
      const updatedProduct = {
        ...product,
        quantitySelected: quantity,
      };
      setProduct(updatedProduct);
      dispatch(
        addProduct({
          product: updatedProduct,
        })
      );
      dispatch(setIsCountryCBDisabled(generalsEnums.trueBoolean));
    } else {
      dispatch(setBackDropState(generalsEnums.falseBoolean));
      dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
      dispatch(setToastSuccessMessage(t('client.ProductDetail.warning')));
    }
  };

  React.useEffect(async () => {
    dispatch(setBackDropState(true));
    setProduct(generalsEnums.emptyObject);
    setQuantity(generalsEnums.oneNumber);
    setProductVariants(generalsEnums.emptyArray);
    setProductName(generalsEnums.emptyString);
    setProductCode(generalsEnums.emptyString);
    setProductPrice(generalsEnums.zeroNumber);
    setProductIsAvailable(generalsEnums.falseBoolean);
    setProductCategories(generalsEnums.emptyString);
    setProductDescription(generalsEnums.emptyString);
    setProductImage(generalsEnums.emptyString);
    setProductBrand(generalsEnums.emptyString);
    const { product } = await products.searchProductById(productId, portalCountry);
    const {
      name,
      price,
      isAvailable,
      image,
      productVariants,
      description,
      categoryName,
      subCategory,
      label,
      codes,
      brand,
      weightValue,
    } = product;
    if (product) setProduct(product);
    if (name) setProductName(name);
    if (price) setProductPrice(price);
    if (isAvailable) setProductIsAvailable(isAvailable);
    if (brand) setProductBrand(brand);
    if (weightValue) setWeight(weightValue);
    if (image) setProductImage(image);
    if (productVariants) setProductVariants(productVariants);
    if (description) setProductDescription(description);
    if (categoryName) setProductCategories(`home / ${categoryName} / ${subCategory} / ${label}`);
    if (Array.isArray(codes)) setProductCode(`${codes[0]}`);
    else setProductCode(`${codes}`);
    dispatch(setBackDropState(false));
  }, [productId, portalCountry]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '35px',
      }}
    >
      <Box component={generalsEnums.BoxDiv} className={classes.root}>
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: responsiveBoxWidth,
          }}
        >
          <ItemImage image={productImage} name={generalsEnums.emptyString} size={responsiveBoxWidth} />
        </Box>

        <Box
          component={generalsEnums.BoxDiv}
          className={classes.productDetailContainer}
          sx={{
            width: responsiveBoxWidth,
          }}
        >
          <Box component={generalsEnums.BoxDiv}>
            <Font
              text={productCategories}
              color={colors.BLACK}
              size={responsive18to10Size}
              lineHeight={responsive18to10LineHeight}
              isHead={generalsEnums.falseBoolean}
            />
          </Box>

          <Box component={generalsEnums.BoxDiv} className={classes.productNameBox}>
            <Font
              text={productName}
              color={colors.BLACK}
              size={responsiveProductNameSize}
              lineHeight={responsiveProductNameLineHeight}
              isHead={generalsEnums.trueBoolean}
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            className={classes.productCodeBox}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Font
              text={productCode}
              color={colors.BLACK_06}
              size={responsiveProductCodeSize}
              lineHeight={responsiveProductCodeLineHeight}
              isHead={generalsEnums.falseBoolean}
            />

            <Font
              text={productBrand}
              color={colors.BLACK_06}
              size={responsiveProductCodeSize}
              lineHeight={responsiveProductCodeLineHeight}
              isHead={generalsEnums.falseBoolean}
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            className={classes.productCodeBox}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Font
              text={weight}
              color={colors.BLACK_06}
              size={responsiveProductCodeSize}
              lineHeight={responsiveProductCodeLineHeight}
              isHead={generalsEnums.falseBoolean}
            />
          </Box>

          {productPrice === generalsEnums.zeroNumber ? (
            <Font
              text={t('client.ProductDetail.priceNotAvailable')}
              color={colors.BLACK}
              size={responsiveProductPriceSize}
              lineHeight={responsiveProductPriceLineHeight}
              isHead={generalsEnums.falseBoolean}
            />
          ) : null}

          <Box component={generalsEnums.BoxDiv} className={classes.productPriceBox}>
            <CurrencyFont
              price={productPrice}
              color={colors.BLACK}
              size={responsiveProductPriceSize}
              lineHeight={responsiveProductPriceLineHeight}
              isHead={generalsEnums.falseBoolean}
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            <BadgeAttom
              kind={productIsAvailable ? generalsEnums.badge.greenSoft : generalsEnums.badge.red}
              text={productIsAvailable ? t('Generals.stockAvailable') : t('Generals.stockNotAvailable')}
            />
          </Box>

          {productVariants.length > 0 ? (
            <Box
              component={generalsEnums.BoxDiv}
              style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: '5px',
              }}
            >
              {productVariants.map((variant, idx) => {
                return (
                  <Box key={idx} component={generalsEnums.BoxDiv} className={classes.productVariantsBox}>
                    <Box component={generalsEnums.BoxDiv} className={classes.textBox}>
                      <Font
                        text={variant.name}
                        color={colors.BLACK}
                        size={responsiveProductCodeSize}
                        lineHeight={responsiveProductCodeLineHeight}
                        isHead={generalsEnums.falseBoolean}
                      />
                    </Box>
                    <ValuesFormControl values={variant.values} variantId={variant.id} classes={classes} />
                  </Box>
                );
              })}
            </Box>
          ) : null}

          <Box component={generalsEnums.BoxDiv} className={classes.productVariantsBox}>
            <Box component={generalsEnums.BoxDiv} className={classes.textBox}>
              <Font
                text={t('Generals.quantity')}
                color={colors.BLACK}
                size={responsiveProductCodeSize}
                lineHeight={responsiveProductCodeLineHeight}
                isHead={generalsEnums.falseBoolean}
              />
            </Box>

            <TextField
              fullWidth
              id="outlined-number"
              type="number"
              size="small"
              value={quantity}
              onChange={(e) => {
                var value = parseInt(e.target.value, 10);

                if (value > max) value = max;
                if (value < min) value = min;

                setQuantity(value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                color: colors.BLACK,
                '& label.Mui-focused': {
                  color: colors.BLACK_06,
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: colors.BLACK_06,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                },
              }}
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: 'auto',
              marginTop: '12px',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.green}
              handleAction={handleClickAddToCart}
              text={t('Generals.addToCart')}
              isDisabled={productIsAvailable ? false : true}
              size={responsiveProductCodeSize}
              lineHeight={responsiveProductCodeLineHeight}
              icon={<AddShoppingCartIcon className={classes.addToCartIcon} />}
            />
          </Box>
        </Box>
      </Box>

      {productDescription !== generalsEnums.emptyString ? (
        <Box
          component={generalsEnums.BoxDiv}
          className={classes.productDescriptionBox}
          sx={{
            width: responsiveDoubleBoxWidth,
          }}
        >
          <Font text="Descripción" color={colors.BLACK} size={20} lineHeight="28px" isHead={true} />

          <Font text={productDescription} color={colors.BLACK} size={16} lineHeight="26px" isHead={false} />
        </Box>
      ) : null}
    </Box>
  );
};

export default ProductDetail;
