import { makeStyles } from '@mui/styles';

import * as colors from '../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  Outlet: {
    width: '100%',
    paddingLeft: '220px',
  },
  navBar: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '220px',
    height: '100vh',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: 1,
    top: 0,
    borderTop: 'none',
    borderLeft: 'none',
    borderBottom: 'none',
    borderRight: `1px solid ${colors.GRAY_228_1}`,
    boxShadow: `0px 0px 10px 0px ${colors.GRAY_228_1}`,
    backgroundColor: colors.WHITE,
  },
  navBarItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '15px',
    width: 'auto',
    height: 'auto',
  },
  logo: {
    width: '95px',
    height: '40px',
    marginTop: '20px',
  },
  NavBarUserChipRoot: {
    width: '180px',
    height: '70px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: `1px solid ${colors.GRAY_228_1}`,
    backgroundColor: colors.GRAY_228_05,
  },
  NavBarUserInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '130px',
    height: '100%',
  },
  NavBarUserInfoItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: 'auto',
    gap: '5px',
    flexWrap: 'wrap',
  },
  NavBarUserLogout: {
    width: '100%',
    height: 'auto',
    marginBottom: '20px',
  },
  ordersIcon: {
    width: '22px',
    height: '22px',
  },
  adminPathButton: {
    textTransform: 'none',
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    color: colors.BLACK,
    border: `1px solid ${colors.WHITE}`,
    backgroundColor: colors.WHITE,
    marginTop: '20px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.WHITE,
      color: colors.BLACK,
      border: `1px solid ${colors.WHITE}`,
    },
  },
});
