import { makeStyles } from '@mui/styles';
import * as colors from '../../../../assets/GlobalColors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '50px',
  },
  header: {
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mailsBox: {
    flexGrow: 1,
    width: '100%',
  },
  item: {
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${colors.BORDER_CCC}`,
    textAlign: 'center',
    borderRadius: '0px',
    boxShadow: '0px 0px 0px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0px',
  },
}));

export { useStyles, colors };
