import React from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTheme } from '@mui/material/styles';

import generalsEnums from '../../utils/Generals';
import Font from '../../Components/Generals/Fonts/Font';

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableListProducts = ({
  tableHeaders,
  rows,
  classes,
  t,
  handleCheckboxProduct,
  _idSelected,
  tableTextSize,
  tableTextLineHeight,
  colors,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(generalsEnums.TableRowsPerPage.ten);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        style={{
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Table>
          <TableHead
            style={{
              boxShadow: 'none',
            }}
          >
            <TableRow>
              {tableHeaders.map((header) => {
                const { text, padding } = header;
                return (
                  <TableCell key={uuid()} align="left" padding={padding}>
                    <Font
                      text={text}
                      color={colors.BLACK}
                      size={tableTextSize}
                      lineHeight={tableTextLineHeight}
                      isHead={generalsEnums.trueBoolean}
                      ml="0px"
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody
            style={{
              boxShadow: 'none',
            }}
          >
            {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
              (row, idx) => {
                const { _id, image, name, category, codes } = row;
                return (
                  <TableRow key={uuid()} index={idx}>
                    <TableCell component="th" scope="row" className={classes.text} align="left" padding="normal">
                      <img src={image} alt={row.name} className={classes.image} />
                    </TableCell>

                    <TableCell scope="row" className={classes.text} align="left" padding="normal">
                      <Font
                        text={name}
                        color={colors.BLACK}
                        size={tableTextSize}
                        lineHeight={tableTextLineHeight}
                        isHead={false}
                        ml="0px"
                      />
                      {/* <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <img src={image} alt={row.name} className={classes.image} />

                        <Font
                          text={name}
                          color={colors.BLACK}
                          size={tableTextSize}
                          lineHeight={tableTextLineHeight}
                          isHead={false}
                          ml="0px"
                        />
                      </Box> */}
                    </TableCell>

                    <TableCell scope="row" className={classes.text} align="left" padding="normal">
                      <Font
                        text={codes}
                        color={colors.BLACK}
                        size={tableTextSize}
                        lineHeight={tableTextLineHeight}
                        isHead={false}
                        ml="0px"
                      />
                    </TableCell>

                    <TableCell scope="row" className={classes.text} align="left" padding="normal">
                      <Font
                        text={category}
                        color={colors.BLACK}
                        size={tableTextSize}
                        lineHeight={tableTextLineHeight}
                        isHead={false}
                        ml="0px"
                      />
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          marginTop: '30px',
        }}
      >
        <TablePagination
          component="div"
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: t('admin.AdminProducts.table-pagination-label-rows-per-page-all'), value: -1 },
          ]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage={
            <Box component="div" style={{ marginTop: '15px' }}>
              {t('admin.AdminProducts.table-pagination-label-rows-per-page')}
            </Box>
          }
          labelDisplayedRows={({ from, to, count }) => (
            <Box component="div" style={{ marginTop: '15px' }}>
              {`${from}-${to} ${t('admin.AdminProducts.table-pagination-label-rows-per-page-of')} ${count}`}
            </Box>
          )}
        />
      </Box>
    </React.Fragment>
  );
};

export default TableListProducts;
