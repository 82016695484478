import React from 'react';
import { useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';

import Font from './Font';
import generalsEnums from '../../../utils/Generals';
import { selectPortalCurrency, selectPortalExchangeRate } from '../../../Redux/Slices/Portal.Slices';

const CurrencyFont = ({ price, color, size, lineHeight, isHead, displayPrefix, change }) => {
  const [currencyPrefix, setCurrencyPrefix] = React.useState('');
  const [itemPrice, setItemPrice] = React.useState(0);

  const currency = useSelector(selectPortalCurrency);
  const exchangeRate = useSelector(selectPortalExchangeRate);

  React.useEffect(() => {
    if (change === false) {
      setItemPrice(price);
      setCurrencyPrefix(`USD`);
      return;
    }

    if (currency === generalsEnums.currencies.mxn) {
      const newPrice = price * exchangeRate;
      setItemPrice(newPrice);
      setCurrencyPrefix(`MXN`);
    } else if (currency === generalsEnums.currencies.usd) {
      setItemPrice(price);
      setCurrencyPrefix(`USD`);
    } else {
      setItemPrice(price);
      setCurrencyPrefix(`USD`);
    }
  }, [currency, price, change]);

  React.useEffect(() => {
    if (displayPrefix !== undefined) {
      setCurrencyPrefix('');
    }
  }, [displayPrefix]);

  return (
    <CurrencyFormat
      value={itemPrice}
      displayType={'text'}
      thousandSeparator={true}
      decimalScale={2}
      prefix={'$ '}
      fixedDecimalScale={true}
      renderText={(value) => (
        <Font
          text={`${value} ${currencyPrefix}`}
          color={color}
          size={size}
          lineHeight={lineHeight}
          isHead={isHead}
          ml="0px"
        />
      )}
    />
  );
};

export default CurrencyFont;
