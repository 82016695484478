import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Font from './Fonts/Font';
import * as colors from '../../assets/GlobalColors';

const GoBackButton = ({ hasText }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const handleClick = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      sx={{
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: '5px',
        boxShadow: 'none',

        '&:hover': {
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          opacity: '0.8',
        },
      }}
    >
      <ArrowBackIosIcon
        sx={{
          color: colors.BLACK_06,
        }}
      />

      {hasText ? (
        <Font text={t('Generals.goBack')} color={colors.BLACK_06} size={14} lineHeight="24px" isHead={true} />
      ) : null}
    </Button>
  );
};

export default GoBackButton;
