import React from 'react';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import generalsEnums from '../../../utils/Generals';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import Font from '../../../Components/Generals/Fonts/Font';
import FocusButton from '../../../Components/Generals/FocusButton';
import Paths from '../../../utils/enums/Paths';
import { setStoreProfilesModalState } from '../../../Redux/Slices/Navigation.Slices';
import { useStyles, colors } from './styles';

const AdminConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const handleNavigateTo = (path) => {
    navigate(path);
  };

  const handleOpenStoreProfilesModal = () => {
    dispatch(setStoreProfilesModalState(true));
  };

  const options = [
    {
      id: uuid(),
      text: t('admin.AdminConfiguration.options.profile'),
      path: Paths.admin.StoreProfile,
      handleAction: handleOpenStoreProfilesModal,
    },
    { id: uuid(), text: t('admin.AdminConfiguration.options.foreingExchange'), path: Paths.admin.ForeingExchange },
    { id: uuid(), text: t('admin.AdminConfiguration.options.emailSettings'), path: Paths.admin.EmailSettings },
    { id: uuid(), text: t('admin.AdminConfiguration.options.ordersSettings'), path: Paths.admin.OrderSettings },
    { id: uuid(), text: t('admin.AdminConfiguration.options.web'), path: Paths.admin.web },
    {
      id: uuid(),
      text: t('admin.AdminConfiguration.options.usersAndPermissions'),
      path: Paths.admin.usersAndPermissions,
    },
    {
      id: uuid(),
      text: t('admin.AdminConfiguration.options.pricing'),
      path: Paths.admin.AdminConfigurations.markup,
    },
    {
      id: uuid(),
      text: t('admin.AdminConfiguration.options.freight'),
      path: Paths.admin.AdminConfigurations.freight,
    },
    {
      id: uuid(),
      text: t('admin.AdminConfiguration.options.weightsRules'),
      path: Paths.admin.AdminConfigurations.weightsRules,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Box component="div" className={classes.root}>
        <Font
          text={t('admin.AdminConfiguration.title')}
          color={colors.BLACK}
          size={20}
          lineHeight="28px"
          isHead={true}
        />

        <Box component="div" className={classes.optionsBox}>
          {options.map((option) => {
            return (
              <TsiButton
                key={option.id}
                type={generalsEnums.buttonTypes.black}
                handleAction={() => {
                  if (option.handleAction) {
                    option.handleAction();
                  } else {
                    handleNavigateTo(option.path);
                  }
                }}
                text={option.text}
                isDisabled={generalsEnums.falseBoolean}
                size={generalsEnums.fontSizes.size12.size}
                lineHeight={generalsEnums.fontSizes.size12.lineHeight}
                w="100%"
                jc="flex-start"
              />
            );
          })}
        </Box>
      </Box>
    </Container>
  );
};

export default AdminConfiguration;
