export const mexicoStates = [
  { label: 'Aguascalientes' },
  { label: 'Baja California' },
  { label: 'Baja California Sur' },
  { label: 'Campeche' },
  { label: 'Chiapas' },
  { label: 'Chihuahua' },
  { label: 'Ciudad de México' },
  { label: 'Coahuila' },
  { label: 'Colima' },
  { label: 'Durango' },
  { label: 'Guanajuato' },
  { label: 'Guerrero' },
  { label: 'Hidalgo' },
  { label: 'Jalisco' },
  { label: 'Estado de México' },
  { label: 'Michoacán' },
  { label: 'Morelos' },
  { label: 'Nayarit' },
  { label: 'Nuevo León' },
  { label: 'Oaxaca' },
  { label: 'Puebla' },
  { label: 'Querétaro' },
  { label: 'Quintana Roo' },
  { label: 'San Luis Potosí' },
  { label: 'Sinaloa' },
  { label: 'Sonora' },
  { label: 'Tabasco' },
  { label: 'Tamaulipas' },
  { label: 'Tlaxcala' },
  { label: 'Yucatán' },
  { label: 'Zacatecas' },
];
