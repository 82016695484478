import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Typography, Button } from '@mui/material';
import * as yup from 'yup';

import generalsEnums from '../../../utils/Generals';
import TsiLogo from '../../../Components/Generals/TsiLogo';
import InputText from '../../../Components/Generals/InputText';
import Users from '../../../entities/Users';
import { fetchCountriesFromApi } from '../../../Redux/Slices/ShoppingCart.Slices';
import { usersTypes } from '../../../utils/enums/usersTypes';
import { useStyles } from './SignInAdmin.styles';
import { setPortalLanguage } from '../../../Redux/Slices/Portal.Slices';
import { setUserInformation, fetchSignout } from '../../../Redux/Slices/User.Slices';
import {
  setSnackBar,
  selectErrorId,
  selectErrorMessage,
  setBackDropState,
} from '../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../assets/GlobalColors';

const SignInAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usersAPI = new Users();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [buttonDisabled, setButtonDisabled] = React.useState(generalsEnums.falseBoolean);
  const [isMfaCodeRequired, setIsMfaCodeRequired] = React.useState(generalsEnums.falseBoolean);

  const errorId = useSelector(selectErrorId);
  const errorMessage = useSelector(selectErrorMessage);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
    mfaCode: isMfaCodeRequired ? yup.string().required() : yup.string(),
  });

  const handleInputMfaCode = (event) => {
    const regex = /^[0-9]*$/;

    if (event.target.value.length <= 6 && regex.test(event.target.value)) {
      formik.setFieldValue('mfaCode', event.target.value);
    }
  };

  const handleSignIn = async (values) => {
    const signinBody = {
      email: values.email,
      password: values.password,
    };

    const signinRes = await usersAPI.signin(signinBody);
    const { userType, token, exp, user_id, name, email, lastName, language, sub } = signinRes;

    if (userType === usersTypes.ADMIN) {
      dispatch(fetchCountriesFromApi());
      dispatch(
        setUserInformation({
          token: token,
          exp: exp,
          user_id: user_id,
          name: name,
          email: email,
          userType: userType,
          lastName: lastName,
          language: language,
          sub: sub,
        })
      );
      dispatch(setBackDropState(false));
      dispatch(setPortalLanguage(language));
      navigate('/ts-admin');
    } else {
      handleAuthenticationError(values.email);
    }
  };

  const handleAuthenticationError = (email) => {
    dispatch(fetchSignout(email));
    formik.resetForm();
    formik.setFieldValue('email', generalsEnums.emptyString);
    formik.setFieldValue('password', generalsEnums.emptyString);
    dispatch(
      setSnackBar({
        snackBarStatus: generalsEnums.trueBoolean,
        snackBarSeverity: generalsEnums.snackBar.error,
        snackBarMessage: t('client.SignIn.error-message'),
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      email: generalsEnums.emptyString,
      password: generalsEnums.emptyString,
      mfaCode: generalsEnums.emptyString,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      dispatch(setBackDropState(true));

      try {
        if (isMfaCodeRequired) {
          const signInWithMFABody = {
            email: values.email,
            password: values.password,
            code: values.mfaCode,
          };

          const response = await usersAPI.signInWithMFA(signInWithMFABody);
          const { statusCode, userType } = response;

          if (statusCode === generalsEnums.statusCodes.ok) {
            if (userType === usersTypes.ADMIN) {
              dispatch(fetchCountriesFromApi());
              dispatch(
                setUserInformation({
                  token: response.token,
                  exp: response.exp,
                  user_id: response.user_id,
                  name: response.name,
                  email: response.email,
                  userType: response.userType,
                  lastName: response.lastName,
                  language: response.language,
                  sub: response.sub,
                })
              );
              dispatch(setBackDropState(false));
              dispatch(setPortalLanguage(response.language));
              navigate('/ts-admin');
            } else {
              handleAuthenticationError(values.email);
            }
          }
        } else {
          const checkUserMFABody = {
            email: values.email,
          };

          const response = await usersAPI.checkUserMFA(checkUserMFABody);
          const { statusCode, data } = response;

          if (statusCode === generalsEnums.statusCodes.ok) {
            const { hasMFA } = data;

            if (hasMFA) {
              setIsMfaCodeRequired(hasMFA);
              dispatch(setBackDropState(false));
            } else {
              await handleSignIn(values);
            }
          }
        }
      } catch (error) {
        dispatch(setBackDropState(false));
      }
    },
  });

  return (
    <Box component="div" className={classes.root}>
      <Container maxWidth="xs">
        <Box component="div" className={classes.formContainer}>
          <TsiLogo />

          <form onSubmit={formik.handleSubmit}>
            <Box component="div" className={classes.formBox}>
              <Box component="div" className={classes.formItem}>
                <InputText
                  value={formik.values.email}
                  name="email"
                  onChange={formik.handleChange}
                  label={t('client.SignIn.label-email')}
                  labelColor={colors.TSADMIN_BLUE_GRAY}
                  ph={t('client.SignIn.placeholder-email')}
                  error={formik.errors.email}
                  isDisabled={false}
                  w="100%"
                  isPassword={false}
                  extraLabel={generalsEnums.undefinedData}
                  rows={generalsEnums.undefinedData}
                  helperText={generalsEnums.undefinedData}
                  hasRows={generalsEnums.falseBoolean}
                  hasErrorMessage={errorId === '1' ? true : false}
                  errorMessage={errorMessage}
                />
              </Box>

              <Box component="div" className={classes.formItem}>
                <InputText
                  value={formik.values.password}
                  name="password"
                  onChange={formik.handleChange}
                  label={t('client.SignIn.label-password')}
                  labelColor={colors.TSADMIN_BLUE_GRAY}
                  ph={t('client.SignIn.placeholder-password')}
                  error={formik.errors.password}
                  isDisabled={false}
                  w="100%"
                  isPassword={true}
                  extraLabel={generalsEnums.undefinedData}
                  rows={generalsEnums.undefinedData}
                  helperText={generalsEnums.undefinedData}
                  hasRows={generalsEnums.falseBoolean}
                  hasErrorMessage={errorId === '2' ? true : false}
                  errorMessage={errorMessage}
                />
              </Box>

              {isMfaCodeRequired ? (
                <Box component="div" className={classes.formItem}>
                  <InputText
                    value={formik.values.mfaCode}
                    name="mfaCode"
                    onChange={(e) => {
                      handleInputMfaCode(e);
                    }}
                    label={t('labels.mfaCode.label')}
                    labelColor={colors.TSADMIN_BLUE_GRAY}
                    ph={t('labels.mfaCode.placeholder')}
                    error={formik.errors.mfaCode}
                    isDisabled={false}
                    w="100%"
                    isPassword={false}
                    extraLabel={generalsEnums.undefinedData}
                    rows={generalsEnums.undefinedData}
                    helperText={generalsEnums.undefinedData}
                    hasRows={generalsEnums.falseBoolean}
                    hasErrorMessage={errorId === '3' ? true : false}
                    errorMessage={errorMessage}
                  />
                </Box>
              ) : null}

              <Button
                type="submit"
                variant="outlined"
                style={{
                  backgroundColor: colors.GREEN_MAIN,
                  border: `0.4px solid ${colors.GREEN_MAIN}`,
                  textTransform: 'none',
                  color: colors.BLACK,
                  borderRadius: '8px',
                  width: '380px',
                  height: '50px',
                }}
                disabled={buttonDisabled}
              >
                <Typography variant="body" className="text-black text-15">
                  {t('client.SignIn.signin-button')}
                </Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default SignInAdmin;
