import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import ResponsiveComponent from '../../../../Hooks/ResponsiveComponent';
import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import InfoBox from './components/InfoBox';
import workerPhoto from './images/worker.svg';
import icon1 from './images/icon1.svg';
import icon2 from './images/icon2.svg';
import icon3 from './images/icon3.svg';
import icon4 from './images/icon4.svg';
import icon5 from './images/icon5.svg';
import { getResponsiveValues } from '../../../../utils/FormatData';
import { useStyles, colors } from './styles';

const About = () => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const titleSizes = [
    [
      generalsEnums.fontSizes.size22.size,
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size32.size,
      generalsEnums.fontSizes.size34.size,
    ],
    [
      generalsEnums.fontSizes.size22.lineHeight,
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size32.lineHeight,
      generalsEnums.fontSizes.size34.lineHeight,
    ],
    ['100%', '100%', '100%', '650px', '650px'],
    ['50%', '50%', '30%', '380px', '400px'],
    ['16px', '16px', '16px', '22px', '22px'],
    ['column', 'column', 'column', 'row', 'row'],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
    ],
    ['28px', '28px', '28px', '48px', '48px'],
    ['32px', '32px', '32px', '48px', '48px'],
    ['160px', '160px', '184px', '184px', '184px'],
    ['160px', '160px', '184px', '184px', '184px'],
    ['300px', '300px', '370px', '400px', '400px'],
    ['200px', '250px', '250px', '250px', '250px'],
    ['58px', '62px', '72px', '76px', '76px'],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
    ],
  ];

  const [
    responsiveTitleSize,
    responsiveLineHeightSize,
    boxOneWidth,
    boxOneHeight,
    boxOneGap,
    boxOneFlexDirection,
    responsiveTextSize,
    responsiveTextLineHeightSize,
    boxOneMarginTop,
    boxRootMarginTop,
    boxPorpuseHeight,
    personalImage,
    boxValuesWidth,
    boxValuesHeight,
    valueIconSize,
    valueTitleSize,
    valueTitleLineHeightSize,
    valueTextSize,
    valueTextLineHeightSize,
  ] = getResponsiveValues(titleSizes, isXs, isSm, isMd, isLg, isXl);

  const values = [
    {
      id: uuidv4(),
      title: t('About.values.honesty.title'),
      text: t('About.values.honesty.text'),
      icon: icon1,
    },
    {
      id: uuidv4(),
      title: t('About.values.teamwork.title'),
      text: t('About.values.teamwork.text'),
      icon: icon2,
    },
    {
      id: uuidv4(),
      title: t('About.values.quality.title'),
      text: t('About.values.quality.text'),
      icon: icon3,
    },
    {
      id: uuidv4(),
      title: t('About.values.passion.title'),
      text: t('About.values.passion.text'),
      icon: icon4,
    },
    {
      id: uuidv4(),
      title: t('About.values.honesty.title'),
      text: t('About.values.honesty.text'),
      icon: icon5,
    },
  ];

  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.root}
      sx={{
        marginTop: boxRootMarginTop,
      }}
    >
      <Font
        text={t('About.title')}
        color={colors.BLACK}
        size={responsiveTitleSize}
        lineHeight={responsiveLineHeightSize}
        isHead={generalsEnums.trueBoolean}
      />

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.responsiveBox}
        sx={{
          flexDirection: boxOneFlexDirection,
          gap: boxOneGap,
          marginTop: boxOneMarginTop,
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          className={classes.boxOne}
          sx={{
            width: boxOneWidth,
            height: boxOneHeight,
          }}
        >
          <Box component={generalsEnums.BoxDiv} className={classes.boxOneInfo}>
            <InfoBox
              generalsEnums={generalsEnums}
              classes={classes}
              title={t('About.vision.title')}
              text={t('About.vision.text')}
              colors={colors}
              responsiveTitleSize={responsiveTitleSize}
              responsiveLineHeightSize={responsiveLineHeightSize}
              responsiveTextSize={responsiveTextSize}
              responsiveTextLineHeightSize={responsiveTextLineHeightSize}
              w={'100%'}
              isGreen={generalsEnums.trueBoolean}
            />

            <Box component={generalsEnums.BoxDiv} className={classes.boxInfoDown}>
              <InfoBox
                generalsEnums={generalsEnums}
                classes={classes}
                title={t('About.mission.title')}
                text={t('About.mission.text')}
                colors={colors}
                responsiveTitleSize={responsiveTitleSize}
                responsiveLineHeightSize={responsiveLineHeightSize}
                responsiveTextSize={responsiveTextSize}
                responsiveTextLineHeightSize={responsiveTextLineHeightSize}
                w={'50%'}
                isGreen={generalsEnums.trueBoolean}
              />

              <InfoBox
                generalsEnums={generalsEnums}
                classes={classes}
                title={t('About.porpuse.title')}
                text={t('About.porpuse.text')}
                colors={colors}
                responsiveTitleSize={responsiveTitleSize}
                responsiveLineHeightSize={responsiveLineHeightSize}
                responsiveTextSize={responsiveTextSize}
                responsiveTextLineHeightSize={responsiveTextLineHeightSize}
                w={'45%'}
                isGreen={generalsEnums.trueBoolean}
              />
            </Box>
          </Box>
        </Box>

        <Box
          component={generalsEnums.BoxDiv}
          className={classes.boxOne}
          sx={{
            width: boxOneWidth,
            height: boxOneHeight,
          }}
        >
          <Image src={workerPhoto} alt="workerPhoto" className={classes.workerPhoto} />
        </Box>
      </Box>

      <Font
        text={t('About.values.title')}
        color={colors.BLACK}
        size={responsiveTitleSize}
        lineHeight={responsiveLineHeightSize}
        isHead={generalsEnums.trueBoolean}
        mt="46px"
      />

      <Box component={generalsEnums.BoxDiv} className={classes.boxValues}>
        {values.map((value) => {
          return (
            <Box
              key={value.id}
              component={generalsEnums.BoxDiv}
              className={classes.boxValue}
              sx={{
                width: boxValuesWidth,
                height: boxValuesHeight,
              }}
            >
              <Box
                component={generalsEnums.BoxDiv}
                sx={{
                  width: valueIconSize,
                  height: valueIconSize,
                }}
              >
                <Image src={value.icon} alt={value.title} className={classes.workerPhoto} />
              </Box>

              <Font
                text={value.title}
                color={colors.BLACK}
                size={valueTitleSize}
                lineHeight={valueTitleLineHeightSize}
                isHead={generalsEnums.trueBoolean}
              />

              <Font
                text={value.text}
                color={colors.BLACK}
                size={valueTextSize}
                lineHeight={valueTextLineHeightSize}
                isHead={generalsEnums.falseBoolean}
              />
            </Box>
          );
        })}
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.responsiveBox}
        sx={{
          flexDirection: boxOneFlexDirection,
          gap: boxOneGap,
          marginTop: boxOneMarginTop,
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          className={classes.boxOne}
          sx={{
            width: boxOneWidth,
            height: boxPorpuseHeight,
          }}
        >
          <Box component={generalsEnums.BoxDiv} className={classes.boxOneInfo}>
            <InfoBox
              generalsEnums={generalsEnums}
              classes={classes}
              title={t('About.background.title')}
              text={t('About.background.text')}
              colors={colors}
              responsiveTitleSize={responsiveTitleSize}
              responsiveLineHeightSize={responsiveLineHeightSize}
              responsiveTextSize={responsiveTextSize}
              responsiveTextLineHeightSize={responsiveTextLineHeightSize}
              w={'100%'}
              isGreen={generalsEnums.falseBoolean}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
