import React from 'react';
import { Popper, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  popper: {
    width: 'auto',
  },
  paper: {
    padding: '4px',
  },
}));

const PopperAttom = ({ anchorRef, open, setOpen, children, isCloseBlock }) => {
  const classes = useStyles();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (isCloseBlock) {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClose);
    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
  }, []);

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      placement="bottom-start"
      className={classes.popper}
      disablePortal={false}
    >
      <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
    </Popper>
  );
};

export default PopperAttom;
