import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class Products extends Axios {
  constructor() {
    super();
    this.urlListProducts = API_ROUTES.products.listProducts;
    this.urlListAllProducts = API_ROUTES.products.listAllProducts;
    this.urlDeleteProduct = API_ROUTES.products.deleteProduct;
    this.urlsearchProductByNameAndCodes = API_ROUTES.products.searchProductByNameAndCodes;
    this.ulrSearchProductById = API_ROUTES.products.searchProductById;
    this.urlGetDeliveryOptions = API_ROUTES.products.getDeliveryOptions;
    this.urlAddNewProducts = API_ROUTES.products.addNewProducts;
    this.urlEditProduct = API_ROUTES.products.editProduct;
    this.urltvhImportProducts = API_ROUTES.products.tvhImportProducts;
    this.urlsignedUrlFile = API_ROUTES.portalSettings.signedUrlFile;
    this.urlproductsPagination = API_ROUTES.products.productsPagination;
    this.urlproductsSearchEngine = API_ROUTES.products.productsSearchEngine;
    this.urlgetFreightCost = API_ROUTES.products.getFreightCost;
    this.urlgetTopSellingProducts = API_ROUTES.products.getTopSellingProducts;
  }

  listAllProducts() {
    return this.get(this.urlListAllProducts);
  }

  deleteProduct(_id) {
    return this.delete(this.urlDeleteProduct, { data: { _id } });
  }

  searchProductByNameAndCodes(input) {
    return this.post(this.urlsearchProductByNameAndCodes, { data: { input } });
  }

  searchProductById(productId, country) {
    return this.get(this.ulrSearchProductById.replace(':productId', productId).replace(':country', country));
  }

  getDeliveryOptions(productId, quantity, zipCode) {
    return this.get(
      this.urlGetDeliveryOptions
        .replace(':productId', productId)
        .replace(':quantity', quantity)
        .replace(':zipCode', zipCode)
    );
  }

  addNewProducts(body) {
    return this.post(this.urlAddNewProducts, body);
  }

  editProduct(body) {
    return this.put(this.urlEditProduct, body);
  }

  async tvhImportProducts(body) {
    return this.post(this.urltvhImportProducts, body);
  }

  async signedUrlFile(body) {
    return this.post(this.urlsignedUrlFile, body);
  }

  async productsPagination(body) {
    return this.post(this.urlproductsPagination, body);
  }

  async productsSearchEngine(body) {
    return this.post(this.urlproductsSearchEngine, body);
  }

  async getFreightCost(body) {
    return this.post(this.urlgetFreightCost, body);
  }

  async getTopSellingProducts() {
    return this.get(this.urlgetTopSellingProducts);
  }
}

export default Products;
