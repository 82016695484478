import React from 'react';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Box, Button } from '@mui/material';

import generalsEnums from '../../utils/Generals';
import Font from '../../Components/Generals/Fonts/Font';
import Addresses from '../../entities/Addresses';
import { useStyles } from './DeleteAddressModal.styles';
import { selectPortalLanguage } from '../../Redux/Slices/Portal.Slices';
import {
  selectDeleteAddressModalState,
  setDeleteAddressModalState,
  selectAddressIdToDelete,
  setSnackBar,
  setBackDropState,
  setAddressesUpdated,
  setToastSuccessMessage,
  setIsToastSuccess,
  setToastError,
} from '../../Redux/Slices/Navigation.Slices';
import * as colors from '../../assets/GlobalColors';

const BlurryDialog = styled(({ displayModal, ...other }) => <Dialog {...other} />)(({ displayModal }) => ({
  backdropFilter: displayModal ? 'blur(5px)' : 'none',
}));

const OptionButton = ({ text, cancel, handleClick, classes }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Button
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      variant="text"
      className={classes.optionButton}
      sx={{
        backgroundColor: cancel ? colors.GREEN_MAIN : colors.WHITE,
        border: `1px solid ${cancel ? colors.GREEN_MAIN : colors.GRAY_BG}`,

        '&:hover': {
          backgroundColor: cancel ? colors.GREEN_MAIN : colors.GRAY_BG,
          border: `1px solid ${cancel ? colors.GREEN_MAIN : colors.GRAY_BG}`,
          opacity: cancel ? '0.8' : '1',
        },
      }}
    >
      <Font
        text={text}
        color={cancel ? colors.BLACK : isHovered ? colors.WHITE : colors.BLACK}
        size={16}
        lineHeight="24px"
        isHead={false}
      />
    </Button>
  );
};

const DeleteAddressModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('global');
  const addressApi = new Addresses();

  const [displayModal, setDisplayModal] = React.useState(generalsEnums.trueBoolean);

  const cartModalState = useSelector(selectDeleteAddressModalState);
  const addressIdToDelete = useSelector(selectAddressIdToDelete);
  const language = useSelector(selectPortalLanguage);

  const handleCloseModal = () => {
    dispatch(setDeleteAddressModalState(generalsEnums.falseBoolean));
  };

  const handleDeleteAddress = async (e) => {
    e.preventDefault();
    if (addressIdToDelete !== '') {
      dispatch(setBackDropState(generalsEnums.trueBoolean));
      setDisplayModal(generalsEnums.falseBoolean);
      const res = await addressApi.deleteUserAddressHandler({
        addressId: addressIdToDelete,
      });
      const { statusCode, messages } = res;

      if (statusCode === 200) {
        dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
        dispatch(setToastSuccessMessage(language === generalsEnums.languages.spanish ? messages.es : messages.en));
        dispatch(setAddressesUpdated(generalsEnums.trueBoolean));

        handleCloseModal();
      } else {
        dispatch(setToastError(generalsEnums.trueBoolean));
        dispatch(setToastSuccessMessage(language === generalsEnums.languages.spanish ? messages.es : messages.en));
        dispatch(setBackDropState(generalsEnums.falseBoolean));
        handleCloseModal();
      }
    } else {
      dispatch(setToastError(generalsEnums.trueBoolean));
      dispatch(setToastSuccessMessage(t('client.MyAddresses.modal.emptyId')));
    }
  };

  // useEffect para reiniciar los states cuando se cierra el modal
  React.useEffect(() => {
    if (cartModalState === generalsEnums.trueBoolean) {
      setDisplayModal(generalsEnums.trueBoolean);
    }
  }, [cartModalState]);

  return (
    <BlurryDialog onClose={handleCloseModal} open={cartModalState} id="cart-modal" displayModal={displayModal}>
      <Box
        component="div"
        className={classes.root}
        sx={{
          display: displayModal ? 'flex' : 'none',
        }}
      >
        <Font
          text={t('client.MyAddresses.modal.title')}
          color={colors.BLACK}
          size={16}
          lineHeight="24px"
          isHead={true}
        />

        <Font
          text={t('client.MyAddresses.modal.warning')}
          color={colors.TSADMIN_RED_DELETE}
          size={16}
          lineHeight="24px"
          isHead={false}
        />

        <Box component="div" className={classes.buttonsBox}>
          <OptionButton
            text={t('client.MyAddresses.modal.btnNo')}
            cancel={true}
            handleClick={handleCloseModal}
            classes={classes}
          />

          <OptionButton
            text={t('client.MyAddresses.modal.btnYes')}
            cancel={false}
            handleClick={handleDeleteAddress}
            classes={classes}
          />
        </Box>
      </Box>
    </BlurryDialog>
  );
};

export default DeleteAddressModal;
