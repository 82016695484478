import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { setSnackBar } from '../../../../Redux/Slices/Navigation.Slices';

const ErrorMessages = ({ errorCode }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    if (errorCode !== null) {
      switch (errorCode) {
        case 'generic_decline':
          setErrorMessage(t('stripe.errors.generic_decline'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.generic_decline'),
            })
          );
          break;
        case 'card_declined':
          setErrorMessage(t('stripe.errors.cardDeclined'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.cardDeclined'),
            })
          );
          break;
        case 'insufficient_funds':
          setErrorMessage(t('stripe.errors.insufficientFunds'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.insufficientFunds'),
            })
          );
          break;
        case 'lost_card':
          setErrorMessage(t('stripe.errors.lost_card'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.lost_card'),
            })
          );
          break;
        case 'stolen_card':
          setErrorMessage(t('stripe.errors.stolen_card'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.stolen_card'),
            })
          );
          break;
        case 'expired_card':
          setErrorMessage(t('stripe.errors.expired_card'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.expired_card'),
            })
          );
          break;
        case 'incorrect_cvc':
          setErrorMessage(t('stripe.errors.incorrect_cvc'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.incorrect_cvc'),
            })
          );
          break;
        case 'processing_error':
          setErrorMessage(t('stripe.errors.processing_error'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.processing_error'),
            })
          );
          break;
        case 'incorrect_number':
          setErrorMessage(t('stripe.errors.incorrect_number'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.incorrect_number'),
            })
          );
          break;
        default:
          setErrorMessage(t('stripe.errors.generic_decline'));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: 'error',
              snackBarMessage: t('stripe.errors.generic_decline'),
            })
          );
          break;
      }
    }
  }, [errorCode]);

  if (errorCode !== null) {
    return (
      <React.Fragment>
        <Typography variant="string" className="test-14">
          {errorMessage}
        </Typography>
      </React.Fragment>
    );
  }

  return null;
};

export default ErrorMessages;
