import React from 'react';
import { Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import Font from '../../Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import * as colors from '../../../../assets/GlobalColors';

const TsiButton = ({
  type,
  handleAction,
  text,
  isDisabled,
  size,
  lineHeight,
  icon,
  br,
  h,
  children,
  formikType,
  jc,
  head,
  hasPadding,
  hasCenter,
}) => {
  const [isFocused, setIsFocused] = React.useState(generalsEnums.falseBoolean);
  const [normalColor, setNormalColor] = React.useState(generalsEnums.emptyString);
  const [hoverColor, setHoverColor] = React.useState(generalsEnums.emptyString);
  const [textColor, setTextColor] = React.useState(generalsEnums.emptyString);
  const [textHoverColor, setTextHoverColor] = React.useState(generalsEnums.emptyString);
  const [isHead, setIsHead] = React.useState(generalsEnums.falseBoolean);
  const [paddingValue, setPaddingValue] = React.useState();

  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);
  const isHorizontal = useMediaQuery('(orientation: landscape) and (min-width: 768px)');
  const heightValue = IpadAir || IpadMini ? (isHorizontal ? '40px' : '24px') : '42px';
  const paddingValueQueries = IpadAir || IpadMini ? (isHorizontal ? '16px' : '14px') : '16px';

  React.useEffect(() => {
    switch (type) {
      case generalsEnums.buttonTypes.save:
        setNormalColor(colors.GREEN_MAIN);
        setHoverColor(colors.GREEN_MAINHOVER);
        setTextColor(colors.BLACK);
        setTextHoverColor(colors.BLACK);
        break;
      case generalsEnums.buttonTypes.black:
        setNormalColor(colors.WHITE);
        setHoverColor(colors.BLACK);
        setTextColor(colors.WHITE);
        setTextHoverColor(colors.BLACK);
        break;
      case generalsEnums.buttonTypes.transparent:
        setNormalColor('transparent');
        setHoverColor('transparent');
        setTextColor(colors.BLACK);
        setTextHoverColor(colors.BLACK);
        break;

      default:
        setNormalColor(colors.GREEN_MAIN);
        setHoverColor(colors.GREEN_MAINHOVER);
        setTextColor(colors.BLACK);
        break;
    }
  }, [type]);

  React.useEffect(() => {
    if (hasPadding !== generalsEnums.undefinedData) {
      setPaddingValue(hasPadding);
    } else {
      setPaddingValue(paddingValueQueries);
    }
  }, [hasPadding]);

  React.useEffect(() => {
    if (head !== generalsEnums.undefinedData) {
      setIsHead(head);
    } else {
      setIsHead(generalsEnums.trueBoolean);
    }
  }, [head]);

  return (
    <Button
      type={formikType}
      variant="text"
      onClick={handleAction}
      onMouseEnter={() => setIsFocused(generalsEnums.trueBoolean)}
      onMouseLeave={() => setIsFocused(generalsEnums.falseBoolean)}
      disabled={isDisabled}
      sx={{
        width: '100%',
        height: h ? h : heightValue,
        borderRadius: br ? br : '8px',
        backgroundColor: normalColor,
        border: `1px solid ${normalColor === colors.WHITE ? colors.BLACK : normalColor}`,
        transition: 'background-color 0.3s ease-in-out',
        display: 'flex',
        padding: paddingValue,
        flexDirection: 'row',
        justifyContent: jc ? jc : 'center',

        '&:hover': {
          backgroundColor: hoverColor,
        },
      }}
    >
      {icon}

      <Font
        text={text}
        color={isFocused ? textColor : textHoverColor}
        size={size || generalsEnums.fontSizes.size14.size}
        lineHeight={lineHeight || generalsEnums.fontSizes.size14.lineHeight}
        isHead={isHead}
        isCenter={hasCenter ? generalsEnums.trueBoolean : generalsEnums.falseBoolean}
        ml="0px"
      />

      {children}
    </Button>
  );
};

export default TsiButton;
