import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import TsiButton from '../../../../../Components/Generals/Figma/Atoms/TsiButton';
import generalsEnums from '../../../../../utils/Generals';
import LogBadge from './LogBadge';
import Font from '../../../../../Components/Generals/Fonts/Font';
import { selectPortalLanguage } from '../../../../../Redux/Slices/Portal.Slices';
import {
  setViewMailTemplateModalState,
  setViewMailTemplateModalObject,
} from '../../../../../Redux/Slices/Navigation.Slices';
import useStyles from './styles';
import * as colors from '../../../../../assets/GlobalColors';

const LogMailCard = ({
  logItem,
  text18Size,
  text18LineHeight,
  text12Size,
  text12LineHeight,
  text16Size,
  text16LineHeight,
  orderNumber,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const dispatch = useDispatch();

  const [logDate, setLogDate] = React.useState(generalsEnums.emptyString);
  const [logTitle, setLogTitle] = React.useState(generalsEnums.emptyString);
  const [logMailDataBody = generalsEnums.emptyString, setLogMailDataBody] = React.useState(generalsEnums.emptyObject);
  const [displayDetailsButton, setDisplayDetailsButton] = React.useState(generalsEnums.falseBoolean);
  const portalLanguage = useSelector(selectPortalLanguage);

  const handleClickOpenModal = () => {
    dispatch(setViewMailTemplateModalState(generalsEnums.trueBoolean));
    dispatch(setViewMailTemplateModalObject(logMailDataBody));
  };

  React.useEffect(() => {
    setLogDate(logItem.date);
    setLogMailDataBody({
      ...logItem,
      orderNumber,
    });
    if (logItem.mailDataBody) {
      setDisplayDetailsButton(true);
    } else {
      setDisplayDetailsButton(false);
    }
  }, [logItem]);

  React.useEffect(() => {
    if (portalLanguage === generalsEnums.languages.english) {
      setLogTitle(logItem.en.text || logItem.en.title);
    } else {
      setLogTitle(logItem.es.text || logItem.es.title);
    }
  }, [logItem, portalLanguage]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.logHistoryCard}
      sx={{
        width: '100%',
      }}
    >
      <LogBadge kind={logItem.kind} />

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.logHistoryText}
        sx={{
          borderRadius: '8px',
          border: `1px solid ${colors.BORDER_GRAY_1}}`,
        }}
      >
        <Box component="div" className={classes.logHistoryTextTitle}>
          <Font
            text={t('LogMailCard.title')}
            color={colors.BLACK}
            size={text18Size}
            lineHeight={text18LineHeight}
            isHead={true}
            isCenter={false}
          />

          <Font
            text={logDate}
            color={colors.TEXT_GRAY_666}
            size={text12Size}
            lineHeight={text12LineHeight}
            isHead={true}
            isCenter={false}
          />
        </Box>

        <Font
          text={logTitle}
          color={colors.BLACK}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={false}
          isCenter={false}
        />

        {displayDetailsButton && (
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: '180px',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.save}
              handleAction={handleClickOpenModal}
              text={t('LogMailCard.button')}
              isDisabled={generalsEnums.falseBoolean}
              size={generalsEnums.fontSizes.size12.size}
              lineHeight={generalsEnums.fontSizes.size12.lineHeight}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LogMailCard;
