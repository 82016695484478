import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import OrderDetailsOrganism from '../../../Components/Generals/Figma/Organisms/OrderDetailsOrganism';
import Font from '../../Generals/Fonts/Font';
import CurrencyFont from '../../Generals/Fonts/CurrencyFont';
import OrderSummaryCard from '../../../Components/Client/Checkout/OrderSummary/OrderSummaryCard';
import StepperAttoms from '../../Generals/Figma/Atoms/StepperAttoms';
import generalsEnums from '../../../utils/Generals';
import ResponsiveComponent from '../../../Hooks/ResponsiveComponent';
import { getOrderFormatDate, formatProductQuantity } from '../../../utils/FormatData';
import { selectPortalLanguage } from '../../../Redux/Slices/Portal.Slices';
import { getResponsiveValues } from '../../../utils/FormatData';
import { useStyles, colors } from './OrderAccordion.styles';

const OrderAccordion = ({ order, text12to16Size, text12to16LineHeight, StepperAttomsSize }) => {
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [orderNumber, setOrderNumber] = React.useState(generalsEnums.emptyString);
  const [orderTotal, setOrderTotal] = React.useState(generalsEnums.zeroNumber);
  const [productsOrder, setProductsOrder] = React.useState(generalsEnums.emptyArray);
  const [orderSubTotal, setOrderSubTotal] = React.useState(generalsEnums.zeroNumber);
  const [orderDelivery, setOrderDelivery] = React.useState(generalsEnums.zeroNumber);
  const [orderTaxes, setOrderTaxes] = React.useState(generalsEnums.zeroNumber);
  const [orderDate, setOrderDate] = React.useState(generalsEnums.emptyString);
  const [productsItems, setProductsItems] = React.useState(generalsEnums.emptyString);
  const [productsLength, setProductsLength] = React.useState(generalsEnums.emptyString);
  const [deliveryName, setDeliveryName] = React.useState(generalsEnums.emptyString);
  const [deliveryContact, setDeliveryContact] = React.useState(generalsEnums.emptyString);
  const [deliveryStreet, setDeliveryStreet] = React.useState(generalsEnums.emptyString);
  const [deliveryCity, setDeliveryCity] = React.useState(generalsEnums.emptyString);
  const [deliveryComments, setDeliveryComments] = React.useState(generalsEnums.emptyString);
  const [billingName, setBillingName] = React.useState(generalsEnums.emptyString);
  const [billingContact, setBillingContact] = React.useState(generalsEnums.emptyString);
  const [billingStreet, setBillingStreet] = React.useState(generalsEnums.emptyString);
  const [billingCity, setBillingCity] = React.useState(generalsEnums.emptyString);
  const [orderStatusData, setOrderStatusData] = React.useState(generalsEnums.emptyObject);

  const languagePortal = useSelector(selectPortalLanguage);

  React.useEffect(() => {
    const { order: orderData, orderStatus, address, billingAddress } = order;
    const { orderNumber, orderTotal, orderProducts, subTotal, orderDelivery, orderTaxes, orderCreateDate } = orderData;
    const orderTotalFloat = parseFloat(orderTotal);

    setOrderStatusData(orderStatus);
    setOrderNumber(orderNumber);
    setOrderTotal(orderTotalFloat);
    setProductsOrder(orderProducts);
    setOrderSubTotal(subTotal);
    setOrderDelivery(orderDelivery);
    setOrderTaxes(orderTaxes);

    setOrderDate(getOrderFormatDate(orderCreateDate, languagePortal));
    const formatProductQuantityData = formatProductQuantity(orderProducts);
    const productsItemsInfo = `${formatProductQuantityData.productsQ} ${t('Order.p')}, ${
      formatProductQuantityData.itemsQ
    } ${t('Order.i')}`;
    setProductsItems(productsItemsInfo);
    setProductsLength(`${orderProducts.length} ${t('Order.products')}`);
    setDeliveryName(`${address.name} ${address.lastName}`);
    setDeliveryContact(
      `${address.workEnterprise}${address.workEnterprise !== generalsEnums.emptyString ? ', ' : ''} ${address.phone}`
    );
    setDeliveryStreet(
      `${address.street}${address.apartment !== generalsEnums.emptyString ? ', ' : ''} ${address.apartment}`
    );
    setDeliveryCity(`${address.city}, ${address.state}, ${address.zipCode}, ${address.country}`);
    setDeliveryComments(address.adititionalInfo);
    setBillingName(`${billingAddress.name} ${billingAddress.lastName}`);
    setBillingContact(
      `${billingAddress.workEnterprise}${billingAddress.workEnterprise !== generalsEnums.emptyString ? ', ' : ''} ${
        billingAddress.phone
      }`
    );
    setBillingStreet(
      `${billingAddress.street}${billingAddress.apartment !== generalsEnums.emptyString ? ', ' : ''} ${
        billingAddress.apartment
      }`
    );
    setBillingCity(
      `${billingAddress.city}, ${billingAddress.state}, ${billingAddress.zipCode}, ${billingAddress.country}`
    );
  }, [order]);

  return (
    <Accordion
      sx={{
        width: '100%',
      }}
    >
      <AccordionSummary className={classes.AccordionSummary} expandIcon={<ExpandMoreIcon />}>
        <Box component={generalsEnums.BoxDiv} className={classes.AccordionSummaryBox}>
          <Box
            component={generalsEnums.BoxDiv}
            className={classes.AccordionSummaryInfoBox}
            sx={{
              width: 'auto',
            }}
          >
            <Box component={generalsEnums.BoxDiv} className={classes.AccordionSummaryInfoItemBox}>
              <Font
                text={t('client.OrderAccordion.text-orderNumber')}
                color={colors.BLACK}
                size={text12to16Size}
                lineHeight={text12to16LineHeight}
                isHead={generalsEnums.trueBoolean}
                ml="0px"
              />

              <Font
                text={orderNumber}
                color={colors.BLACK}
                size={text12to16Size}
                lineHeight={text12to16LineHeight}
                isHead={generalsEnums.falseBoolean}
                ml="0px"
              />
            </Box>

            <Box component={generalsEnums.BoxDiv} className={classes.AccordionSummaryInfoItemBox}>
              <Font
                text={t('client.OrderAccordion.text-total')}
                color={colors.BLACK}
                size={text12to16Size}
                lineHeight={text12to16LineHeight}
                isHead={generalsEnums.trueBoolean}
                ml="0px"
              />

              <CurrencyFont
                price={orderTotal}
                color={colors.BLACK}
                size={text12to16Size}
                lineHeight={text12to16LineHeight}
                isHead={generalsEnums.falseBoolean}
                change={generalsEnums.falseBoolean}
              />
            </Box>
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            className={classes.AccordionSummaryInfoBox}
            sx={{
              width: StepperAttomsSize,
            }}
          >
            <StepperAttoms w="100%" orderStatusData={orderStatusData} />
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          width: '100%',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <OrderSummaryCard
            isSummary={generalsEnums.trueBoolean}
            handlePrintOrderTicket={generalsEnums.nullData}
            productsOrder={productsOrder}
            orderSubTotal={orderSubTotal}
            orderDelivery={orderDelivery}
            orderTaxes={orderTaxes}
            orderTotal={orderTotal}
            canEdit={generalsEnums.nullData}
            orderSummary={generalsEnums.trueBoolean}
            w={'100%'}
          />

          <OrderDetailsOrganism
            orderDate={orderDate}
            productsItems={productsItems}
            productsLength={productsLength}
            deliveryName={deliveryName}
            deliveryContact={deliveryContact}
            deliveryStreet={deliveryStreet}
            deliveryCity={deliveryCity}
            deliveryComments={deliveryComments}
            billingName={billingName}
            billingContact={billingContact}
            billingStreet={billingStreet}
            billingCity={billingCity}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export const Main = ({ order }) => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
    ['350px', '350px', '350px', '500px', '800px'],
  ];

  const [text12to16Size, text12to16LineHeight, StepperAttomsSize] = getResponsiveValues(
    responsiveArray,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl
  );

  return (
    <OrderAccordion
      order={order}
      text12to16Size={text12to16Size}
      text12to16LineHeight={text12to16LineHeight}
      StepperAttomsSize={StepperAttomsSize}
    />
  );
};

export default Main;
