import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import generalsEnums from '../../utils/Generals';

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      component="div"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        width: '100%',
      }}
    >
      {value === index && <Box component="div">{children}</Box>}
    </Box>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const LazyTabs = React.lazy(() => import('@mui/material/Tabs'));
const LazyTab = React.lazy(() => import('@mui/material/Tab'));

const CustomTabs = ({ bColor, border, textColor, indicatorColor, tabs }) => {
  const [value, setValue] = React.useState(generalsEnums.zeroNumber);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Suspense fallback={<div />}>
      <LazyTabs
        value={value}
        onChange={handleChange}
        aria-label="ant example"
        sx={{
          width: '100%',
          backgroundColor: bColor,
          border: border,
          borderBottom: border === 'none' ? `1px sold ${indicatorColor}` : border,
          borderRadius: '6px',
          '& .MuiTabs-indicator': {
            height: '3px',
            backgroundColor: indicatorColor,
          },
        }}
      >
        {tabs.map((tab, idx) => {
          return (
            <LazyTab
              key={idx}
              label={tab.label}
              sx={{
                textTransform: 'none',
                color: textColor,
                '&.Mui-selected': {
                  color: textColor,
                },
                '&:hover': {
                  color: textColor,
                  opacity: 1,
                },
                '&.Mui-focusVisible': {
                  color: textColor,
                },
              }}
            />
          );
        })}
      </LazyTabs>

      {tabs.map((tab, idx) => {
        return (
          <CustomTabPanel key={idx} value={value} index={tab.value}>
            {tab.component}
          </CustomTabPanel>
        );
      })}
    </React.Suspense>
  );
};

export default CustomTabs;
