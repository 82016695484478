import React from 'react';
import { Box, FormControl, NativeSelect, Checkbox } from '@mui/material';

import generalsEnums from '../../utils/Generals';
import Font from '../../Components/Generals/Fonts/Font';
import FormikTextField from '../../Components/Generals/FormikTextField';
import { useStyles } from './ClientInfoModal.styles';
import * as colors from '../../assets/GlobalColors';

const Info = ({ t, formik, clientInfoOnlyView, action }) => {
  const classes = useStyles();

  const [price, setPrice] = React.useState(generalsEnums.zeroNumber);
  const [groupSelected, setGroupSelected] = React.useState(generalsEnums.emptyString);
  const [allowMarketingEmailsSelected, setAllowMarketingEmailsSelected] = React.useState(generalsEnums.falseBoolean);

  const handlePriceFormat = (event, setState, formikName) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    if (regex.test(inputValue)) {
      setState(inputValue);
      formik.setFieldValue(formikName, inputValue);
    } else {
      setState(parseFloat(inputValue.slice(0, -1)));
      formik.setFieldValue(formikName, parseFloat(inputValue.slice(0, -1)));
    }
  };

  const handleGroupChange = (event) => {
    setGroupSelected(event.target.value);
    formik.setFieldValue('group', event.target.value);
  };

  const handleAllowMarketingEmailsChange = (event) => {
    setAllowMarketingEmailsSelected(event.target.checked);
    formik.setFieldValue('allowMarketingEmails', event.target.checked);
  };

  React.useEffect(() => {
    setAllowMarketingEmailsSelected(formik.values.allowMarketingEmails);
    setPrice(formik.values.credit);
  }, [formik]);

  return (
    <Box component="div" className={classes.InfoRoot}>
      <Box
        component="div"
        className={classes.InfoRow}
        sx={{
          flexDirection: 'column',
          padding: '0px 25px 0px 0px',
          borderRight: '1px solid #e0e0e0',
        }}
      >
        <Box
          component="div"
          className={classes.InfoRow}
          sx={{
            flexDirection: 'row',
          }}
        >
          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.name')} *`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormikTextField
              itemValue={formik.values.name}
              itemSize="medium"
              itemId="name"
              itemName="name"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.name ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: '350px',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={t('Modals.ClientInfoModal.Info.phName')}
              itemMultiline={false}
              itemRows={1}
              itemMoney={false}
              itemDisabled={clientInfoOnlyView}
              itemZipCode={false}
            />
          </Box>

          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.lastName')} *`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormikTextField
              itemValue={formik.values.lastName}
              itemSize="medium"
              itemId="lastName"
              itemName="lastName"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.lastName ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: '350px',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={t('Modals.ClientInfoModal.Info.phLastName')}
              itemMultiline={false}
              itemRows={1}
              itemMoney={false}
              itemDisabled={clientInfoOnlyView}
              itemZipCode={false}
            />
          </Box>
        </Box>

        <Box
          component="div"
          className={classes.InfoRow}
          sx={{
            flexDirection: 'row',
          }}
        >
          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.email')} *`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormikTextField
              itemValue={formik.values.email}
              itemSize="medium"
              itemId="email"
              itemName="email"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.email ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: '350px',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={t('Modals.ClientInfoModal.Info.phEmail')}
              itemMultiline={false}
              itemRows={1}
              itemMoney={false}
              itemDisabled={clientInfoOnlyView}
              itemZipCode={false}
            />
          </Box>

          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.phoneNumber')} *`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormikTextField
              itemValue={formik.values.phoneNumber}
              itemSize="medium"
              itemId="phoneNumber"
              itemName="phoneNumber"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.phoneNumber ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: '350px',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={t('Modals.ClientInfoModal.Info.phPhoneNumber')}
              itemMultiline={false}
              itemRows={1}
              itemMoney={false}
              itemDisabled={clientInfoOnlyView}
              itemZipCode={false}
            />
          </Box>
        </Box>

        <Box
          component="div"
          className={classes.InfoRow}
          sx={{
            flexDirection: 'row',
          }}
        >
          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.workEnterprise')}`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormikTextField
              itemValue={formik.values.workEnterprise}
              itemSize="medium"
              itemId="workEnterprise"
              itemName="workEnterprise"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.workEnterprise ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: '350px',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={t('Modals.ClientInfoModal.Info.phWorkEnterprise')}
              itemMultiline={false}
              itemRows={1}
              itemMoney={false}
              itemDisabled={clientInfoOnlyView}
              itemZipCode={false}
            />
          </Box>

          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.taxCode')}`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormikTextField
              itemValue={formik.values.taxCode}
              itemSize="medium"
              itemId="taxCode"
              itemName="taxCode"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.taxCode ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: '350px',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={t('Modals.ClientInfoModal.Info.phTaxCode')}
              itemMultiline={false}
              itemRows={1}
              itemMoney={false}
              itemDisabled={clientInfoOnlyView}
              itemZipCode={false}
            />
          </Box>
        </Box>

        <Box
          component="div"
          className={classes.InfoRow}
          sx={{
            flexDirection: 'row',
          }}
        >
          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.credit')} *`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormikTextField
              itemValue={price}
              itemSize="medium"
              itemId="credit"
              itemName="credit"
              itemVariant="outlined"
              itemOnChange={(e) => {
                handlePriceFormat(e, setPrice, 'credit');
              }}
              itemStyle={{
                border: formik.errors.credit ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: '350px',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={generalsEnums.emptyString}
              itemMultiline={false}
              itemRows={1}
              itemMoney={false}
              itemDisabled={clientInfoOnlyView}
              itemZipCode={false}
            />
          </Box>

          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.group')}`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormControl fullWidth>
              <NativeSelect
                value={groupSelected}
                inputProps={{
                  name: 'group',
                  id: 'controlled-native',
                }}
                onChange={(e) => handleGroupChange(e)}
                variant="outlined"
                style={{
                  width: '350px',
                  height: '50px',
                  border: formik.errors.group ? '1.5px solid red' : `1.5px solid ${colors.GRAY_214_1}`,
                  borderRadius: '5px',
                  padding: '0 10px',
                }}
              >
                <option value={null} className="text-04 text-13">
                  {t('Modals.ClientInfoModal.Info.phGroup')}
                </option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Box>

        <Box
          component="div"
          className={classes.InfoRow}
          sx={{
            flexDirection: 'row',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Checkbox
              checked={allowMarketingEmailsSelected}
              onChange={(e) => {
                handleAllowMarketingEmailsChange(e);
              }}
              color="primary"
              sx={{
                '& .MuiSvgIcon-root': {
                  color: colors.GREEN_MAIN,
                },
              }}
            />

            <Font
              text={`${t('Modals.ClientInfoModal.Info.allowMarketingEmails')}`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />
          </Box>
        </Box>

        {action === 'new' ? (
          <Box
            component="div"
            className={classes.InfoRow}
            sx={{
              flexDirection: 'row',
            }}
          >
            <Box component="div" className={classes.InfoInputsRow}>
              <Font
                text={`${t('Modals.ClientInfoModal.Info.password')} *`}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={true}
              />

              <FormikTextField
                itemValue={formik.values.password}
                itemSize="medium"
                itemId="password"
                itemName="password"
                itemVariant="outlined"
                itemOnChange={(e) => {
                  formik.handleChange(e);
                }}
                itemStyle={{
                  border: formik.errors.password ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                  borderRadius: '5px',
                  width: '350px',
                  backgroundColor: colors.WHITE,
                }}
                itemType="text"
                itemPlaceHolderColor={colors.BLACK}
                itemPlaceHolderSize="13px"
                itemPlaceholder={t('Modals.ClientInfoModal.Info.phPassword')}
                itemMultiline={false}
                itemRows={1}
                itemMoney={false}
                itemDisabled={clientInfoOnlyView}
                itemZipCode={false}
              />
            </Box>

            <Box component="div" className={classes.InfoInputsRow}>
              <Font
                text={`${t('Modals.ClientInfoModal.Info.confirmPassword')} *`}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={true}
              />

              <FormikTextField
                itemValue={formik.values.confirmPassword}
                itemSize="medium"
                itemId="confirmPassword"
                itemName="confirmPassword"
                itemVariant="outlined"
                itemOnChange={(e) => {
                  formik.handleChange(e);
                }}
                itemStyle={{
                  border: formik.errors.confirmPassword ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                  borderRadius: '5px',
                  width: '350px',
                  backgroundColor: colors.WHITE,
                }}
                itemType="text"
                itemPlaceHolderColor={colors.BLACK}
                itemPlaceHolderSize="13px"
                itemPlaceholder={t('Modals.ClientInfoModal.Info.phConfirmPassword')}
                itemMultiline={false}
                itemRows={1}
                itemMoney={false}
                itemDisabled={clientInfoOnlyView}
                itemZipCode={false}
              />
            </Box>
          </Box>
        ) : null}
      </Box>

      <Box component="div" className={classes.InfoRow}>
        <Box
          component="div"
          className={classes.InfoRow}
          sx={{
            flexDirection: 'row',
          }}
        >
          <Box component="div" className={classes.InfoInputsRow}>
            <Font
              text={`${t('Modals.ClientInfoModal.Info.notes')}`}
              color={colors.BLACK}
              size={14}
              lineHeight="22px"
              isHead={true}
            />

            <FormikTextField
              itemValue={formik.values.userNotes}
              itemSize="medium"
              itemId="userNotes"
              itemName="userNotes"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.userNotes ? '1px solid red' : `1px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: '350px',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={t('Modals.ClientInfoModal.Info.phNotes')}
              itemMultiline={true}
              itemRows={4}
              itemMoney={false}
              itemDisabled={clientInfoOnlyView}
              itemZipCode={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Info;
