import React from 'react';
import { Box } from '@mui/material';

import RadioButtonAttom from '../Atoms/RadioButtonAttom';
import generalsEnums from '../../../../utils/Generals';
import * as colors from '../../../../assets/GlobalColors';

const RadioFieldMolecule = ({ isChecked, isDisabled, children, handleChange }) => {
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '24px',
        border: `1px solid ${colors.BORDER_CCC}`,
        borderRadius: generalsEnums.boderRadius.b8,
      }}
    >
      <RadioButtonAttom isChecked={isChecked} isDisabled={isDisabled} handleChange={handleChange} />

      {children}
    </Box>
  );
};

export default RadioFieldMolecule;
