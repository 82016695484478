import React from 'react';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as yup from 'yup';

import generalsEnums from '../../../../utils/Generals';
import Font from '../../../Generals/Fonts/Font';
import FormikTextField from '../../../Generals/FormikTextField';
import ErrorMessages from '../ErrorMessages/ErrorMessages';
import { useStyles } from './Payment.styles';
import * as colors from '../../../../assets/GlobalColors';

const Payment = ({ t, clientSecret, handleCreateOrder }) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();

  const [errorCode, setErrorCode] = React.useState(null);

  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: generalsEnums.emptyString,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        })
        .then(async function (result) {
          const { error, paymentIntent } = result;
          if (error) {
            setErrorCode(result.error.decline_code);
          }
          if (paymentIntent) {
            handleCreateOrder(paymentIntent);
          }
        });
    },
  });

  const options = {
    style: {
      base: {
        color: colors.BLACK_06,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: colors.BLACK_06,
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 'auto',
          padding: '20px',
          borderRadius: '8px',
          border: `1px solid ${colors.BLACK_06}`,
        }}
      >
        <FormikTextField
          itemSize="small"
          itemId="name"
          itemName="name"
          itemVariant="outlined"
          itemOnChange={formik.handleChange}
          itemStyle={{
            border: formik.errors.name ? '0.4px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
            borderRadius: '5px',
            width: '400px',
            backgroundColor: colors.GRAY_BG_04,
            marginBottom: '20px',
          }}
          itemType="text"
          itemPlaceHolderColor={colors.BLACK}
          itemPlaceHolderSize="13px"
          itemPlaceholder="Name"
        />

        <CardElement options={options} displayName="a" />

        <Button type="submit" variant="contained" className={classes.button}>
          <Font text={t('Generals.pay-order')} color={colors.BLACK} size={16} lineHeight="26px" isHead={false} />
        </Button>
      </div>

      <ErrorMessages errorCode={errorCode} />
    </form>
  );
};

export default Payment;
