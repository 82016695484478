import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import RouterIndex from './Router/RouterIndex';
import global_en from './translation/en/global.json';
import global_es from './translation/es/global.json';
import { selectPortalLanguage } from './Redux/Slices/Portal.Slices';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

const Aplication = () => {
  const portalLanguage = useSelector(selectPortalLanguage);

  i18next.init({
    interpolation: { escapeValue: false },
    lng: portalLanguage,
    resources: {
      en: {
        global: global_en,
      },
      es: {
        global: global_es,
      },
    },
  });

  return (
    <React.Fragment>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <RouterIndex />
        </ThemeProvider>
      </I18nextProvider>
    </React.Fragment>
  );
};

export default Aplication;
