import React from 'react';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import LogoWhite from '../../../assets/Logos/LogoWhite.svg';
import Font from '../../../Components/Generals/Fonts/Font';
import Paths from '../../../utils/enums/Paths';
import FocusButton from '../../../Components/Generals/FocusButton';
import * as colors from '../../../assets/GlobalColors';

const Building = ({ isAdmin }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const handleNavigateToHome = () => {
    navigate(isAdmin ? Paths.admin.Home : Paths.client.Home);
  };

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '90px',
        padding: '90px',
        backgroundColor: colors.GRAY_BG,
        border: `1px solid ${colors.GRAY_BG}`,
      }}
    >
      <Image src={LogoWhite} alt="product" />

      <Box
        component="div"
        sx={{
          width: '80%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '25px',
        }}
      >
        <Font
          text={isAdmin ? t('Building.admin') : t('Building.client')}
          color={colors.WHITE}
          size={36}
          lineHeight="46px"
          isHead={true}
          isCenter={true}
        />

        <Font
          text={t('Building.text')}
          color={colors.WHITE}
          size={20}
          lineHeight="28px"
          isHead={false}
          isCenter={true}
        />

        <Font
          text={t('Building.text2')}
          color={colors.WHITE}
          size={20}
          lineHeight="28px"
          isHead={false}
          mt="50px"
          isCenter={true}
        />
      </Box>

      <FocusButton
        handleAction={handleNavigateToHome}
        normalColor={colors.GREEN_MAIN}
        focusedColor={colors.BLACK_06}
        text={t('Building.button')}
        w="200px"
      />
    </Box>
  );
};

export default Building;
