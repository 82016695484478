import React from 'react';
import { Box } from '@mui/material';

import { useStyles } from './PersonalForm.styles';
import FormikTextField from '../../Generals/FormikTextField';
import * as colors from '../../../assets/GlobalColors';

const PersonalForm = ({
  phName,
  customerName,
  customerNameValue,
  nameError,
  phLastName,
  customerLastName,
  customerLastNameValue,
  lastNameError,
  phWorkEnterprise,
  customerWorkEnterprise,
  customerWorkEnterpriseValue,
  phPhone,
  customerPhone,
  customerPhoneValue,
  formik,
  onlyView,
}) => {
  const classes = useStyles();

  const handlePhoneNumberInput = (e) => {
    const { value } = e.target;
    const phone = value.replace(/[^0-9]+/g, '');

    if (phone.length <= 10) {
      formik.setFieldValue(customerPhone, phone);
    }
  };

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.row}>
        <Box
          component="div"
          style={{
            width: '50%',
            height: '100%',
          }}
        >
          <FormikTextField
            itemValue={customerNameValue}
            itemSize="medium"
            itemId={customerName}
            itemName={customerName}
            itemVariant="outlined"
            itemOnChange={(e) => {
              formik.handleChange(e);
            }}
            itemStyle={{
              border: nameError ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
              borderRadius: '5px',
              width: '450px',
              backgroundColor: colors.WHITE,
            }}
            itemType="text"
            itemPlaceHolderColor={colors.BLACK}
            itemPlaceHolderSize="13px"
            itemPlaceholder={phName}
            itemDisabled={onlyView}
          />
        </Box>

        <Box
          component="div"
          style={{
            width: '45%',
            height: '100%',
          }}
        >
          <FormikTextField
            itemValue={customerLastNameValue}
            itemSize="medium"
            itemId={customerLastName}
            itemName={customerLastName}
            itemVariant="outlined"
            itemOnChange={(e) => {
              formik.handleChange(e);
            }}
            itemStyle={{
              border: lastNameError ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
              borderRadius: '5px',
              width: '450px',
              backgroundColor: colors.WHITE,
            }}
            itemType="text"
            itemPlaceHolderColor={colors.BLACK}
            itemPlaceHolderSize="13px"
            itemPlaceholder={phLastName}
            itemDisabled={onlyView}
          />
        </Box>
      </Box>

      <Box component="div" className={classes.row}>
        <Box
          component="div"
          style={{
            width: '50%',
            height: '100%',
          }}
        >
          <FormikTextField
            itemValue={customerWorkEnterpriseValue}
            itemSize="medium"
            itemId={customerWorkEnterprise}
            itemName={customerWorkEnterprise}
            itemVariant="outlined"
            itemOnChange={(e) => {
              formik.handleChange(e);
            }}
            itemStyle={{
              border: `0.4px solid ${colors.GRAY_214_1}`,
              borderRadius: '5px',
              width: '450px',
              backgroundColor: colors.WHITE,
            }}
            itemType="text"
            itemPlaceHolderColor={colors.BLACK}
            itemPlaceHolderSize="13px"
            itemPlaceholder={phWorkEnterprise}
            itemDisabled={onlyView}
          />
        </Box>

        <Box
          component="div"
          style={{
            width: '45%',
            height: '100%',
          }}
        >
          <FormikTextField
            itemValue={customerPhoneValue}
            itemSize="medium"
            itemId={customerPhone}
            itemName={customerPhone}
            itemVariant="outlined"
            itemOnChange={(e) => {
              handlePhoneNumberInput(e);
            }}
            itemStyle={{
              border: lastNameError ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
              borderRadius: '5px',
              width: '450px',
              backgroundColor: colors.WHITE,
            }}
            itemType="text"
            itemPlaceHolderColor={colors.BLACK}
            itemPlaceHolderSize="13px"
            itemPlaceholder={phPhone}
            itemDisabled={onlyView}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalForm;
