import React from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import _debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
  IconButton,
  Button,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';

import BadgeAttom from '../../../../Components/Generals/Figma/Atoms/BadgeAttom';
import StepperAttoms from '../../../../Components/Generals/Figma/Atoms/StepperAttoms';
import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import CheckboxAttom from '../../../../Components/Generals/Figma/Atoms/CheckboxAttom';
import * as colors from '../../../../assets/GlobalColors';

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const OrdersTable = ({
  rows,
  displayStatusCb,
  handleSelectAll,
  handleSelectOne,
  idsSelected,
  handleIsIdSelected,
  handleNavigateToOrder,
}) => {
  const { t } = useTranslation('global');

  const [page, setPage] = React.useState(generalsEnums.zeroNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(generalsEnums.TableRowsPerPage.ten);
  const isAllSelected = idsSelected.length === rows.length;

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        style={{
          width: '100%',
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '5px',
          marginTop: '30px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {displayStatusCb && <CheckboxAttom handleSelect={handleSelectAll} isChecked={isAllSelected} />}
              </TableCell>

              <TableCell align="left">
                <Font
                  text={t('admin.ViewOrders.tableHeaders.h0')}
                  color={colors.BLACK}
                  size={generalsEnums.fontSizes.size16.size}
                  lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                  isHead={generalsEnums.falseBoolean}
                />
              </TableCell>

              <TableCell align="left">
                <Font
                  text={t('admin.ViewOrders.tableHeaders.h1')}
                  color={colors.BLACK}
                  size={generalsEnums.fontSizes.size16.size}
                  lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                  isHead={generalsEnums.falseBoolean}
                />
              </TableCell>

              <TableCell align="left">
                <Font
                  text={t('admin.ViewOrders.tableHeaders.h2')}
                  color={colors.BLACK}
                  size={generalsEnums.fontSizes.size16.size}
                  lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                  isHead={generalsEnums.falseBoolean}
                />
              </TableCell>

              <TableCell align="left">
                <Font
                  text={t('admin.ViewOrders.tableHeaders.h3')}
                  color={colors.BLACK}
                  size={generalsEnums.fontSizes.size16.size}
                  lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                  isHead={generalsEnums.falseBoolean}
                />
              </TableCell>

              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            style={{
              width: '100%',
              boxShadow: 'none',
            }}
          >
            {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => {
              const { order } = row;
              const { order: orderData, user: userData, orderStatus: orderStatusData } = order;

              const { orderNumber, orderTotal, _id } = orderData;
              const { name, lastName } = userData;
              const { activeStep, name: nameStatus } = orderStatusData;
              const activeStepInt = parseInt(activeStep, 10);

              return (
                <TableRow key={uuid()}>
                  <TableCell component="th" scope="row" align="left">
                    {displayStatusCb && (
                      <CheckboxAttom
                        handleSelect={(event) => handleSelectOne(event, _id)}
                        isChecked={handleIsIdSelected(_id)}
                      />
                    )}
                  </TableCell>

                  <TableCell align="left">
                    <Button
                      onClick={() => handleNavigateToOrder(orderNumber)}
                      sx={{ textTransform: 'none', padding: '0px', minWidth: '0px' }}
                      variant="text"
                    >
                      <Font
                        text={orderNumber}
                        color={colors.TSADMIN_BLUE}
                        size={generalsEnums.fontSizes.size16.size}
                        lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                        isHead={generalsEnums.trueBoolean}
                      />
                    </Button>
                  </TableCell>

                  <TableCell align="left">
                    <Font
                      text={orderTotal}
                      color={colors.BLACK}
                      size={generalsEnums.fontSizes.size16.size}
                      lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                      isHead={generalsEnums.falseBoolean}
                    />
                  </TableCell>

                  <TableCell align="left">
                    <Font
                      text={`${name} ${lastName}`}
                      color={colors.BLACK}
                      size={generalsEnums.fontSizes.size16.size}
                      lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                      isHead={generalsEnums.falseBoolean}
                    />
                  </TableCell>

                  <TableCell align="left">
                    <StepperAttoms w="100%" orderStatusData={orderStatusData} />
                  </TableCell>

                  <TableCell align="left">
                    <BadgeAttom kind={generalsEnums.badge.gray} text={nameStatus} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          marginTop: '30px',
        }}
      >
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: t('Generals.all'), value: -1 }]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage={
            <Box component="div" style={{ marginTop: '15px' }}>
              {t('Generals.rows')}
            </Box>
          }
          labelDisplayedRows={({ from, to, count }) => (
            <Box component="div" style={{ marginTop: '15px' }}>
              {`${from}-${to} ${t('admin.AdminProducts.table-pagination-label-rows-per-page-of')} ${count}`}
            </Box>
          )}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default OrdersTable;
