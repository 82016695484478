import React from 'react';
import { Button } from '@mui/material';

import * as colors from '../../assets/GlobalColors';
import Font from '../Generals/Fonts/Font';

const HeaderButton = ({ text, handleOnClick, disableButton }) => {
  return (
    <Button
      disabled={disableButton}
      onClick={handleOnClick}
      variant="contained"
      style={{
        width: '160px',
        height: '40px',
        borderRadius: '8px',
        backgroundColor: colors.GREEN_MAIN,
        border: `0.4px solid ${colors.GREEN_MAIN}`,
        boxShadow: 'none',
        textTransform: 'none',
        lineHeight: '14px',
      }}
    >
      <Font text={text} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />
    </Button>
  );
};

export default HeaderButton;
