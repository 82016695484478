import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import * as yup from 'yup';

import portalSettings from '../../../../entities/portalSettings';
import EnableQrMfa from './EnableQrMfa';
import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import InputText from '../../../../Components/Generals/InputText';
import NativeComboBox from '../../../../Components/Generals/NativeComboBox';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import Users from '../../../../entities/Users';
import ChangePassword from './ChangePassword';
import RadioFieldMolecule from '../../../../Components/Generals/Figma/Molecules/RadioFieldMolecule';
import { useStyles } from './styles';
import { setPortalLanguage } from '../../../../Redux/Slices/Portal.Slices';
import { setBackDropState, setToastError, setToastSuccessMessage } from '../../../../Redux/Slices/Navigation.Slices';
import {
  selectName,
  selectLastName,
  selectLanguage,
  selectUserId,
  setUpdateAdminUser,
  selectSub,
  selectToken,
  selectEmail,
} from '../../../../Redux/Slices/User.Slices';
import * as colors from '../../../../assets/GlobalColors';

const MyProfile = () => {
  const dispatch = useDispatch();
  const usersAPI = new Users();
  const portalSettingsAPI = new portalSettings();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [showChangePass, setShowChangePass] = React.useState(generalsEnums.falseBoolean);
  const [qrsfa, setQrsfa] = React.useState(generalsEnums.falseBoolean);
  const [openQr, setOpenQr] = React.useState(generalsEnums.falseBoolean);
  const [qrImage, setQrImage] = React.useState(generalsEnums.emptyString);
  const [storeProfileID, setStoreProfileID] = React.useState(generalsEnums.emptyString);
  const [isValidateOnChange, setIsValidateOnChange] = React.useState(false);

  const name = useSelector(selectName);
  const lastName = useSelector(selectLastName);
  const language = useSelector(selectLanguage);
  const userId = useSelector(selectUserId);
  const sub = useSelector(selectSub);
  const token = useSelector(selectToken);
  const email = useSelector(selectEmail);
  const languages = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'es',
      label: 'Español',
    },
  ];

  const schema = yup.object().shape({
    name: yup.string().required(),
    lastName: yup.string().required(),
    language: yup.string().required(),
  });

  const handleShowChangePass = (e) => {
    e.preventDefault();
    setShowChangePass(!showChangePass);
  };

  const handleQrsfa = (e) => {
    e.preventDefault();
    setOpenQr(!openQr);
  };

  const handleCancelQr = () => {
    setOpenQr(!openQr);
    setQrImage(generalsEnums.emptyString);
  };

  const handleCloseQrSuccess = () => {
    setQrsfa(generalsEnums.trueBoolean);
    handleCancelQr();
  };

  const handleChangeLanguage = (e) => {
    formik.setFieldValue('language', e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      name: generalsEnums.emptyString,
      lastName: generalsEnums.emptyString,
      language: generalsEnums.emptyString,
    },
    validationSchema: schema,
    validateOnChange: isValidateOnChange,
    validateOnBlur: false,
    onSubmit: async (values) => {
      dispatch(setBackDropState(true));
      const body = {
        _id: userId,
        name: values.name,
        lastName: values.lastName,
        language: values.language,
      };

      const response = await usersAPI.editAdminUser(body);
      const { statusCode } = response;

      if (statusCode === generalsEnums.statusCodes.ok) {
        dispatch(
          setUpdateAdminUser({
            name: values.name,
            lastName: values.lastName,
            language: values.language,
          })
        );
        dispatch(setPortalLanguage(values.language));
        dispatch(setBackDropState(false));
      } else dispatch(setBackDropState(false));
    },
  });

  React.useEffect(() => {
    formik.setFieldValue('name', name);
    formik.setFieldValue('lastName', lastName);
    formik.setFieldValue('language', language);
  }, [name, lastName, language]);

  React.useEffect(async () => {
    if (openQr) {
      dispatch(setBackDropState(true));

      const { accessToken } = token;
      const body = {
        AccessToken: accessToken,
        email: email,
      };

      const response = await usersAPI.get2faQR(body);
      const { statusCode, data } = response;

      if (statusCode === generalsEnums.statusCodes.ok) {
        dispatch(setBackDropState(false));
        setQrImage(data);
      } else dispatch(setBackDropState(false));
    }
  }, [openQr]);

  React.useEffect(async () => {
    dispatch(setBackDropState(true));

    const body = {
      email: email,
    };

    const response = await usersAPI.checkUserMFA(body);
    const { statusCode, data } = response;

    if (statusCode === generalsEnums.statusCodes.ok) {
      const { hasMFA } = data;
      dispatch(setBackDropState(false));
      setQrsfa(hasMFA);
    } else dispatch(setBackDropState(false));
  }, []);

  React.useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const res = await portalSettingsAPI.getProfile();
        const { data } = res.data;
        const { profile } = data;
        const { _id } = profile;
        setStoreProfileID(_id);
      } catch (error) {}
    };

    fetchProfileData();
  }, []);

  React.useEffect(() => {
    if (Object.keys(formik.errors).length !== 0) {
      dispatch(setToastError(true));
      dispatch(setToastSuccessMessage(t('Errors.incompleteForm')));
      setIsValidateOnChange(true);
    }
  }, [formik.errors]);

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        padding: '20px 24px',
      }}
    >
      <Box
        component="div"
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '12px',
          border: '1px solid #ccc',
          padding: '24px',
          backgroundColor: colors.WHITE,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '24px',
        }}
      >
        <Font
          text={t('admin.MyProfile.title')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size28.size}
          lineHeight={generalsEnums.fontSizes.size28.lineHeight}
          isHead={true}
        />

        <Font
          text={t('admin.MyProfile.profile')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size20.size}
          lineHeight={generalsEnums.fontSizes.size20.lineHeight}
          isHead={true}
        />

        <Font
          text={t('admin.MyProfile.profileInstructions')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size16.size}
          lineHeight={generalsEnums.fontSizes.size16.lineHeight}
          isHead={false}
        />

        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <Box
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '24px',
            }}
          >
            <InputText
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              label={t('admin.MyProfile.name.label')}
              labelColor={colors.BLACK_06}
              ph={t('admin.MyProfile.name.placeholder')}
              error={formik.errors.name}
              isDisabled={false}
              hasErrorMessage={generalsEnums.falseBoolean}
              w="400px"
            />

            <InputText
              value={formik.values.lastName}
              name="lastName"
              onChange={formik.handleChange}
              label={t('admin.MyProfile.lastName.label')}
              labelColor={colors.BLACK_06}
              ph={t('admin.MyProfile.lastName.placeholder')}
              error={formik.errors.lastName}
              isDisabled={false}
              hasErrorMessage={generalsEnums.falseBoolean}
              w="400px"
            />
          </Box>

          <Font
            text={t('admin.MyProfile.language.title')}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size20.size}
            lineHeight={generalsEnums.fontSizes.size20.lineHeight}
            isHead={true}
          />

          <Font
            text={t('admin.MyProfile.language.description')}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size16.size}
            lineHeight={generalsEnums.fontSizes.size16.lineHeight}
            isHead={false}
          />

          <NativeComboBox
            valueForm={formik.values.language}
            label={t('admin.MyProfile.language.label')}
            labelColor={colors.BLACK_06}
            data={languages}
            helperText={t('admin.MyProfile.language.helperText')}
            w="400px"
            change={handleChangeLanguage}
          />

          <Box
            component="div"
            sx={{
              width: '250px',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.save}
              handleAction={() => {
                formik.handleSubmit();
              }}
              text={t('Generals.saveChanges')}
              isDisabled={false}
            />
          </Box>
        </form>

        {!showChangePass ? (
          <Box
            component="div"
            sx={{
              width: '250px',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.save}
              handleAction={handleShowChangePass}
              text={t('admin.MyProfile.changePass')}
              isDisabled={false}
            />
          </Box>
        ) : (
          <ChangePassword
            t={t}
            colors={colors}
            handleShowChangePass={handleShowChangePass}
            usersAPI={usersAPI}
            sub={sub}
          />
        )}

        <Font
          text={t('admin.MyProfile.MFA.title')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size20.size}
          lineHeight={generalsEnums.fontSizes.size20.lineHeight}
          isHead={true}
        />

        <Font
          text={t('admin.MyProfile.MFA.description')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size16.size}
          lineHeight={generalsEnums.fontSizes.size16.lineHeight}
          isHead={false}
        />

        <RadioFieldMolecule
          isChecked={generalsEnums.trueBoolean}
          isDisabled={generalsEnums.trueBoolean}
          handleChange={generalsEnums.nullData}
        >
          <Box component="div" className={classes.RadioFieldMoleculeBox}>
            <Font
              text={t('admin.MyProfile.MFA.email.title')}
              color={colors.TEXT_GRAY_102}
              size={generalsEnums.fontSizes.size18.size}
              lineHeight={generalsEnums.fontSizes.size18.lineHeight}
              isHead={generalsEnums.trueBoolean}
            />

            <Font
              text={t('admin.MyProfile.MFA.email.type')}
              color={colors.TEXT_GRAY_102}
              size={generalsEnums.fontSizes.size16.size}
              lineHeight={generalsEnums.fontSizes.size16.lineHeight}
              isHead={generalsEnums.trueBoolean}
            />

            <Font
              text={t('admin.MyProfile.MFA.email.description')}
              color={colors.TEXT_GRAY_102}
              size={generalsEnums.fontSizes.size16.size}
              lineHeight={generalsEnums.fontSizes.size16.lineHeight}
              isHead={generalsEnums.falseBoolean}
            />
          </Box>
        </RadioFieldMolecule>

        <RadioFieldMolecule isChecked={qrsfa ? qrsfa : openQr} isDisabled={qrsfa} handleChange={handleQrsfa}>
          <Box component="div" className={classes.RadioFieldMoleculeBox}>
            <Font
              text={t('admin.MyProfile.MFA.qr.title')}
              color={colors.TEXT_GRAY_102}
              size={generalsEnums.fontSizes.size18.size}
              lineHeight={generalsEnums.fontSizes.size18.lineHeight}
              isHead={generalsEnums.trueBoolean}
            />

            <Font
              text={t('admin.MyProfile.MFA.qr.type')}
              color={colors.TEXT_GRAY_102}
              size={generalsEnums.fontSizes.size16.size}
              lineHeight={generalsEnums.fontSizes.size16.lineHeight}
              isHead={generalsEnums.trueBoolean}
            />

            <Font
              text={t('admin.MyProfile.MFA.qr.description')}
              color={colors.TEXT_GRAY_102}
              size={generalsEnums.fontSizes.size16.size}
              lineHeight={generalsEnums.fontSizes.size16.lineHeight}
              isHead={generalsEnums.falseBoolean}
            />

            {qrImage !== generalsEnums.emptyString ? (
              <EnableQrMfa
                qrImage={qrImage}
                t={t}
                colors={colors}
                handleCancelQr={handleCancelQr}
                sub={sub}
                AccessToken={token.accessToken}
                usersAPI={usersAPI}
                handleCloseQrSuccess={handleCloseQrSuccess}
              />
            ) : null}
          </Box>
        </RadioFieldMolecule>
      </Box>
    </Box>
  );
};

export default MyProfile;
