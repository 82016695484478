import React from 'react';
import Image from 'react-bootstrap/Image';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { Box, Button } from '@mui/material';

import Paths from '../utils/enums/Paths';
import ImageNotFound from '../assets/Images/404image.svg';
import Font from '../Components/Generals/Fonts/Font';
import * as colors from '../assets/GlobalColors';

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const laptop = useMediaQuery('(min-width:1440px)');

  const handleGoBack = (event) => {
    event.preventDefault();
    navigate(Paths.client.Home2);
  };

  return (
    <Container maxWidth="xl">
      <Box
        component="div"
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: `0px 0px 0px ${laptop ? '200px' : '40px'}`,
        }}
      >
        <Box
          component="div"
          sx={{
            width: '500px',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '16px',
          }}
        >
          <Font text={t('404')} color={colors.BLACK_06} size={128} lineHeight="152px" isHead={true} />

          <Font text={t('NotFound.title')} color={colors.BLACK} size={36} lineHeight="46px" isHead={true} />

          <Box
            component="div"
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '10px',
            }}
          >
            <Font text={t('NotFound.parragraph1')} color={colors.BLACK} size={16} lineHeight="26px" isHead={false} />

            <Font text={t('NotFound.parragraph2')} color={colors.BLACK} size={16} lineHeight="26px" isHead={false} />
          </Box>

          <Button
            onClick={handleGoBack}
            variant="text"
            sx={{
              width: '300px',
              height: '40px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5p',
              padding: '15px 32px',
              backgroundColor: colors.GREEN_MAIN,
              border: `1px solid ${colors.GREEN_MAIN}`,

              '&:hover': {
                backgroundColor: colors.GREEN_MAIN,
                border: `1px solid ${colors.GREEN_MAIN}`,
                opacity: 0.8,
              },
            }}
          >
            <Font text={t('NotFound.button')} color={colors.BLACK} size={16} lineHeight="26px" isHead={false} />
          </Button>
        </Box>

        <Image
          src={ImageNotFound}
          alt={t('NotFound.imageAlt')}
          style={{
            width: laptop ? '800px' : '600px',
            height: laptop ? '800px' : '600px',
            transition: 'all 0.5s ease-in-out',
          }}
        />
      </Box>
    </Container>
  );
};

export default NotFound;
