import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import OrderStatus from '../../entities/OrderStatus';

const ordersStatus = new OrderStatus();

const initialState = {
  statusFromApi: [],
  statusToEdit: [],
};

export const fetchOrderStatusFromApi = createAsyncThunk('OrderStatus/fetchOrderStatusFromApi', async () => {
  const response = await ordersStatus.getAllOrderStatus();
  return response;
});

export const OrderStatusSlices = createSlice({
  name: 'OrderStatus',
  initialState,
  reducers: {
    setStatusToEdit: (state, action) => {
      const { edit } = action.payload;

      if (edit === true) {
        state.statusToEdit = state.statusFromApi.map((status) => {
          return {
            ...status,
            isEditing: false,
          };
        });
      } else if (edit === false) {
        state.statusToEdit = [];
      }
    },
    setStatusToEditNameById: (state, action) => {
      const { _id, name } = action.payload;

      state.statusToEdit = state.statusToEdit.map((status) => {
        if (status._id === _id) {
          return {
            ...status,
            name: name,
            isEditing: true,
          };
        } else {
          return status;
        }
      });
    },
    setStatusToEditDescriptionById: (state, action) => {
      const { _id, description } = action.payload;

      state.statusToEdit = state.statusToEdit.map((status) => {
        if (status._id === _id) {
          return {
            ...status,
            description: description,
            isEditing: true,
          };
        } else {
          return status;
        }
      });
    },
    setRestoreStatusToEditById: (state, action) => {
      const { _id } = action.payload;
      const statusToRestore = state.statusFromApi.filter((status) => status._id === _id);
      state.statusToEdit = state.statusToEdit.map((status) => {
        if (status._id === _id) {
          return {
            ...statusToRestore[0],
            isEditing: false,
          };
        } else {
          return status;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderStatusFromApi.fulfilled, (state, action) => {
      state.statusFromApi = action.payload.orderStatus;
    });
  },
});

export const { setStatusToEdit, setStatusToEditNameById, setStatusToEditDescriptionById, setRestoreStatusToEditById } =
  OrderStatusSlices.actions;

export const selectOrderStatus = (state) => state.OrderStatus.statusFromApi;
export const selectStatusToEdit = (state) => state.OrderStatus.statusToEdit;
export const selectAvailableSabe = (state) => {
  const availableSave = state.OrderStatus.statusToEdit.every((status) => status.isEditing === false);
  return availableSave;
};

export default OrderStatusSlices.reducer;
