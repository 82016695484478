import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

import generalsEnums from '../../../../utils/Generals';
import * as colors from '../../../../assets/GlobalColors';

const CircularProgressAttom = ({ circularSize, children }) => {
  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: '100%',
        height: '100%',
        padding: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '14px',
      }}
    >
      <CircularProgress
        size={circularSize}
        sx={{
          color: colors.GREEN_MAIN,
        }}
      />

      {children}
    </Box>
  );
};

export default CircularProgressAttom;
