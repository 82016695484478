import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';

import generalsEnums from '../../../utils/Generals';
import Users from '../../../entities/Users';
import FocusButton from '../../../Components/Generals/FocusButton';
import { useStyles } from './MyAccount.styles';
import { setSnackBar, setBackDropState } from '../../../Redux/Slices/Navigation.Slices';
import { selectEmail, setSignout } from '../../../Redux/Slices/User.Slices';
import * as colors from '../../../assets/GlobalColors';

const MyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation('global');
  const usersApi = new Users();

  const email = useSelector(selectEmail);
  const language = localStorage.getItem('language');

  const sections = [
    {
      title: t('client.MyAccount.button-myorders'),
      path: '/my-orders',
    },
    {
      title: t('client.MyAccount.button-myData'),
      path: '/my-data',
    },
    {
      title: t('client.MyAccount.button-myAddresses'),
      path: '/my-addresses',
    },
    {
      title: t('client.MyAccount.button-support'),
      path: '/client-support',
    },
  ];

  const handleSectionClick = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  const handleSignOut = async (e) => {
    e.preventDefault();
    dispatch(setBackDropState(generalsEnums.trueBoolean));

    const response = await usersApi.SignOut(email);

    const { statusCode, messages } = response;

    if (statusCode === 200) {
      const message = language === generalsEnums.languages.spanish ? messages.es : messages.en;

      dispatch(
        setSnackBar({
          snackBarStatus: generalsEnums.trueBoolean,
          snackBarSeverity: generalsEnums.snackBar.success,
          snackBarMessage: message,
        })
      );

      setTimeout(() => {
        dispatch(setBackDropState(generalsEnums.falseBoolean));
        dispatch(setSignout());
      }, generalsEnums.timeOut.time1500);
    } else {
      dispatch(setBackDropState(generalsEnums.falseBoolean));

      const message = language === generalsEnums.languages.spanish ? messages.es : messages.en;

      dispatch(
        setSnackBar({
          snackBarStatus: generalsEnums.trueBoolean,
          snackBarSeverity: generalsEnums.snackBar.success,
          snackBarMessage: message,
        })
      );
    }
  };

  return (
    <Box component="div" className={classes.root}>
      <Box
        component="div"
        className={classes.userSection}
        style={{
          flexDirection: 'column',
        }}
      >
        {sections.map((section, idx) => {
          return (
            <Button
              key={idx}
              className={classes.sectionButton}
              variant="contained"
              onClick={(e) => handleSectionClick(e, section.path)}
            >
              {section.title}

              <ArrowForwardIosIcon />
            </Button>
          );
        })}
      </Box>

      <FocusButton handleAction={handleSignOut} normalColor={colors.WHITE} focusedColor={colors.TSADMIN_RED_DELETE} />
    </Box>
  );
};

export default MyAccount;
