import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Box } from '@mui/material';

import portalSettings from '../../entities/portalSettings';
import Profile from './Profile';
import {
  selectStoreProfilesModalState,
  setStoreProfilesModalState,
  setSnackBar,
} from '../../Redux/Slices/Navigation.Slices';
import * as colors from '../../assets/GlobalColors';

const BlurryDialog = styled(Dialog)(({}) => ({
  backdropFilter: 'blur(5px)',
}));

const StoreProfilesModal = () => {
  const portalSettingsAPI = new portalSettings();
  const dispatch = useDispatch();

  const [profile, setProfile] = React.useState({});
  const [countries, setCountries] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [backDropState, setBackDropState] = React.useState(false);

  const storeProfilesModalState = useSelector(selectStoreProfilesModalState);
  const language = localStorage.getItem('language');

  const handleCloseStoreProfilesModal = () => {
    dispatch(setStoreProfilesModalState(false));
  };

  const handleOpenSnackBar = (message, severity) => {
    dispatch(
      setSnackBar({
        snackBarStatus: true,
        snackBarSeverity: severity,
        snackBarMessage: message,
      })
    );
  };

  React.useEffect(async () => {
    if (storeProfilesModalState) {
      setBackDropState(true);

      const res = await portalSettingsAPI.getProfile();
      const { data } = res.data;
      const { profile: profileData, countries: countriesData, languages: languagesData } = data;

      if (data) {
        setProfile(profileData);
        setCountries(countriesData);
        setLanguages(languagesData);
        setBackDropState(false);
      }
    } else {
      setProfile({});
      setCountries([]);
      setLanguages([]);
    }
  }, [storeProfilesModalState]);

  if (!backDropState) {
    return (
      <BlurryDialog open={storeProfilesModalState} onClose={handleCloseStoreProfilesModal}>
        <Box
          component="div"
          sx={{
            padding: '1rem',
          }}
        >
          <Profile
            isDisabled={false}
            profile={profile}
            countries={countries}
            languages={languages}
            portalSettingsAPI={portalSettingsAPI}
            language={language}
            handleOpenSnackBar={handleOpenSnackBar}
            handleCloseStoreProfilesModal={handleCloseStoreProfilesModal}
          />
        </Box>
      </BlurryDialog>
    );
  }

  return (
    <Backdrop sx={{ color: colors.GREEN_MAIN, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropState}>
      <CircularProgress color="inherit" size={100} />
    </Backdrop>
  );
};

export default StoreProfilesModal;
