import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

import Font from './Fonts/Font';
import generalsEnums from '../../utils/Generals';

export const useStyles = makeStyles({
  rootButton: {
    height: '40px',
    borderRadius: '6px',
  },
});

const FocusButton = ({ handleAction, normalColor, focusedColor, text, w, icon, iconStartType }) => {
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [isFocused, setIsFocused] = React.useState(generalsEnums.falseBoolean);
  const [iconStartComponent, setIconStartComponent] = React.useState(<></>);

  return (
    <Button
      component="div"
      variant="text"
      onClick={handleAction}
      onMouseEnter={() => setIsFocused(generalsEnums.trueBoolean)}
      onMouseLeave={() => setIsFocused(generalsEnums.falseBoolean)}
      className={classes.rootButton}
      sx={{
        width: w ? w : '150px',
        backgroundColor: normalColor,
        transition: 'background-color 0.3s ease-in-out',
        display: 'flex',
        justifyContent: icon ? 'space-between' : 'center',

        '&:hover': {
          backgroundColor: focusedColor,
        },
      }}
    >
      {iconStartType === 'add' ? <AddIcon sx={{ color: isFocused ? normalColor : focusedColor }} /> : null}

      <Font
        text={text ? text : t('client.Header.text-logout')}
        color={isFocused ? normalColor : focusedColor}
        size={14}
        lineHeight="22px"
        isHead={true}
        isCenter={false}
      />

      {icon ? (
        <ArrowForwardIosIcon
          sx={{
            color: isFocused ? normalColor : focusedColor,
            fontSize: '14px',
          }}
        />
      ) : null}
    </Button>
  );
};

export default FocusButton;
