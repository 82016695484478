import React from 'react';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import generalsEnums from '../utils/Generals';
import Paths from '../utils/enums/Paths';

import NotFound from './NotFound';
import Header from '../Templates/Header/Header';
import Building from '../Screens/General/Building/Building';

import ClientPrivateRoutes from './ClientPrivateRoutes';
import ClientProfileRoutes from './ClientProfileRoutes/ClientProfileRoutes';

import Home from '../Screens/Client/Home/Home';
import ProductsView from '../Screens/Client/ProductsView/ProductsView';
import ProductDetail from '../Screens/Client/ProductDetail/ProductDetail';
import Checkout from '../Screens/Client/ConfirmOrder/Checkout';
import OrderSummary from '../Screens/Client/OrderSummary/OrderSummary';
import ContactUs from '../Screens/Client/Information/ContactUs/ContactUs';
import About from '../Screens/Client/Information/About/About';
import DataForm from '../Screens/Client/DataForm/DataForm';
import LandingPage from '../Screens/Client/LandingPage/LandingPage';
import MyAccount from '../Screens/Client/MyAccount/MyAccount';
import MyOrders from '../Screens/Client/MyOrders/MyOrders';
import MyAddresses from '../Screens/Client/MyAddresses/MyAddresses';
import MyPersonalInfo from '../Screens/Client/MyPersonalInfo/MyPersonalInfo';
import UserManagement from '../Screens/Client/User/UserManagement';

import AdminPortalPrivateRoutes from './AdminPortalPrivateRoutes';
import AdminNavBar from '../Templates/AdminNavBar/AdminNavBar';

import AdminPortalHome from '../Screens/AdminPortal/Home/AdminPortalHome';
import AddProduct from '../Screens/AdminPortal/Products/AddProduct/AddProduct';
import SignInAdmin from '../Screens/AdminPortal/SignInAdmin/SignInAdmin';
import ViewProducts from '../Screens/AdminPortal/Products/ViewProducts/ViewProducts';
import AdminConfiguration from '../Screens/AdminPortal/AdminConfiguration/AdminConfiguration';
import Order from '../Screens/AdminPortal/Order/Order';
import ViewOrders from '../Screens/AdminPortal/Orders/View/ViewOrders';
import OrdersStatusPage from '../Screens/AdminPortal/Orders/OrdersStatus/OrdersStatusPage';
import ClientsView from '../Screens/AdminPortal/Clients/ClientsView/ClientsView';
import ClientsEdit from '../Screens/AdminPortal/Clients/ClientsEdit/ClientsEdit';
import NewVariants from '../Screens/AdminPortal/Products/NewVariants/NewVariants';
import LogoAndFavicon from '../Screens/AdminPortal/Sideboard/LogoAndFavicon/LogoAndFavicon';
import MyProfile from '../Screens/AdminPortal/Users/MyProfile/MyProfile';
import TransactionalEmails from '../Screens/AdminPortal/Emails/TransactionalEmails/TransactionalEmails';
import EmailTemplate from '../Screens/AdminPortal/Emails/EmailTemplate/EmailTemplate';
import Markup from '../Screens/AdminPortal/AdminConfiguration/Markup/Markup';
import Freight from '../Screens/AdminPortal/AdminConfiguration/Freight/Freight';
import WeightsRules from '../Screens/AdminPortal/AdminConfiguration/WeightsRules/WeightsRules';
import SecurityDashboard from '../Screens/AdminPortal/Security/SecurityDashboard';
import ListDetails from '../Screens/AdminPortal/Security/ListDetails';

import OrderNotes from '../Modals/OrderNotes/OrderNotes';
import DeleteAddressModal from '../Modals/DeleteAddressModal/DeleteAddressModal';
import EditAddressModal from '../Modals/EditAddressModal/EditAddressModal';
import ClientInfoModal from '../Modals/ClientInfoModal/ClientInfoModal';
import StoreProfilesModal from '../Modals/StoreProfilesModal/StoreProfilesModal';
import TvhCreaeteOrder from '../Modals/TvhCreaeteOrder/TvhCreaeteOrder';
import ViewMailTemplateModal from '../Modals/ViewMailTemplateModal/ViewMailTemplateModal';

import Users from '../entities/Users';
import ResponsiveComponent from '../Hooks/ResponsiveComponent';
import { getResponsiveValues } from '../utils/FormatData';
import { selectPortalLanguage } from '../Redux/Slices/Portal.Slices';
import { selectToken, isLogged, selectExp, setSignout, selectEmail } from '../Redux/Slices/User.Slices';
import {
  setSnackBar,
  selectBackDropState,
  setBackDropState,
  setErrorId,
  setErrorMessage,
  selectPreventReload,
  selectIsToastSuccess,
  selectToastSuccessMessage,
  selectIsToastError,
} from '../Redux/Slices/Navigation.Slices';
import * as colors from '../assets/GlobalColors';

const user = new Users();

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

const RouterIndex = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const [toastErrorMessage, setToastErrorMessage] = React.useState(generalsEnums.emptyString);

  const token = useSelector(selectToken);
  const backDropState = useSelector(selectBackDropState);
  const language = useSelector(selectPortalLanguage);
  const isUserLogged = useSelector(isLogged);
  const exp = useSelector(selectExp);
  const email = useSelector(selectEmail);
  const preventReload = useSelector(selectPreventReload);
  const isToastSuccess = useSelector(selectIsToastSuccess);
  const toastSuccessMessage = useSelector(selectToastSuccessMessage);
  const isToastError = useSelector(selectIsToastError);

  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();
  const responsiveArray = [
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];
  const [font20Size, font20LineHeight, font16Size, font16LineHeight] = getResponsiveValues(
    responsiveArray,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl
  );

  axios.interceptors.request.use((config) => {
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token.idToken}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      dispatch(setErrorId(''));
      dispatch(setErrorMessage(''));
      return response;
    },
    (error) => {
      dispatch(setBackDropState(false));
      const { statusCode, messages, id } = error.response.data;

      let message = generalsEnums.emptyString;
      if (messages !== undefined) {
        message = language === generalsEnums.languages.spanish ? messages.es : messages.en;
      } else {
        switch (statusCode) {
          case generalsEnums.statusCodes.notFound:
            message = t('Errors.api.notFound');
            break;
          case generalsEnums.statusCodes.badRequest:
            message = t('Errors.api.badRequest');
            break;
          default:
            message = t('Errors.api.internalServerError');
            break;
        }
      }

      if (id !== generalsEnums.undefinedData) {
        dispatch(setErrorId(id));
        dispatch(setErrorMessage(message));
      }

      setToastErrorMessage(message);

      return Promise.reject(error);
    }
  );

  React.useEffect(async () => {
    if (isUserLogged) {
      const now = new Date();
      const expDate = new Date(exp * 1000);
      if (now > expDate) {
        dispatch(setBackDropState(true));
        dispatch(
          setSnackBar({
            snackBarStatus: generalsEnums.trueBoolean,
            snackBarSeverity: generalsEnums.snackBar.error,
            snackBarMessage: t('Errors.expired'),
          })
        );
        try {
          await user.signout(email);
          dispatch(setBackDropState(false));
          dispatch(setSignout());
        } catch (error) {
          dispatch(setBackDropState(false));
          dispatch(setSignout());
        }
      }
    }
  }, [isUserLogged]);

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    if (preventReload) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [preventReload]);

  React.useEffect(() => {
    const showToast = (message, toastFunction, setToastFunction) => {
      if (message !== generalsEnums.emptyString) {
        toastFunction(message);
        setTimeout(() => {
          setToastFunction(generalsEnums.emptyString);
        }, generalsEnums.timeOut.time1500);
      }
    };

    showToast(toastErrorMessage, toast.error, setToastErrorMessage);
  }, [toastErrorMessage, toastSuccessMessage]);

  React.useEffect(() => {
    if (isToastSuccess === generalsEnums.trueBoolean && toastSuccessMessage !== generalsEnums.emptyString) {
      toast.success(toastSuccessMessage);
    } else if (isToastError === generalsEnums.trueBoolean && toastSuccessMessage !== generalsEnums.emptyString) {
      toast.error(toastSuccessMessage);
    }
  }, [isToastSuccess, toastSuccessMessage, isToastError]);

  return (
    <React.StrictMode>
      <Backdrop sx={{ color: colors.GREEN_MAIN, zIndex: (theme) => theme.zIndex.drawer + 2 }} open={backDropState}>
        <CircularProgress color="inherit" size={100} />
      </Backdrop>

      <Toaster position="top-center" reverseOrder={true} />

      <OrderNotes />
      <DeleteAddressModal />
      <EditAddressModal />
      <ClientInfoModal />
      <StoreProfilesModal />
      <TvhCreaeteOrder />
      <ViewMailTemplateModal
        font20Size={font20Size}
        font20LineHeight={font20LineHeight}
        font16Size={font16Size}
        font16LineHeight={font16LineHeight}
      />

      <Router>
        <ScrollToTop>
          <Routes>
            <Route>
              <Route
                element={
                  <Header
                    font20Size={font20Size}
                    font20LineHeight={font20LineHeight}
                    font16Size={font16Size}
                    font16LineHeight={font16LineHeight}
                  />
                }
              >
                <Route path={Paths.client.NotFound} element={<NotFound />} />

                <Route path={Paths.client.Home} element={<Home />} />
                <Route path={Paths.client.Home2} element={<Home />} />

                <Route path={Paths.client.ProductsView} element={<ProductsView isRoot={true} />} />
                <Route path="/product-detail/:productId" element={<ProductDetail />} />

                <Route path={Paths.client.Checkout} element={<Checkout />} />
                <Route path="/order-summary/:orderNumber" element={<OrderSummary />} />

                <Route path={Paths.client.MostSold} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.Services} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.Galery} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.Background} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.Tracking} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.ReturnsAndClaims} element={<ContactUs isContactUs={false} />} />
                <Route path={Paths.client.ContactUs} element={<ContactUs isContactUs={true} />} />
                <Route path={Paths.client.About} element={<About />} />
                <Route path={Paths.client.Delivery} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.Support} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.ReturnsPolicy} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.CancelationsPolicy} element={<Building isAdmin={false} />} />
                <Route path={Paths.client.DataForm} element={<DataForm />} />

                <Route path={Paths.client.LandingPage} element={<LandingPage />} />
              </Route>

              <Route path={Paths.UserManagement.global} element={<UserManagement />} />

              <Route element={<ClientPrivateRoutes />}>
                <Route element={<Header />}>
                  <Route element={<ClientProfileRoutes />}>
                    <Route path={Paths.client.MyAccount} element={<MyAccount />} />
                    <Route path={Paths.client.MyOrders} element={<MyOrders />} />
                    <Route path={Paths.client.MyAddresses} element={<MyAddresses />} />
                    <Route path={Paths.client.MyPersonalInfo} element={<MyPersonalInfo />} />
                    <Route path={Paths.client.ClientSupport} element={<Building isAdmin={false} />} />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route>
              <Route path="/ts-admin/signin" element={<SignInAdmin />} />

              <Route element={<AdminPortalPrivateRoutes />}>
                <Route element={<AdminNavBar />}>
                  <Route path={Paths.admin.Home} element={<AdminPortalHome />} />
                  <Route path={Paths.admin.Home2} element={<AdminPortalHome />} />

                  <Route path={Paths.admin.Products.view} element={<ViewProducts />} />
                  <Route path={Paths.admin.Products.add} element={<AddProduct />} />
                  <Route path={Paths.admin.Products.edit} element={<AddProduct />} />
                  <Route path={Paths.admin.Products.search} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Products.import} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Products.export} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Products.categories} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Products.newvariants} element={<NewVariants />} />

                  <Route path={Paths.admin.AdminConfiguration} element={<AdminConfiguration />} />
                  <Route path={Paths.admin.StoreProfile} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.ForeingExchange} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.EmailSettings} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.OrderSettings} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.web} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.usersAndPermissions} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.AdminConfigurations.markup} element={<Markup />} />
                  <Route path={Paths.admin.AdminConfigurations.freight} element={<Freight />} />
                  <Route path={Paths.admin.AdminConfigurations.weightsRules} element={<WeightsRules />} />

                  <Route path={`${Paths.admin.Orders.viewOrder}:orderNumber`} element={<Order />} />
                  <Route
                    path={Paths.admin.Orders.view}
                    element={<ViewOrders isClaims={generalsEnums.falseBoolean} />}
                  />
                  <Route
                    path={Paths.admin.Orders.claims}
                    element={<ViewOrders isClaims={generalsEnums.trueBoolean} />}
                  />
                  <Route path={Paths.admin.Orders.add} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Orders.search} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Orders.export} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Orders.delivery} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Orders.followship} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Orders.status} element={<OrdersStatusPage />} />

                  <Route path={Paths.admin.Clients.view} element={<ClientsView />} />
                  <Route path={Paths.admin.Clients.add} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Clients.search} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Clients.import} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Clients.export} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Clients.groups} element={<Building isAdmin={true} />} />
                  <Route path={`${Paths.admin.Clients.edit}/edit/:_id`} element={<ClientsEdit />} />

                  <Route path={Paths.admin.Integrations.integrations} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Integrations.tvh} element={<Building isAdmin={true} />} />

                  <Route path={Paths.admin.Tvh.ImportFiles} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Tvh.FindProduct} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Tvh.OrdersInProcess} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Tvh.OrdersClosed} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Tvh.Tracking} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Tvh.Configuration} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Tvh.find} element={<Building isAdmin={true} />} />

                  <Route path={Paths.admin.Integrations.stripe} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Integrations.skydropx} element={<Building isAdmin={true} />} />

                  <Route path={Paths.admin.Sideboard.view} element={<Building isAdmin={true} />} />
                  <Route path={Paths.admin.Sideboard.LogoAndFavicon} element={<LogoAndFavicon />} />

                  <Route path={Paths.admin.Users.MyProfile} element={<MyProfile />} />

                  <Route path={Paths.admin.Marketing.TransactionalEmails} element={<TransactionalEmails />} />
                  <Route path={Paths.admin.Marketing.EmailTemplate} element={<EmailTemplate />} />

                  <Route path={Paths.admin.Security.SecurityDashboard} element={<SecurityDashboard />} />
                  <Route path={Paths.admin.Security.ListDetails} element={<ListDetails />} />

                  <Route path={Paths.admin.Building} element={<Building isAdmin={true} />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </ScrollToTop>
      </Router>
    </React.StrictMode>
  );
};

export default RouterIndex;
