import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';

import Font from '../../../Components/Generals/Fonts/Font';
import Paths from '../../../utils/enums/Paths';
import FocusButton from '../../../Components/Generals/FocusButton';
import claims from '../../../assets/Images/claims.svg';
import { useStyles } from './LandingPage.styles';
import { selectPortalLanguage } from '../../../Redux/Slices/Portal.Slices';
import {
  TandC,
  TandCEn,
  privacy,
  privacyEn,
  returnsPolicyEs,
  returnsPolicyEn,
  helpAndSupport,
  helpAndSupportEn,
  policiesEs,
  policiesEn,
} from './PageData/PageData';
import * as colors from '../../../assets/GlobalColors';

const LandingPage = () => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const classes = useStyles();
  const { type } = useParams();

  const [pageData, setPageData] = React.useState({});
  const [displayClaims, setDisplayClaims] = React.useState(false);
  const portalLanguage = useSelector(selectPortalLanguage);
  const mediaQuery1200 = useMediaQuery('(max-width:1200px)');

  const handleClickIndex = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNavigationTo = (e, path) => {
    e.preventDefault();
    navigate(`/${path}`);
  };

  React.useEffect(() => {
    switch (type) {
      case 'terms-and-conditions':
        if (portalLanguage === 'es') setPageData(TandC);
        else setPageData(TandCEn);
        break;
      case 'privacy-policy':
        if (portalLanguage === 'es') setPageData(privacy);
        else setPageData(privacyEn);
        break;
      case 'returnsPolicy':
        if (portalLanguage === 'es') setPageData(returnsPolicyEs);
        else setPageData(returnsPolicyEn);
        break;
      case 'help-and-support':
        setDisplayClaims(true);
        if (portalLanguage === 'es') setPageData(helpAndSupport);
        else setPageData(helpAndSupportEn);
        break;
      case 'policies':
        if (portalLanguage === 'es') setPageData(policiesEs);
        else setPageData(policiesEn);
        break;
      default:
        setPageData(TandC);
        break;
    }
  }, [type, portalLanguage]);

  if (Object.keys(pageData).length > 0) {
    return (
      <Container maxWidth="xl">
        <Box
          component="div"
          className={classes.root}
          sx={{
            flexDirection: mediaQuery1200 ? 'column-reverse' : 'row',
          }}
        >
          <Box component="div" className={classes.infoBox}>
            <Font text={pageData.title} color={colors.BLACK} size={36} lineHeight="46px" isHead={true} />

            <Box component="div" className={classes.infoDescriptionsBox}>
              {pageData.descriptions.map((description, idx) => {
                if (description.type === 'paragraph') {
                  return (
                    <Font
                      key={idx}
                      text={description.text}
                      color={colors.BLACK}
                      size={16}
                      lineHeight="26px"
                      isHead={description.bold}
                    />
                  );
                } else return null;
              })}
            </Box>

            <Box component="div" className={classes.infoDescriptionsBox}>
              {pageData.sections.map((section, idx) => {
                return (
                  <Box
                    key={idx}
                    id={section.id}
                    component="div"
                    className={classes.infoDescriptionsBox}
                    sx={{
                      marginBottom: '40px',
                    }}
                  >
                    <Font
                      key={idx}
                      text={`${idx + 1}. ${section.title}`}
                      color={colors.GREEN_MAIN}
                      size={28}
                      lineHeight="36px"
                      isHead={true}
                    />

                    {section.descriptions.map((description, idx) => {
                      return (
                        <RenderedDescription
                          key={idx}
                          description={description}
                        />
                      )
                    })}
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box component="div" className={classes.stickyBox}>
            <Box component="div" className={classes.indexBox}>
              <Font text="Índice" color={colors.WHITE} size={20} lineHeight="28px" isHead={true} />

              <Box component="div" className={classes.infoDescriptionsBox}>
                <Button
                  onClick={(e) => handleScrollTop(e)}
                  sx={{
                    padding: '0px',
                  }}
                >
                  <Font text={pageData.title} color={colors.GREEN_MAIN} size={16} lineHeight="26px" isHead={true} />
                </Button>

                {pageData.sections.map((section, idx) => {
                  return (
                    <Button
                      key={idx}
                      onClick={(e) => handleClickIndex(e, section.id)}
                      sx={{
                        padding: '0px',
                      }}
                    >
                      <Font
                        text={`${idx + 1}. ${section.title}`}
                        color={colors.GREEN_MAIN}
                        size={16}
                        lineHeight="26px"
                        isHead={true}
                      />
                    </Button>
                  );
                })}
              </Box>
            </Box>

            {displayClaims ? (
              <Box
                component="div"
                sx={{
                  width: '400px',
                  height: '380px',
                  backgroundImage: `url(${claims})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '20px',
                }}
              >
                <Font
                  text={t('client.helpAndSupport.claims.title')}
                  color={colors.WHITE}
                  size={20}
                  lineHeight="28px"
                  isHead={true}
                />

                <Font
                  text={t('client.helpAndSupport.claims.text')}
                  color={colors.WHITE}
                  size={20}
                  lineHeight="28px"
                  isHead={false}
                />

                <FocusButton
                  handleAction={(e) => handleNavigationTo(e, Paths.client.ReturnsAndClaims)}
                  normalColor={colors.GREEN_MAIN}
                  focusColor={colors.BLACK}
                  text={t('client.helpAndSupport.claims.button')}
                  w="300px"
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Container>
    );
  }

  return null;
};

const RenderedDescription = ({ description }) => {
  const navigate = useNavigate();

  const handleNavigation = (e) => {
    e.preventDefault();
    navigate('/information/privacy-policy');
  };

  if (description.type === 'paragraph') {
    return (
      <Font
        text={description.text}
        color={colors.BLACK}
        size={16}
        lineHeight="26px"
        isHead={description.bold}
        underline={description.underline}
        italic={description.italic}
      />
    );
  }

  if (description.type === 'list') {
    return (
      <>
        {description.text ? (
          <RenderedDescription
            description={{
              ...description,
              type: 'paragraph',
            }}
          />
        ) : null}

        <ul>
          {description.list.map((listItem, idx) => {
            return (
              <li key={idx}>
                <RenderedDescription
                  description={listItem}
                />
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  if (description.type === 'numeric') {
    return (
      <>
        {description.text ? (
          <RenderedDescription
            description={{
              ...description,
              type: 'paragraph',
            }}
          />
        ) : null}

        <ol>
          {description.numeric.map((numericItem, idx) => {
            return (
              <li key={idx}>
                <RenderedDescription
                  description={numericItem}
                />
              </li>
            );
          })}
        </ol>
      </>
    );
  }

  if (description.type === 'columns') {
    return (
      <>
        {description.text ? (
          <RenderedDescription
            description={{
              ...description,
              type: 'paragraph',
            }}
          />
        ) : null}

        <Grid container spacing={2} sx={{ marginLeft: '1em' }}>
          {description.columns.map((column, idx) => (
            <Grid item xs={12} sm={6} key={idx}>
              {column.text ? (
                <RenderedDescription
                  description={{
                    ...column,
                    type: 'paragraph',
                    bold: true,
                  }}
                />
              ) : null}

              {column.row.map((rowItem, idx) => (
                <RenderedDescription
                  key={idx}
                  description={rowItem}
                />
              ))}
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  if (description.type === 'accordion') {
    return (
      <Accordion
        sx={{
          width: '100%',
          border: `2px solid ${colors.BLACK}`,
          borderRadius: '6px',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <RenderedDescription
            description={{
              ...description,
              type: 'paragraph',
            }}
          />
        </AccordionSummary>

        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {description.details.map((detailItem, idx) => {
            return (
              <RenderedDescription
                key={idx}
                description={detailItem}
              />
            )
          })}
        </AccordionDetails>
      </Accordion>
    );
  }

  if (description.type === 'textButton') {
    return (
      <Button
        onClick={handleNavigation}
        variant="text"
        sx={{
          width: 'fit-content',
        }}
      >
        <Font
          text={description.text}
          color={colors.TSADMIN_BLUE}
          size={16}
          lineHeight="26px"
          isHead={description.bold}
        />
      </Button>
    );
  }

  return null;
};

export default LandingPage;
