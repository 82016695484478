import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class Orders extends Axios {
  constructor() {
    super();
    this.urlCreateOrder = API_ROUTES.orders.createOrder;
    this.urlGetOrder = API_ROUTES.orders.getOrder;
    this.urlGetAllOrders = API_ROUTES.orders.getAllOrders;
    this.urleditOrderStatusByOrderId = API_ROUTES.orders.editOrderStatusByOrderId;
    this.urlcreateOrderTicket = API_ROUTES.orders.createOrderTicket;
    this.urltvhCreateOrder = API_ROUTES.orders.tvhCreateOrder;
    this.urlgetDashboardOrders = API_ROUTES.orders.getDashboardOrders;
    this.urlcreateOrderClaim = API_ROUTES.orders.createOrderClaim;
    this.urladminViewOrders = API_ROUTES.orders.adminViewOrders;
    this.urladminUpadteOrderStatus = API_ROUTES.orders.adminUpadteOrderStatus;
  }

  async createOrder(order) {
    const response = await this.post(this.urlCreateOrder, order);
    return response;
  }

  async getOrder(orderNumber) {
    const response = await this.get(this.urlGetOrder.replace(':orderNumber', orderNumber));
    return response;
  }

  async getAllOrderByUserId(userId) {
    const response = await this.get(API_ROUTES.orders.getAllOrderByUserId.replace(':userId', userId));
    return response;
  }

  async getAllOrders() {
    const response = await this.get(this.urlGetAllOrders);
    return response;
  }

  async editOrderStatusByOrderId(body) {
    const response = await this.post(this.urleditOrderStatusByOrderId, body);
    return response;
  }

  async createOrderTicket(body) {
    const response = await this.post(this.urlcreateOrderTicket, body);
    return response;
  }

  async tvhCreateOrder(body) {
    const response = await this.post(this.urltvhCreateOrder, body);
    return response;
  }

  async getDashboardOrders(minDate, maxDate) {
    const url = this.urlgetDashboardOrders.replace(':minDate', minDate).replace(':maxDate', maxDate);
    const response = await this.get(url);
    return response;
  }

  async createOrderClaim(body) {
    const response = await this.post(this.urlcreateOrderClaim, body);
    return response;
  }

  async adminViewOrders(orderStatus) {
    const url = this.urladminViewOrders.replace(':orderStatus', orderStatus);
    const response = await this.get(url);
    return response;
  }

  async adminUpadteOrderStatus(body) {
    const response = await this.post(this.urladminUpadteOrderStatus, body);
    return response;
  }
}

export default Orders;
