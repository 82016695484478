import React from 'react';
import Image from 'react-bootstrap/esm/Image';
import { useDispatch, useSelector } from 'react-redux';

import portalSettings from '../../entities/portalSettings';
import generalsEnums from '../../utils/Generals';
import Font from './Fonts/Font';
import { getSize } from '../../utils/GetFontSize';
import { setPortalLanguage, setPortalCountry } from '../../Redux/Slices/Portal.Slices';
import {
  setLogoId,
  selectLogoId,
  setLogo,
  selectLogo,
  setLogoSize,
  selectLogoSize,
  setLogoHeight,
  selectLogoHeight,
  setFaviconId,
  selectFaviconId,
  setFaviconImage,
  selectFaviconImage,
  selectStoreName,
  setStoreName,
  selectLanguageStore,
  selectUserType,
  selectLanguage,
} from '../../Redux/Slices/User.Slices';

const TsiLogo = () => {
  const dispatch = useDispatch();
  const portalSettingsAPI = new portalSettings();

  const logoId = useSelector(selectLogoId);
  const logo = useSelector(selectLogo);
  const size = useSelector(selectLogoSize);
  const height = useSelector(selectLogoHeight);
  const faviconId = useSelector(selectFaviconId);
  const faviconImage = useSelector(selectFaviconImage);
  const storeName = useSelector(selectStoreName);
  const languageStore = useSelector(selectLanguageStore);

  React.useEffect(async () => {
    const response = await portalSettingsAPI.getPortalDefaultLogo();
    const faviconResponse = await portalSettingsAPI.getPortalFavicon();
    const storeProfileResponse = await portalSettingsAPI.getProfileForClient();

    if (faviconResponse !== generalsEnums.undefinedData) {
      const { data } = faviconResponse.data;
      const { _id, image } = data[0];

      if (faviconId !== _id) {
        dispatch(setFaviconId(_id));
        dispatch(setFaviconImage(image));
      }
    }

    if (response !== generalsEnums.undefinedData) {
      const { data } = response.data;
      const { _id } = data[0];

      if (logoId !== _id) {
        dispatch(setLogoId(_id));
        dispatch(setLogo(data[0]));
        const { fontSize, lineHeight } = getSize(data[0].fontSize);
        dispatch(setLogoSize(fontSize));
        dispatch(setLogoHeight(lineHeight));
      }
    }

    if (storeProfileResponse !== generalsEnums.undefinedData) {
      const { data } = storeProfileResponse.data;
      const { storeName: storeNameRes, language: languageRes, country: countryRes } = data;

      if (storeName !== storeNameRes) {
        dispatch(setStoreName(storeNameRes));
      }

      dispatch(setPortalCountry(countryRes));
    }
  }, []);

  React.useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');
    link.setAttribute('href', faviconImage);
  }, [faviconImage]);

  React.useEffect(() => {
    if (storeName !== generalsEnums.emptyString) {
      document.title = storeName;
    }
  }, [storeName]);

  React.useEffect(() => {
    if (languageStore !== generalsEnums.emptyString) {
      localStorage.setItem('language', languageStore);
    }
  }, [languageStore]);

  if (Object.keys(logo).length > 0) {
    if (logo.type === generalsEnums.variants.text) {
      return <Font text={logo.text} color={logo.fontColor} size={size} lineHeight={height} isHead={true} />;
    } else if (logo.type === generalsEnums.variants.image) {
      return (
        <Image
          src={logo.image}
          alt="logo"
          style={{
            width: 'auto',
            height: 'auto',
          }}
        />
      );
    }
  }

  return null;
};

export default TsiLogo;
