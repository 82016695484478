import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  RadioFieldMoleculeBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '12px',
  },
});
