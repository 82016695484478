import { makeStyles } from '@mui/styles';

import * as colors from '../../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.GRAY_204_1}`,
    borderRadius: '6px',
  },
  element: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: colors.WHITE,
  },
  header: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${colors.GRAY_204_1}`,
    backgroundColor: colors.WHITE,
    padding: '10px 10px 10px 10px',
  },
  body: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Accordion: {
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    height: 'auto',
  },
  CloseIcon: {
    color: colors.BLACK,
    width: '20px',
    height: '20px',
  },
  table: {
    border: 'none',
    boxShadow: 'none',
  },
  payButton: {
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: colors.GREEN_MAIN,
    color: colors.BLACK,

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      color: colors.BLACK,
      opacity: '0.8',
    },
  },
  policies: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textButton: {
    width: 'auto',
    height: '20px',
    color: colors.BLACK,
  },
  productItem: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '10px',
    marginTop: '10px',
  },
  image: {
    maxWidth: '80px',
    minWidth: '80px',
    maxHeight: '100px',
    minHeight: '100px',
    padding: '0',
    margin: '0',
    objectFit: 'cover',
  },
  productInfo: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  DeleteIcon: {
    color: colors.TSADMIN_RED_DELETE,
  },
  quantityBox: {
    width: '100%',
    height: '20px',
  },
  emptyCart: {
    width: '550px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '25px',
  },
  EmptyCart: {
    width: '150px',
    height: '150px',
  },
  editButton: {
    width: '120px',
    height: '30px',
    border: 'none',
    backgroundColor: colors.WHITE,
    borderRadius: '8px',

    '&:hover': {
      border: 'none',
      backgroundColor: colors.WHITE,
      opacity: '0.8',
    },
  },
  emptyBox: {
    width: '100%',
    height: '100px',
  },
});
