import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '20px',
  },
  form: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: '16px',
  },
  formItem: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '5px',
  },
  continueButton: {
    width: '170px',
    height: '40px',
    backgroundColor: colors.GREEN_MAIN,
    border: `1px solid ${colors.GREEN_MAIN}`,
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      border: `1px solid ${colors.GREEN_MAIN}`,
      borderRadius: '6px',
      opacity: '0.8',
    },
  },
});
