import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import generalsEnums from '../../../../utils/Generals';
import Font from '../../../../Components/Generals/Fonts/Font';
import useStyles from './LogsCards/styles';
import * as colors from '../../../../assets/GlobalColors';

const styles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '24px',
  },
});

const ClaimsTab = ({
  claimData,
  text20Size,
  text20LineHeight,
  text18Size,
  text18LineHeight,
  text16Size,
  text16LineHeight,
}) => {
  const classes = styles();
  const cardClasses = useStyles();
  const { t } = useTranslation('global');

  const [claimDate, setClaimDate] = React.useState(generalsEnums.emptyString);
  const [claimSubject, setClaimSubject] = React.useState(generalsEnums.emptyString);
  const [claimMessage, setClaimMessage] = React.useState(generalsEnums.emptyString);

  React.useEffect(() => {
    if (claimData !== generalsEnums.emptyObject) {
      const { claimDate, claimSubject, claimMessage } = claimData;
      setClaimDate(claimDate);
      setClaimSubject(claimSubject);
      setClaimMessage(claimMessage);
    }
  }, [claimData]);

  if (claimData !== generalsEnums.emptyObject) {
    return (
      <Box
        component={generalsEnums.BoxDiv}
        className={cardClasses.logHistoryCard}
        sx={{
          width: 'auto',
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          className={cardClasses.logHistoryText}
          sx={{
            borderRadius: '8px',
            border: `1px solid ${colors.BORDER_GRAY_1}}`,
          }}
        >
          <Box component={generalsEnums.BoxDiv} className={cardClasses.logHistoryTextTitle}>
            <Font
              text={claimSubject}
              color={colors.BLACK}
              size={text18Size}
              lineHeight={text18LineHeight}
              isHead={generalsEnums.trueBoolean}
              isCenter={generalsEnums.falseBoolean}
              ml="0px"
            />

            <Font
              text={claimDate}
              color={colors.TEXT_GRAY_666}
              size={text16Size}
              lineHeight={text16LineHeight}
              isHead={generalsEnums.trueBoolean}
              isCenter={generalsEnums.falseBoolean}
              ml="0px"
            />
          </Box>

          <Font
            text={claimMessage}
            color={colors.BLACK}
            size={text16Size}
            lineHeight={text16LineHeight}
            isHead={generalsEnums.falseBoolean}
            isCenter={generalsEnums.falseBoolean}
            ml="0px"
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box component={generalsEnums.BoxDiv} className={classes.root}>
      <Font
        text={t('ClaimsTab.empty')}
        color={colors.BLACK}
        size={text20Size}
        lineHeight={text20LineHeight}
        isHead={generalsEnums.trueBoolean}
        isCenter={generalsEnums.falseBoolean}
        ml="0px"
      />
    </Box>
  );
};

export default ClaimsTab;
