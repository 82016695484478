import { createSlice } from '@reduxjs/toolkit';

import generalsEnums from '../../utils/Generals';

const initialState = {
  portalLanguage: generalsEnums.portalLanguages.en.value,
  portalCurrency: generalsEnums.currencies.usd,
  portalExchangeRate: generalsEnums.nullData,
  profileStoreAddress: generalsEnums.emptyString,
  portalCountry: generalsEnums.countries.unitedStates,
  currentPageItems: 1,
  mailTemplates: [],
  isCountryCBDisabled: false,
  blockCountry: false,
  userIdAddress: generalsEnums.emptyString,
};

export const PortalSlices = createSlice({
  name: 'PortalSlices',
  initialState,
  reducers: {
    setUserIdAddress: (state, action) => {
      state.userIdAddress = action.payload;
    },
    setBlockCountry: (state, action) => {
      state.blockCountry = action.payload;
    },
    setIsCountryCBDisabled: (state, action) => {
      state.isCountryCBDisabled = action.payload;
    },
    setMailTemplates: (state, action) => {
      state.mailTemplates = action.payload;
    },
    setCurrentPageItems: (state, action) => {
      state.currentPageItems = action.payload;
    },
    setPortalCountry: (state, action) => {
      state.portalCountry = action.payload;
    },
    setProfileStoreAddress: (state, action) => {
      state.profileStoreAddress = action.payload;
    },
    setPortalExchangeRate: (state, action) => {
      state.portalExchangeRate = action.payload;
    },
    setPortalCurrencyUSD: (state) => {
      state.portalCurrency = generalsEnums.currencies.usd;
    },
    setPortalCurrencyMXN: (state) => {
      state.portalCurrency = generalsEnums.currencies.mxn;
    },
    setPortalLanguage: (state, action) => {
      state.portalLanguage = action.payload;
    },
  },
});

export const {
  setUserIdAddress,
  setIsCountryCBDisabled,
  setMailTemplates,
  setCurrentPageItems,
  setPortalCountry,
  setProfileStoreAddress,
  setPortalExchangeRate,
  setPortalCurrencyUSD,
  setPortalCurrencyMXN,
  setPortalLanguage,
  setBlockCountry,
} = PortalSlices.actions;

export const selectPortalLanguage = (state) => state.PortalSlices.portalLanguage;
export const selectLanguageOptions = () => {
  return [
    {
      value: generalsEnums.portalLanguages.es.value,
      label: generalsEnums.portalLanguages.es.label,
    },
    {
      value: generalsEnums.portalLanguages.en.value,
      label: generalsEnums.portalLanguages.en.label,
    },
  ];
};
export const selectCurrencyOptions = () => {
  return [
    {
      value: generalsEnums.currencies.usd,
      label: '$ USD',
    },
    {
      value: generalsEnums.currencies.mxn,
      label: '$ MXN',
    },
  ];
};
export const selectPortalCurrency = (state) => state.PortalSlices.portalCurrency;
export const selectPortalExchangeRate = (state) => state.PortalSlices.portalExchangeRate;
export const selectProfileStoreAddress = (state) => state.PortalSlices.profileStoreAddress;
export const selectCountryOptions = () => {
  return [
    {
      value: generalsEnums.countries.mexico,
      label: 'México',
    },
    {
      value: generalsEnums.countries.unitedStates,
      label: 'USA',
    },
  ];
};
export const selectPortalCountry = (state) => state.PortalSlices.portalCountry;
export const selectCountriesForCB = (state) => {
  const countriesArray = [
    {
      value: 'mx',
      label: 'México',
    },
    {
      value: 'us',
      label: 'United States',
    },
  ];

  return countriesArray;
};
export const selectCurrentPageItems = (state) => state.PortalSlices.currentPageItems;
export const selectMailTemplates = (state) => state.PortalSlices.mailTemplates;
export const selectTemplateByName = (templateName) => (state) => {
  const mailTemplates = state.PortalSlices.mailTemplates;
  const template = mailTemplates.find((template) => template.templateName === templateName);
  return template;
};
export const selectCountryCBDisabled = (state) => state.PortalSlices.isCountryCBDisabled;
export const selectBlockCountry = (state) => state.PortalSlices.blockCountry;
export const selectUserIdAddress = (state) => state.PortalSlices.userIdAddress;

export default PortalSlices.reducer;
