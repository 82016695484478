import React from 'react';
import Box from '@mui/material/Box';

import generalsEnums from '../Generals';

const AdminScreenTemplate = ({ children, gap }) => {
  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: '100%',
        height: '100%',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: gap ? gap : '0px',
      }}
    >
      {children}
    </Box>
  );
};

export default AdminScreenTemplate;
