import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import Addresses from '../../../entities/Addresses';
import AddressBook from './AddressBook';
import AddressForm from '../../../Components/Generals/Forms/AddressForm';
import ResponsiveComponent from '../../../Hooks/ResponsiveComponent';
import Font from '../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../utils/Generals';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import { getResponsiveValues } from '../../../utils/FormatData';
import { getAddressText } from '../../../utils/FormatData';
import { setBackDropState } from '../../../Redux/Slices/Navigation.Slices';
import { addDeliveryAddress, addBillingAddress } from '../../../Redux/Slices/ShoppingCart.Slices';
import * as colors from '../../../assets/GlobalColors';

const SelectAddress = ({
  t,
  kind,
  logged,
  titleSize,
  titleLineHeight,
  addressSize,
  addressLineHeight,
  addressObject,
  userLoggedAddress,
  userId,
  setUserLoggedAddress,
}) => {
  const AddressesAPI = new Addresses();
  const dispatch = useDispatch();

  const [title, setTitle] = React.useState(generalsEnums.emptyString);
  const [addressText, setAddressText] = React.useState(generalsEnums.emptyString);
  const [buttonText, setButtonText] = React.useState(generalsEnums.emptyString);
  const [showAddressBook, setShowAddressBook] = React.useState(generalsEnums.falseBoolean);
  const [showAddressForm, setShowAddressForm] = React.useState(generalsEnums.falseBoolean);
  const [showCustomerAdititionalInfo, setShowCustomerAdititionalInfo] = React.useState(generalsEnums.falseBoolean);
  const [addressUserData, setAddressUserData] = React.useState(generalsEnums.emptyString);
  const [addressStreet, setAddressStreet] = React.useState(generalsEnums.emptyString);
  const [addressCity, setAddressCity] = React.useState(generalsEnums.emptyString);

  const handleEditAddress = (e) => {
    e.preventDefault();
    if (logged) {
      if (Object.keys(addressObject).length > 0) {
        setShowAddressBook(generalsEnums.falseBoolean);
        setShowAddressForm(generalsEnums.trueBoolean);
      } else {
        setShowAddressBook(generalsEnums.trueBoolean);
        setShowAddressForm(generalsEnums.falseBoolean);
      }
    } else {
      setShowAddressForm(generalsEnums.trueBoolean);
      setShowAddressBook(generalsEnums.falseBoolean);
    }
  };

  const handleOpenAddressFormFromAddressBook = (e) => {
    e.preventDefault();
    setShowAddressBook(generalsEnums.falseBoolean);
    setShowAddressForm(generalsEnums.trueBoolean);
  };

  const handleCancelAddressForm = () => {
    setShowAddressForm(generalsEnums.falseBoolean);
  };

  const handleSaveAddress = async (address, sameAsBillingAddress, saveUserAddress) => {
    dispatch(setBackDropState(generalsEnums.trueBoolean));

    if (kind === generalsEnums.SelectAddress.address) {
      dispatch(
        addDeliveryAddress({
          deliveryAddress: address,
        })
      );

      if (sameAsBillingAddress) {
        dispatch(
          addBillingAddress({
            billingAddress: address,
          })
        );
      }
    } else if (kind === generalsEnums.SelectAddress.billing) {
      dispatch(
        addBillingAddress({
          billingAddress: address,
        })
      );
    }

    if (saveUserAddress) {
      await AddressesAPI.createUsersAddressesHandler({
        ...address,
        userId,
      });
      setUserLoggedAddress(generalsEnums.emptyArray);
    }

    setShowAddressForm(generalsEnums.falseBoolean);
    setShowAddressBook(generalsEnums.falseBoolean);
    dispatch(setBackDropState(generalsEnums.falseBoolean));
  };

  const setState = (stateToUpdate, value) => {
    switch (stateToUpdate) {
      case 'addressUserData':
        setAddressUserData(value);
        break;
      case 'addressStreet':
        setAddressStreet(value);
        break;
      case 'addressCity':
        setAddressCity(value);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (kind === generalsEnums.SelectAddress.address) {
      setTitle(t('SelectAddress.address.title'));
      setShowCustomerAdititionalInfo(generalsEnums.trueBoolean);
    } else if (kind === generalsEnums.SelectAddress.billing) {
      setTitle(t('SelectAddress.billing.title'));
      setShowCustomerAdititionalInfo(generalsEnums.falseBoolean);
    }
  }, [kind]);

  React.useEffect(() => {
    if (Object.keys(addressObject).length > 0) {
      setButtonText(t('Generals.edit'));
      const addressTextFormates = getAddressText(addressObject);

      const addressStatesToUpdate = {
        addressUserData: 'addressUserData',
        addressStreet: 'addressStreet',
        addressCity: 'addressCity',
      };

      Object.keys(addressStatesToUpdate).forEach((key) => {
        if (addressTextFormates[key]) {
          const stateToUpdate = addressStatesToUpdate[key];
          setState(stateToUpdate, addressTextFormates[key]);
        }
      });

      setAddressText(generalsEnums.emptyString);
    } else {
      setButtonText(t('Generals.add'));
      setAddressText(t('SelectAddress.addAddress'));
    }
  }, [addressObject]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        minWidth: '650px',
        minHeight: '50px',
        width: '100%',
        height: 'auto',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '12px',
        borderBottom: `2px solid ${colors.BORDER_GRAY}`,
      }}
    >
      <Font
        text={title}
        color={colors.BLACK}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: 'auto',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {addressText !== generalsEnums.emptyString && (
            <Font
              text={addressText}
              color={colors.BLACK}
              size={addressSize}
              lineHeight={addressLineHeight}
              isHead={generalsEnums.falseBoolean}
              ml="0px"
            />
          )}

          <Font
            text={addressUserData}
            color={colors.BLACK}
            size={addressSize}
            lineHeight={addressLineHeight}
            isHead={generalsEnums.falseBoolean}
            ml="0px"
          />

          <Font
            text={addressStreet}
            color={colors.BLACK}
            size={addressSize}
            lineHeight={addressLineHeight}
            isHead={generalsEnums.falseBoolean}
            ml="0px"
          />

          <Font
            text={addressCity}
            color={colors.BLACK}
            size={addressSize}
            lineHeight={addressLineHeight}
            isHead={generalsEnums.falseBoolean}
            ml="0px"
          />
        </Box>

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100px',
          }}
        >
          <TsiButton
            type={generalsEnums.buttonTypes.black}
            handleAction={handleEditAddress}
            text={buttonText}
            isDisabled={generalsEnums.falseBoolean}
            size={generalsEnums.fontSizes.size12.size}
            lineHeight={generalsEnums.fontSizes.size12.lineHeight}
          />
        </Box>
      </Box>

      {showAddressBook && (
        <AddressBook
          t={t}
          userLoggedAddress={userLoggedAddress}
          addressSize={addressSize}
          addressLineHeight={addressLineHeight}
          handleOpenAddressFormFromAddressBook={handleOpenAddressFormFromAddressBook}
          addressObject={addressObject}
          handleSaveAddress={handleSaveAddress}
        />
      )}

      {showAddressForm && (
        <AddressForm
          t={t}
          handleCancelAddressForm={handleCancelAddressForm}
          addressObject={addressObject}
          addressSize={addressSize}
          addressLineHeight={addressLineHeight}
          showCustomerAdititionalInfo={showCustomerAdititionalInfo}
          handleSaveAddress={handleSaveAddress}
          logged={logged}
        />
      )}
    </Box>
  );
};

const Main = (props) => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size22.size,
      generalsEnums.fontSizes.size26.size,
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size28.size,
    ],
    [
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size22.lineHeight,
      generalsEnums.fontSizes.size26.lineHeight,
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size28.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];

  const [titleSize, titleLineHeight, addressSize, addressLineHeight] = getResponsiveValues(
    responsiveArray,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl
  );

  return (
    <SelectAddress
      {...props}
      titleSize={titleSize}
      titleLineHeight={titleLineHeight}
      addressSize={addressSize}
      addressLineHeight={addressLineHeight}
    />
  );
};

export default Main;
