import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  CategoryFilter: {
    width: '250px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.WHITE,
  },
  products: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
  },
  productsList: {
    width: '100%',
    height: 'auto',
    marginTop: '48px',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: colors.BLACK,
      border: `1px solid ${colors.BLACK}`,
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      color: colors.GREEN_MAIN,
      backgroundColor: colors.GREEN_MAIN_1,
      border: `1px solid ${colors.GREEN_MAIN}`,
    },
  },
});
