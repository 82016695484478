import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors.js';

export const useStyles = makeStyles({
  header: {
    width: '100%',
    height: 'auto',
    padding: '24px',
    display: 'flex',
    gap: '16px',
  },
  HeaderItemRoot: {
    width: 'auto',
    minWidth: '50px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  HeaderItemText: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colors.GREEN_MAIN}`,
  },
});
