import { makeStyles } from '@mui/styles';

import * as colors from '../../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '50px',
    padding: '15px',
  },
  headerBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  allButton: {
    width: '150px',
    height: '40px',
    padding: '0px',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '0px',
    textAlign: 'left',
    textOverflow: 'ellipsis',

    '&:hover': {
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0px',
      opacity: '0.8',
    },
  },
  actionsBox: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px',
  },
  deleteButton: {
    padding: '0px',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    color: colors.TSADMIN_RED_DELETE,
  },
  deleteBox: {
    width: 500,
    height: 250,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
  },
  deleteButtonsBox: {
    width: '100%',
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  cancelButton: {
    width: 100,
    height: 40,
    borderRadius: '6px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.GRAY_214_1}`,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.WHITE,
      border: `1px solid ${colors.GRAY_214_1}`,
      boxShadow: 'none',
      opacity: 0.8,
    },
  },
  deleteButtonDialog: {
    width: 100,
    height: 40,
    borderRadius: '6px',
    backgroundColor: colors.TSADMIN_RED_DELETE,
    border: `1px solid ${colors.TSADMIN_RED_DELETE}`,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.TSADMIN_RED_DELETE,
      border: `1px solid ${colors.TSADMIN_RED_DELETE}`,
      boxShadow: 'none',
      opacity: 0.8,
    },
  },
  AccordionSummaryBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  rowBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  rowDetailsBox: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0px 10px 0px 10px',
  },
  OrderInfoBox: {
    width: '500px',
    height: 'auto',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '5px',
    border: `1px solid ${colors.GRAY_214_1}`,
    borderRadius: '6px',
  },
  OrderInfoBoxRow: {
    height: 'auto',
    padding: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '5px',
  },
  addButton: {
    width: '150px',
    height: '40px',
    padding: '0px',
    backgroundColor: colors.GREEN_MAIN,
    border: `1px solid ${colors.GREEN_MAIN}`,
    boxShadow: 'none',
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      border: `1px solid ${colors.GREEN_MAIN}`,
      opacity: '0.8',
    },
  },
  OrderInfoTextBox: {
    width: '100%',
    height: 'auto',
  },
  ViewNotesButton: {
    width: '100px',
    height: '30px',
    padding: '0px',
    borderRadius: '6px',
    backgroundColor: colors.GREEN_MAIN,
    border: `1px solid ${colors.GREEN_MAIN}`,
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      border: `1px solid ${colors.GREEN_MAIN}`,
      opacity: '0.8',
    },
  },
});
