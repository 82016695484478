import React from 'react';
import uuid from 'react-uuid';
import { makeStyles } from '@mui/styles';
import { Box, Divider } from '@mui/material';

import NotificationCard from './LogsCards/NotificationCard';
import LogMailCard from './LogsCards/LogMailCard';
import OrderDetailsOrganism from '../../../../Components/Generals/Figma/Organisms/OrderDetailsOrganism';
import BadgeAttom from '../../../../Components/Generals/Figma/Atoms/BadgeAttom';
import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import OrderSummaryCard from '../../../../Components/Client/Checkout/OrderSummary/OrderSummaryCard';
import DividerAttom from '../../../../Components/Generals/Figma/Atoms/DividerAttom';
import * as colors from '../../../../assets/GlobalColors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '24px',
  },
  summary: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '24px',
    overflow: 'auto',
  },
  details: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: '8px',
    padding: '16px',
    gap: '16px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.BORDER_CCC}`,
  },
  detailsTitle: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsAddress: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '4px',
  },
  logHistoryCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    padding: '8px',
  },
  logHistoryText: {
    width: '100%',
    padding: '16px',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: '8px',
    backgroundColor: colors.WHITE,
  },
  logHistoryTextTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    width: '18px',
    height: '18px',
  },
});

const OrderGeneralTab = ({
  productsOrder,
  orderSubTotal,
  orderDelivery,
  orderTaxes,
  orderTotal,
  isHorizontal,
  orderDate,
  text12Size,
  text12LineHeight,
  text20Size,
  text20LineHeight,
  text18Size,
  text18LineHeight,
  text16Size,
  text16LineHeight,
  productsLength,
  deliveryName,
  billingName,
  deliveryContact,
  billingContact,
  deliveryStreet,
  billingStreet,
  deliveryCity,
  billingCity,
  contactTitle,
  userName,
  userPhone,
  userMail,
  orderIP,
  logHistory,
  languagePortal,
  productsItems,
  deliveryComments,
  orderNumber,
}) => {
  const classes = useStyles();

  return (
    <Box
      component="div"
      className={classes.root}
      sx={{
        flexDirection: isHorizontal ? 'row' : 'column',
        gap: isHorizontal ? '0px' : '24px',
        marginBottom: '50px',
      }}
    >
      <Box
        component="div"
        className={classes.summary}
        sx={{
          width: '500px',
        }}
      >
        <OrderSummaryCard
          isSummary={generalsEnums.trueBoolean}
          handlePrintOrderTicket={generalsEnums.nullData}
          productsOrder={productsOrder}
          orderSubTotal={orderSubTotal}
          orderDelivery={orderDelivery}
          orderTaxes={orderTaxes}
          orderTotal={orderTotal}
          canEdit={generalsEnums.nullData}
          orderSummary={generalsEnums.trueBoolean}
          w={'100%'}
        />

        <OrderDetailsOrganism
          orderDate={orderDate}
          productsItems={productsItems}
          productsLength={productsLength}
          deliveryName={deliveryName}
          deliveryContact={deliveryContact}
          deliveryStreet={deliveryStreet}
          deliveryCity={deliveryCity}
          deliveryComments={deliveryComments}
          billingName={billingName}
          billingContact={billingContact}
          billingStreet={billingStreet}
          billingCity={billingCity}
        />

        <Box component="div" className={classes.details}>
          <Box component="div" className={classes.detailsTitle}>
            <Font
              text={contactTitle}
              color={colors.BLACK}
              size={text20Size}
              lineHeight={text20LineHeight}
              isHead={true}
              isCenter={false}
            />
          </Box>

          <DividerAttom w="100%" color={colors.BORDER_CCC} />

          <Box component="div" className={classes.detailsAddress}>
            <Font
              text={userName}
              color={colors.BLACK}
              size={text16Size}
              lineHeight={text16LineHeight}
              isHead={false}
              isCenter={false}
            />

            <Font
              text={userPhone}
              color={colors.GREEN_MAIN}
              size={text16Size}
              lineHeight={text16LineHeight}
              isHead={false}
              isCenter={false}
            />

            <Font
              text={userMail}
              color={colors.GREEN_MAIN}
              size={text16Size}
              lineHeight={text16LineHeight}
              isHead={false}
              isCenter={false}
            />

            <Font
              text={orderIP}
              color={colors.TEXT_GRAY_102}
              size={text16Size}
              lineHeight={text16LineHeight}
              isHead={false}
              isCenter={false}
            />
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          width: '600px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '32px',
          overflow: 'auto',
        }}
      >
        {logHistory.map((logItem) => {
          const { date, logs: logsArr } = logItem;
          return (
            <React.Fragment key={uuid()}>
              <Divider
                sx={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BadgeAttom kind={generalsEnums.badge.greenSoft} text={date} />
              </Divider>

              {logsArr.map((logItem) => {
                const { kind } = logItem;
                return (
                  <React.Fragment key={uuid()}>
                    {kind === generalsEnums.logsKind.mail ? (
                      <LogMailCard
                        logItem={logItem}
                        text18Size={text18Size}
                        text18LineHeight={text18LineHeight}
                        text12Size={text12Size}
                        text12LineHeight={text12LineHeight}
                        text16Size={text16Size}
                        text16LineHeight={text16LineHeight}
                        orderNumber={orderNumber}
                      />
                    ) : kind === generalsEnums.logsKind.notification ? (
                      <NotificationCard
                        logItem={logItem}
                        text18Size={text18Size}
                        text18LineHeight={text18LineHeight}
                        text12Size={text12Size}
                        text12LineHeight={text12LineHeight}
                        text16Size={text16Size}
                        text16LineHeight={text16LineHeight}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })} 
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default OrderGeneralTab;
