import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { Box, Button, IconButton } from '@mui/material';

import Paths from '../../utils/enums/Paths';
import generalsEnums from '../../utils/Generals';
import TsiLogo from '../../Components/Generals/TsiLogo';
import fb from '../../assets/Icons/facebook.png';
import ws from '../../assets/Icons/whatsapp.png';
import stripe from '../../assets/Icons/stripe.png';
import Font from '../../Components/Generals/Fonts/Font';
import { useStyles } from './ClientFooter.styles';
import { selectProfileStoreAddress } from '../../Redux/Slices/Portal.Slices';

const USA_ADDRESS = 'Headquarters Nogales, Arizona, USA. 940 N Perkins Ave. Nogales, AZ 85621. USA';

const ClientFooter = () => {
  const navigate = useNavigate();

  const classes = useStyles();
  const { t } = useTranslation('global');

  const mediaQuery1200 = useMediaQuery('(max-width:1200px)');
  const profileStoreAddress = useSelector(selectProfileStoreAddress);

  const handleSelectLogo = () => {
    navigate(Paths.client.Home);
  };

  const handleTSI = (e) => {
    e.preventDefault();
    window.open(Paths.TSILanding, '_blank');
  };

  const handleContactUs = (e) => {
    e.preventDefault();
    navigate(Paths.client.ContactUs);
  };

  const handleAboutUs = (e) => {
    e.preventDefault();
    navigate(Paths.client.About);
  };

  const handleSupport = (e) => {
    e.preventDefault();
    navigate(Paths.client.Information + 'help-and-support');
  };

  const handleTerms = (e) => {
    e.preventDefault();
    navigate(Paths.client.Information + 'terms-and-conditions');
  };

  const handleReturnsPolicy = (e) => {
    e.preventDefault();
    navigate(Paths.client.Information + 'returnsPolicy');
  };

  const handlePrivacy = (e) => {
    e.preventDefault();
    navigate(Paths.client.Information + 'privacy-policy');
  };

  const handlePolicies = (e) => {
    e.preventDefault();
    navigate(Paths.client.Information + 'policies');
  };

  const handleSelectFacebook = (e) => {
    e.preventDefault();
  };

  const handleSelectWhatsapp = (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_WHATSAPP_API_TSI;
    window.open(url, '_blank');
  };

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: '100%',
        height: 'auto',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        paddingTop: '40px',
        paddingBottom: '40px',
      }}
    >
      <Container maxWidth="xl">
        <Box
          component={generalsEnums.BoxDiv}
          className={classes.root}
          sx={{
            flexDirection: mediaQuery1200 ? 'column' : 'row',
            height: mediaQuery1200 ? 'auto' : '250px',
            alignItems: mediaQuery1200 ? 'center' : 'center',
            gap: mediaQuery1200 ? '30px' : '0px',
          }}
        >
          <Box
            component={generalsEnums.BoxDiv}
            className={classes.aboutUsBox}
            sx={{
              width: mediaQuery1200 ? '100%' : '300px',
            }}
          >
            <Button
              variant="outlined"
              onClick={handleSelectLogo}
              style={{
                textTransform: 'none',
                border: 'none',
                padding: '0px',
              }}
              className={classes.logo}
            >
              <TsiLogo />
            </Button>

            <Font
              text={t('client.ClientFooter.text-aboutUs')}
              color="#000000"
              size={20}
              lineHeight="28px"
              isHead={true}
            />

            <Box component={generalsEnums.BoxDiv}>
              <Font text={profileStoreAddress} color="#000000" size={14} lineHeight="22px" isHead={false} />
            </Box>
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            className={classes.linkBox}
            sx={{
              width: mediaQuery1200 ? '100%' : '170px',
            }}
          >
            <Button variant="text" className={classes.textButton} disabled>
              <Font
                text={t('client.ClientFooter.text-links')}
                color="#000000"
                size={28}
                lineHeight="28px"
                isHead={true}
              />
            </Button>

            <Button onClick={handleTSI} variant="text" className={classes.textButton}>
              <Font
                text={t('client.ClientFooter.text-tsi')}
                color="#000000"
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>

            <Button onClick={handleContactUs} variant="text" className={classes.textButton}>
              <Font
                text={t('client.ClientFooter.text-contactUs')}
                color="#000000"
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>

            <Button onClick={handleAboutUs} variant="text" className={classes.textButton}>
              <Font
                text={t('client.ClientFooter.text-about')}
                color="#000000"
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>

            <Button onClick={handleSupport} variant="text" className={classes.textButton}>
              <Font
                text={t('client.ClientFooter.text-support')}
                color="#000000"
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            className={classes.informationBox}
            sx={{
              width: mediaQuery1200 ? '100%' : '200px',
            }}
          >
            <Button variant="text" className={classes.textButton} disabled>
              <Font
                text={t('client.ClientFooter.text-information')}
                color="#000000"
                size={20}
                lineHeight="28px"
                isHead={true}
              />
            </Button>

            <Button onClick={handleTerms} variant="text" className={classes.textButton}>
              <Font
                text={t('client.ClientFooter.text-terms')}
                color="#000000"
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>

            <Button onClick={handleReturnsPolicy} variant="text" className={classes.textButton}>
              <Font
                text={t('client.ClientFooter.text-returnsPolicy')}
                color="#000000"
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>

            <Button onClick={handlePrivacy} variant="text" className={classes.textButton}>
              <Font
                text={t('client.ClientFooter.text-privacy')}
                color="#000000"
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>

            <Button onClick={handlePolicies} variant="text" className={classes.textButton}>
              <Font
                text={t('client.ClientFooter.text-policies')}
                color="#000000"
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            className={classes.socialMediaBox}
            sx={{
              width: mediaQuery1200 ? '100%' : '380px',
            }}
          >
            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <Font
                text={t('client.ClientFooter.text-social')}
                color="#000000"
                size={20}
                lineHeight="28px"
                isHead={true}
              />

              <Box
                component={generalsEnums.BoxDiv}
                sx={{
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <IconButton onClick={handleSelectFacebook}>
                  <img src={fb} alt="Facebook Icon" className={classes.socialMediaIcon} />
                </IconButton>

                <IconButton onClick={handleSelectWhatsapp}>
                  <img src={ws} alt="Whatsapp Icon" className={classes.socialMediaIcon} />
                </IconButton>
              </Box>
            </Box>

            <img src={stripe} alt="Stripe Icon" className={classes.stripe} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ClientFooter;
