import React from 'react';
import { useMediaQuery } from '@mui/material';

const ResponsiveComponent = () => {
  const isXs = useMediaQuery('(max-width:600px)');
  const isSm = useMediaQuery('(max-width:960px)');
  const isMd = useMediaQuery('(max-width:1280px)');
  const isLg = useMediaQuery('(max-width:1920px)');
  const isXl = useMediaQuery('(min-width:1921px)');

  return {
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
  };
};

export default ResponsiveComponent;
