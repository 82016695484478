import React from 'react';
import { Button } from '@mui/material';

import * as colors from '../../assets/GlobalColors';
import Font from '../Generals/Fonts/Font';

const HeaderButtonUnestiled = ({ text, handleOnClick, icon }) => {
  return (
    <Button
      onClick={handleOnClick}
      variant="contained"
      style={{
        height: '40px',
        borderRadius: '8px',
        backgroundColor: colors.WHITE,
        border: 'none',
        boxShadow: 'none',
        gap: '10px',
      }}
    >
      {icon}

      <Font text={text} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />
    </Button>
  );
};

export default HeaderButtonUnestiled;
