import React from 'react';
import uuid from 'react-uuid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Typography, Badge, Popover } from '@mui/material';

import Searcher from '../../Components/Generals/Figma/Molecules/Searcher';
import portalSettings from '../../entities/portalSettings';
import TsiLogo from '../../Components/Generals/TsiLogo';
import Paths from '../../utils/enums/Paths';
import ShoppingCartIcon from '../../assets/Icons/ShoppingCartIcon.png';
import UserIcon from '../../assets/Icons/UserIcon.png';
import HeaderNavButton from '../../Components/Client/HeaderNavButton';
import ClientFooter from '../ClientFooter/ClientFooter';
import CartModal from '../../Modals/CartModal/CartModal';
import EmptyCart from '../../assets/Icons/EmptyCart.png';
import StripeIntegration from '../../entities/StripeIntegration';
import NativeComboBox from '../../Components/Generals/NativeComboBox';
import SearchBrands from '../../Components/Generals/SearchBrands';

import { useStyles } from './Header.styles';
import { setCartModalState } from '../../Redux/Slices/Navigation.Slices';
import {
  selectLanguageOptions,
  selectPortalLanguage,
  selectCurrencyOptions,
  selectPortalCurrency,
  setPortalCurrencyUSD,
  setPortalCurrencyMXN,
  selectPortalExchangeRate,
  setPortalExchangeRate,
  selectProfileStoreAddress,
  setProfileStoreAddress,
  selectPortalCountry,
  setPortalCountry,
  setPortalLanguage,
  selectCountryCBDisabled,
  setIsCountryCBDisabled,
  setBlockCountry,
} from '../../Redux/Slices/Portal.Slices';
import {
  selectProductsQuantity,
  fetchCountriesFromApi,
  selectPaymentIntentId,
  addClientSecret,
  addPaymentIntentId,
  addFreight,
  selectIsProductsEmpty,
} from '../../Redux/Slices/ShoppingCart.Slices';
import { selectIsUserSigned, selectFirstName } from '../../Redux/Slices/User.Slices';
import * as colors from '../../assets/GlobalColors';
import generalsEnums from '../../utils/Generals';
import { brands } from '../../utils/brands/brandNames.js';

const stripeIntegration = new StripeIntegration();

const styles = () => ({
  customBadge: {
    backgroundColor: (props) => props.color,
    color: colors.BLACK_1,
  },
});

const SimpleBadge = (props) => {
  const { classes } = props;
  const productsQuantity = useSelector(selectProductsQuantity);
  return (
    <div>
      <Badge classes={{ badge: classes.customBadge }} className={classes.margin} badgeContent={productsQuantity}>
        <img src={ShoppingCartIcon} alt="Shopping cart icon for TSI" className={classes.Icon} />
      </Badge>
    </div>
  );
};

const Header = ({ font20Size, font20LineHeight, font16Size, font16LineHeight }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const StyledBadge = withStyles(styles)(SimpleBadge);
  const { t } = useTranslation('global');
  const portalSettingsAPI = new portalSettings();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState(generalsEnums.emptyString);
  const [currency, setCurrency] = React.useState(generalsEnums.emptyString);
  const [country, setCountry] = React.useState(generalsEnums.emptyString);
  const [brand, setBrand] = React.useState(generalsEnums.emptyString);
  const anchorRef = React.useRef(null);

  const isUserSigned = useSelector(selectIsUserSigned);
  const firstName = useSelector(selectFirstName);
  const paymentIntentId = useSelector(selectPaymentIntentId);
  const languageOptions = useSelector(selectLanguageOptions);
  const portalLanguage = useSelector(selectPortalLanguage);
  const currencyOptions = useSelector(selectCurrencyOptions);
  const portalCurrency = useSelector(selectPortalCurrency);
  const mediaQuery1200 = useMediaQuery('(max-width:1200px)');
  const exchangeRate = useSelector(selectPortalExchangeRate);
  const storeProfileAddress = useSelector(selectProfileStoreAddress);
  const portalCountry = useSelector(selectPortalCountry);
  const countryCBDisabled = useSelector(selectCountryCBDisabled);
  const isProductsEmpty = useSelector(selectIsProductsEmpty);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const countriesArray = [
    {
      value: 'mx',
      label: 'México',
    },
    {
      value: 'us',
      label: 'United States',
    },
  ];

  const brandsArray = brands;

  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    console.log('Selected value:', event.target.value);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    navigate(Paths.UserManagement.signin);
  };

  const handleSelectLogo = () => {
    navigate('/');
  };

  const handleClick = () => {
    navigate('/my-account');
  };

  const handleClickShoppingCart = (e) => {
    e.preventDefault();
    dispatch(setCartModalState(true));
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (e) => {
    dispatch(setPortalLanguage(e.target.value));
  };

  const handleCurrencyChange = (e) => {
    if (e.target.value === generalsEnums.currencies.usd) {
      dispatch(setPortalCurrencyUSD());
    } else if (e.target.value === generalsEnums.currencies.mxn) {
      dispatch(setPortalCurrencyMXN());
    }
  };

  const handleCountryChange = (e) => {
    dispatch(setPortalCountry(e.target.value));
  };

  const handleBrandChange = (e) => {
    setBrand(e.target.value);
  }

  const navLinks = [
    {
      text: t('ClientHeader.navs.catalog'),
      route: Paths.client.ProductsView,
      isCategory: false,
      isLocal: true,
    },
    {
      text: t('ClientHeader.navs.nav-sells'),
      route: Paths.client.MostSold,
      isCategory: false,
      isLocal: true,
    },
    {
      text: t('ClientHeader.navs.nav-tracking'),
      route: Paths.client.Tracking,
      isCategory: false,
      isLocal: true,
    },
    {
      text: t('ClientHeader.navs.nav-contact'),
      route: Paths.client.ContactUs,
      isCategory: false,
      isLocal: true,
    },
    {
      text: t('ClientHeader.navs.nav-help'),
      route: `${Paths.client.Information}${Paths.client.HelpAndSupport}`,
      isCategory: false,
      isLocal: true,
    },
  ];

  const navTopLinks = [
    {
      text: t('ClientHeader.navTopLinks.tsi'),
      route: Paths.TSILanding,
      isCategory: false,
      isLocal: false,
    },
    {
      text: t('ClientHeader.navTopLinks.aboutUs'),
      route: Paths.client.About,
      isCategory: false,
      isLocal: true,
    },
    {
      text: t('ClientHeader.navTopLinks.terms'),
      route: `information/terms-and-conditions`,
      isCategory: false,
      isLocal: true,
    },
    {
      text: t('ClientHeader.navTopLinks.policies'),
      route: `information/policies`,
      isCategory: false,
      isLocal: true,
    },
  ];

  React.useEffect(() => {
    dispatch(fetchCountriesFromApi());
  }, []);

  React.useEffect(async () => {
    const path = location.pathname;
    if (paymentIntentId !== '' && path !== '/checkout') {
      await stripeIntegration.cancelPaymentIntent(paymentIntentId);
      dispatch(
        addClientSecret({
          clientSecret: '',
        })
      );
      dispatch(
        addPaymentIntentId({
          paymentIntentId: '',
        })
      );
      dispatch(
        addFreight({
          freight: 0,
        })
      );
    }
  }, [location, paymentIntentId]);

  React.useEffect(async () => {
    const path = location.pathname;
    if (path !== '/checkout') {
      dispatch(
        addFreight({
          freight: 0,
        })
      );
    }
  }, [location]);

  React.useEffect(() => {
    if (portalLanguage === generalsEnums.languages.english) {
      setLanguage(generalsEnums.languages.english);
    } else if (portalLanguage === generalsEnums.languages.spanish) {
      setLanguage(generalsEnums.languages.spanish);
    }
  }, [portalLanguage]);

  React.useEffect(() => {
    if (portalCurrency === generalsEnums.currencies.usd) {
      setCurrency(generalsEnums.currencies.usd);
    } else if (portalCurrency === generalsEnums.currencies.mxn) {
      setCurrency(generalsEnums.currencies.mxn);
    }
  }, [portalCurrency]);

  React.useEffect(async () => {
    if (exchangeRate === generalsEnums.nullData) {
      const getExchangeRateResponse = await portalSettingsAPI.getExchangeRate();
      const { data } = getExchangeRateResponse;
      const { data: rateData } = data;
      const { rates } = rateData;
      const { MXN } = rates;
      dispatch(setPortalExchangeRate(MXN));
    }
  }, [exchangeRate]);

  React.useEffect(async () => {
    const getStoreProfileAddressResponse = await portalSettingsAPI.getStoreProfileAddress();
    const { data: getStoreProfileAddressResponseData } = getStoreProfileAddressResponse;
    const { data: address } = getStoreProfileAddressResponseData;
    dispatch(setProfileStoreAddress(address));
  }, [storeProfileAddress]);

  React.useEffect(() => {
    setCountry(portalCountry);
  }, [portalCountry]);

  React.useEffect(() => {
    setBrand('Select brand');
  }, [portalCountry]);

  React.useEffect(() => {
    if (isProductsEmpty === true) {
      dispatch(setIsCountryCBDisabled(false));
    }
  }, [isProductsEmpty]);

  React.useEffect(() => {
    const path = location.pathname;
    if (path === Paths.client.Checkout) {
      dispatch(setBlockCountry(true));
    } else dispatch(setBlockCountry(false));
  }, [location]);

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        component="div"
        className={classes.container}
        sx={{
          border: 'none',
        }}
      >
        <Box
          component="div"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 15px 5px 15px',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '32px',
            }}
          >
            {navTopLinks.map((link, idx) => {
              return (
                <HeaderNavButton
                  key={uuid()}
                  text={link.text}
                  route={link.route}
                  isCategory={link.isCategory}
                  isLocal={link.isLocal}
                />
              );
            })}
          </Box>

          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '14px',
              padding: '0px',
            }}
          >
            <Box
              component="div"
              sx={{
                width: '130px',
              }}
            >
              <NativeComboBox
                valueForm={currency}
                label={generalsEnums.emptyString}
                labelColor={colors.BLACK_06}
                data={currencyOptions}
                change={(e) => {
                  handleCurrencyChange(e);
                }}
                w="100%"
              />
            </Box>

            <Box
              component="div"
              sx={{
                width: '130px',
              }}
            >
              <NativeComboBox
                valueForm={language}
                label={generalsEnums.emptyString}
                labelColor={colors.BLACK_06}
                data={languageOptions}
                change={(e) => {
                  handleLanguageChange(e);
                }}
                w="100%"
              />
            </Box>

            <Box
              component="div"
              sx={{
                width: '130px',
              }}
            >
              <NativeComboBox
                valueForm={country}
                label={generalsEnums.emptyString}
                labelColor={colors.BLACK_06}
                data={countriesArray}
                disableCB={countryCBDisabled}
                change={(e) => {
                  handleCountryChange(e);
                }}
                w="100%"
              />
            </Box>
          </Box>
        </Box>

        <Box component="div" className={classes.navContainer}>
          <Box component="div" className={classes.topContainer}>
            <Button
              variant="outlined"
              onClick={handleSelectLogo}
              style={{
                textTransform: 'none',
                border: 'none',
                padding: '0px',
              }}
            >
              <TsiLogo />
            </Button>

          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              padding: '0px',
            }}
          > 
            <Searcher anchorRef={anchorRef} searchValueCombo={selectedValue} />

            <SearchBrands
              id="searchBrands"
              data={brandsArray}
              isBrands={true}
              value={selectedValue}
              onChange={handleChange}
              label="Select Brand"
              labelColor="#000000"
              helperText="Select a brand from the list"
              w="250px"
              labelHead={true}
              disableCB={false}
              error={false}
            />
            
          </Box>

            <Box component="div" className={classes.userNav}>
              {!mediaQuery1200 ? (
                <>
                  {isUserSigned ? (
                    <>
                      <Button
                        variant="outlined"
                        onClick={handleClick}
                        className={classes.shoppingCartButton}
                        style={{
                          textTransform: 'none',
                          border: 'none',
                        }}
                      >
                        <img src={UserIcon} alt="User icon for TSI" className={classes.Icon} />

                        <Typography variant="body1" className="text-black text-14">
                          {firstName}
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={handleSignIn}
                      variant="outlined"
                      className={classes.userButton}
                      style={{
                        textTransform: 'none',
                        border: 'none',
                      }}
                    >
                      <img src={UserIcon} alt="Sign In icon for TSI" className={classes.Icon} />

                      <Typography variant="body1" className="text-black text-14">
                        {t('client.Header.text-signin-button')}
                      </Typography>
                    </Button>
                  )}
                </>
              ) : null}

              <Button
                onClick={handleClickShoppingCart}
                aria-describedby={id}
                variant="outlined"
                className={classes.shoppingCartButton}
                style={{
                  textTransform: 'none',
                  border: 'none',
                }}
              >
                <StyledBadge color={colors.GREEN_MAIN} />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Box
                  style={{
                    width: '300px',
                    height: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '15px',
                  }}
                >
                  <img src={EmptyCart} alt="Empty cart icon for TSI" className={classes.EmptyCart} />

                  <Typography variant="string" className="text-black text-14">
                    Carrito vacío
                  </Typography>
                </Box>
              </Popover>
            </Box>
          </Box>

          {!mediaQuery1200 ? (
            <Button variant="text" className={classes.backButton} onClick={handleClickBack}>
              <ArrowBackIosIcon fontSize="small" />
              <Typography variant="string" className="text-14">
                {t('client.Header.button-back')}
              </Typography>
            </Button>
          ) : null}
        </Box>

        <Box component="div" className={classes.categoriesNav}>
          {navLinks.map((link, idx) => {
            return (
              <HeaderNavButton
                key={uuid()}
                text={link.text}
                route={link.route}
                isCategory={link.isCategory}
                isLocal={link.isLocal}
              />
            );
          })}
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          width: '100%',
          minHeight: '100vh',
          height: 'auto',
          marginBottom: '100px',
          backgroundColor: 'transparent',
        }}
      >
        <Outlet />
      </Box>

      <ClientFooter
        font20Size={font20Size}
        font20LineHeight={font20LineHeight}
        font16Size={font16Size}
        font16LineHeight={font16LineHeight}
      />

      <CartModal />
    </Box>
  );
};

export default Header;
