import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import portalSettings from '../../../../entities/portalSettings';
import generalsEnums from '../../../../utils/Generals';
import Font from '../../../../Components/Generals/Fonts/Font';
import AdminScreenTemplate from '../../../../utils/templates/AdminScreen.Template';
import ResponsiveComponent from '../../../../Hooks/ResponsiveComponent';
import EmptyMarkup from './EmptyMarkup';
import ListMarkup from './ListMarkup';
import { useStyles } from './styles';
import { getResponsiveValues } from '../../../../utils/FormatData';
import { selectPortalLanguage } from '../../../../Redux/Slices/Portal.Slices';
import { getMessageByPortalLanguage, getMarkupPercentage, getMarkupRule } from '../../../../utils/FormatData';
import {
  setIsToastSuccess,
  setToastSuccessMessage,
  setBackDropState,
} from '../../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../../assets/GlobalColors';

const Freight = () => {
  const dispatch = useDispatch();
  const portalSettingsAPI = new portalSettings();
  const classes = useStyles();
  const { t } = useTranslation('global');
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const [freightMx, setFreightMx] = React.useState(generalsEnums.emptyArray);
  const [freightUs, setFreightUs] = React.useState(generalsEnums.emptyArray);

  const portalLanguage = useSelector(selectPortalLanguage);

  const handleAddNewMarkup = (e) => {
    e.preventDefault();
  };

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size22.size,
      generalsEnums.fontSizes.size24.size,
      generalsEnums.fontSizes.size24.size,
    ],
    [
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size22.lineHeight,
      generalsEnums.fontSizes.size24.lineHeight,
      generalsEnums.fontSizes.size24.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];

  const [
    titleSize,
    titleLineHeight,
    titleEmptySize,
    titleEmptyLineHeight,
    descEmptySize,
    descEmptyLineHeight,
    listCurrencySize,
    listCurrencyLineHeight,
    listTableSize,
    listTableLineHeight,
  ] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  React.useEffect(() => {
    const fetchData = async () => {
      dispatch(setBackDropState(generalsEnums.trueBoolean));
      const getAllActiveFreightRes = await portalSettingsAPI.getAllActiveFreight();
      const { data } = getAllActiveFreightRes;
      const { statusCode } = data;

      if (statusCode === generalsEnums.statusCodes.ok) {
        const { data: freightData, messages: resMessages } = data;

        freightData.forEach((freight) => {
          const { country } = freight;
          if (country === generalsEnums.countries.mexico) {
            setFreightMx((prevFreightMx) => [...prevFreightMx, freight]);
          } else {
            setFreightUs((prevFreightUs) => [...prevFreightUs, freight]);
          }
        });

        const message = getMessageByPortalLanguage(resMessages, portalLanguage);
        dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
        dispatch(setToastSuccessMessage(message));
        dispatch(setBackDropState(generalsEnums.falseBoolean));
      } else {
        dispatch(setIsToastSuccess(generalsEnums.falseBoolean));
      }
    };

    fetchData();
  }, []);

  return (
    <AdminScreenTemplate>
      <Font
        text={t('Freight.title')}
        color={colors.BLACK}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.columnBox}
        sx={{
          marginTop: '28px',
        }}
      >
        <ListMarkup
          t={t}
          colors={colors}
          generalsEnums={generalsEnums}
          classes={classes}
          descEmptySize={descEmptySize}
          descEmptyLineHeight={descEmptyLineHeight}
          listCurrencySize={listCurrencySize}
          listCurrencyLineHeight={listCurrencyLineHeight}
          listTableSize={listTableSize}
          listTableLineHeight={listTableLineHeight}
          getMarkupRule={getMarkupRule}
          freightMx={freightMx}
          freightUs={freightUs}
        />
      </Box>
    </AdminScreenTemplate>
  );
};

export default Freight;
