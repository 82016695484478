import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DividerAttom from '../../../../Components/Generals/Figma/Atoms/DividerAttom';
import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import ResponsiveComponent from '../../../../Hooks/ResponsiveComponent';
import { getResponsiveValues } from '../../../../utils/FormatData';
import * as colors from '../../../../assets/GlobalColors';

const useStyles = makeStyles({
  details: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: '8px',
    padding: '16px',
    gap: '16px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.BORDER_CCC}`,
  },
  detailsTitle: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsAddress: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '4px',
  },
});

const OrderDetailsOrganism = ({
  text20Size,
  text20LineHeight,
  text12Size,
  text12LineHeight,
  text16Size,
  text16LineHeight,
  text18Size,
  text18LineHeight,
  orderDate,
  productsItems,
  productsLength,
  deliveryName,
  deliveryContact,
  deliveryStreet,
  deliveryCity,
  deliveryComments,
  billingName,
  billingContact,
  billingStreet,
  billingCity,
}) => {
  const { t } = useTranslation('global');
  const classes = useStyles();

  return (
    <Box component={generalsEnums.BoxDiv} className={classes.details}>
      <Box component={generalsEnums.BoxDiv} className={classes.detailsTitle}>
        <Font
          text={t('Order.orderDetailsTitle')}
          color={colors.BLACK}
          size={text20Size}
          lineHeight={text20LineHeight}
          isHead={generalsEnums.trueBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={orderDate}
          color={colors.TEXT_GRAY_666}
          size={text12Size}
          lineHeight={text12LineHeight}
          isHead={generalsEnums.trueBoolean}
          isCenter={generalsEnums.falseBoolean}
        />
      </Box>

      <DividerAttom w="100%" color={colors.BORDER_CCC} />

      <Box component={generalsEnums.BoxDiv} className={classes.detailsAddress}>
        <Font
          text={productsItems}
          color={colors.BLACK}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={false}
          isCenter={false}
        />
      </Box>

      <DividerAttom w="100%" color={colors.BORDER_CCC} />

      <Box component={generalsEnums.BoxDiv} className={classes.detailsAddress}>
        <Font
          text={t('Order.delivery')}
          color={colors.BLACK}
          size={text18Size}
          lineHeight={text18LineHeight}
          isHead={generalsEnums.trueBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={productsLength}
          color={colors.BLACK}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={deliveryName}
          color={colors.BLACK}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={deliveryContact}
          color={colors.TEXT_GRAY_102}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={deliveryStreet}
          color={colors.BLACK}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={deliveryCity}
          color={colors.TEXT_GRAY_102}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={`${t('OrderGeneralTab.comments')} ${deliveryComments}`}
          color={colors.TEXT_GRAY_102}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />
      </Box>

      <DividerAttom w="100%" color={colors.BORDER_CCC} />

      <Box component={generalsEnums.BoxDiv} className={classes.detailsAddress}>
        <Font
          text={t('Order.billing')}
          color={colors.BLACK}
          size={text18Size}
          lineHeight={text18LineHeight}
          isHead={generalsEnums.trueBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={billingName}
          color={colors.BLACK}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={billingContact}
          color={colors.TEXT_GRAY_102}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={billingStreet}
          color={colors.BLACK}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />

        <Font
          text={billingCity}
          color={colors.TEXT_GRAY_102}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={generalsEnums.falseBoolean}
          isCenter={generalsEnums.falseBoolean}
        />
      </Box>
    </Box>
  );
};

const Main = ({
  orderDate,
  productsItems,
  productsLength,
  deliveryName,
  deliveryContact,
  deliveryStreet,
  deliveryCity,
  deliveryComments,
  billingName,
  billingContact,
  billingStreet,
  billingCity,
}) => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size10.size,
      generalsEnums.fontSizes.size10.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size12.size,
    ],
    [
      generalsEnums.fontSizes.size10.lineHeight,
      generalsEnums.fontSizes.size10.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size18.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
    ],
  ];

  const [
    text20Size,
    text20LineHeight,
    text12Size,
    text12LineHeight,
    text16Size,
    text16LineHeight,
    text18Size,
    text18LineHeight,
  ] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  return (
    <OrderDetailsOrganism
      text20Size={text20Size}
      text20LineHeight={text20LineHeight}
      text12Size={text12Size}
      text12LineHeight={text12LineHeight}
      text16Size={text16Size}
      text16LineHeight={text16LineHeight}
      text18Size={text18Size}
      text18LineHeight={text18LineHeight}
      orderDate={orderDate}
      productsItems={productsItems}
      productsLength={productsLength}
      deliveryName={deliveryName}
      deliveryContact={deliveryContact}
      deliveryStreet={deliveryStreet}
      deliveryCity={deliveryCity}
      deliveryComments={deliveryComments}
      billingName={billingName}
      billingContact={billingContact}
      billingStreet={billingStreet}
      billingCity={billingCity}
    />
  );
};

export default Main;
