import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';

import Font from '../../../../Components/Generals/Fonts/Font';
import Icons from '../../../../Components/Generals/Figma/Icons/Icons';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import CurrencyFont from '../../../../Components/Generals/Fonts/CurrencyFont';
import Item from './Item';

const DisyplayEachCountry = ({
  t,
  generalsEnums,
  colors,
  listCurrencySize,
  listCurrencyLineHeight,
  descEmptySize,
  descEmptyLineHeight,
  listTableSize,
  listTableLineHeight,
  getMarkupRule,
  classes,
  arr,
  isMx,
}) => {
  const [arrOfFreight, setArrOfFreight] = React.useState(generalsEnums.emptyArray);

  React.useEffect(() => {
    // ordenar arr de menor a mayor por el campo minPrice y maxPrice
    const arrOfFreight = arr.sort((a, b) => a.minPrice - b.minPrice);

    setArrOfFreight(arrOfFreight);
  }, [arr]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: 'auto',
        minWidth: '100px',
        height: 'auto',
      }}
    >
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: 'auto',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          padding: '16px',
          borderRadius: '8px 8px 0px 0px',
          border: `1px solid ${colors.GREEN_MAIN}`,
          backgroundColor: colors.GREEN_MAIN_1,
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: 'auto',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Font
            text={isMx ? t('Freight.list.mx.title') : t('Freight.list.us.title')}
            color={colors.BLACK}
            size={listCurrencySize}
            lineHeight={listCurrencyLineHeight}
            isHead={generalsEnums.trueBoolean}
            ml="0px"
          />

          <Font
            text={`${t('Freight.currency')}: ${isMx ? t('Freight.list.mx.currency') : t('Freight.list.us.currency')}`}
            color={colors.BLACK}
            size={descEmptySize}
            lineHeight={descEmptyLineHeight}
            isHead={generalsEnums.falseBoolean}
            ml="0px"
          />
        </Box>

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: 'auto',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TsiButton
            text={generalsEnums.emptyString}
            type={generalsEnums.buttonTypes.save}
            onClick={null}
            icon={<Icons kind={generalsEnums.icons.kind.Add} c={classes.Icon} />}
          />
        </Box>
      </Box>

      <Item
        generalsEnums={generalsEnums}
        classes={classes}
        colors={colors}
        isWhite={generalsEnums.falseBoolean}
        listToRender={
          <>
            <Box
              component={generalsEnums.BoxDiv}
              className={classes.listRuleBox}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '8px',
              }}
            >
              <Font
                text={t('Freight.list.table.rule')}
                color={colors.BLACK}
                size={listTableSize}
                lineHeight={listTableLineHeight}
                isHead={generalsEnums.trueBoolean}
                ml="0px"
              />
            </Box>

            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '220px',
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '8px',
              }}
            >
              <Font
                text={t('Freight.list.table.prices')}
                color={colors.BLACK}
                size={listTableSize}
                lineHeight={listTableLineHeight}
                isHead={generalsEnums.trueBoolean}
                ml="0px"
              />
            </Box>

            <Box
              component={generalsEnums.BoxDiv}
              className={classes.listMarkupBox}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '8px',
              }}
            >
              <Font
                text={t('Freight.list.table.freight')}
                color={colors.BLACK}
                size={listTableSize}
                lineHeight={listTableLineHeight}
                isHead={generalsEnums.trueBoolean}
                ml="0px"
              />
            </Box>
          </>
        }
      />

      {arrOfFreight.map((freight) => {
        const { freight: freightPrice, minPrice, maxPrice } = freight;
        const kind = typeof freightPrice;

        return (
          <Item
            key={uuidv4()}
            generalsEnums={generalsEnums}
            classes={classes}
            colors={colors}
            isWhite={generalsEnums.trueBoolean}
            listToRender={
              <>
                <Box
                  component={generalsEnums.BoxDiv}
                  className={classes.listRuleBox}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '8px',
                  }}
                >
                  <Font
                    text={getMarkupRule(
                      minPrice,
                      maxPrice,
                      t('Markup.list.table.smallerThan'),
                      t('Markup.list.table.biggerThan'),
                      t('Markup.list.table.between')
                    )}
                    color={colors.BLACK}
                    size={listTableSize}
                    lineHeight={listTableLineHeight}
                    isHead={generalsEnums.falseBoolean}
                    ml="0px"
                  />
                </Box>

                <Box
                  component={generalsEnums.BoxDiv}
                  sx={{
                    width: '220px',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '8px',
                  }}
                >
                  <CurrencyFont
                    price={minPrice}
                    color={colors.BLACK}
                    size={listTableSize}
                    lineHeight={listTableLineHeight}
                    isHead={generalsEnums.trueBoolean}
                    displayPrefix={generalsEnums.falseBoolean}
                  />

                  <Font
                    text={t('Markup.list.table.and')}
                    color={colors.BLACK}
                    size={listTableSize}
                    lineHeight={listTableLineHeight}
                    isHead={generalsEnums.trueBoolean}
                    ml="0px"
                  />

                  <CurrencyFont
                    price={maxPrice}
                    color={colors.BLACK}
                    size={listTableSize}
                    lineHeight={listTableLineHeight}
                    isHead={generalsEnums.trueBoolean}
                    displayPrefix={generalsEnums.falseBoolean}
                  />
                </Box>

                <Box
                  component={generalsEnums.BoxDiv}
                  className={classes.listMarkupBox}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '8px',
                  }}
                >
                  {kind === 'number' ? (
                    <CurrencyFont
                      price={freightPrice}
                      color={colors.BLACK}
                      size={listTableSize}
                      lineHeight={listTableLineHeight}
                      isHead={generalsEnums.falseBoolean}
                      displayPrefix={generalsEnums.falseBoolean}
                    />
                  ) : (
                    <Font
                      text={t('Freight.alert')}
                      color={colors.TSADMIN_RED_DELETE}
                      size={listTableSize}
                      lineHeight={listTableLineHeight}
                      isHead={generalsEnums.falseBoolean}
                      ml="0px"
                    />
                  )}
                </Box>
              </>
            }
          />
        );
      })}
    </Box>
  );
};

const ListMarkup = ({
  t,
  colors,
  generalsEnums,
  classes,
  descEmptySize,
  descEmptyLineHeight,
  listCurrencySize,
  listCurrencyLineHeight,
  listTableSize,
  listTableLineHeight,
  getMarkupRule,
  freightMx,
  freightUs,
}) => {
  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.columnBox}
      sx={{
        gap: '12px',
      }}
    >
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <DisyplayEachCountry
          t={t}
          generalsEnums={generalsEnums}
          colors={colors}
          listCurrencySize={listCurrencySize}
          listCurrencyLineHeight={listCurrencyLineHeight}
          descEmptySize={descEmptySize}
          descEmptyLineHeight={descEmptyLineHeight}
          listTableSize={listTableSize}
          listTableLineHeight={listTableLineHeight}
          getMarkupRule={getMarkupRule}
          classes={classes}
          arr={freightMx}
          isMx={generalsEnums.trueBoolean}
        />

        <DisyplayEachCountry
          t={t}
          generalsEnums={generalsEnums}
          colors={colors}
          listCurrencySize={listCurrencySize}
          listCurrencyLineHeight={listCurrencyLineHeight}
          descEmptySize={descEmptySize}
          descEmptyLineHeight={descEmptyLineHeight}
          listTableSize={listTableSize}
          listTableLineHeight={listTableLineHeight}
          getMarkupRule={getMarkupRule}
          classes={classes}
          arr={freightUs}
          isMx={generalsEnums.falseBoolean}
        />
      </Box>
    </Box>
  );
};

export default ListMarkup;
