import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import * as colors from '../../../assets/GlobalColors';

import Paths from '../../../utils/enums/Paths';
import TsiLogo from '../../../Components/Generals/TsiLogo';
import ClientSignInImage from '../../../assets/Images/UserManagement/ClientSignInImage.png';
import ClientSignUp from '../../../assets/Images/UserManagement/ClientSignUp.png';
import ResetPass from '../../../assets/Images/UserManagement/ResetPass.png';
import generalsEnums from '../../../utils/Generals';
import ResponsiveComponent from '../../../Hooks/ResponsiveComponent';
import SignIn from './SignIn';
import SignUp from './SignUp';
import VerifyNewAccount from './VerifyNewAccount';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import { getResponsiveValues } from '../../../utils/FormatData';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundColor: colors.GRAY_BG,
  },
});

const UserManagement = () => {
  const classes = useStyles();
  const { type } = useParams();
  const { t } = useTranslation('global');

  const [image, setImage] = React.useState();
  const [imageAlt, setImageAlt] = React.useState(generalsEnums.emptyString);
  const [caseToRender, setCaseToRender] = React.useState(<></>);

  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    ['column', 'column', 'row', 'row', 'row'],
    ['center', 'center', 'flex-start', 'flex-start', 'flex-start'],
    ['100%', '100%', '450px', '550px', '650px'],
    ['none', 'none', 'flex', 'flex', 'flex'],
    ['100%', '100%', '100%', '100%', '100%'],
    [
      generalsEnums.fontSizes.size24.size,
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size32.size,
      generalsEnums.fontSizes.size32.size,
    ],
    [
      generalsEnums.fontSizes.size24.lineHeight,
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size32.lineHeight,
      generalsEnums.fontSizes.size32.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
    ['50%', '70%', '100%', '100%', '100%'],
    ['0px', '0px', '100px', '100px', '100px'],
  ];

  const [
    containerFD,
    isCenter,
    renderWidth,
    displayImage,
    imageSize,
    titleSize,
    titleLineHeight,
    textSize,
    textLineHeight,
    signInWidth,
    renderMarginLeft,
  ] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  React.useEffect(() => {
    switch (type) {
      case Paths.UserManagement.signin.split('/')[2]:
        setImage(ClientSignInImage);
        setImageAlt('UserManagement.imagesAlt.global');
        setCaseToRender(
          <SignIn
            t={t}
            isCenter={isCenter}
            colors={colors}
            titleSize={titleSize}
            titleLineHeight={titleLineHeight}
            textSize={textSize}
            textLineHeight={textLineHeight}
            Paths={Paths}
            signInWidth={signInWidth}
          />
        );
        break;
      case Paths.UserManagement.signup.split('/')[2]:
        setImage(ClientSignUp);
        setImageAlt('UserManagement.imagesAlt.global');
        setCaseToRender(
          <SignUp
            t={t}
            isCenter={isCenter}
            colors={colors}
            titleSize={titleSize}
            titleLineHeight={titleLineHeight}
            textSize={textSize}
            textLineHeight={textLineHeight}
            Paths={Paths}
            signInWidth={signInWidth}
          />
        );
        break;
      case Paths.UserManagement.verifyNewAccount.split('/')[2]:
        setImage(ClientSignUp);
        setImageAlt('UserManagement.imagesAlt.global');
        setCaseToRender(
          <VerifyNewAccount
            t={t}
            isCenter={isCenter}
            colors={colors}
            titleSize={titleSize}
            titleLineHeight={titleLineHeight}
            textSize={textSize}
            textLineHeight={textLineHeight}
            Paths={Paths}
            signInWidth={signInWidth}
          />
        );
        break;
      case Paths.UserManagement.forgotPassword.split('/')[2]:
        setImage(ResetPass);
        setImageAlt('UserManagement.imagesAlt.global');
        setCaseToRender(
          <ForgotPassword
            t={t}
            isCenter={isCenter}
            colors={colors}
            titleSize={titleSize}
            titleLineHeight={titleLineHeight}
            textSize={textSize}
            textLineHeight={textLineHeight}
            Paths={Paths}
            signInWidth={signInWidth}
          />
        );
        break;
      case Paths.UserManagement.resetPassword.split('/')[2]:
        setImage(ResetPass);
        setImageAlt('UserManagement.imagesAlt.global');
        setCaseToRender(
          <ResetPassword
            t={t}
            isCenter={isCenter}
            colors={colors}
            titleSize={titleSize}
            titleLineHeight={titleLineHeight}
            textSize={textSize}
            textLineHeight={textLineHeight}
            Paths={Paths}
            signInWidth={signInWidth}
          />
        );
        break;
    }
  }, [type, t, isCenter, titleSize, titleLineHeight, textSize, textLineHeight, Paths, signInWidth]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.container}
      sx={{
        flexDirection: containerFD,
        justifyContent: 'space-between',
      }}
    >
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: renderWidth,
          marginLeft: renderMarginLeft,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: isCenter,
          padding: '20px',
          gap: '40px',
        }}
      >
        <TsiLogo />

        {caseToRender}
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          display: displayImage,
        }}
      >
        <Image
          src={image}
          alt={t(imageAlt)}
          fluid
          style={{
            width: imageSize,
            height: imageSize,
            padding: '0px',
          }}
        />
      </Box>
    </Box>
  );
};

export default UserManagement;
