import React from 'react';

import generalsEnums from '../../../../utils/Generals';
import Profile from './iconsFiles/Profile.svg';
import Store from './iconsFiles/Store.svg';
import ShopTwo from './iconsFiles/ShopTwo.svg';
import ArrowBack from './iconsFiles/ArrowBack.svg';
import ForwardToInbox from './iconsFiles/ForwardToInbox.svg';
import Error from './iconsFiles/Error.svg';
import NotificationsActive from './iconsFiles/NotificationsActive.svg';
import Search from './iconsFiles/Search.svg';
import Package from './iconsFiles/Package.svg';
import Delete from './iconsFiles/Delete.svg';
import Add from './iconsFiles/Add.svg';
import Security from './iconsFiles/Security.svg';

const Icons = ({ kind, c, w, h }) => {
  const [icon, setIcon] = React.useState(null);

  React.useEffect(() => {
    switch (kind) {
      case generalsEnums.icons.kind.profile:
        setIcon(Profile);
        break;
      case generalsEnums.icons.kind.store:
        setIcon(Store);
        break;
      case generalsEnums.icons.kind.shopTwo:
        setIcon(ShopTwo);
        break;
      case generalsEnums.icons.kind.ArrowBack:
        setIcon(ArrowBack);
        break;
      case generalsEnums.icons.kind.ForwardToInbox:
        setIcon(ForwardToInbox);
        break;
      case generalsEnums.icons.kind.Error:
        setIcon(Error);
        break;
      case generalsEnums.icons.kind.NotificationsActive:
        setIcon(NotificationsActive);
        break;
      case generalsEnums.icons.kind.Search:
        setIcon(Search);
        break;
      case generalsEnums.icons.kind.Package:
        setIcon(Package);
        break;
      case generalsEnums.icons.kind.Delete:
        setIcon(Delete);
        break;
      case generalsEnums.icons.kind.Add:
        setIcon(Add);
        break;
      case generalsEnums.icons.kind.Security:
        setIcon(Security);
        break;
      default:
        setIcon(null);
    }
  }, [kind]);

  if (icon !== null) {
    return (
      <img
        src={icon}
        alt="icon"
        className={c}
        style={{
          width: c === undefined ? c : w,
          height: c === undefined ? c : h,
        }}
      />
    );
  }

  return null;
};

export default Icons;
