import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import Orders from '../../../entities/Orders';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import Addresses from '../../../entities/Addresses';
import Products from '../../../entities/Products';
import generalsEnums from '../../../utils/Generals';
import Element from '../../../Components/Client/Checkout/Element/Element';
import OrderSummaryCard from '../../../Components/Client/Checkout/OrderSummary/OrderSummaryCard';
import Payment from '../../../Components/Client/Stripe/Payment/Payment';
import Users from '../../../entities/Users';
import StripeIntegration from '../../../entities/StripeIntegration';
import Font from '../../../Components/Generals/Fonts/Font';
import UserForm from './UserForm';
import SelectAddress from './SelectAddress';
import Paths from '../../../utils/enums/Paths';
import { usersTypes } from '../../../utils/enums/usersTypes';
import { useStyles } from './Checkout.styles';
import { STRIPE_PUBLISHABLE_KEY } from '../../../utils/stripe/keys';
import { selectFullName, selectUserId } from '../../../Redux/Slices/User.Slices';
import { isLogged, selectEmail, fetchSignout } from '../../../Redux/Slices/User.Slices';
import { selectPortalLanguage, selectPortalCountry, setIsCountryCBDisabled } from '../../../Redux/Slices/Portal.Slices';
import {
  setBackDropState,
  setToastError,
  setToastSuccessMessage,
  setClearShoppingCart,
} from '../../../Redux/Slices/Navigation.Slices';
import {
  selectDeliveryAddress,
  selectBillingAddress,
  selectTotalForStripe,
  clearCart,
  selectUserData,
  addClientSecret,
  selectClientSecret,
  addPaymentIntentId,
  selectPaymentIntentId,
  selectFreight,
  selectProducts,
  addFreight,
  selectTaxes,
  selectSubTotal,
  updateTaxes,
} from '../../../Redux/Slices/ShoppingCart.Slices';
import * as colors from '../../../assets/GlobalColors';

const users = new Users();
const stripeIntegration = new StripeIntegration();

const Checkout = () => {
  const ProductsAPI = new Products();
  const AddressesAPI = new Addresses();
  const OrdersAPI = new Orders();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const { t } = useTranslation('global');

  const [userLoggedAddress, setUserLoggedAddress] = React.useState(generalsEnums.emptyArray);
  const [user, setUser] = React.useState(<></>);
  const [showUserForm, setShowUserForm] = React.useState(generalsEnums.falseBoolean);
  const [isSummary, setIsSummary] = React.useState(generalsEnums.falseBoolean);
  const [readyToPay, setReadyToPay] = React.useState(generalsEnums.nullData);
  const [buttonTitleText, setButtonTitleText] = React.useState(generalsEnums.emptyString);
  const [buttonText, setButtonText] = React.useState(generalsEnums.emptyString);
  const [displayButton, setDisplayButton] = React.useState(generalsEnums.falseBoolean);
  const [actionCase, setActionCase] = React.useState(generalsEnums.zeroNumber);

  const fullName = useSelector(selectFullName);
  const deliveryAddress = useSelector(selectDeliveryAddress);
  const logged = useSelector(isLogged);
  const email = useSelector(selectEmail);
  const billingAddress = useSelector(selectBillingAddress);
  const totalForStripe = useSelector(selectTotalForStripe);
  const userId = useSelector(selectUserId);
  const userData = useSelector(selectUserData);
  const clientSecret = useSelector(selectClientSecret);
  const paymentIntentId = useSelector(selectPaymentIntentId);
  const mediaQuery1200 = useMediaQuery('(max-width:1240px)');
  const freight = useSelector(selectFreight);
  const products = useSelector(selectProducts);
  const language = useSelector(selectPortalLanguage);
  const country = useSelector(selectPortalCountry);
  const taxes = useSelector(selectTaxes);
  const subTotal = useSelector(selectSubTotal);
  const displayStripeComponent = clientSecret !== generalsEnums.emptyString;

  const handleSignout = async (e) => {
    e.preventDefault();
    dispatch(setBackDropState(true));
    dispatch(fetchSignout(email));
    dispatch(clearCart());
    dispatch(setBackDropState(false));
    navigate('/');
  };

  const handleUserForm = (e) => {
    e.preventDefault();
    setShowUserForm(!showUserForm);
  };

  const handleCreatePaymentIntent = async () => {
    dispatch(setBackDropState(true));
    let customerId = generalsEnums.emptyString;

    if (logged) {
      const response = await users.getStripeCustomerId(email);
      customerId = response.customerId;
    }

    const paymentIntentResponse = await stripeIntegration.createPaymentIntent(
      customerId,
      totalForStripe,
      generalsEnums.currencies.usd
    );

    dispatch(
      addPaymentIntentId({
        paymentIntentId: paymentIntentResponse.paymentIntent.id,
      })
    );
    dispatch(
      addClientSecret({
        clientSecret: paymentIntentResponse.paymentIntent.client_secret,
      })
    );
    dispatch(setBackDropState(false));
  };

  const handleCreateOrder = async (paymentIntent) => {
    dispatch(setBackDropState(true));
    let bodyPaymentIntent = generalsEnums.emptyObject;

    if (paymentIntent !== generalsEnums.nullData) {
      bodyPaymentIntent = paymentIntent;
    }

    const bodyUserType = logged ? usersTypes.CLIENT : usersTypes.GUEST;
    const bodyUserId = logged ? userId : generalsEnums.emptyString;
    const bodyUserData = logged ? { name: fullName, email: email } : userData;
    const bodyDeliveryAddress = deliveryAddress;
    const bodyProductsToSave = products.map((product) => {
      return {
        _id: product._id,
        quantitySelected: product.quantitySelected,
        price: product.price,
        quantityPrice: product.quantityPrice,
      };
    });
    const bodyDelivery = typeof freight === 'number' ? freight : generalsEnums.nullData;
    const bodyBillingAddress = billingAddress;
    const bodyTotal = totalForStripe;
    const bodyTaxes = taxes;
    const bodySubTotal = subTotal;
    const bodyOrderLanguage = language;
    const bodyCountry = country;

    const createOrderBody = {
      paymentIntent: bodyPaymentIntent,
      userType: bodyUserType,
      userId: bodyUserId,
      userData: bodyUserData,
      deliveryAddress: bodyDeliveryAddress,
      products: bodyProductsToSave,
      delivery: bodyDelivery,
      billingAddress: bodyBillingAddress,
      total: bodyTotal,
      taxes: bodyTaxes,
      subTotal: bodySubTotal,
      orderLanguage: bodyOrderLanguage,
      country: bodyCountry,
    };

    const createOrderBodyResponse = await OrdersAPI.createOrder(createOrderBody);
    const { statusCode, orderNumber } = createOrderBodyResponse;
    if (statusCode === 200) {
      dispatch(
        addClientSecret({
          clientSecret: '',
        })
      );
      dispatch(
        addPaymentIntentId({
          paymentIntentId: '',
        })
      );
      dispatch(setClearShoppingCart(generalsEnums.trueBoolean));
      dispatch(setBackDropState(generalsEnums.falseBoolean));
      dispatch(setIsCountryCBDisabled(generalsEnums.trueBoolean));
      navigate('/order-summary/' + orderNumber);
    }
  };

  const handlePaymentAction = async (e) => {
    e.preventDefault();
    setIsSummary(true);

    const showErrorAndSetBackDrop = (message) => {
      dispatch(setToastError(true));
      dispatch(setToastSuccessMessage(message));
      dispatch(setBackDropState(false));
    };

    if (Object.keys(userData).length === 0 && !logged) {
      showErrorAndSetBackDrop(t('client.Checkout.handlePaymentIntentErrors.userDataEmpty'));
      return;
    }

    if (Object.keys(deliveryAddress).length === 0) {
      showErrorAndSetBackDrop(t('client.Checkout.handlePaymentIntentErrors.deliveryAddressEmpty'));
      return;
    }

    if (Object.keys(billingAddress).length === 0) {
      showErrorAndSetBackDrop(t('client.Checkout.handlePaymentIntentErrors.billingAddressEmpty'));
      return;
    }

    if (actionCase === 1) {
      handleCreatePaymentIntent();
    } else if (actionCase === 2) {
      handleCreateOrder(generalsEnums.nullData);
    }
  };

  const handleCancelPaymentIntent = async () => {
    dispatch(setBackDropState(true));

    await stripeIntegration.cancelPaymentIntent(paymentIntentId);
    dispatch(
      addClientSecret({
        clientSecret: '',
      })
    );

    dispatch(
      addPaymentIntentId({
        paymentIntentId: '',
      })
    );

    dispatch(setBackDropState(false));
  };

  const handleChangeIsSummary = (e) => {
    e.preventDefault();
    setIsSummary(!isSummary);
    if (isSummary && paymentIntentId !== '') {
      handleCancelPaymentIntent();
    }
  };

  const handleNavigateToSignIn = (e) => {
    e.preventDefault();
    navigate(Paths.UserManagement.signup);
  };

  const setButtonProperties = (title, text, actionCase, displayButton) => {
    setButtonTitleText(t(title));
    setButtonText(t(text));
    setActionCase(actionCase);
    setDisplayButton(displayButton);
  };

  React.useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setUser(<Font text={userData.name} color={colors.BLACK} size={16} lineHeight="18px" isHead={false} />);
    }
  }, [userData]);

  React.useEffect(() => {
    if (logged) {
      setShowUserForm(false);
    } else {
      setShowUserForm(true);
    }
  }, [logged]);

  React.useEffect(() => {
    const fetchFreightCost = async () => {
      dispatch(setBackDropState(true));
      const productsFiltered = products.map(({ codes, quantitySelected }) => ({ codes, quantitySelected }));
      const getFreightCostBody = { country, products: productsFiltered };

      try {
        const {
          data: { kind, freight: freightFromAPI, messages },
        } = await ProductsAPI.getFreightCost(getFreightCostBody);

        if (freightFromAPI === null) {
          dispatch(
            addFreight({
              freight: messages[language],
            })
          );
        } else {
          dispatch(
            addFreight({
              freight: freightFromAPI,
            })
          );
        }
      } catch (error) {}

      dispatch(setBackDropState(false));
    };

    fetchFreightCost();
  }, [products, country]);

  React.useEffect(async () => {
    if (userId !== generalsEnums.nullData) {
      dispatch(setBackDropState(generalsEnums.trueBoolean));

      const getAllAddressesByUserIdRes = await AddressesAPI.getAllAddressesByUserId(userId);
      const { addresses: addressesUserRes } = getAllAddressesByUserIdRes;
      const addressesUserResFiltered = addressesUserRes.filter((address) => address.country === country);

      if (addressesUserRes.length === 0) {
        setUserLoggedAddress(generalsEnums.nullData);
      } else {
        setUserLoggedAddress(addressesUserResFiltered);
      }

      dispatch(setBackDropState(generalsEnums.falseBoolean));
    }
  }, [userId]);

  React.useEffect(() => {
    if (readyToPay === generalsEnums.trueBoolean) {
      setButtonProperties('Generals.pay', 'Generals.pay', 1, generalsEnums.trueBoolean);
    } else {
      setButtonProperties(
        'OrderSummaryCard.freightIsString',
        'OrderSummaryCard.sendToEvaluateFreight',
        2,
        generalsEnums.trueBoolean
      );
    }
  }, [readyToPay]);

  React.useEffect(() => {
    if (freight !== generalsEnums.nullData && typeof freight === 'number') {
      setReadyToPay(generalsEnums.trueBoolean);
    }
  }, [freight]);

  React.useEffect(() => {
    if (Object.keys(deliveryAddress).length > 0) {
      dispatch(updateTaxes());
    }
  }, [deliveryAddress]);

  return (
    <Container maxWidth="xl">
      <Box
        component="div"
        className={classes.root}
        sx={{
          flexDirection: mediaQuery1200 ? 'column' : 'row',
          alignItems: mediaQuery1200 ? 'center' : 'flex-start',
          justifyContent: mediaQuery1200 ? 'center' : 'space-between',
          gap: mediaQuery1200 ? '20px' : '0px',
        }}
      >
        <Box
          component="div"
          className={classes.container}
          sx={{
            border: '1px solid red',
          }}
        >
          {logged === false ? (
            <Element
              title={t('Generals.client')}
              content={user}
              edit={Object.keys(userData).length === 0 ? t('Generals.add') : t('Generals.edit')}
              handleClick={handleUserForm}
            />
          ) : (
            <Element
              title={t('Generals.client')}
              content={<Font text={fullName} color={colors.BLACK} size={16} lineHeight="18px" isHead={false} />}
              edit={t('Generals.logout')}
              handleClick={handleSignout}
            />
          )}

          {showUserForm === true ? <UserForm classes={classes} setShowUserForm={setShowUserForm} /> : null}

          {logged === false ? (
            <Box
              component="div"
              sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Font
                text={t('client.Checkout.suggest.title')}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={true}
              />

              <Button
                variant="text"
                onClick={handleNavigateToSignIn}
                sx={{
                  padding: '0px',
                }}
              >
                <Font
                  text={t('client.Checkout.suggest.button')}
                  color={colors.TSADMIN_BLUE}
                  size={14}
                  lineHeight="22px"
                  isHead={false}
                />
              </Button>
            </Box>
          ) : null}

          <SelectAddress
            t={t}
            kind={generalsEnums.SelectAddress.address}
            logged={logged}
            addressObject={deliveryAddress}
            userLoggedAddress={userLoggedAddress}
            userId={userId}
            setUserLoggedAddress={setUserLoggedAddress}
          />

          <SelectAddress
            t={t}
            kind={generalsEnums.SelectAddress.billing}
            logged={logged}
            addressObject={billingAddress}
            userLoggedAddress={userLoggedAddress}
            userId={userId}
            setUserLoggedAddress={setUserLoggedAddress}
          />

          <Box
            component="div"
            sx={{
              width: 'auto',
              height: 'auto',
              display: displayButton ? 'flex' : 'none',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Font text={buttonTitleText} color={colors.BLACK} size={28} lineHeight="36px" isHead={true} />

            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '200px',
              }}
            >
              {displayStripeComponent === false ? (
                <TsiButton
                  type={generalsEnums.buttonTypes.save}
                  handleAction={handlePaymentAction}
                  text={buttonText}
                  isDisabled={generalsEnums.falseBoolean}
                  size={generalsEnums.fontSizes.size12.size}
                  lineHeight={generalsEnums.fontSizes.size12.lineHeight}
                />
              ) : null}
            </Box>

            {displayStripeComponent === true ? (
              <Elements stripe={stripePromise}>
                <Payment t={t} clientSecret={clientSecret} handleCreateOrder={handleCreateOrder} />
              </Elements>
            ) : null}
          </Box>
        </Box>

        <OrderSummaryCard
          isSummary={isSummary}
          canEdit={true}
          handleChangeIsSummary={handleChangeIsSummary}
          orderSummary={false}
        />
      </Box>
    </Container>
  );
};

export default Checkout;
