import React from 'react';
import uuid from 'react-uuid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

import generalsEnums from '../../utils/Generals';
import Font from './Fonts/Font';
import * as colors from '../../assets/GlobalColors';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.TOOLTIP,
      border: `1px solid ${colors.TOOLTIP}`,
    },
  })
);

const InputText = ({
  value,
  name,
  onChange,
  label,
  labelColor,
  ph,
  error,
  isDisabled,
  w,
  isPassword,
  extraLabel,
  rows,
  helperText,
  hasRows,
  hasErrorMessage,
  errorMessage,
  mt,
  children,
  labelHead,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      component="div"
      sx={{
        minWidth: w !== generalsEnums.undefinedData ? w : '280px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '8px',
        marginTop: mt !== generalsEnums.undefinedData ? mt : '0px',
      }}
    >
      <Box
        component="div"
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Font
          text={label}
          color={labelColor}
          size={generalsEnums.fontSizes.size16.size}
          lineHeight={generalsEnums.fontSizes.size16.lineHeight}
          isHead={labelHead !== generalsEnums.undefinedData ? labelHead : generalsEnums.trueBoolean}
          ml="0px"
        />

        {extraLabel !== generalsEnums.undefinedData ? extraLabel : null}
      </Box>

      <Box
        component="div"
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '1px',
        }}
      >
        {children}

        <Box
          component="div"
          sx={{
            width: '100%',
            borderRadius: '8px',
            backgroundColor: colors.WHITE,
            border: `1px solid ${error ? colors.TSADMIN_RED_DELETE : colors.GREEN_MAIN}`,
          }}
        >
          <TextField
            id={uuid()}
            value={value}
            name={name}
            placeholder={ph}
            onChange={onChange}
            disabled={isDisabled}
            rows={rows}
            type={isPassword ? (showPassword ? 'text' : 'password') : 'text'}
            multiline={hasRows}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              endAdornment:
                isPassword === true ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: error ? colors.TSADMIN_RED_DELETE : colors.GREEN_MAIN,
              },
              '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: error ? colors.TSADMIN_RED_DELETE : colors.GREEN_MAIN,
              },
              '& .MuiOutlinedInput-input': {
                borderRadius: '8px',
              },
            }}
          />
        </Box>

        {helperText !== generalsEnums.undefinedData ? (
          <HtmlTooltip
            placement="right"
            title={
              <React.Fragment>
                <Font text={helperText} color={colors.WHITE} size={14} lineHeight="22px" isHead={false} />
              </React.Fragment>
            }
          >
            <IconButton>
              <HelpIcon
                sx={{
                  color: colors.BLACK_06,
                }}
              />
            </IconButton>
          </HtmlTooltip>
        ) : null}
      </Box>

      {hasErrorMessage ? (
        <Font
          text={errorMessage}
          color={colors.TSADMIN_RED_DELETE}
          size={generalsEnums.fontSizes.size14.size}
          lineHeight={generalsEnums.fontSizes.size14.lineHeight}
          isHead={false}
        />
      ) : null}
    </Box>
  );
};

export default InputText;
