import React from 'react';
import { Box, Button } from '@mui/material';

import Font from '../../../Generals/Fonts/Font';
import { useStyles } from './Element.styles';
import * as colors from '../../../../assets/GlobalColors';

const Element = ({ title, content, edit, handleClick }) => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.infoBox}>
        <Box component="div" className={classes.contentBox}>
          <Font text={title} color={colors.BLACK} size={28} lineHeight="36px" isHead={true} />
        </Box>

        {content}
      </Box>

      <Button onClick={handleClick} variant="contained" className={classes.button}>
        <Font text={edit} color={colors.BLACK_06} size={14} lineHeight="22px" isHead={true} />
      </Button>
    </Box>
  );
};

export default Element;
