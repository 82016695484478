import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import * as yup from 'yup';

import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import InputText from '../../../../Components/Generals/InputText';
import generalsEnums from '../../../../utils/Generals';
import { setBackDropState } from '../../../../Redux/Slices/Navigation.Slices';
import { setSignout } from '../../../../Redux/Slices/User.Slices';

const ChangePassword = ({ t, colors, handleShowChangePass, usersAPI, sub }) => {
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    oldPassword: yup.string().required(),
    newPassword: yup.string().required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: generalsEnums.emptyString,
      newPassword: generalsEnums.emptyString,
      confirmPassword: generalsEnums.emptyString,
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const body = {
        sub: sub,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };

      dispatch(setBackDropState(true));

      const response = await usersAPI.changeAdminUserPassword(body);
      const { statusCode } = response;

      if (statusCode === generalsEnums.statusCodes.ok) {
        dispatch(setSignout());
        dispatch(setBackDropState(false));
      } else dispatch(setBackDropState(false));
    },
  });

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '24px',
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <InputText
          value={formik.values.oldPassword}
          name="oldPassword"
          onChange={formik.handleChange}
          label={t('admin.MyProfile.ChangePassword.oldPassword.label')}
          labelColor={colors.BLACK_06}
          ph={t('admin.MyProfile.ChangePassword.oldPassword.placeholder')}
          error={formik.errors.oldPassword}
          isDisabled={false}
          w="500px"
          isPassword={true}
        />

        <InputText
          value={formik.values.newPassword}
          name="newPassword"
          onChange={formik.handleChange}
          label={t('admin.MyProfile.ChangePassword.newPassword.label')}
          labelColor={colors.BLACK_06}
          ph={t('admin.MyProfile.ChangePassword.newPassword.placeholder')}
          error={formik.errors.newPassword}
          isDisabled={false}
          w="500px"
          isPassword={true}
        />

        <InputText
          value={formik.values.confirmPassword}
          name="confirmPassword"
          onChange={formik.handleChange}
          label={t('admin.MyProfile.ChangePassword.confirmPassword.label')}
          labelColor={colors.BLACK_06}
          ph={t('admin.MyProfile.ChangePassword.confirmPassword.placeholder')}
          error={formik.errors.confirmPassword}
          isDisabled={false}
          w="500px"
          isPassword={true}
        />

        <Box
          component="div"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <Box
            component="div"
            sx={{
              width: '100px',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.black}
              handleAction={handleShowChangePass}
              text={t('Generals.cancelAction')}
              isDisabled={false}
            />
          </Box>

          <Box
            component="div"
            sx={{
              width: '250px',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.save}
              handleAction={() => {
                formik.handleSubmit();
              }}
              text={t('admin.MyProfile.ChangePassword.save')}
              isDisabled={false}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePassword;
