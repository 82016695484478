import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class StripeIntegration extends Axios {
  constructor() {
    super();
    this.urlCreatePaymentIntent = API_ROUTES.stripeIntegration.createPaymentIntent;
    this.urlcancelPaymentIntent = API_ROUTES.stripeIntegration.cancelPaymentIntent;
  }

  createPaymentIntent = async (stripeCustomerId, amount, currency) => {
    const response = await this.post(this.urlCreatePaymentIntent, {
      customerId: stripeCustomerId,
      amount: parseFloat(amount),
      currency: currency,
    });
    return response;
  };

  cancelPaymentIntent = async (paymentIntentId) => {
    const response = await this.post(this.urlcancelPaymentIntent, {
      paymentIntentId: paymentIntentId,
    });
    return response;
  };
}

export default StripeIntegration;
