import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import Image from 'react-bootstrap/esm/Image';
import uuid from 'react-uuid';
import { Box } from '@mui/material';
import 'react-alice-carousel/lib/alice-carousel.css';

import generalsEnums from '../../../utils/Generals';
import Slide1 from '../../../assets/Images/HomeSlider/slide1.png';
import Slide2 from '../../../assets/Images/HomeSlider/slide2.png';
import Slide3 from '../../../assets/Images/HomeSlider/slide3.png';
import Slide4 from '../../../assets/Images/HomeSlider/slide4.png';
import Slide5 from '../../../assets/Images/HomeSlider/slide5.png';
import Slide6 from '../../../assets/Images/HomeSlider/slide6.png';
import Slide7 from '../../../assets/Images/HomeSlider/slide7.png';
import Slide8 from '../../../assets/Images/HomeSlider/slide8.png';
import Slide9 from '../../../assets/Images/HomeSlider/slide9.png';
import ResponsiveComponent from '../../../Hooks/ResponsiveComponent';
import { getResponsiveValues } from '../../../utils/FormatData';

const SliderAutoPlay = () => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    ['100%', '100%', '80%', '70%', '60%'],
    ['300px', '350px', '400px', '500px', '500px'],
  ];

  const [imgWidth, imgHeight] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  const images = [
    {
      src: Slide1,
    },
    {
      src: Slide2,
    },
    {
      src: Slide3,
    },
    {
      src: Slide4,
    },
    {
      src: Slide5,
    },
    {
      src: Slide6,
    },
    {
      src: Slide7,
    },
    {
      src: Slide8,
    },
    {
      src: Slide9,
    },
  ];

  const items = images.map((img) => {
    const { src } = img;
    return (
      <Box
        key={uuid()}
        component={generalsEnums.BoxDiv}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image
          src={src}
          alt=""
          style={{
            width: imgWidth,
            height: imgHeight,
          }}
        />
      </Box>
    );
  });

  return (
    <AliceCarousel
      autoPlay
      autoPlayControls={false}
      autoPlayStrategy="none"
      autoPlayInterval={6000}
      animationDuration={1000}
      animationType="fadeout"
      infinite
      touchTracking={false}
      disableDotsControls
      disableButtonsControls
      items={items}
    />
  );
};

export default SliderAutoPlay;
