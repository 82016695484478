import React from 'react';
import { Box } from '@mui/material';

import Font from '../../../../Components/Generals/Fonts/Font';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';

const EmptyMarkup = ({
  t,
  colors,
  generalsEnums,
  classes,
  titleEmptySize,
  titleEmptyLineHeight,
  descEmptySize,
  descEmptyLineHeight,
  handleAddNewMarkup,
}) => {
  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.columnBox}
      sx={{
        gap: '12px',
      }}
    >
      <Font
        text={t('Markup.empty.title')}
        color={colors.GREEN_MAIN}
        size={titleEmptySize}
        lineHeight={titleEmptyLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Font
        text={t('Markup.empty.description')}
        color={colors.TEXT_GRAY_666}
        size={descEmptySize}
        lineHeight={descEmptyLineHeight}
        isHead={generalsEnums.falseBoolean}
        ml="0px"
      />

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.buttonAddNewMarkup}
      >
        <TsiButton
          type={generalsEnums.buttonTypes.save}
          handleAction={handleAddNewMarkup}
          text={t('Markup.empty.add')}
          isDisabled={generalsEnums.falseBoolean}
          size={descEmptySize}
          lineHeight={descEmptyLineHeight}
        />
      </Box>
    </Box>
  );
};

export default EmptyMarkup;
