import React from 'react';
import uuid from 'react-uuid';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Box, IconButton, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';

import generalsEnums from '../../utils/Generals';
import CurrencyFont from '../../Components/Generals/Fonts/CurrencyFont';
import EmptyCart from '../../assets/Icons/EmptyCart.png';
import Font from '../../Components/Generals/Fonts/Font';
import ProductCart from '../../Components/Generals/Figma/Molecules/ProductCart';
import { useStyles } from './CartModal.styles';
import { setCartModalState, selectCartModalState } from '../../Redux/Slices/Navigation.Slices';
import {
  selectSubTotal,
  selectTaxes,
  selectTotal,
  selectProducts,
  deleteProduct,
  AddQuantity,
  SubQuantity,
  selectIsCartEmpty,
  selectDelivery,
} from '../../Redux/Slices/ShoppingCart.Slices';
import * as colors from '../../assets/GlobalColors';

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: 'blur(5px)',
}));

const CartModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const cartModalState = useSelector(selectCartModalState);
  const subTotal = useSelector(selectSubTotal);
  const taxes = useSelector(selectTaxes);
  const total = useSelector(selectTotal);
  const products = useSelector(selectProducts);
  const isCartEmpty = useSelector(selectIsCartEmpty);
  const delivery = useSelector(selectDelivery);

  const handleClose = (e) => {
    e.preventDefault();
    dispatch(setCartModalState(false));
  };

  const handleContinueShopping = (e) => {
    e.preventDefault();
    dispatch(setCartModalState(false));
    navigate('/checkout');
  };

  const handleClickSubQuantity = (e, _id) => {
    e.preventDefault();

    dispatch(
      SubQuantity({
        _id: _id,
      })
    );
  };

  const handleClickAddQuantity = (e, _id) => {
    e.preventDefault();
    dispatch(
      AddQuantity({
        _id: _id,
      })
    );
  };

  const handleClickDeleteProduct = (e, _id) => {
    e.preventDefault();

    dispatch(
      deleteProduct({
        _id: _id,
      })
    );
  };

  const handleNavigate = (e, type) => {
    e.preventDefault();

    if (type === 'terms') {
      navigate('information/terms-and-conditions');
    } else if (type === 'support') {
      navigate('information/help-and-support');
    }

    dispatch(setCartModalState(false));
  };

  const rows = [
    {
      name: t('Generals.subtotal'),
      price: subTotal,
    },
    {
      name: t('Generals.delivery'),
      price: delivery || 0,
    },
    {
      name: t('Generals.taxes'),
      price: taxes,
    },
  ];

  if (isCartEmpty === false) {
    return (
      <BlurryDialog
        PaperProps={{
          style: {
            width: 'auto',
            position: 'absolute',
            right: 0,
            marginRight: '0px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          },
        }}
        onClose={handleClose}
        open={cartModalState}
        id="cart-modal"
      >
        <Box component="div" className={classes.root}>
          <Box component="div" className={classes.element}>
            <Box component="div" className={classes.header}>
              <Font text={t('client.CartModal.title')} color="#000000" size={16} lineHeight="26px" isHead={true} />

              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon className={classes.CloseIcon} />
              </IconButton>
            </Box>

            <Box component="div" className={classes.body}>
              {products.map((product, idx) => {
                return (
                  <ProductCart
                    key={idx}
                    product={product}
                    t={t}
                    handleClickSubQuantity={handleClickSubQuantity}
                    handleClickAddQuantity={handleClickAddQuantity}
                    handleClickDeleteProduct={handleClickDeleteProduct}
                  />
                );
              })}
            </Box>
          </Box>

          <Box
            component="div"
            className={classes.element}
            sx={{
              gap: '8px',
            }}
          >
            <Box component="div" className={classes.header}>
              <Accordion className={classes.Accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.CloseIcon} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Font
                    text={t('client.CartModal.our-policy')}
                    color="#000000"
                    size={14}
                    lineHeight="22px"
                    isHead={true}
                  />
                </AccordionSummary>

                <AccordionDetails>
                  <Box component={generalsEnums.BoxDiv} className={classes.policies}>
                    <Button
                      onClick={(e) => {
                        handleNavigate(e, 'terms');
                      }}
                      variant="text"
                      className={classes.textButton}
                    >
                      <Font
                        text={t('CartModal.terms')}
                        color="#000000"
                        size={generalsEnums.fontSizes.size14.size}
                        lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                        isHead={false}
                      />
                    </Button>

                    <Button
                      onClick={(e) => {
                        handleNavigate(e, 'support');
                      }}
                      variant="text"
                      className={classes.textButton}
                    >
                      <Font
                        text={t('CartModal.support')}
                        color="#000000"
                        size={generalsEnums.fontSizes.size14.size}
                        lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                        isHead={false}
                      />
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box
              component="div"
              sx={{
                width: '100%',
                height: 'auto',
                padding: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {rows.map((row) => (
                <Box
                  key={uuid()}
                  component={generalsEnums.BoxDiv}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    padding: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '8px',
                  }}
                >
                  <Font text={row.name} color="#000000" size={14} lineHeight="22px" isHead={true} ml="0px" />

                  <CurrencyFont
                    price={row.price}
                    color={colors.BLACK}
                    size={generalsEnums.fontSizes.size14.size}
                    lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                    isHead={false}
                  />
                </Box>
              ))}

              <CurrencyFont
                price={total}
                color={colors.BLACK}
                size={generalsEnums.fontSizes.size20.size}
                lineHeight={generalsEnums.fontSizes.size20.lineHeight}
                isHead={true}
              />
            </Box>

            <Button onClick={handleContinueShopping} variant="contained" className={classes.payButton}>
              <Font text={t('Generals.pay')} color="#000000" size={14} lineHeight="22px" isHead={false} />
            </Button>
          </Box>
        </Box>
      </BlurryDialog>
    );
  }

  return (
    <BlurryDialog
      PaperProps={{
        style: {
          width: 'auto',
          position: 'absolute',
          right: 0,
          marginRight: '0px',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        },
      }}
      onClose={handleClose}
      open={cartModalState}
      id="cart-modal"
    >
      <Box component="div" className={classes.root}>
        <Box component="div" className={classes.header}>
          <Font text={t('client.CartModal.title')} color="#000000" size={16} lineHeight="26px" isHead={true} />

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon className={classes.CloseIcon} />
          </IconButton>
        </Box>

        <Box component="div" className={classes.emptyCart}>
          <img src={EmptyCart} alt="Empty cart icon for TSI" className={classes.EmptyCart} />

          <Font text="Carrito vacío" color="#000000" size={20} lineHeight="28px" isHead={true} />
        </Box>
      </Box>
    </BlurryDialog>
  );
};

export default CartModal;
