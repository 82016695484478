import React from 'react';
import { Box, Typography } from '@mui/material';

const Font = ({ text, color, size, lineHeight, isHead, ml, mt, isCenter, underline, italic }) => {
  return (
    <Typography
      component="div"
      sx={{
        marginLeft: ml ? ml : '5px',
        marginTop: mt ? mt : '0px',
        textAlign: isCenter === true ? 'center' : 'left',
      }}
    >
      <Box
        sx={{
          fontStyle: italic ? 'italic' : 'normal',
          fontWeight: isHead ? 'bold' : 'normal',
          fontSize: size,
          lineHeight: lineHeight,
          color: color ? color : '#000000',
          width: 'auto',
          textDecoration: underline ? 'underline' : '',
        }}
      >
        {text}
      </Box>
    </Typography>
  );
};

export default Font;
