import { makeStyles } from '@mui/styles';

import * as colors from '../../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {},
  button: {
    width: '100%',
    height: '40px',
    borderRadius: '6px',
    marginTop: '20px',
    backgroundColor: colors.GREEN_MAIN,
    border: `1px solid ${colors.GREEN_MAIN}`,
    backgroundColor: colors.GREEN_MAIN,
    color: colors.BLACK,

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      border: `1px solid ${colors.GREEN_MAIN}`,
      opacity: '0.8',
    },
  },
});
