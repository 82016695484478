import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';

import Addresses from '../../../entities/Addresses';
import Font from '../../../Components/Generals/Fonts/Font';
import Address from './Address';
import generalsEnums from '../../../utils/Generals';
import FocusButton from '../../../Components/Generals/FocusButton';
import { selectUserId } from '../../../Redux/Slices/User.Slices';
import { useStyles } from './MyAddresses.styles';
import {
  setBackDropState,
  setSnackBar,
  selectAddressesUpdated,
  setAddressesUpdated,
  setEditAddressModalState,
  setEditAddressModalAction,
} from '../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../assets/GlobalColors';

const MyAddresses = ({ userIdClient, isCheckout, handleAddAddressDispatch, handleAddAddressForm }) => {
  const dispatch = useDispatch();
  const addressesAPI = new Addresses();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const userIdSelector = useSelector(selectUserId);
  const addressesUpdated = useSelector(selectAddressesUpdated);

  const [addressesToDisplay, setAddressesToDisplay] = React.useState(generalsEnums.emptyArray);
  const [userId, setUserId] = React.useState(generalsEnums.emptyString);
  const [addressSelected, setAddressSelected] = React.useState(generalsEnums.emptyObject);
  const [addressSelectedId, setAddressSelectedId] = React.useState(generalsEnums.emptyString);

  const handleAddAddress = (e) => {
    e.preventDefault();
    dispatch(setEditAddressModalAction('add'));
    dispatch(setEditAddressModalState(true));
  };

  const getAddresses = async () => {
    dispatch(setBackDropState(true));

    const res = await addressesAPI.getAllAddressesByUserId(userId);
    const { statusCode, addresses, messages } = res;

    if (statusCode === 200) {
      setAddressesToDisplay(addresses);
      dispatch(setBackDropState(false));
      dispatch(setAddressesUpdated(false));
    }
  };

  const handleSelectAddress = (address) => {
    if (address._id === setAddressSelectedId._id) {
      setAddressSelected(generalsEnums.emptyObject);
      setAddressSelectedId(generalsEnums.emptyString);
    } else {
      setAddressSelected(address);
      setAddressSelectedId(address._id);
    }
  };

  const handleUseAddress = (e) => {
    e.preventDefault();
    if (addressSelected === generalsEnums.emptyString) {
      dispatch(
        setSnackBar({
          snackBarStatus: true,
          snackBarSeverity: generalsEnums.snackBar.error,
          snackBarMessage: t('client.MyAddresses.inCheckout.addreesEmpty'),
        })
      );
    } else {
      handleAddAddressDispatch(addressSelected);
    }
  };

  React.useEffect(() => {
    if (userIdClient !== generalsEnums.undefinedData) {
      setUserId(userIdClient);
    } else {
      setUserId(userIdSelector);
    }
  }, [userIdSelector, userIdClient]);

  React.useEffect(async () => {
    if (userId !== generalsEnums.emptyString) {
      await getAddresses();
    }
  }, [userId]);

  React.useEffect(() => {
    if (addressesUpdated) {
      getAddresses();
    }
  }, [addressesUpdated]);

  return (
    <Box
      component="div"
      className={classes.root}
      sx={{
        gap: '5px',
      }}
    >
      <Font text={t('client.MyAddresses.title')} color={colors.BLACK} size={24} lineHeight="32px" isHead={true} />

      {addressesToDisplay.length === 0 ? (
        isCheckout ? null : (
          <Box component="div" className={classes.emptyAddressesRoot}>
            <Font
              text={t('client.MyAddresses.empty')}
              color={colors.BLACK_06}
              size={20}
              lineHeight="28px"
              isHead={true}
            />
          </Box>
        )
      ) : (
        <Box
          component="div"
          className={classes.root}
          sx={{
            marginTop: '25px',
            gap: '20px',
          }}
        >
          {addressesToDisplay
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .map((address, idx) => {
              return (
                <Address
                  address={address}
                  key={idx}
                  classes={classes}
                  t={t}
                  isCheckout={isCheckout}
                  handleSelectAddress={handleSelectAddress}
                  isAddressSelected={addressSelectedId === address._id}
                />
              );
            })}
        </Box>
      )}

      {isCheckout ? (
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: '20px',
          }}
        >
          <FocusButton
            handleAction={handleAddAddressForm}
            normalColor={colors.WHITE}
            focusedColor={colors.BLACK}
            text={t('client.MyAddresses.inCheckout.newAddress')}
            w="250px"
            iconStartType="add"
          />

          <FocusButton
            handleAction={handleUseAddress}
            normalColor={colors.GREEN_MAIN}
            focusedColor={colors.BLACK}
            text={t('client.MyAddresses.inCheckout.add')}
            w="250px"
          />
        </Box>
      ) : (
        <Button onClick={handleAddAddress} variant="contained" className={classes.addAddressButton}>
          <Font text={t('client.MyAddresses.btnAdd')} color={colors.BLACK} size={14} lineHeight="24px" isHead={false} />
        </Button>
      )}
    </Box>
  );
};

export default MyAddresses;
