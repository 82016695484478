import React from 'react';
import uuid from 'react-uuid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch } from 'react-redux';
import { Box, Container, Grid } from '@mui/material';

import ProductCard from '../../../Components/Client/ProductCard/ProductCard';
import Products from '../../../entities/Products';
import generalsEnums from '../../../utils/Generals';
import { useStyles } from './ProductsView.styles';
import { setBackDropState } from '../../../Redux/Slices/Navigation.Slices';
import { setCurrentPageItems } from '../../../Redux/Slices/Portal.Slices';

const ProductsView = ({ isRoot, topSellings }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const productsAPI = new Products();
  const pageSize = 50;

  const [items, setItems] = React.useState([]);
  const [itemsPerPage, setItemsPerPage] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [lastEvaluatedKey, setLastEvaluatedKey] = React.useState({});
  const [columns, setColumns] = React.useState(0);
  const [displayPagination, setDisplayPagination] = React.useState(true);

  const isItemsEmpty = items.length === 0;

  const getProductsFromAPI = async () => {
    let offset = null;

    if (Object.keys(lastEvaluatedKey).length > 0) {
      offset = lastEvaluatedKey;
    }

    console.log('offset', offset);

    const res = await productsAPI.productsPagination({
      offset,
    });
    const { statusCode, data } = res;

    if (statusCode === generalsEnums.statusCodes.ok) {
      const { products, LastEvaluatedKey } = data;

      if (currentPage === 1) {
        setItems(products);
      } else {
        const newItems = [...items, ...products];

        setItems(newItems);
        setCurrentPage(currentPage);
        dispatch(setCurrentPageItems(currentPage));
      }

      setLastEvaluatedKey(LastEvaluatedKey);
      dispatch(setBackDropState(false));
    } else {
      dispatch(setBackDropState(false));
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    dispatch(setCurrentPageItems(page));
    const firstIndex = (page - 1) * pageSize;
    const lastIndex = firstIndex + pageSize;
    const itemsPerPage = items.slice(firstIndex, lastIndex);
    setItemsPerPage(itemsPerPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    if (isItemsEmpty) {
      if (isRoot === false) {
        setItems(topSellings);
        setDisplayPagination(false);
      } else {
        dispatch(setBackDropState(true));
        getProductsFromAPI();
      }
    }
  }, [isItemsEmpty, isRoot, topSellings]);

  React.useEffect(() => {
    if (currentPage === 1) {
      setItemsPerPage(items.slice(0, pageSize));
    } else {
      const firstIndex = (currentPage - 1) * pageSize;
      const lastIndex = firstIndex + pageSize;
      const itemsPerPage = items.slice(firstIndex, lastIndex);
      setItemsPerPage(itemsPerPage);
    }
  }, [pageSize, items, currentPage]);

  React.useEffect(() => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      setColumns(1);
    } else if (screenWidth < 960) {
      setColumns(2);
    } else {
      setColumns(3);
    }
  }, []);

  React.useEffect(async () => {
    if (isRoot) {
      if (currentPage === Math.ceil(items.length / pageSize) - 1) {
        await getProductsFromAPI();
      } else if (currentPage === Math.ceil(items.length / pageSize)) {
        await getProductsFromAPI();
      }
    }
  }, [currentPage, items, pageSize, lastEvaluatedKey, isRoot]);

  return (
    <Box component="div" className={classes.root}>
      <Container maxWidth="lg">
        <Box component="div" className={classes.products}>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: '48px',
            }}
          >
            {itemsPerPage.map((product) => (
              <Grid item key={uuid()} xs={12} sm={6} md={4}>
                <ProductCard
                  product={product}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${columns}, 1fr)`,
                    gap: 16,
                    justifyContent: 'center',
                  }}
                />
              </Grid>
            ))}
          </Grid>

          {displayPagination && (
            <Pagination
              count={Math.ceil(items.length / pageSize)}
              page={currentPage}
              renderItem={(item) => (
                <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
              )}
              onChange={handlePageChange}
              shape="rounded"
              className={classes.pagination}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ProductsView;
