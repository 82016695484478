import React from 'react';
import { Box } from '@mui/material';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './NewVariants.styles';
import {
  selectNewVariants,
  setAddNewVariant,
  setClearNewVariants,
  setProductVariants,
  setOrderNewVariants,
} from '../../../../Redux/Slices/Products.Slices';
import Font from '../../../../Components/Generals/Fonts/Font';
import WhiteButton from '../../../../Components/Generals/WhiteButton';
import GreenButton from '../../../../Components/Generals/GreenButton';
import Variant from './Variant';
import { setBackDropState } from '../../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../../assets/GlobalColors';

const NewVariants = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation('global');
  const mediaQuery1200 = useMediaQuery('(max-width:1200px)');
  const newVariants = useSelector(selectNewVariants);

  const [optionsQuantity, setOptionsQuantity] = React.useState(0);
  const [variantsQuantity, setVariantsQuantity] = React.useState(0);
  const [items, setItems] = React.useState([]);

  const handleClickCancel = (e) => {
    e.preventDefault();
    navigate(-1);
    dispatch(setClearNewVariants());
  };

  const handleClickAdd = (e) => {
    e.preventDefault();
    dispatch(setAddNewVariant());
  };

  const handleClickSave = (e) => {
    e.preventDefault();
    dispatch(setBackDropState(true));
    dispatch(setProductVariants(items));
    setTimeout(() => {
      dispatch(setBackDropState(false));
      navigate(-1);
    }, 1000);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(items, result.source.index, result.destination.index);

    dispatch(
      setOrderNewVariants({
        updatedItems: updatedItems,
      })
    );
    setItems(updatedItems);
  };

  React.useEffect(() => {
    setItems(newVariants);
  }, [newVariants]);

  return (
    <Container maxWidth="lg">
      <Box component="div" className={classes.root}>
        <Box
          component="div"
          className={classes.headerBox}
          sx={{
            flexDirection: mediaQuery1200 ? 'column' : 'row',
          }}
        >
          <Box
            component="div"
            className={classes.headerTextBox}
            sx={{
              flexDirection: mediaQuery1200 ? 'column' : 'row',
              alignItems: mediaQuery1200 ? 'flex-start' : 'flex-end',
            }}
          >
            <Font text={t('admin.NewVariants.title')} color={colors.BLACK} size={20} lineHeight="28px" isHead={true} />

            <Font
              text={`${optionsQuantity} ${t('admin.NewVariants.option')} ${variantsQuantity} ${t(
                'admin.NewVariants.variant'
              )}`}
              color={colors.BLACK_06}
              size={18}
              lineHeight="28px"
              isHead={false}
            />
          </Box>

          <Box
            component="div"
            className={classes.headerTextBox}
            sx={{
              flexDirection: mediaQuery1200 ? 'column' : 'row',
              alignItems: mediaQuery1200 ? 'flex-start' : 'flex-end',
            }}
          >
            <WhiteButton text={t('Generals.cancelAction')} icon={null} handle={handleClickCancel} />

            <WhiteButton
              text={t('admin.NewVariants.add')}
              icon={<AddIcon sx={{ color: colors.BLACK }} />}
              handle={handleClickAdd}
            />

            <GreenButton text={t('admin.NewVariants.save')} icon={null} handle={handleClickSave} />
          </Box>
        </Box>

        <Box
          component="div"
          className={classes.bodyBox}
          sx={{
            gap: '30px',
          }}
        >
          <Font
            text={t('admin.NewVariants.explain')}
            color={colors.BLACK_06}
            size={14}
            lineHeight="22px"
            isHead={false}
          />

          <Box component="div" className={classes.bodyBox}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    component="div"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                    }}
                  >
                    {items.map((item, index) => {
                      return <Variant key={item.id} draggableId={item.id} index={index} item={item} t={t} />;
                    })}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default NewVariants;
