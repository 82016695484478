export const usersTypes = {
  ADMIN: 'TSADMIN',
  CLIENT: 'CLIENT',
  GUEST: 'GUEST',
};

export const getUserType = (userType) => {
  switch (userType) {
    case usersTypes.ADMIN:
      return 'Admin';
    case usersTypes.CLIENT:
      return 'Cliente';
    case usersTypes.GUEST:
      return 'Invitado';
    default:
      return '-';
  }
};
