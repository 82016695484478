import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import Font from '../../../Components/Generals/Fonts/Font';
import ProductsView from '../ProductsView/ProductsView';
import Products from '../../../entities/Products';
import SliderAutoPlay from '../../../Components/Generals/Sliders/SliderAutoPlay';
import generalsEnums from '../../../utils/Generals';
import ResponsiveComponent from '../../../Hooks/ResponsiveComponent';
import { getResponsiveValues } from '../../../utils/FormatData';
import { useStyles } from './Home.styles';
import * as colors from '../../../assets/GlobalColors';

const Home = () => {
  const classes = useStyles();
  const productsAPI = new Products();
  const { t } = useTranslation('global');

  const [topSellings, setTopSellings] = React.useState(generalsEnums.emptyArray);

  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size34.size,
      generalsEnums.fontSizes.size34.size,
      generalsEnums.fontSizes.size34.size,
      generalsEnums.fontSizes.size34.size,
      generalsEnums.fontSizes.size34.size,
    ],
    [
      generalsEnums.fontSizes.size34.lineHeight,
      generalsEnums.fontSizes.size34.lineHeight,
      generalsEnums.fontSizes.size34.lineHeight,
      generalsEnums.fontSizes.size34.lineHeight,
      generalsEnums.fontSizes.size34.lineHeight,
    ],
  ];

  const [titleSize, titleLineHeight] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  React.useEffect(async () => {
    const getTopSellingProductsResponse = await productsAPI.getTopSellingProducts();
    const { data } = getTopSellingProductsResponse;
    setTopSellings(data);
  }, []);

  return (
    <Box component={generalsEnums.BoxDiv} className={classes.container}>
      <SliderAutoPlay />

      <Font
        text={t('Home.topTitle')}
        color={colors.BLACK_06}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={true}
        ml="0px"
      />

      <ProductsView isRoot={false} topSellings={topSellings} />
    </Box>
  );
};

export default Home;
