import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Fade, Paper, Popper, Box } from '@mui/material';

import Font from '../Generals/Fonts/Font';
import Paths from '../../utils/enums/Paths';
import generalsEnums from '../../utils/Generals';
import {
  selectCategoriesFilter,
  setCategoryFilter,
  setSubcategoryFilter,
  setLabelFilter,
} from '../../Redux/Slices/Products.Slices';
import * as colors from '../../assets/GlobalColors';

const CategoryPopoverButton = ({ Category, handleClickRightPopper }) => {
  return (
    <Button
      onClick={handleClickRightPopper('right', Category)}
      variant={generalsEnums.variants.text}
      style={{
        width: '100%',
        height: '35px',
        textTransform: 'none',
        fontSize: '14px',
        lineHeight: '14px',
        color: colors.BLACK_1,
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {Category.categoryName} <KeyboardArrowRightIcon />
    </Button>
  );
};

const SubCategoryPopoverBox = ({ subCategory, handleClickLabel }) => {
  return (
    <Box
      component="div"
      style={{
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '5px',
      }}
    >
      <Box
        component="div"
        style={{
          color: colors.BLACK_1,
          fontSize: '14px',
          lineHeight: '14px',
          marginBottom: '5px',
        }}
      >
        {subCategory ? subCategory.subCategory : generalsEnums.emptyString}
      </Box>

      {subCategory.labels.map((label, idx) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClickLabel(subCategory, label);
            }}
            key={idx}
            variant={generalsEnums.variants.text}
            style={{
              color: colors.BLACK_06,
              fontSize: '14px',
              lineHeight: '14px',
              textTransform: 'none',
              border: 'none',
            }}
          >
            {label.label}
          </Button>
        );
      })}
    </Box>
  );
};

const HeaderNavButton = ({ text, route, isCategory, isLocal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(generalsEnums.nullData);
  const [open, setOpen] = React.useState(generalsEnums.falseBoolean);
  const [placement, setPlacement] = React.useState();
  const [rightAnchorEl, setRightAnchorEl] = React.useState(generalsEnums.nullData);
  const [rightOpen, setRightOpen] = React.useState(generalsEnums.falseBoolean);
  const [rightPlacement, setRightPlacement] = React.useState();
  const [categorySelected, setCategorySelected] = React.useState(generalsEnums.emptyObject);

  const categories = useSelector(selectCategoriesFilter);

  const handleClickPopper = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setRightOpen(generalsEnums.falseBoolean);
    setPlacement(newPlacement);
  };

  const handleClickNavigate = () => {
    if (isLocal) {
      navigate(route ? route : Paths.client.Home2);
    } else {
      window.open(route, '_blank');
    }
  };

  const handleClickRightPopper = (newPlacement, Category) => (event) => {
    setRightAnchorEl(event.currentTarget);
    setRightOpen(generalsEnums.trueBoolean);
    setRightPlacement(newPlacement);
    setCategorySelected(Category);
    dispatch(
      setCategoryFilter({
        uuid: Category.category_uuid,
        isSelected: !Category.checked,
      })
    );
  };

  const handleClickLabel = (subCategory, label) => {
    dispatch(
      setSubcategoryFilter({
        uuid: subCategory.subcategory_uuid,
        isSelected: !subCategory.checked,
      })
    );
    dispatch(
      setLabelFilter({
        uuid: label.label_uuid,
        isSelected: !label.checked,
      })
    );
    setOpen(generalsEnums.falseBoolean);
    setRightOpen(generalsEnums.falseBoolean);
    navigate(Paths.client.ProductsView);
  };

  const handleClickAway = () => {
    setOpen(generalsEnums.falseBoolean);
  };

  const handleClickRightAway = () => {
    setRightOpen(generalsEnums.falseBoolean);
  };

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (isCategory) {
              handleClickPopper('bottom')(e);
            } else {
              handleClickNavigate();
            }
          }}
          variant={generalsEnums.variants.text}
          style={{
            width: 'auto',
            height: 'auto',
            fontSize: '14px',
            lineHeight: '14px',
            color: colors.BLACK_06,
          }}
        >
          <Font
            text={text}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size16.size}
            lineHeight={generalsEnums.fontSizes.size16.lineHeight}
            isHead={generalsEnums.falseBoolean}
          />

          {isCategory === true ? <ExpandMoreIcon /> : null}
        </Button>
      </ClickAwayListener>

      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box
                component="div"
                style={{
                  width: '230px',
                  height: 'auto',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                {categories.map((category, idx) => {
                  return (
                    <CategoryPopoverButton
                      key={idx}
                      Category={category}
                      handleClickRightPopper={handleClickRightPopper}
                    />
                  );
                })}
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>

      <Popper open={rightOpen} anchorEl={rightAnchorEl} placement={rightPlacement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box
                component="div"
                style={{
                  width: '550px',
                  height: 'auto',
                  padding: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <Box
                  component="div"
                  style={{
                    width: '100%',
                    height: '18px',
                    textAlign: 'left',
                    color: colors.BLACK_1,
                    fontSize: '16px',
                    lineHeight: '16px',
                  }}
                >
                  {categorySelected.categoryName}
                </Box>

                <Box
                  component="div"
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '15px',
                    flexWrap: 'wrap',
                  }}
                >
                  {categorySelected.subcategories.map((subCategory, idx) => {
                    return (
                      <SubCategoryPopoverBox key={idx} subCategory={subCategory} handleClickLabel={handleClickLabel} />
                    );
                  })}
                </Box>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default HeaderNavButton;
