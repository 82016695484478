import { makeStyles } from '@mui/styles';

import * as colors from '../../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
    marginBottom: '50px',
  },
  headerBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${colors.WHITE}`,
    borderRadius: '8px',
    backgroundColor: colors.WHITE,
    padding: '10px',
    gap: '20px',
  },
  headerTextBox: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  bodyBox: {
    width: '95%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  Variant: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '15px 15px 50px 15px',
    border: `1px solid ${colors.WHITE}`,
    backgroundColor: colors.WHITE,
    borderBottom: `2px solid ${colors.BLACK_06}`,
    shadow: 'none',
    gap: '35px',
  },
  dragButton: {
    boxShadow: 'none',
    border: 'none',
    backgroundColor: colors.WHITE,
    cursor: 'grab',

    '&:hover': {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: colors.WHITE,
      cursor: 'grab',
    },
  },
  textFieldItem: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  valueItem: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  addValueButton: {
    width: 150,
    height: 40,
    borderRadius: '8px',
  },
});
