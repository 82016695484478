import React from 'react';
import { Box } from '@mui/material';

const Item = ({ generalsEnums, classes, colors, isWhite, ruleText, priceText, markupText, listToRender }) => {
  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.rowBox}
      sx={{
        padding: '8px 16px',
        gap: '8px',
        border: `1px solid ${colors.GREEN_MAIN}`,
        backgroundColor: isWhite ? colors.WHITE : colors.GREEN_MAIN_2,
      }}
    >
      {listToRender}
    </Box>
  );
};

export default Item;
