import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';

import generalsEnums from '../../../../utils/Generals';

const DateControlledPicker = ({ initialValue, currentMaxDate, handleChangeDate, text }) => {
  const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
    handleChangeDate(newValue);
  };

  React.useEffect(() => {
    setValue(new Date(initialValue));
  }, [initialValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: 'auto',
          height: 'auto',
        }}
      >
        {text}

        <DatePicker
          maxDate={currentMaxDate}
          value={value}
          onChange={(newValue) => handleChange(newValue)}
          renderInput={(props) => <TextField {...props} variant="outlined" margin="normal" />}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateControlledPicker;
