export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const BLACK_06 = 'rgba(0, 0, 0, 0.6)';
export const BLACK_1 = 'rgba(0, 0, 0, 1)';

export const GRAY_1 = '#F2F2F2';
export const GRAY_BG = 'rgba(34, 34, 34, 1)';
export const GRAY_BG_04 = 'rgba(255, 255, 255, 0.04)';
export const GRAY_BG_1 = 'rgba(255, 255, 255, 1)';
export const GRAY_BG_6 = 'rgba(255, 255, 255, 0.6)';
export const GRAY_214_1 = 'rgba(214, 214, 214, 1)';
export const GRAY_228_1 = 'rgba(228, 228, 228, 1)';
export const GRAY_228_05 = 'rgba(228, 228, 228, 0.5)';
export const GRAY_229_1 = 'rgba(229, 229, 229, 1)';
export const GRAY_196_1 = 'rgba(196, 196, 196, 1)';
export const GRAY_240_1 = 'rgba(240, 240, 240, 1)';
export const GRAY_214 = 'rgb(214, 214, 214)';
export const GRAY_204_1 = 'rgba(204, 204, 204, 1)';
export const GRAY_245 = 'rgb(245, 245, 245)';
export const GRAY_CCC = '#CCC';
export const GRAY_1_1 = 'rgba(102, 102, 102, 1)';

export const GREEN_MAIN = 'rgba(186, 207, 55, 1)';
export const GREEN_MAINHOVER = 'rgba(167, 186, 50, 1)';
export const GREEEN_186 = 'rgba(186, 207, 55, 0.20)';
export const GREEN_MAIN_1 = 'rgba(241, 245, 215, 1)';
export const GREEN_MAIN_2 = 'rgba(214, 226, 135, 1)';
export const GREEN_MAIN_3 = '#F1F5D7';

export const TOOLTIP = 'rgb(52, 49, 63)';

export const ORANGE = 'rgb(208, 102, 59)';
export const ORANGE_207 = 'rgba(207, 137, 55, 1)';
export const ORANGE_235 = 'rgba(235, 206, 173, 1)';

export const TSADMIN_BG = 'rgb(250, 250, 250)';
export const TSADMIN_BLUE_GRAY = 'rgba(26, 62, 104, 0.48)';
export const TSADMIN_BLUE = 'rgba(0, 113, 242, 1)';
export const TSADMIN_RED_DELETE = 'rgba(234, 67, 53, 1)';
export const TSADMIN_RED_DELETE_1 = 'rgba(234, 67, 53, 0.1)';
export const TSADMIN_RED_DELETE_2 = 'rgba(255, 229, 229, 1)';

export const BORDER_GRAY = '#D6D6D6';
export const BORDER_GRAY_2 = '#e0e0e0';

export const BORDER_CCC = '#CCC';
export const BORDER_GRAY_1 = '#999';

export const TEXT_GRAY_102 = 'rgba(102, 102, 102, 1)';
export const TEXT_GRAY_666 = '#666';
export const TEXT_GRAY_34 = 'rgba(34, 34, 34, 1)';

export const BADGE_GREEN_BKC = 'rgba(188, 227, 201, 1)';
export const BADGE_GREEN_TEXT = 'rgba(16, 156, 62, 1)';
export const BADGE_GRAY_BKC = 'rgba(204, 204, 204, 1)';
export const BADGE_GRAY_TEXT = 'rgba(102, 102, 102, 1)';
export const BADGE_RED_BKC = 'rgba(248, 211, 208, 1)';
export const BADGE_RED_TEXT = 'rgba(234, 67, 53, 1)';
export const BADGE_GREEN_SOFT = 'rgba(241, 245, 215, 1)';

export const OS_GREEN = 'rgba(186, 207, 55, 1)';
export const OS_YELLOW = 'rgba(255, 204, 0, 1)';
export const OS_RED = 'rgba(234, 67, 53, 1)';
export const OS_ORANGE = 'rgba(208, 127, 22, 1)';
export const OS_GREEN_2 = 'rgba(16, 156, 62, 1)';
