import { makeStyles } from '@mui/styles';

import * as color from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '50px',
    marginTop: '25px',
    marginBottom: '50px',
  },
  optionsBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    padding: '5px',
    gap: '4px',
    backgroundColor: color.WHITE,
  },
});

export const colors = color;
