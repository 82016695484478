import { format, parseISO } from 'date-fns';
import { es, enUS } from 'date-fns/locale';

import generalsEnums from './Generals';
import { mxStatesCodes, usStatesCodes } from './DataSeeds';

export const formatItemCodes = (codes) => {
  let formattedCode = '';

  if (Array.isArray(codes)) {
    formattedCode = codes[0];
  } else {
    formattedCode = codes;
  }

  return formattedCode;
};

export const formatProductQuantity = (orderProducts) => {
  let productsQ = 0;
  let itemsQ = 0;

  orderProducts.forEach((product) => {
    productsQ += 1;
    itemsQ += product.quantitySelected;
  });

  return { productsQ, itemsQ };
};

export const getResponsiveValue = (values, isXs, isSm, isMd, isLg, isXl) => {
  const conditions = [
    { match: isXs, value: values[0] },
    { match: isSm, value: values[1] },
    { match: isMd, value: values[2] },
    { match: isLg, value: values[3] },
    { match: isXl, value: values[4] },
  ];

  const responsiveValue = conditions.find((condition) => condition.match)?.value;
  return responsiveValue !== undefined ? responsiveValue : values[4];
};

export const getResponsiveValues = (valuesArray, isXs, isSm, isMd, isLg, isXl) => {
  return valuesArray.map((values) => getResponsiveValue(values, isXs, isSm, isMd, isLg, isXl));
};

export const getMessageByPortalLanguage = (messages, portalLanguage) => {
  return messages[portalLanguage] || messages.en;
};

export const getMarkupPercentage = (markup) => {
  const markupPercentage = markup * 100;
  const markupPercentageFormatted = Math.round((markupPercentage + Number.EPSILON) * 100) / 100;
  return `${markupPercentageFormatted}%`;
};

export const getMarkupRule = (minPrice, maxPrice, smallerThan, biggerThan, between) => {
  if (minPrice === 0) {
    return smallerThan;
  } else if (maxPrice === 0) {
    return biggerThan;
  } else {
    return between;
  }
};

export const getAddressText = (address) => {
  const { name, lastName, street, apartment, workEnterprise, city, state, zipCode, country } = address;
  const countryPcs = country.toUpperCase();

  const addressUserData = `${name} ${lastName}`;
  const addressStreet = `${street}${apartment ? `, ${apartment}` : ''}${workEnterprise ? `, ${workEnterprise}` : ''}`;
  const addressCity = `${city}, ${state}, ${zipCode}, ${countryPcs}`;

  return { addressUserData, addressStreet, addressCity };
};

export const getLisWeightsRulesQty = (quantity, text) => {
  return quantity === 0 ? text : quantity;
};

export const getLisWeightsRulesCondition = (condition, greaterOrEqualText, lessOrEqualText) => {
  if (condition === generalsEnums.weightConditions.greaterOrEqual) {
    return greaterOrEqualText;
  } else if (condition === generalsEnums.weightConditions.lessOrEqual) {
    return lessOrEqualText;
  }
};

export const getStateCodeByName = (stateName, country) => {
  let stateCode = '';

  if (country === generalsEnums.countries.mexico) {
    stateCode = mxStatesCodes.find((state) => Object.keys(state)[0] === stateName)[stateName];
  } else if (country === generalsEnums.countries.unitedStates) {
    stateCode = usStatesCodes.find((state) => Object.keys(state)[0] === stateName)[stateName];
  }

  return stateCode;
};

export const getMinDate = () => {
  const dateTodaySixMonthAgo = new Date();
  dateTodaySixMonthAgo.setMonth(dateTodaySixMonthAgo.getMonth() - 6);
  dateTodaySixMonthAgo.setHours(0, 0, 0, 0);

  return dateTodaySixMonthAgo.toISOString();
};

export const getMinDate30DaysAgo = () => {
  const dateToday30DaysAgo = new Date();
  dateToday30DaysAgo.setDate(dateToday30DaysAgo.getDate() - 30);
  dateToday30DaysAgo.setHours(0, 0, 0, 0);

  return dateToday30DaysAgo.toISOString();
};

export const getMaxDate = () => {
  const dateToday = new Date();
  dateToday.setHours(23, 59, 59, 999);

  return dateToday.toISOString();
};

export const getTaxByCounty = (country, state) => {
  if (country === generalsEnums.countries.mexico && state) {
    return 0;
  } else if (country === generalsEnums.countries.unitedStates && state) {
    if (state === 'Arizona') return 8.6;

    return 0;
  }
};

export const getOrderFormatDate = (dateString, languagePortal) => {
  const date = parseISO(dateString);
  const dateFormatEs = format(date, 'EEEE dd/MM/yyyy, HH:mm:ss', { locale: es });
  const dateFormatEn = format(date, 'EEEE dd/MM/yyyy, HH:mm:ss', { locale: enUS });

  return languagePortal === generalsEnums.languages.spanish ? dateFormatEs : dateFormatEn;
};

const statusColorMap = {
  [generalsEnums.orderStatus.os1]: 'rgb(143,209,79)',
  [generalsEnums.orderStatus.os7]: 'rgb(143,209,79)',
  [generalsEnums.orderStatus.os4]: 'rgb(143,209,79)',
  [generalsEnums.orderStatus.os2]: 'rgb(254,244,69)',
  [generalsEnums.orderStatus.os3]: 'rgb(254,244,69)',
  [generalsEnums.orderStatus.os14]: 'rgb(254,244,69)',
  [generalsEnums.orderStatus.os8]: 'rgb(250,199,16)',
  [generalsEnums.orderStatus.os5]: 'rgb(12,167,137)',
  [generalsEnums.orderStatus.os11]: 'rgb(12,167,137)',
  [generalsEnums.orderStatus.os12]: 'rgb(12,167,137)',
  [generalsEnums.orderStatus.os13]: 'rgb(12,167,137)',
  [generalsEnums.orderStatus.os9]: 'rgb(242,71,38)',
  [generalsEnums.orderStatus.os10]: 'rgb(242,71,38)',
  [generalsEnums.orderStatus.os6]: 'rgb(242,71,38)',
};

export const getStatusColor = (os) => {
  if (os !== undefined) {
    return statusColorMap[os];
  } else {
    return 'rgb(143,209,79)';
  }
};

export const getDisableButtonByOs = (os) => {
  const disableOsValues = [
    generalsEnums.orderStatus.os7,
    generalsEnums.orderStatus.os2,
    generalsEnums.orderStatus.os3,
    generalsEnums.orderStatus.os8,
  ];
  return disableOsValues.includes(os);
};
