import React from 'react';
import { InputAdornment, IconButton, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const FormikTextField = ({
  itemValue,
  itemSize,
  itemId,
  itemName,
  itemVariant,
  itemOnChange,
  itemStyle,
  itemType,
  itemPlaceHolderColor,
  itemPlaceHolderSize,
  itemPlaceholder,
  itemMultiline,
  itemRows,
  itemMoney,
  itemDisabled,
  itemZipCode,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleInputNumbers = (e) => {
    const regex = /^[0-9\b]+$/;

    if (e.target.value === '' || regex.test(e.target.value)) {
      itemOnChange(e);
    }
  };

  return (
    <OutlinedInput
      value={itemValue}
      size={itemSize}
      id={itemId}
      name={itemName}
      variant={itemVariant}
      onChange={(e) => {
        if (itemZipCode === true) {
          handleInputNumbers(e);
        } else {
          itemOnChange(e);
        }
      }}
      style={itemStyle}
      type={itemType === 'text' ? 'text' : itemType === 'password' ? (showPassword ? 'text' : 'password') : null}
      endAdornment={
        itemType === 'password' ? (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
              {showPassword ? (
                <VisibilityOff
                  style={{
                    color: itemPlaceHolderColor,
                  }}
                />
              ) : (
                <Visibility
                  style={{
                    color: itemPlaceHolderColor,
                  }}
                />
              )}
            </IconButton>
          </InputAdornment>
        ) : null
      }
      startAdornment={
        itemMoney === true ? (
          <InputAdornment position="start">
            <AttachMoneyIcon
              style={{
                color: itemPlaceHolderColor,
              }}
            />
          </InputAdornment>
        ) : null
      }
      inputProps={{
        placeholder: itemPlaceholder,
        style: {
          color: itemPlaceHolderColor,
          fontSize: itemPlaceHolderSize,
        },
        pattern: itemZipCode === true ? '\\d{5,6}' : null,
        maxLength: itemZipCode === true ? 6 : null,
      }}
      multiline={itemMultiline ? itemMultiline : false}
      autoComplete="off"
      disabled={itemDisabled}
      rows={itemRows !== null ? itemRows : 4}
    />
  );
};

export default FormikTextField;
