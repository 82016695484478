import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  columnBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  rowBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  buttonAddNewMarkup: {
    width: '150px',
  },
  listRuleBox: {
    width: '150px',
    height: 'auto',
    padding: '2px',
  },
  listPriceBox: {
    width: '250px',
    height: 'auto',
    padding: '2px',
  },
  listMarkupBox: {
    width: '100px',
    height: 'auto',
    padding: '2px',
  },
  Icon: {
    width: '20px',
    height: '20px',
    color: '#000000',
  },
});
