import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import Font from '../../Components/Generals/Fonts/Font';
import * as colors from '../../assets/GlobalColors';

const NavBarButton = ({ value, text, handleOnClick, isSelected, icon, childs, childPathSelected }) => {
  const [displayChilds, setDisplayChilds] = React.useState(false);
  const [childsToDisplay, setChildsToDisplay] = React.useState([]);

  React.useEffect(() => {
    if (childs) {
      setDisplayChilds(true);
      setChildsToDisplay(childs);
    }

    return () => {
      setDisplayChilds(false);
      setChildsToDisplay([]);
    };
  }, [childs]);

  if (displayChilds) {
    return (
      <Accordion
        onChange={(e) => {
          e.preventDefault();
          handleOnClick(value);
        }}
        sx={{
          width: '100%',
          height: 'auto',
          border: 'none',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Font
            text={text}
            color={isSelected ? colors.BLACK : colors.BLACK_06}
            size={14}
            lineHeight="22px"
            isHead={true}
            isCenter={false}
          />
        </AccordionSummary>

        <AccordionDetails>
          {childsToDisplay.map((item, index) => {
            return (
              <NavBarButton
                key={index}
                value={item.value}
                text={item.name}
                handleOnClick={handleOnClick}
                isSelected={childPathSelected === item.value ? true : false}
                icon={item.icon}
                childs={Object.hasOwnProperty.call(item, 'childs') === true ? item.childs : null}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Button
      variant="outlined"
      onClick={(e) => {
        e.preventDefault();
        handleOnClick(value);
      }}
      style={{
        textTransform: 'none',
        width: '100%',
        height: '40px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
        color: isSelected ? colors.BLACK : colors.BLACK_06,
        border: 'none',
        backgroundColor: 'transparent',
      }}
    >
      {icon}

      <Font
        text={text}
        color={isSelected ? colors.BLACK : colors.BLACK_06}
        size={14}
        lineHeight="22px"
        isHead={false}
        isCenter={false}
      />
    </Button>
  );
};

export default NavBarButton;
