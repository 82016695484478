import React from 'react';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Dialog, Box } from '@mui/material';

import FormikTextField from '../../../Components/Generals/FormikTextField';
import Font from '../../../Components/Generals/Fonts/Font';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import generalsEnums from '../../../utils/Generals';
import * as colors from '../../../assets/GlobalColors';

const BlurryDialog = styled(Dialog)(({}) => ({
  backdropFilter: 'blur(5px)',
}));

const CancelOrderModal = ({ CancelOrderModalOpen, setCancelOrderModalOpen, handleCancelAction }) => {
  const { t } = useTranslation('global');

  const [disableButtons, setDisableButtons] = React.useState(generalsEnums.falseBoolean);
  const [inputValue, setInputValue] = React.useState(generalsEnums.emptyString);

  const handleCloseModal = () => {
    setCancelOrderModalOpen(generalsEnums.falseBoolean);
    setInputValue(generalsEnums.emptyString);
  };

  const handleSave = (e) => {
    e.preventDefault();
    handleCancelAction(inputValue);
  };

  React.useEffect(() => {
    if (inputValue === generalsEnums.emptyString) {
      setDisableButtons(generalsEnums.trueBoolean);
    } else {
      setDisableButtons(generalsEnums.falseBoolean);
    }
  }, [inputValue]);

  return (
    <BlurryDialog onClose={handleCloseModal} open={CancelOrderModalOpen} id="cart-modal">
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '360px',
          height: '355px',
          borderRadius: '12px',
          padding: '32px',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <Font
          text={t('CancelOrderModal.title')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size18.size}
          lineHeight={generalsEnums.fontSizes.size18.lineHeight}
          isHead={true}
          isCenter={true}
        />

        <FormikTextField
          itemValue={inputValue}
          itemSize="medium"
          itemId="message"
          itemName="message"
          itemVariant="outlined"
          itemOnChange={(e) => {
            const value = e.target.value;
            setInputValue(value);
          }}
          itemStyle={{
            border: `0.4px solid ${colors.GRAY_214_1}`,
            borderRadius: '5px',
            width: '100%',
            backgroundColor: colors.WHITE,
          }}
          itemType="text"
          itemPlaceHolderColor={colors.BLACK}
          itemPlaceHolderSize="13px"
          itemPlaceholder={t('CancelOrderModal.ph')}
          itemMultiline={true}
          itemRows={5}
        />

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: 'auto',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.black}
              handleAction={handleCloseModal}
              text={t('Generals.cancelAction')}
              isDisabled={generalsEnums.falseBoolean}
              size={generalsEnums.fontSizes.size14.size}
              lineHeight={generalsEnums.fontSizes.size14.lineHeight}
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: 'auto',
            }}
          >
            <TsiButton
              type={generalsEnums.buttonTypes.save}
              handleAction={handleSave}
              text={t('Generals.save')}
              isDisabled={disableButtons}
              size={generalsEnums.fontSizes.size14.size}
              lineHeight={generalsEnums.fontSizes.size14.lineHeight}
            />
          </Box>
        </Box>
      </Box>
    </BlurryDialog>
  );
};

export default CancelOrderModal;
