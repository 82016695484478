import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class Countries extends Axios {
  constructor() {
    super();
    this.urlgetAllCountries = API_ROUTES.addresses.getAllCountries;
  }

  async getAllCountries() {
    const response = await this.get(this.urlgetAllCountries);
    return response;
  }
}

export default Countries;
