import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class MailShots extends Axios {
  constructor() {
    super();
    this.urlcontactTSI = API_ROUTES.MailShots.contactTSI;
    this.urlgetMailTemplates = API_ROUTES.MailShots.getMailTemplates;
    this.urlupdateMailTemplateAvailable = API_ROUTES.MailShots.updateMailTemplateAvailable;
    this.urlabandonedCart = API_ROUTES.MailShots.abandonedCart;
    this.urlshippingUpdate = API_ROUTES.MailShots.shippingUpdate;
    this.urlpaymentDeclined = API_ROUTES.MailShots.paymentDeclined;
    this.urlcomplaintResponse = API_ROUTES.MailShots.complaintResponse;
    this.urlcomplaintSolution = API_ROUTES.MailShots.complaintSolution;
    this.urlcomplaintConfirm = API_ROUTES.MailShots.complaintConfirm;
    this.urlclaimOnOrder = API_ROUTES.MailShots.claimOnOrder;
    this.urldeliveryConfirmation = API_ROUTES.MailShots.deliveryConfirmation;
    this.urltrackingUpdate = API_ROUTES.MailShots.trackingUpdate;
    this.urlshippingConfirmation = API_ROUTES.MailShots.shippingConfirmation;
    this.urlpurchaseConfirmation = API_ROUTES.MailShots.purchaseConfirmation;
  }

  async purchaseConfirmation(body) {
    const response = await this.post(this.urlpurchaseConfirmation, body);
    return response;
  }

  async shippingConfirmation(body) {
    const response = await this.post(this.urlshippingConfirmation, body);
    return response;
  }

  async trackingUpdate(body) {
    const response = await this.post(this.urltrackingUpdate, body);
    return response;
  }

  async deliveryConfirmation(body) {
    const response = await this.post(this.urldeliveryConfirmation, body);
    return response;
  }

  async claimOnOrder(body) {
    const response = await this.post(this.urlclaimOnOrder, body);
    return response;
  }

  async complaintConfirm(body) {
    const response = await this.post(this.urlcomplaintConfirm, body);
    return response;
  }

  async complaintSolution(body) {
    const response = await this.post(this.urlcomplaintSolution, body);
    return response;
  }

  async complaintResponse(body) {
    const response = await this.post(this.urlcomplaintResponse, body);
    return response;
  }

  async paymentDeclined(body) {
    const response = await this.post(this.urlpaymentDeclined, body);
    return response;
  }

  async shippingUpdate(body) {
    const response = await this.post(this.urlshippingUpdate, body);
    return response;
  }

  async abandonedCart(body) {
    const response = await this.post(this.urlabandonedCart, body);
    return response;
  }

  async contactTSI(body) {
    const response = await this.post(this.urlcontactTSI, body);
    return response;
  }

  async getMailTemplates() {
    const response = await this.get(this.urlgetMailTemplates);
    return response;
  }

  async updateMailTemplateAvailable(body) {
    const response = await this.post(this.urlupdateMailTemplateAvailable, body);
    return response;
  }
}

export default MailShots;
