import axios from 'axios';

class Axios {
  constructor() {
    this.axios = axios;
  }

  get(url) {
    return new Promise(async (resolve, reject) => {
      await this.axios
        .get(url)
        .then((response) => {
          if (response !== undefined) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post(url, body) {
    return new Promise(async (resolve, reject) => {
      await this.axios
        .post(url, body)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete(url, data) {
    return new Promise(async (resolve, reject) => {
      await this.axios
        .delete(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  put(url, data) {
    return new Promise(async (resolve, reject) => {
      await this.axios
        .put(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default Axios;
