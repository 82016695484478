import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Paper, Button } from '@mui/material';

import generalsEnums from '../../../../utils/Generals';
import Font from '../../../../Components/Generals/Fonts/Font';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import MailShots from '../../../../entities/MailShots';
import SwitchAttom from '../../../../Components/Generals/Figma/Atoms/SwitchAttom';
import Paths from '../../../../utils/enums/Paths';
import { selectMailTemplates, setMailTemplates } from '../../../../Redux/Slices/Portal.Slices';
import { setBackDropState, setEmailTemplate } from '../../../../Redux/Slices/Navigation.Slices';
import { useStyles, colors } from './styles';

const TransactionalEmails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('global');
  const MailShotsAPI = new MailShots();
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = React.useState(generalsEnums.falseBoolean);
  const [templates, setTemplates] = React.useState(generalsEnums.emptyArray);
  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);
  const mailTemplates = useSelector(selectMailTemplates);
  const language = localStorage.getItem('language');

  const textTemplateSize =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size14.size : generalsEnums.fontSizes.size16.size;
  const textTemplateLineHeight =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size14.lineHeight : generalsEnums.fontSizes.size16.lineHeight;
  const rootPadding = IpadAir || IpadMini ? generalsEnums.paddings.root.tablet : generalsEnums.paddings.root.desktop;

  const handleTemplateSwitch = async (_id) => {
    dispatch(setBackDropState(generalsEnums.trueBoolean));

    const updateMailTemplateAvailableBody = {
      _id,
    };

    const res = await MailShotsAPI.updateMailTemplateAvailable(updateMailTemplateAvailableBody);
    const { statusCode } = res;

    if (statusCode === generalsEnums.statusCodes.ok) dispatch(setBackDropState(generalsEnums.falseBoolean));
    else dispatch(setBackDropState(generalsEnums.falseBoolean));
  };

  const handleClickNameTemplate = (template) => {
    navigate(Paths.admin.Marketing.EmailTemplate);
    dispatch(setEmailTemplate(template));
  };

  React.useEffect(() => {
    dispatch(setBackDropState(generalsEnums.trueBoolean));

    const fetchMailTemplates = async () => {
      const response = await MailShotsAPI.getMailTemplates();
      const { statusCode, data } = response;
      setTemplates(data);
      dispatch(setMailTemplates(data));
      dispatch(setBackDropState(generalsEnums.falseBoolean));
    };

    if (mailTemplates.length === generalsEnums.zeroNumber) {
      fetchMailTemplates();
    } else {
      setTemplates(mailTemplates);
      dispatch(setBackDropState(generalsEnums.falseBoolean));
    }

    fetchMailTemplates();
  }, [mailTemplates]);

  return (
    <Box
      component="div"
      className={classes.root}
      sx={{
        padding: rootPadding,
      }}
    >
      <Box component="div" className={classes.header}>
        <Font
          text={t('EmailsSettings.title')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size24.size}
          lineHeight={generalsEnums.fontSizes.size24.lineHeight}
          isHead={true}
        />

        <Box
          component="div"
          sx={{
            width: '180px',
          }}
        >
          <TsiButton
            type={generalsEnums.buttonTypes.save}
            handleAction={() => {}}
            text={t('Generals.saveChanges')}
            isDisabled={isDisabled}
          />
        </Box>
      </Box>

      <Box component="div" className={classes.mailsBox}>
        <Font
          text={t('EmailsSettings.templates')}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size16.size}
          lineHeight={generalsEnums.fontSizes.size16.lineHeight}
          isHead={generalsEnums.trueBoolean}
        />

        <Grid
          container
          spacing={2}
          sx={{
            marginTop: '8px',
          }}
        >
          {templates.map((template) => (
            <Grid item xs={12} sm={6} key={template._id}>
              <Paper className={classes.item}>
                <Button
                  variant="text"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClickNameTemplate(template);
                  }}
                  sx={{
                    padding: '0px',
                  }}
                >
                  <Font
                    text={language === generalsEnums.languages.spanish ? template.nameEs : template.nameEn}
                    color={colors.TSADMIN_BLUE}
                    size={textTemplateSize}
                    lineHeight={textTemplateLineHeight}
                    isHead={generalsEnums.falseBoolean}
                  />
                </Button>

                <SwitchAttom itemChecked={template.available} itemChange={() => handleTemplateSwitch(template._id)} />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default TransactionalEmails;
