import React from 'react';
import uuid from 'react-uuid';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { Box } from '@mui/material';

import CancelOrderModal from './CancelOrder.modal';
import CancellationTab from './Tabs/CancellationTab';
import ClaimsTab from './Tabs/ClaimsTab';
import generalsEnums from '../../../utils/Generals';
import Orders from '../../../entities/Orders';
import GoBackButton from '../../../Components/Generals/Figma/Molecules/GoBackButton';
import Font from '../../../Components/Generals/Fonts/Font';
import BadgeAttom from '../../../Components/Generals/Figma/Atoms/BadgeAttom';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import TabsAttoms from '../../../Components/Generals/Figma/Atoms/TabsAttoms';
import StepperAttoms from '../../../Components/Generals/Figma/Atoms/StepperAttoms';
import OrderGeneralTab from './Tabs/OrderGeneralTab';
import { formatProductQuantity, getDisableButtonByOs } from '../../../utils/FormatData';
import { selectPortalLanguage } from '../../../Redux/Slices/Portal.Slices';
import { useStyles, colors } from './Order.styles';
import {
  setBackDropState,
  setOrderNumber,
  setTvhCreateOrderState,
  selectUpdateOrder,
  setUpdateOrder,
  setIsToastSuccess,
  setToastSuccessMessage,
} from '../../../Redux/Slices/Navigation.Slices';

const Order = ({ formik }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { orderNumber } = useParams();
  const { t } = useTranslation('global');
  const ordersAPI = new Orders();

  const [orderStatusCodeName, setOrderStatusCodeName] = React.useState(generalsEnums.emptyString);
  const [orderDate, setOrderDate] = React.useState(generalsEnums.emptyString);
  const [products, setProducts] = React.useState(generalsEnums.emptyArray);
  const [orderSubTotal, setOrderSubTotal] = React.useState(generalsEnums.zeroNumber);
  const [orderDelivery, setOrderDelivery] = React.useState(generalsEnums.zeroNumber);
  const [orderTaxes, setOrderTaxes] = React.useState(generalsEnums.zeroNumber);
  const [orderTotal, setOrderTotal] = React.useState(generalsEnums.zeroNumber);
  const [productsLength, setProductsLength] = React.useState(generalsEnums.emptyString);
  const [deliveryName, setDeliveryName] = React.useState(generalsEnums.emptyString);
  const [billingName, setBillingName] = React.useState(generalsEnums.emptyString);
  const [deliveryContact, setDeliveryContact] = React.useState(generalsEnums.emptyString);
  const [billingContact, setBillingContact] = React.useState(generalsEnums.emptyString);
  const [deliveryStreet, setDeliveryStreet] = React.useState(generalsEnums.emptyString);
  const [billingStreet, setBillingStreet] = React.useState(generalsEnums.emptyString);
  const [deliveryCity, setDeliveryCity] = React.useState(generalsEnums.emptyString);
  const [billingCity, setBillingCity] = React.useState(generalsEnums.emptyString);
  const [deliveryComments, setDeliveryComments] = React.useState(generalsEnums.emptyString);
  const [userPhone, setUserPhone] = React.useState(generalsEnums.emptyString);
  const [userName, setUserName] = React.useState(generalsEnums.emptyString);
  const [userMail, setUserMail] = React.useState(generalsEnums.emptyString);
  const [orderIP, setOrderIP] = React.useState(generalsEnums.emptyString);
  const [buttonDisabled, setButtonDisabled] = React.useState(generalsEnums.trueBoolean);
  const [tvhOrderId, setTvhOrderId] = React.useState(generalsEnums.emptyString);
  const [tvhCreateOrderButtonDisabled, setTvhCreateOrderButtonDisabled] = React.useState(generalsEnums.falseBoolean);
  const [logHistory, setLogHistory] = React.useState(generalsEnums.emptyArray);
  const [productsItems, setProductsItems] = React.useState(generalsEnums.emptyString);
  const [orderStatusData, setOrderStatusData] = React.useState(generalsEnums.emptyObject);
  const [displayCancelButton, setDisplayCancelButton] = React.useState(generalsEnums.falseBoolean);
  const [orderId, setOrderId] = React.useState(generalsEnums.emptyString);
  const [claimData, setClaimData] = React.useState(generalsEnums.emptyObject);
  const [cancellationReason, setCancellationReason] = React.useState(generalsEnums.emptyObject);
  const [CancelOrderModalOpen, setCancelOrderModalOpen] = React.useState(generalsEnums.falseBoolean);

  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);
  const rootPadding = IpadAir || IpadMini ? generalsEnums.paddings.root.tablet : generalsEnums.paddings.root.desktop;
  const text20Size = IpadAir || IpadMini ? generalsEnums.fontSizes.size14.size : generalsEnums.fontSizes.size20.size;
  const text20LineHeight =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size14.lineHeight : generalsEnums.fontSizes.size20.lineHeight;
  const textSizeButton =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size12.size : generalsEnums.fontSizes.size14.size;
  const textLineHeightButton =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size12.lineHeight : generalsEnums.fontSizes.size14.lineHeight;
  const text12Size = IpadAir || IpadMini ? generalsEnums.fontSizes.size10.size : generalsEnums.fontSizes.size12.size;
  const text12LineHeight =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size10.lineHeight : generalsEnums.fontSizes.size12.lineHeight;
  const text18Size = IpadAir || IpadMini ? generalsEnums.fontSizes.size14.size : generalsEnums.fontSizes.size18.size;
  const text18LineHeight =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size14.lineHeight : generalsEnums.fontSizes.size18.lineHeight;
  const text16Size = IpadAir || IpadMini ? generalsEnums.fontSizes.size14.size : generalsEnums.fontSizes.size16.size;
  const text16LineHeight =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size14.lineHeight : generalsEnums.fontSizes.size16.lineHeight;
  const isHorizontal = useMediaQuery('(orientation: landscape) and (min-width: 768px)');
  const updateOrder = useSelector(selectUpdateOrder);
  const languagePortal = useSelector(selectPortalLanguage);

  const handleCancelOrder = async (e) => {
    e.preventDefault();
    setCancelOrderModalOpen(true);
  };

  const handleCancelAction = async (inputValue) => {
    setCancelOrderModalOpen(generalsEnums.falseBoolean);
    dispatch(setBackDropState(generalsEnums.trueBoolean));

    const adminUpadteOrderStatusBody = {
      ids: [orderId],
      os: generalsEnums.orderStatus.os6,
      cancellationReason: inputValue,
    };
    const adminUpadteOrderStatusRes = await ordersAPI.adminUpadteOrderStatus(adminUpadteOrderStatusBody);
    const { messages } = adminUpadteOrderStatusRes;
    dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
    dispatch(setToastSuccessMessage(messages[languagePortal]));
    dispatch(setBackDropState(generalsEnums.falseBoolean));
    dispatch(setUpdateOrder(generalsEnums.trueBoolean));
  };

  const handleAproveOrder = (e) => {
    e.preventDefault();
    dispatch(setOrderNumber(orderNumber));
    dispatch(setTvhCreateOrderState(true));
  };

  const formatDate = (dateString, languagePortal) => {
    const date = parseISO(dateString);
    const dateFormatEs = format(date, 'EEEE dd/MM/yyyy, HH:mm:ss', { locale: es });
    const dateFormatEn = format(date, 'EEEE dd/MM/yyyy, HH:mm:ss', { locale: enUS });

    return languagePortal === generalsEnums.languages.spanish ? dateFormatEs : dateFormatEn;
  };

  const tabs = [
    {
      id: uuid(),
      label: t('Order.tabs.general'),
      value: 0,
      children: (
        <OrderGeneralTab
          productsOrder={products}
          orderSubTotal={orderSubTotal}
          orderDelivery={orderDelivery}
          orderTaxes={orderTaxes}
          orderTotal={orderTotal}
          isHorizontal={isHorizontal}
          orderDate={orderDate}
          text12Size={text12Size}
          text12LineHeight={text12LineHeight}
          text20Size={text20Size}
          text20LineHeight={text20LineHeight}
          text18Size={text18Size}
          text18LineHeight={text18LineHeight}
          text16Size={text16Size}
          text16LineHeight={text16LineHeight}
          productsLength={productsLength}
          deliveryName={deliveryName}
          billingName={billingName}
          deliveryContact={deliveryContact}
          billingContact={billingContact}
          deliveryStreet={deliveryStreet}
          billingStreet={billingStreet}
          deliveryCity={deliveryCity}
          billingCity={billingCity}
          contactTitle={t('Order.contactTitle')}
          userName={userName}
          userPhone={userPhone}
          userMail={userMail}
          orderIP={orderIP}
          logHistory={logHistory}
          languagePortal={languagePortal}
          productsItems={productsItems}
          deliveryComments={deliveryComments}
          orderNumber={orderNumber}
        />
      ),
    },
    {
      id: uuid(),
      label: t('Order.tabs.claims'),
      value: 1,
      children: (
        <ClaimsTab
          claimData={claimData}
          text20Size={text20Size}
          text20LineHeight={text20LineHeight}
          text18Size={text18Size}
          text18LineHeight={text18LineHeight}
          text16Size={text16Size}
          text16LineHeight={text16LineHeight}
        />
      ),
    },
    {
      id: uuid(),
      label: t('Order.tabs.cancellations'),
      value: 2,
      children: (
        <CancellationTab
          cancellationReason={cancellationReason}
          text20Size={text20Size}
          text20LineHeight={text20LineHeight}
          text18Size={text18Size}
          text18LineHeight={text18LineHeight}
          text16Size={text16Size}
          text16LineHeight={text16LineHeight}
        />
      ),
    },
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      dispatch(setBackDropState(true));

      try {
        const response = await ordersAPI.getOrder(orderNumber);
        const { statusCode, order } = response;

        if (statusCode === generalsEnums.statusCodes.ok) {
          const {
            orderCreateDate,
            subTotal,
            orderDelivery,
            orderTaxes,
            orderTotal,
            orderProducts,
            tvhOrderId,
            orderStatus: orderStatusData,
            _id: orderId,
            claimData,
            cancellationReason,
          } = order.order;
          const { address, billingAddress, user, logs, orderStatus } = order;
          const { name: orderStatusName } = orderStatus;

          const test = formatProductQuantity(orderProducts);
          const productsItemsInfo = `${test.productsQ} ${t('Order.p')}, ${test.itemsQ} ${t('Order.i')}`;
          setProductsItems(productsItemsInfo);

          setDisplayCancelButton(getDisableButtonByOs(orderStatusData));
          setOrderStatusData(orderStatus);
          setLogHistory(logs);
          setOrderStatusCodeName(orderStatusName);
          setOrderDate(formatDate(orderCreateDate, languagePortal));
          setProducts(orderProducts);
          setOrderSubTotal(subTotal);
          setOrderDelivery(orderDelivery);
          setOrderTaxes(orderTaxes);
          setOrderTotal(orderTotal);
          setOrderId(orderId);

          if (cancellationReason) {
            setCancellationReason(cancellationReason);
          }

          setProductsLength(`${orderProducts.length} ${t('Order.products')}`);
          setDeliveryName(`${address.name} ${address.lastName}`);
          setBillingName(`${billingAddress.name} ${billingAddress.lastName}`);
          setDeliveryContact(
            `${address.workEnterprise}${address.workEnterprise !== generalsEnums.emptyString ? ', ' : ''} ${
              address.phone
            }`
          );
          setBillingContact(
            `${billingAddress.workEnterprise}${
              billingAddress.workEnterprise !== generalsEnums.emptyString ? ', ' : ''
            } ${billingAddress.phone}`
          );
          setDeliveryStreet(
            `${address.street}${address.apartment !== generalsEnums.emptyString ? ', ' : ''} ${address.apartment}`
          );
          setBillingStreet(
            `${billingAddress.street}${billingAddress.apartment !== generalsEnums.emptyString ? ', ' : ''} ${
              billingAddress.apartment
            }`
          );
          setDeliveryCity(`${address.city}, ${address.state}, ${address.zipCode}, ${address.country}`);
          setBillingCity(
            `${billingAddress.city}, ${billingAddress.state}, ${billingAddress.zipCode}, ${billingAddress.country}`
          );
          setDeliveryComments(address.adititionalInfo);
          setUserName(`${user.name} ${user.lastName}`);
          setUserPhone(user.phoneNumber);
          setUserMail(user.email);
          setOrderIP(generalsEnums.ipSample);
          setButtonDisabled(generalsEnums.falseBoolean);

          if (tvhOrderId) {
            setTvhOrderId(tvhOrderId);
            setTvhCreateOrderButtonDisabled(generalsEnums.trueBoolean);
          }

          if (orderStatusData === generalsEnums.orderStatus.os6) {
            setTvhCreateOrderButtonDisabled(generalsEnums.trueBoolean);
          }
          if (claimData) {
            setClaimData(claimData);
          }
          dispatch(setUpdateOrder(false));
        }
      } catch (error) {
      } finally {
        dispatch(setBackDropState(false));
      }
    };

    fetchData();
  }, [orderNumber, updateOrder]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.root}
      sx={{
        padding: rootPadding,
      }}
    >
      <Box component={generalsEnums.BoxDiv}>
        <GoBackButton />
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.header}
        sx={{
          flexDirection: isHorizontal ? 'row' : 'column-reverse',
          alignItems: isHorizontal ? 'center' : 'flex-start',
          justifyContent: isHorizontal ? 'space-between' : 'flex-start',
          gap: isHorizontal ? '0px' : '20px',
          marginTop: isHorizontal ? '0px' : '20px',
        }}
      >
        <Box component={generalsEnums.BoxDiv} className={classes.rowNormal} sx={{ gap: '20px' }}>
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Font
              text={`${t('Order.orderNumber')}${orderNumber}`}
              color={colors.BLACK}
              size={text20Size}
              lineHeight={text20LineHeight}
              isHead={true}
              isCenter={false}
            />

            {tvhOrderId !== generalsEnums.emptyString ? (
              <Font
                text={`${t('Order.tvhOrderId')}${tvhOrderId}`}
                color={colors.BLACK}
                size={text20Size}
                lineHeight={text20LineHeight}
                isHead={true}
                isCenter={false}
              />
            ) : null}
          </Box>

          <BadgeAttom kind={generalsEnums.badge.gray} text={orderStatusCodeName} />
        </Box>

        <Box component={generalsEnums.BoxDiv} className={classes.rowNormal} sx={{ width: 'auto', gap: '20px' }}>
          <Box component={generalsEnums.BoxDiv} sx={{ width: '160px' }}>
            {displayCancelButton ? (
              <TsiButton
                type={generalsEnums.buttonTypes.black}
                handleAction={handleCancelOrder}
                text={t('Order.cancelOrder')}
                isDisabled={buttonDisabled}
                size={textSizeButton}
                lineHeight={textLineHeightButton}
              />
            ) : null}
          </Box>

          <Box component={generalsEnums.BoxDiv} sx={{ width: '180px' }}>
            <TsiButton
              type={generalsEnums.buttonTypes.save}
              handleAction={handleAproveOrder}
              text={t('Order.aproveOrder')}
              size={textSizeButton}
              lineHeight={textLineHeightButton}
              isDisabled={tvhCreateOrderButtonDisabled}
            />
          </Box>
        </Box>
      </Box>

      <Font
        text={orderDate}
        color={colors.TEXT_GRAY_666}
        size={text12Size}
        lineHeight={text12LineHeight}
        isHead={true}
        isCenter={false}
      />

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '400px',
          marginTop: '48px',
        }}
      >
        <StepperAttoms w="100%" orderStatusData={orderStatusData} />
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '100%',
          marginTop: '48px',
        }}
      >
        <TabsAttoms itemTabs={tabs} />
      </Box>

      <CancelOrderModal
        CancelOrderModalOpen={CancelOrderModalOpen}
        setCancelOrderModalOpen={setCancelOrderModalOpen}
        handleCancelAction={handleCancelAction}
      />
    </Box>
  );
};

export default Order;
