import React from 'react';
import { Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';

import { selectFullName } from '../../Redux/Slices/User.Slices';

const NavBarUserChip = ({ classes }) => {
  const name = useSelector(selectFullName);

  return (
    <Box component="div" className={classes.NavBarUserChipRoot}>
      <AccountCircleIcon />
      <Box component="div" className={classes.NavBarUserInfo}>
        <Box component="div" className={classes.NavBarUserInfoItem}>
          <Typography
            variant="body1"
            style={{
              fontSize: '12px',
              lineHeight: '12px',
            }}
            className="text-black"
          >
            {name}
          </Typography>
        </Box>

        <Box component="div" className={classes.NavBarUserInfoItem}>
          <Typography
            variant="body1"
            style={{
              fontSize: '11px',
            }}
            className="text-blue-gray"
          >
            Admin
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NavBarUserChip;
