import React from 'react';
import { Checkbox } from '@mui/material';

import * as colors from '../../../../assets/GlobalColors';

const CheckboxAttom = ({ handleSelect, isChecked }) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleSelect(event);
  };

  React.useEffect(() => {
    if (isChecked !== undefined) {
      setChecked(isChecked);
    }
  }, [isChecked]);

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      sx={{ color: colors.GREEN_MAIN, '&.Mui-checked': { color: colors.GREEN_MAIN } }}
    />
  );
};

export default CheckboxAttom;
