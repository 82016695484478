import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import MailShots from '../../../../entities/MailShots';
import Template from './Template';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import GoBackButton from '../../../../Components/Generals/Figma/Molecules/GoBackButton';
import { selectEmail } from '../../../../Redux/Slices/User.Slices';
import { selectTemplate, setBackDropState } from '../../../../Redux/Slices/Navigation.Slices';
import { useStyles, colors } from './styles';

const EmailTemplate = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const template = useSelector(selectTemplate);
  const { t } = useTranslation('global');
  const mailShotsAPI = new MailShots();

  const [templateName, setTemplateName] = React.useState(generalsEnums.emptyString);
  const [templateData, setTemplateData] = React.useState(generalsEnums.emptyObject);
  const [tamplateLanguage, setTamplateLanguage] = React.useState(generalsEnums.languages.spanish);

  const language = localStorage.getItem('language');
  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);
  const rootPadding = IpadAir || IpadMini ? generalsEnums.paddings.root.tablet : generalsEnums.paddings.root.desktop;
  const text24Size = IpadAir || IpadMini ? generalsEnums.fontSizes.size22.size : generalsEnums.fontSizes.size24.size;
  const text24LineHeight =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size22.lineHeight : generalsEnums.fontSizes.size24.lineHeight;
  const textSizeButton =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size14.size : generalsEnums.fontSizes.size16.size;
  const textLineHeightButton =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size14.lineHeight : generalsEnums.fontSizes.size16.lineHeight;
  const OrderSummaryCardWidth = IpadAir || IpadMini ? '550px' : '850px';
  const isHorizontal = useMediaQuery('(orientation: landscape) and (min-width: 768px)');
  const mail = useSelector(selectEmail);

  const handleSendEmail = async () => {
    dispatch(setBackDropState(true));

    switch (templateData.templateName) {
      case 'purchaseConfirmation':
        const purchaseConfirmationBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
          orderDate: '21/07/2023',
          products: [
            {
              name: 'strip',
              q: '1',
              qPrice: '$1,000.00',
            },
            {
              name: 'Wheel Replacement',
              q: '1',
              qPrice: '$1,000.00',
            },
            {
              name: 'Toolbox',
              q: '1',
              qPrice: '$1,000.00',
            },
          ],
          total: '$4,000.00 MXN',
          address: 'Calle Pedro Moreno 397, Guadalajara, Jalisco, C.P. 44100',
        };
        await mailShotsAPI.purchaseConfirmation(purchaseConfirmationBody);
        dispatch(setBackDropState(false));
        break;

      case 'shippingConfirmation':
        const shippingConfirmationBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
          shippingDate: '21/07/2023',
          shippingProvider: 'DHL',
          trackingNumber: '6510668684612',
        };
        await mailShotsAPI.shippingConfirmation(shippingConfirmationBody);
        dispatch(setBackDropState(false));
        break;

      case 'trackingUpdate':
        const trackingUpdateBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
          state: 'En camino',
          currentLocation: 'CDMX',
          updateDate: '25/07/23',
        };
        await mailShotsAPI.trackingUpdate(trackingUpdateBody);
        dispatch(setBackDropState(false));
        break;

      case 'deliveryConfirmation':
        const deliveryConfirmationBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
          deliveryDate: '31/07/23',
          whoReceives: 'Juan Martinez López',
          deliveryAddress: 'Calle Pedro Moreno 397, Guadalajara, Jalisco, C.P. 44100',
        };
        await mailShotsAPI.deliveryConfirmation(deliveryConfirmationBody);
        dispatch(setBackDropState(false));
        break;

      case 'claimOnOrder':
        const claimOnOrderBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
        };
        await mailShotsAPI.claimOnOrder(claimOnOrderBody);
        dispatch(setBackDropState(false));
        break;

      case 'complaintConfirm':
        const complaintConfirmBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
          total: '$1,500.00',
        };
        await mailShotsAPI.complaintConfirm(complaintConfirmBody);
        dispatch(setBackDropState(false));
        break;

      case 'complaintSolution':
        const complaintSolutionBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
        };
        await mailShotsAPI.urlcomplaintSolution(complaintSolutionBody);
        dispatch(setBackDropState(false));
        break;

      case 'complaintResponse':
        const complaintResponseBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
        };
        await mailShotsAPI.complaintResponse(complaintResponseBody);
        dispatch(setBackDropState(false));
        break;

      case 'paymentDeclined':
        const paymentDeclinedBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
        };
        await mailShotsAPI.paymentDeclined(paymentDeclinedBody);
        dispatch(setBackDropState(false));
        break;

      case 'shippingUpdate':
        const shippingUpdateBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          order: '000378',
          partialShipmentDate: '24/07/2023',
          shippingProvider: 'DHL',
          trackingNumber: '78892731267200',
          productsShipped: [
            {
              name: 'strip',
              q: '1',
              qPrice: '$1,000.00',
            },
            {
              name: 'Wheel Replacement',
              q: '1',
              qPrice: '$1,000.00',
            },
            {
              name: 'Toolbox',
              q: '1',
              qPrice: '$1,000.00',
            },
          ],
          pendingProducts: [
            {
              name: 'strip',
              q: '1',
              qPrice: '$1,000.00',
            },
            {
              name: 'Wheel Replacement',
              q: '1',
              qPrice: '$1,000.00',
            },
            {
              name: 'Toolbox',
              q: '1',
              qPrice: '$1,000.00',
            },
          ],
        };
        await mailShotsAPI.shippingUpdate(shippingUpdateBody);
        dispatch(setBackDropState(false));
        break;

      case 'abandonedCart':
        const abandonedCartBody = {
          language: tamplateLanguage,
          emailTo: mail,
          name: 'John Doe',
          products: [
            {
              name: 'strip',
              q: '1',
              qPrice: '$1,000.00',
            },
            {
              name: 'Wheel Replacement',
              q: '1',
              qPrice: '$1,000.00',
            },
            {
              name: 'Toolbox',
              q: '1',
              qPrice: '$1,000.00',
            },
          ],
        };
        await mailShotsAPI.abandonedCart(abandonedCartBody);
        dispatch(setBackDropState(false));
        break;

      default:
        dispatch(setBackDropState(false));
        break;
    }
  };

  React.useEffect(() => {
    if (Object.keys(template).length > 0) {
      setTemplateName(language === generalsEnums.languages.spanish ? template.nameEs : template.nameEn);
      setTemplateData(template);
    }
  }, [template]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.root}
      sx={{
        padding: rootPadding,
      }}
    >
      <Box component={generalsEnums.BoxDiv}>
        <GoBackButton />
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.header}
        sx={{
          flexDirection: isHorizontal ? 'row' : 'column-reverse',
          alignItems: isHorizontal ? 'center' : 'flex-start',
          justifyContent: isHorizontal ? 'space-between' : 'flex-start',
          gap: isHorizontal ? '0px' : '20px',
          marginTop: isHorizontal ? '0px' : '20px',
        }}
      >
        <Box component={generalsEnums.BoxDiv} className={classes.rowNormal} sx={{ gap: '5px' }}>
          <Font
            text={t('EmailTemplate.title')}
            color={colors.BLACK}
            size={text24Size}
            lineHeight={text24LineHeight}
            isHead={true}
            isCenter={false}
          />

          <Font
            text={`"${templateName}"`}
            color={colors.GREEN_MAIN}
            size={text24Size}
            lineHeight={text24LineHeight}
            isHead={true}
            isCenter={false}
          />
        </Box>

        <Box component={generalsEnums.BoxDiv} className={classes.rowNormal} sx={{ gap: '5px' }}>
          <TsiButton
            type={generalsEnums.buttonTypes.save}
            handleAction={handleSendEmail}
            text={t('EmailTemplate.button')}
            isDisabled={false}
            size={textSizeButton}
            lineHeight={textLineHeightButton}
          />
        </Box>
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '100%',
          height: '100%',
          marginTop: '44px',
        }}
      >
        <Template
          generalsEnums={generalsEnums}
          colors={colors}
          templateData={templateData}
          tamplateLanguage={tamplateLanguage}
          setTamplateLanguage={setTamplateLanguage}
        />
      </Box>
    </Box>
  );
};

export default EmailTemplate;
