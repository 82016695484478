import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import generalsEnums from '../../../../../utils/Generals';
import LogBadge from './LogBadge';
import Font from '../../../../../Components/Generals/Fonts/Font';
import { selectPortalLanguage } from '../../../../../Redux/Slices/Portal.Slices';
import useStyles from './styles';
import * as colors from '../../../../../assets/GlobalColors';

const NotificationCard = ({
  logItem,
  text18Size,
  text18LineHeight,
  text12Size,
  text12LineHeight,
  text16Size,
  text16LineHeight,
}) => {
  const classes = useStyles();

  const [logDate, setLogDate] = React.useState(generalsEnums.emptyString);
  const [logTitle, setLogTitle] = React.useState(generalsEnums.emptyString);
  const [logText, setLogText] = React.useState(generalsEnums.emptyString);

  const portalLanguage = useSelector(selectPortalLanguage);

  React.useEffect(() => {
    const { date, en, es } = logItem;
    setLogDate(date);

    const languageObject = portalLanguage === generalsEnums.languages.english ? en : es;
    const { text, title } = languageObject;

    setLogTitle(title);
    setLogText(text);
  }, [logItem, portalLanguage]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.logHistoryCard}
      sx={{
        width: '100%',
      }}
    >
      <LogBadge kind={logItem.kind} />

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.logHistoryText}
        sx={{
          borderRadius: '8px',
          border: `1px solid ${colors.BORDER_GRAY_1}}`,
        }}
      >
        <Box component="div" className={classes.logHistoryTextTitle}>
          <Font
            text={logTitle}
            color={colors.BLACK}
            size={text18Size}
            lineHeight={text18LineHeight}
            isHead={true}
            isCenter={false}
          />

          <Font
            text={logDate}
            color={colors.TEXT_GRAY_666}
            size={text12Size}
            lineHeight={text12LineHeight}
            isHead={true}
            isCenter={false}
          />
        </Box>

        <Font
          text={logText}
          color={colors.BLACK}
          size={text16Size}
          lineHeight={text16LineHeight}
          isHead={false}
          isCenter={false}
        />
      </Box>
    </Box>
  );
};

export default NotificationCard;
