import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GetAppIcon from '@mui/icons-material/GetApp';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import TimelineIcon from '@mui/icons-material/Timeline';
import AppsIcon from '@mui/icons-material/Apps';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import generalsEnums from '../../utils/Generals';
import Icons from '../../Components/Generals/Figma/Icons/Icons';
import Paths from '../../utils/enums/Paths';
import OrdersIcon from '../../assets/Icons/OrdersIcon.png';
import TsiLogo from '../../Components/Generals/TsiLogo';
import NavBarButton from '../../Components/TsAdmin/NavBarButton';
import NavBarUserChip from '../../Components/TsAdmin/NavBarUserChip';
import { useStyles } from './AdminNavBar.styles';
import { fetchSignout, selectEmail } from '../../Redux/Slices/User.Slices';

const AdminNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [pathSelected, setPathSelected] = React.useState(0);
  const [showChild, setShowChild] = React.useState(false);
  const [adminPath, setAdminPath] = React.useState({});
  const [childPathSelected, setChildPathSelected] = React.useState(0);

  const email = useSelector(selectEmail);

  const tsAdminPath = [
    {
      path: Paths.admin.Home,
      name: t('admin.AdminNavBar.text-home'),
      value: 1,
      icon: <HomeIcon className={classes.ordersIcon} />,
      displayChild: false,
      childs: [],
    },
    {
      path: Paths.admin.Products.view,
      name: t('admin.AdminNavBar.text-products'),
      value: 2,
      icon: <StoreIcon className={classes.ordersIcon} />,
      displayChild: true,
      childs: [
        {
          path: Paths.admin.Products.view,
          name: t('admin.AdminNavBar.view'),
          value: 21,
          icon: <VisibilityIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Products.add,
          name: t('admin.AdminNavBar.add'),
          value: 22,
          icon: <AddIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Products.search,
          name: t('admin.AdminNavBar.search'),
          value: 23,
          icon: <SearchIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Products.import,
          name: t('admin.AdminNavBar.import'),
          value: 24,
          icon: <AttachFileIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Products.export,
          name: t('admin.AdminNavBar.export'),
          value: 25,
          icon: <GetAppIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Products.categories,
          name: t('admin.AdminNavBar.categories'),
          value: 26,
          icon: <></>,
        },
      ],
    },
    {
      path: Paths.admin.Orders.view,
      name: t('admin.AdminNavBar.text-orders'),
      value: 4,
      icon: <img src={OrdersIcon} alt="Orders" className={classes.ordersIcon} />,
      displayChild: true,
      childs: [
        {
          path: Paths.admin.Orders.view,
          name: t('admin.AdminNavBar.view'),
          value: 41,
          icon: <VisibilityIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Orders.claims,
          name: t('admin.AdminNavBar.claims'),
          value: 42,
          icon: <VisibilityIcon className={classes.ordersIcon} />,
        },
        // {
        //   path: Paths.admin.Orders.add,
        //   name: t('admin.AdminNavBar.add'),
        //   value: 42,
        //   icon: <AddIcon className={classes.ordersIcon} />,
        // },
        // {
        //   path: Paths.admin.Orders.search,
        //   name: t('admin.AdminNavBar.search'),
        //   value: 43,
        //   icon: <SearchIcon className={classes.ordersIcon} />,
        // },
        // {
        //   path: Paths.admin.Orders.export,
        //   name: t('admin.AdminNavBar.export'),
        //   value: 45,
        //   icon: <GetAppIcon className={classes.ordersIcon} />,
        // },
        // {
        //   path: Paths.admin.Orders.delivery,
        //   name: t('admin.AdminNavBar.delivery'),
        //   value: 46,
        //   icon: <></>,
        // },
        // {
        //   path: Paths.admin.Orders.followship,
        //   name: t('admin.AdminNavBar.followship'),
        //   value: 47,
        //   icon: <></>,
        // },
        {
          path: Paths.admin.Orders.status,
          name: t('admin.AdminNavBar.status'),
          value: 48,
          icon: <></>,
        },
      ],
    },
    {
      path: Paths.admin.Clients.view,
      name: t('admin.AdminNavBar.text-clients'),
      value: 5,
      icon: <RecentActorsIcon className={classes.ordersIcon} />,
      icon: <StorefrontIcon className={classes.ordersIcon} />,
      displayChild: true,
      childs: [
        {
          path: Paths.admin.Clients.view,
          name: t('admin.AdminNavBar.view'),
          value: 51,
          icon: <VisibilityIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Clients.add,
          name: t('admin.AdminNavBar.add'),
          value: 52,
          icon: <AddIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Clients.search,
          name: t('admin.AdminNavBar.search'),
          value: 53,
          icon: <SearchIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Clients.import,
          name: t('admin.AdminNavBar.import'),
          value: 54,
          icon: <AttachFileIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Clients.export,
          name: t('admin.AdminNavBar.export'),
          value: 55,
          icon: <GetAppIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Clients.groups,
          name: t('admin.AdminNavBar.groups'),
          value: 56,
          icon: <></>,
        },
      ],
    },
    {
      path: Paths.admin.Sideboard.view,
      name: t('admin.AdminNavBar.Sideboard.view'),
      value: 6,
      icon: <StorefrontIcon className={classes.ordersIcon} />,
      displayChild: true,
      childs: [
        {
          path: Paths.admin.Sideboard.LogoAndFavicon,
          name: t('admin.AdminNavBar.Sideboard.logoAndFavicon'),
          value: 61,
          icon: <></>,
        },
      ],
    },
    {
      path: Paths.admin.Marketing.TransactionalEmails,
      name: t('admin.AdminNavBar.text-marketing'),
      value: 7,
      icon: <Icons kind={generalsEnums.icons.kind.shopTwo} c={classes.ordersIcon} />,
      displayChild: true,
      childs: [
        {
          path: Paths.admin.Marketing.TransactionalEmails,
          name: t('EmailsSettings.title'),
          value: 71,
          icon: <VisibilityIcon className={classes.ordersIcon} />,
        },
      ],
    },
    {
      path: Paths.admin.Home,
      name: t('admin.AdminNavBar.text-analysis'),
      value: 8,
      icon: <TimelineIcon className={classes.ordersIcon} />,
      displayChild: true,
      childs: [
        {
          path: Paths.admin.Home,
          name: t('admin.AdminNavBar.view'),
          value: 81,
          icon: <VisibilityIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Home,
          name: t('admin.AdminNavBar.add'),
          value: 82,
          icon: <AddIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Home,
          name: t('admin.AdminNavBar.search'),
          value: 83,
          icon: <SearchIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Home,
          name: t('admin.AdminNavBar.import'),
          value: 84,
          icon: <AttachFileIcon className={classes.ordersIcon} />,
        },
        {
          path: Paths.admin.Home,
          name: t('admin.AdminNavBar.export'),
          value: 85,
          icon: <GetAppIcon className={classes.ordersIcon} />,
        },
      ],
    },
    {
      path: Paths.admin.Integrations.integrations,
      name: t('admin.AdminNavBar.integrations.text-integrations'),
      value: 9,
      icon: <AppsIcon className={classes.ordersIcon} />,
      displayChild: true,
      childs: [
        {
          path: Paths.admin.Integrations.tvh,
          name: t('admin.AdminNavBar.integrations.tvh'),
          value: 92,
          icon: <></>,
          childs: [
            {
              path: Paths.admin.Tvh.ImportFiles,
              name: t('admin.AdminNavBar.integrations.tvhChilds.import'),
              value: 921,
              icon: <></>,
            },
            {
              path: Paths.admin.Tvh.FindProduct,
              name: t('admin.AdminNavBar.integrations.tvhChilds.find'),
              value: 922,
              icon: <></>,
            },
            {
              path: Paths.admin.Tvh.OrdersInProcess,
              name: t('admin.AdminNavBar.integrations.tvhChilds.orders'),
              value: 923,
              icon: <></>,
            },
            {
              path: Paths.admin.Tvh.OrdersClosed,
              name: t('admin.AdminNavBar.integrations.tvhChilds.ordersClosed'),
              value: 924,
              icon: <></>,
            },
            {
              path: Paths.admin.Tvh.Tracking,
              name: t('admin.AdminNavBar.integrations.tvhChilds.Tracking'),
              value: 925,
              icon: <></>,
            },
            {
              path: Paths.admin.Tvh.Configuration,
              name: t('admin.AdminNavBar.integrations.tvhChilds.configuration'),
              value: 926,
              icon: <></>,
            },
          ],
        },
        {
          path: Paths.admin.Integrations.stripe,
          name: t('admin.AdminNavBar.integrations.stripe'),
          value: 93,
          icon: <></>,
        },
        {
          path: Paths.admin.Integrations.skydropx,
          name: t('admin.AdminNavBar.integrations.skydropx'),
          value: 94,
          icon: <></>,
        },
      ],
    },
    {
      path: Paths.admin.Security.SecurityDashboard,
      name: t('admin.AdminNavBar.Security'),
      value: 11,
      icon: <Icons kind={generalsEnums.icons.kind.Security} c={classes.ordersIcon} />,
      displayChild: false,
      childs: [],
    },
    {
      path: Paths.admin.Users.MyProfile,
      name: t('admin.AdminNavBar.MyProfile'),
      value: 10,
      icon: <Icons kind={generalsEnums.icons.kind.profile} c={classes.ordersIcon} />,
      displayChild: false,
      childs: [],
    },
  ];

  const tsAdminConfiguration = {
    path: '/ts-admin/configuration',
    name: t('admin.AdminNavBar.text-configuration'),
    value: 3,
    icon: <SettingsIcon />,
  };

  const handlePathSelected = (value) => {
    setPathSelected(value);
    const adminPath = tsAdminPath.find((item) => item.value === value);
    if (adminPath.displayChild === false) {
      navigate(adminPath.path);
    } else {
      setShowChild(adminPath.displayChild);
      setAdminPath(adminPath);
      setChildPathSelected(adminPath.childs[0].value);
      navigate(adminPath.childs[0].path);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    setPathSelected(0);
    setShowChild(false);
    setAdminPath({});
    setChildPathSelected(0);
    navigate(tsAdminPath[0].path);
  };

  const handlePathSelectedChild = (value) => {
    setChildPathSelected(value);

    const path = adminPath.childs.find((item) => item.value === value);
    if (path === undefined) {
      for (let i = 0; i < adminPath.childs.length; i++) {
        const element = adminPath.childs[i];
        if (Object.hasOwnProperty.call(element, 'childs') === true) {
          const path = element.childs.find((item) => item.value === value);
          if (path !== undefined) {
            navigate(path.path);
            break;
          }
        }
      }
    } else {
      navigate(path.path);
    }
  };

  const handlePathSelectedConfiguration = (value) => {
    setPathSelected(value);
    navigate(tsAdminConfiguration.path);
  };

  const handleSignout = () => {
    dispatch(fetchSignout(email));
  };

  const handleSetLocation = (pathValue, pathIndex, childValue) => {
    setPathSelected(pathValue);
    setShowChild(true);
    setAdminPath(tsAdminPath[pathIndex]);
    setChildPathSelected(childValue);
  };

  React.useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case Paths.admin.Home:
        setPathSelected(0);
        setShowChild(false);
        setAdminPath({});
        setChildPathSelected(0);
        break;
      case Paths.admin.Products.view:
        handleSetLocation(2, 1, 21);
        break;
      case Paths.admin.Products.add:
        handleSetLocation(2, 1, 22);
        break;
      case Paths.admin.Products.search:
        handleSetLocation(2, 1, 23);
        break;
      case Paths.admin.Products.import:
        handleSetLocation(2, 1, 24);
        break;
      case Paths.admin.Products.export:
        handleSetLocation(2, 1, 25);
        break;
      case Paths.admin.Products.categories:
        handleSetLocation(2, 1, 26);
        break;
      case Paths.admin.Orders.view:
        handleSetLocation(4, 2, 41);
        break;
      case Paths.admin.Orders.add:
        handleSetLocation(4, 2, 42);
        break;
      case Paths.admin.Orders.search:
        handleSetLocation(4, 2, 43);
        break;
      case Paths.admin.Orders.export:
        handleSetLocation(4, 2, 45);
        break;
      case Paths.admin.Orders.delivery:
        handleSetLocation(4, 2, 46);
        break;
      case Paths.admin.Orders.followship:
        handleSetLocation(4, 2, 47);
        break;
      case Paths.admin.Orders.status:
        handleSetLocation(4, 2, 48);
        break;
      case Paths.admin.Clients.view:
        handleSetLocation(5, 3, 51);
        break;
      case Paths.admin.Clients.add:
        handleSetLocation(5, 3, 52);
        break;
      case Paths.admin.Clients.search:
        handleSetLocation(5, 3, 53);
        break;
      case Paths.admin.Clients.import:
        handleSetLocation(5, 3, 54);
        break;
      case Paths.admin.Clients.export:
        handleSetLocation(5, 3, 55);
        break;
      case Paths.admin.Clients.groups:
        handleSetLocation(5, 3, 56);
        break;
      case Paths.admin.Sideboard.LogoAndFavicon:
        handleSetLocation(6, 4, 61);
        break;
      case Paths.admin.Marketing.TransactionalEmails:
        handleSetLocation(7, 5, 71);
        break;
      case Paths.admin.Integrations.tvh:
        handleSetLocation(9, 7, 92);
        break;
      case Paths.admin.Integrations.stripe:
        handleSetLocation(9, 7, 93);
        break;
      case Paths.admin.Integrations.skydropx:
        handleSetLocation(9, 7, 94);
        break;
      case Paths.admin.Security.SecurityDashboard:
        setPathSelected(11);
        setShowChild(false);
        setAdminPath({});
        setChildPathSelected(0);
        break;

      default:
        break;
    }
  }, [location]);

  return (
    <Box component="div" className={classes.root}>
      <Box
        component="div"
        className={classes.navBar}
        style={{
          display: showChild === false ? 'flex' : 'none',
          transition: 'all 0.5s ease-in-out',
        }}
      >
        <Box component="div" className={classes.navBarItem}>
          <TsiLogo />

          {tsAdminPath.map((item, index) => {
            return (
              <NavBarButton
                key={index}
                value={item.value}
                text={item.name}
                handleOnClick={handlePathSelected}
                isSelected={pathSelected === item.value ? true : false}
                icon={item.icon}
              />
            );
          })}
        </Box>

        <Box component="div" className={classes.navBarItem}>
          <NavBarButton
            value={tsAdminConfiguration.value}
            text={tsAdminConfiguration.name}
            handleOnClick={handlePathSelectedConfiguration}
            isSelected={pathSelected === tsAdminConfiguration.value ? true : false}
            icon={tsAdminConfiguration.icon}
          />

          <NavBarButton
            value={100}
            text={t('admin.AdminNavBar.text-logout')}
            handleOnClick={handleSignout}
            isSelected={pathSelected === 100 ? true : false}
            icon={<ExitToAppIcon />}
          />

          <Box component="div" className={classes.NavBarUserLogout}>
            <NavBarUserChip classes={classes} />
          </Box>
        </Box>
      </Box>

      {Object.keys(adminPath).length !== 0 ? (
        <Box
          component="div"
          className={classes.navBar}
          style={{
            display: showChild === true ? 'flex' : 'none',
            transition: 'all 0.5s ease-in-out',
          }}
        >
          <Box
            component="div"
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Button onClick={handleBack} variant="contained" className={classes.adminPathButton}>
              <ArrowBackIosIcon />
              {adminPath.name}
              {adminPath.icon}
            </Button>

            <Box
              component="div"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {adminPath.childs.map((item, index) => {
                return (
                  <NavBarButton
                    key={index}
                    value={item.value}
                    text={item.name}
                    handleOnClick={handlePathSelectedChild}
                    isSelected={childPathSelected === item.value ? true : false}
                    icon={item.icon}
                    childs={Object.hasOwnProperty.call(item, 'childs') === true ? item.childs : null}
                    childPathSelected={childPathSelected}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : null}

      <Box component="div" className={classes.Outlet}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminNavBar;
