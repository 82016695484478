import React from 'react';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import Paths from '../../../../utils/enums/Paths';
import InputAttom from '../../../../Components/Generals/Figma/Atoms/InputAttom';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import OrdersTable from './OrdersTable';
import TabsAttoms from '../../../../Components/Generals/Figma/Atoms/TabsAttoms';
import AdminScreenTemplate from '../../../../utils/templates/AdminScreen.Template';
import generalsEnums from '../../../../utils/Generals';
import Font from '../../../../Components/Generals/Fonts/Font';
import Orders from '../../../../entities/Orders';
import MailShots from '../../../../entities/MailShots';
import NativeComboBox from '../../../../Components/Generals/NativeComboBox';
import { selectPortalLanguage, selectMailTemplates, setMailTemplates } from '../../../../Redux/Slices/Portal.Slices';
import {
  setBackDropState,
  setToastError,
  setToastSuccessMessage,
  setIsToastSuccess,
} from '../../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../../assets/GlobalColors';

const ViewOrders = ({ isClaims }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('global');
  const OrdersAPI = new Orders();
  const MailShotsAPI = new MailShots();

  const [tabSelected, setTabSelected] = React.useState(generalsEnums.zeroNumber);
  const [originalItems, setOriginalItems] = React.useState(generalsEnums.emptyArray);
  const [items, setItems] = React.useState(generalsEnums.emptyArray);
  const [nextStatusData, setNextStatusData] = React.useState(generalsEnums.emptyArray);
  const [idsSelected, setIdsSelected] = React.useState(generalsEnums.emptyArray);
  const [osSelected, setOsSelected] = React.useState(generalsEnums.emptyString);
  const [reload, setReload] = React.useState(generalsEnums.trueBoolean);
  const [searchInput, setSearchInput] = React.useState(generalsEnums.emptyString);
  const [tabs, setTabs] = React.useState(generalsEnums.emptyArray);
  const [titleText, setTitleText] = React.useState(generalsEnums.emptyString);
  const displayStatusCb = nextStatusData.length > generalsEnums.zeroNumber ? true : false;
  const portalLanguage = useSelector(selectPortalLanguage);
  const mailTemplates = useSelector(selectMailTemplates);

  const handleChangeTab = (tabValue) => {
    setTabSelected(tabValue);
    setReload(generalsEnums.trueBoolean);
    setSearchInput(generalsEnums.emptyString);
  };

  const handleGetOs = () => {
    if (isClaims === generalsEnums.falseBoolean) {
      switch (tabSelected) {
        case 0:
          return 'all';
        case 1:
          return 'os2';
        case 2:
          return 'os3';
        case 3:
          return 'os8';
        case 4:
          return 'os4';
        case 5:
          return 'os5';
        default:
          return 'all';
      }
    } else {
      switch (tabSelected) {
        case 0:
          return 'claims';
        case 1:
          return 'os10';
        case 2:
          return 'os14';
        default:
          return 'claims';
      }
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const ids = items.map((item) => {
        const { order } = item;
        const { order: orderData } = order;
        const { _id } = orderData;

        return _id;
      });

      setIdsSelected(ids);
      return;
    }
    setIdsSelected(generalsEnums.emptyArray);
  };

  const handleSelectOne = (event, _id) => {
    if (idsSelected.indexOf(_id) !== -1) {
      const newIds = idsSelected.filter((item) => item !== _id);
      setIdsSelected(newIds);
      return;
    } else {
      setIdsSelected([...idsSelected, _id]);
    }
  };

  const handleIsIdSelected = (_id) => {
    return idsSelected.indexOf(_id) !== -1;
  };

  const handleOsChange = (e) => {
    const { value } = e.target;
    setOsSelected(value);
  };

  const handleCancelUpdateOs = () => {
    setOsSelected(generalsEnums.emptyString);
    setIdsSelected(generalsEnums.emptyArray);
  };

  const handleSaveUpdateOs = async () => {
    if (idsSelected.length === generalsEnums.zeroNumber && osSelected === generalsEnums.emptyString) {
      dispatch(setToastError(generalsEnums.trueBoolean));
      dispatch(setToastSuccessMessage(t('admin.ViewOrders.errors.emptyAll')));
      return;
    }

    if (idsSelected.length === generalsEnums.zeroNumber) {
      dispatch(setToastError(generalsEnums.trueBoolean));
      dispatch(setToastSuccessMessage(t('admin.ViewOrders.errors.emptyidsSelected')));
      return;
    }

    if (osSelected === generalsEnums.emptyString) {
      dispatch(setToastError(generalsEnums.trueBoolean));
      dispatch(setToastSuccessMessage(t('admin.ViewOrders.errors.emptyOsSelected')));
      return;
    }

    dispatch(setBackDropState(true));

    const adminUpadteOrderStatusBody = {
      ids: idsSelected,
      os: osSelected,
    };

    const adminUpadteOrderStatusRes = await OrdersAPI.adminUpadteOrderStatus(adminUpadteOrderStatusBody);
    const { messages } = adminUpadteOrderStatusRes;
    dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
    dispatch(setToastSuccessMessage(messages[portalLanguage]));
    dispatch(setBackDropState(generalsEnums.falseBoolean));
    handleCancelUpdateOs();
    setSearchInput(generalsEnums.emptyString);
    setReload(generalsEnums.trueBoolean);
  };

  const handleSearch = () => {
    if (searchInput === generalsEnums.emptyString) {
      setItems(originalItems);
      return;
    } else {
      const filteredItems = originalItems.filter((item) => {
        const { searchValue } = item;
        return searchValue.toLowerCase().includes(searchInput.toLowerCase());
      });

      setItems(filteredItems);
    }
  };

  const handleNavigateToOrder = (orderNumber) => {
    navigate(`${Paths.admin.Orders.viewOrder}${orderNumber}`);
  };

  React.useEffect(() => {
    if (searchInput === generalsEnums.emptyString) {
      setItems(originalItems);
      return;
    }
  }, [searchInput]);

  React.useEffect(() => {
    const fetchData = async () => {
      dispatch(setBackDropState(true));
      setItems(generalsEnums.emptyArray);
      setNextStatusData(generalsEnums.emptyArray);
      const adminViewOrdersRes = await OrdersAPI.adminViewOrders(handleGetOs);
      const { data } = adminViewOrdersRes;
      const { orders, nextStatus } = data;
      setOriginalItems(orders);
      setItems(orders);
      if (nextStatus.length > generalsEnums.zeroNumber) setNextStatusData(nextStatus);
      dispatch(setBackDropState(false));
      setReload(generalsEnums.falseBoolean);
      setSearchInput(generalsEnums.emptyString);
    };

    if (reload) {
      fetchData();
    }
  }, [tabSelected, reload]);

  React.useEffect(async () => {
    if (mailTemplates.length === generalsEnums.zeroNumber) {
      const response = await MailShotsAPI.getMailTemplates();
      const { data } = response;
      dispatch(setMailTemplates(data));
    }
  }, [mailTemplates]);

  React.useEffect(() => {
    if (isClaims === generalsEnums.falseBoolean) {
      const tabsData = [
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.all'),
          value: 0,
          children: <></>,
        },
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.os2'),
          value: 1,
          children: <></>,
        },
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.os3'),
          value: 2,
          children: <></>,
        },
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.os8'),
          value: 3,
          children: <></>,
        },
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.os4'),
          value: 4,
          children: <></>,
        },
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.os5'),
          value: 5,
          children: <></>,
        },
      ];
      setTabs(tabsData);
      setTitleText(t('admin.ViewOrders.title'));
      setReload(generalsEnums.trueBoolean);
    } else {
      const tabsData = [
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.all'),
          value: 0,
          children: <></>,
        },
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.os10'),
          value: 1,
          children: <></>,
        },
        {
          id: uuid(),
          label: t('admin.ViewOrders.tableHeaders.os14'),
          value: 2,
          children: <></>,
        },
      ];
      setTabs(tabsData);
      setTitleText(t('admin.ViewOrders.titleClaims'));
      setReload(generalsEnums.trueBoolean);
    }
  }, [isClaims]);

  return (
    <AdminScreenTemplate>
      <Font text={titleText} color={colors.BLACK} size={24} lineHeight="32px" isHead={true} />

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          marginTop: '22px',
        }}
      >
        <TabsAttoms itemTabs={tabs} onHandleChangeTab={handleChangeTab} />
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: displayStatusCb ? 'space-between' : 'flex-end',
          alignItems: 'flex-end',
          gap: '6px',
        }}
      >
        {displayStatusCb && (
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: 'auto',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-end',
              gap: '6px',
            }}
          >
            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '200px',
              }}
            >
              <NativeComboBox
                w="100%"
                valueForm={osSelected}
                label={t('admin.ViewOrders.cbTitle')}
                labelHead={generalsEnums.trueBoolean}
                labelColor={colors.BLACK_06}
                data={nextStatusData}
                change={(e) => {
                  handleOsChange(e);
                }}
              />
            </Box>

            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '100px',
              }}
            >
              <TsiButton
                type={generalsEnums.buttonTypes.transparent}
                handleAction={handleCancelUpdateOs}
                text={t('Generals.cancelAction')}
                isDisabled={generalsEnums.falseBoolean}
                size={generalsEnums.fontSizes.size12.size}
                lineHeight={generalsEnums.fontSizes.size12.lineHeight}
              />
            </Box>

            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '100px',
              }}
            >
              <TsiButton
                type={generalsEnums.buttonTypes.save}
                handleAction={handleSaveUpdateOs}
                text={t('Generals.save')}
                isDisabled={generalsEnums.falseBoolean}
                size={generalsEnums.fontSizes.size12.size}
                lineHeight={generalsEnums.fontSizes.size12.lineHeight}
              />
            </Box>
          </Box>
        )}

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '500px',
          }}
        >
          <InputAttom
            isSearcher={generalsEnums.trueBoolean}
            value={searchInput}
            setValue={setSearchInput}
            handleAction={handleSearch}
            isButtonDisabled={generalsEnums.falseBoolean}
            placeholder={t('admin.ViewOrders.searchTitle')}
          />
        </Box>
      </Box>

      <OrdersTable
        rows={items}
        displayStatusCb={displayStatusCb}
        handleSelectAll={handleSelectAll}
        handleSelectOne={handleSelectOne}
        idsSelected={idsSelected}
        handleIsIdSelected={handleIsIdSelected}
        handleNavigateToOrder={handleNavigateToOrder}
      />
    </AdminScreenTemplate>
  );
};

export default ViewOrders;
