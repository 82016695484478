import React from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Checkbox } from '@mui/material';
import * as yup from 'yup';

import NativeComboBox from '../NativeComboBox';
import Font from '../Fonts/Font';
import generalsEnums from '../../../utils/Generals';
import InputText from '../InputText';
import TsiButton from '../Figma/Atoms/TsiButton';
import { getStateCodeByName } from '../../../utils/FormatData';
import { selectPortalCountry, selectCountriesForCB, selectBlockCountry } from '../../../Redux/Slices/Portal.Slices';
import { setToastError, setToastSuccessMessage } from '../../../Redux/Slices/Navigation.Slices';
import { mxStatesComboBox, usStatesComboBox } from '../../../utils/DataSeeds';
import * as colors from '../../../assets/GlobalColors';

const AddressForm = ({
  t,
  handleCancelAddressForm,
  addressObject,
  addressSize,
  addressLineHeight,
  showCustomerAdititionalInfo,
  handleSaveAddress,
  logged,
  saveAdrsCheckbox,
}) => {
  const dispatch = useDispatch();

  const [countriesData, setCountriesData] = React.useState(generalsEnums.emptyArray);
  const [countryCallingCode, setCountryCallingCode] = React.useState(generalsEnums.emptyString);
  const [selectedCountry, setSelectedCountry] = React.useState(generalsEnums.emptyString);
  const [sameAsBillingAddress, setSameAsBillingAddress] = React.useState(generalsEnums.falseBoolean);
  const [saveUserAddress, setSaveUserAddress] = React.useState(generalsEnums.falseBoolean);
  const [satatesData, setStatesData] = React.useState(generalsEnums.emptyArray);
  const [selectedState, setSelectedState] = React.useState(generalsEnums.emptyString);
  const [displaySaveAdrsCheckbox, setDisplaySaveAdrsCheckbox] = React.useState(generalsEnums.trueBoolean);
  const [isValidateOnChange, setIsValidateOnChange] = React.useState(false);

  const portalCountry = useSelector(selectPortalCountry);
  const countriesForCB = useSelector(selectCountriesForCB);
  const blockCountry = useSelector(selectBlockCountry);

  const schema = yup.object().shape({
    customerName: yup.string().required(),
    customerLastName: yup.string().required(),
    customerWorkEnterprise: yup.string(),
    customerPhone: yup.string(),
    customerStreet: yup.string().required(),
    customerApartment: yup.string(),
    customerCity: yup.string().required(),
    customerCountry: yup.string().required(),
    customerState: yup.string().required(),
    customerZipCode: yup.string().required(),
    customerAdititionalInfo: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      _id: generalsEnums.emptyString,
      customerName: generalsEnums.emptyString,
      customerLastName: generalsEnums.emptyString,
      customerWorkEnterprise: generalsEnums.emptyString,
      customerPhone: generalsEnums.emptyString,
      customerStreet: generalsEnums.emptyString,
      customerApartment: generalsEnums.emptyString,
      customerCity: generalsEnums.emptyString,
      customerCountry: generalsEnums.emptyString,
      customerState: generalsEnums.emptyString,
      customerZipCode: generalsEnums.emptyString,
      customerAdititionalInfo: generalsEnums.emptyString,
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: isValidateOnChange,
    onSubmit: (values) => {
      const body = {
        _id: values._id,
        name: values.customerName,
        lastName: values.customerLastName,
        workEnterprise: values.customerWorkEnterprise,
        phone: values.customerPhone,
        countryCallingCode: countryCallingCode,
        street: values.customerStreet,
        apartment: values.customerApartment,
        city: values.customerCity,
        country: values.customerCountry,
        state: values.customerState,
        zipCode: values.customerZipCode,
        adititionalInfo: values.customerAdititionalInfo,
        stateCode: getStateCodeByName(values.customerState, selectedCountry),
      };

      handleSaveAddress(body, sameAsBillingAddress, saveUserAddress);
    },
  });

  const handleSameAddressChecked = (e) => {
    const { checked } = e.target;

    setSameAsBillingAddress(checked);
  };

  const handleSaveUserAddressChecked = (e) => {
    const { checked } = e.target;

    setSaveUserAddress(checked);
  };

  const handlePhoneNumberInput = (e) => {
    const { value } = e.target;
    const phone = value.replace(/[^0-9]+/g, '');

    if (phone.length <= 10) {
      formik.setFieldValue('customerPhone', phone);
    }
  };

  const handleSelectCountry = (e) => {
    const { value } = e.target;
    setSelectedCountry(value);
    formik.setFieldValue('customerCountry', value);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setCountriesData(generalsEnums.emptyArray);
    setCountryCallingCode(generalsEnums.emptyString);
    setSelectedCountry(generalsEnums.emptyString);
    setSameAsBillingAddress(generalsEnums.falseBoolean);
    formik.resetForm();
    handleCancelAddressForm();
  };

  const handleSelectState = (e) => {
    const { value } = e.target;
    setSelectedState(value);
    formik.setFieldValue('customerState', value);
  };

  const handleChangePostalCode = (e) => {
    const { value } = e.target;
    const postalCode = value.replace(/[^0-9]+/g, '');

    if (postalCode.length <= 6) {
      formik.setFieldValue('customerZipCode', postalCode);
    }
  };

  React.useEffect(() => {
    const countryCallingCodes = {
      [generalsEnums.countries.mexico]: generalsEnums.phoneCodes.mx,
      [generalsEnums.countries.unitedStates]: generalsEnums.phoneCodes.us,
    };

    const countryStates = {
      [generalsEnums.countries.mexico]: mxStatesComboBox,
      [generalsEnums.countries.unitedStates]: usStatesComboBox,
    };

    if (countryCallingCode === generalsEnums.emptyString) {
      setCountryCallingCode(countryCallingCodes[portalCountry]);
      setStatesData(countryStates[portalCountry]);
    }
  }, [countryCallingCode, portalCountry]);

  React.useEffect(() => {
    if (selectedCountry === generalsEnums.emptyString && portalCountry !== generalsEnums.emptyString) {
      setSelectedCountry(portalCountry);
      formik.setFieldValue('customerCountry', portalCountry);
    }
  }, [selectedCountry, selectPortalCountry]);

  React.useEffect(() => {
    if (Object.keys(addressObject).length > 0) {
      const {
        _id,
        name,
        lastName,
        workEnterprise,
        phone,
        countryCallingCode,
        street,
        apartment,
        city,
        country,
        state,
        zipCode,
        adititionalInfo,
      } = addressObject;

      formik.setFieldValue('_id', _id);
      formik.setFieldValue('customerName', name);
      formik.setFieldValue('customerLastName', lastName);
      formik.setFieldValue('customerWorkEnterprise', workEnterprise);
      formik.setFieldValue('customerPhone', phone);
      formik.setFieldValue('customerStreet', street);
      formik.setFieldValue('customerApartment', apartment);
      formik.setFieldValue('customerCity', city);
      formik.setFieldValue('customerCountry', country);

      formik.setFieldValue('customerZipCode', zipCode);
      formik.setFieldValue('customerAdititionalInfo', adititionalInfo);
      setSelectedCountry(country);
      setCountryCallingCode(countryCallingCode);

      formik.setFieldValue('customerState', state);
      setSelectedState(state);
    } else {
    }
  }, [addressObject]);

  React.useEffect(() => {
    if (Object.keys(formik.errors).length !== 0) {
      dispatch(setToastError(true));
      dispatch(setToastSuccessMessage(t('Errors.incompleteForm')));
      setIsValidateOnChange(true);
    }
  }, [formik.errors]);

  React.useEffect(() => {
    const countriesLada = [
      { label: '🇲🇽 +52', value: '+52' },
      { label: '🇺🇸 +1', value: '+1' },
    ];

    setCountriesData(countriesLada);

    if (selectedCountry === generalsEnums.countries.mexico) {
      setStatesData(mxStatesComboBox);
    } else if (selectedCountry === generalsEnums.countries.unitedStates) {
      setStatesData(usStatesComboBox);
    }
  }, [selectedCountry]);

  React.useEffect(() => {
    if (saveAdrsCheckbox !== generalsEnums.undefinedData) {
      setDisplaySaveAdrsCheckbox(saveAdrsCheckbox);
    }
  }, [saveAdrsCheckbox]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '16px',
      }}
    >
      <Font
        text={t('AddressForm.title')}
        color={colors.BLACK}
        size={addressSize}
        lineHeight={addressLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '16px',
          }}
        >
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <InputText
              value={formik.values.customerName}
              name="customerName"
              onChange={formik.handleChange}
              label={t('AddressForm.form.customerName.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerName.ph')}
              error={formik.errors.customerName}
              isDisabled={generalsEnums.falseBoolean}
              w="49%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={generalsEnums.undefinedData}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.falseBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
            />

            <InputText
              value={formik.values.customerLastName}
              name="customerLastName"
              onChange={formik.handleChange}
              label={t('AddressForm.form.customerLastName.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerLastName.ph')}
              error={formik.errors.customerLastName}
              isDisabled={generalsEnums.falseBoolean}
              w="49%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={generalsEnums.undefinedData}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.falseBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <InputText
              value={formik.values.customerWorkEnterprise}
              name="customerWorkEnterprise"
              onChange={formik.handleChange}
              label={t('AddressForm.form.customerWorkEnterprise.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerWorkEnterprise.ph')}
              error={formik.errors.customerWorkEnterprise}
              isDisabled={generalsEnums.falseBoolean}
              w="49%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={generalsEnums.undefinedData}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.falseBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
            />

            <InputText
              value={formik.values.customerPhone}
              name="customerPhone"
              onChange={handlePhoneNumberInput}
              label={t('AddressForm.form.customerPhone.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerPhone.ph')}
              error={formik.errors.customerPhone}
              isDisabled={generalsEnums.falseBoolean}
              w="49%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={generalsEnums.undefinedData}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.falseBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
              children={
                <NativeComboBox
                  w="120px"
                  valueForm={countryCallingCode}
                  label={generalsEnums.emptyString}
                  labelColor={colors.BLACK_06}
                  data={countriesData}
                  helperText={generalsEnums.undefinedData}
                  change={(e) => {
                    setCountryCallingCode(e.target.value);
                  }}
                />
              }
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <InputText
              value={formik.values.customerStreet}
              name="customerStreet"
              onChange={formik.handleChange}
              label={t('AddressForm.form.customerStreet.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerStreet.ph')}
              error={formik.errors.customerStreet}
              isDisabled={generalsEnums.falseBoolean}
              w="49%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={generalsEnums.undefinedData}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.falseBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
            />

            <InputText
              value={formik.values.customerApartment}
              name="customerApartment"
              onChange={formik.handleChange}
              label={t('AddressForm.form.customerApartment.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerApartment.ph')}
              error={formik.errors.customerApartment}
              isDisabled={generalsEnums.falseBoolean}
              w="49%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={generalsEnums.undefinedData}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.falseBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <NativeComboBox
              w="49%"
              valueForm={selectedCountry}
              label={t('AddressForm.form.customerCountry.label')}
              labelHead={generalsEnums.falseBoolean}
              labelColor={colors.TEXT_GRAY_102}
              data={countriesForCB}
              helperText={generalsEnums.undefinedData}
              change={(e) => {
                handleSelectCountry(e);
              }}
              disableCB={blockCountry}
            />

            <NativeComboBox
              w="49%"
              valueForm={selectedState}
              label={t('AddressForm.form.customerState.label')}
              labelHead={generalsEnums.falseBoolean}
              labelColor={colors.TEXT_GRAY_102}
              data={satatesData}
              helperText={generalsEnums.undefinedData}
              change={(e) => {
                handleSelectState(e);
              }}
              error={formik.errors.customerState}
            />
          </Box>

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <InputText
              value={formik.values.customerZipCode}
              name="customerZipCode"
              onChange={handleChangePostalCode}
              label={t('AddressForm.form.customerZipCode.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerZipCode.ph')}
              error={formik.errors.customerZipCode}
              isDisabled={generalsEnums.falseBoolean}
              w="49%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={generalsEnums.undefinedData}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.falseBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
            />

            <InputText
              value={formik.values.customerCity}
              name="customerCity"
              onChange={formik.handleChange}
              label={t('AddressForm.form.customerCity.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerCity.ph')}
              error={formik.errors.customerCity}
              isDisabled={generalsEnums.falseBoolean}
              w="49%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={generalsEnums.undefinedData}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.falseBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
            />
          </Box>

          {showCustomerAdititionalInfo && (
            <InputText
              value={formik.values.customerAdititionalInfo}
              name="customerAdititionalInfo"
              onChange={formik.handleChange}
              label={t('AddressForm.form.customerAdititionalInfo.label')}
              labelColor={colors.TEXT_GRAY_102}
              labelHead={generalsEnums.falseBoolean}
              ph={t('AddressForm.form.customerAdititionalInfo.ph')}
              error={formik.errors.email}
              isDisabled={generalsEnums.falseBoolean}
              w="100%"
              isPassword={generalsEnums.falseBoolean}
              extraLabel={generalsEnums.undefinedData}
              rows={4}
              helperText={generalsEnums.undefinedData}
              hasRows={generalsEnums.trueBoolean}
              hasErrorMessage={false}
              errorMessage={generalsEnums.emptyString}
              mt="0px"
            />
          )}

          {showCustomerAdititionalInfo && (
            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Checkbox
                checked={sameAsBillingAddress}
                onChange={handleSameAddressChecked}
                sx={{
                  '&.Mui-checked': {
                    color: colors.GREEN_MAIN,
                  },
                }}
              />

              <Font
                text={t('AddressForm.form.sameAsBillingAddress.label')}
                color={colors.BLACK}
                size={addressSize}
                lineHeight={addressLineHeight}
                isHead={generalsEnums.falseBoolean}
                ml="0px"
              />
            </Box>
          )}

          {logged && (
            <>
              {displaySaveAdrsCheckbox && (
                <Box
                  component={generalsEnums.BoxDiv}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    checked={saveUserAddress}
                    onChange={handleSaveUserAddressChecked}
                    sx={{
                      '&.Mui-checked': {
                        color: colors.GREEN_MAIN,
                      },
                    }}
                  />

                  <Font
                    text={t('AddressForm.form.saveUserAddress.label')}
                    color={colors.BLACK}
                    size={addressSize}
                    lineHeight={addressLineHeight}
                    isHead={generalsEnums.falseBoolean}
                    ml="0px"
                  />
                </Box>
              )}
            </>
          )}

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              gap: '8px',
            }}
          >
            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '100px',
              }}
            >
              <TsiButton
                type={generalsEnums.buttonTypes.black}
                handleAction={handleCancel}
                text={t('AddressForm.cancel')}
                isDisabled={generalsEnums.falseBoolean}
                size={generalsEnums.fontSizes.size12.size}
                lineHeight={generalsEnums.fontSizes.size12.lineHeight}
              />
            </Box>

            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '150px',
              }}
            >
              <TsiButton
                type={generalsEnums.buttonTypes.save}
                formikType="submit"
                text={t('AddressForm.save')}
                isDisabled={generalsEnums.falseBoolean}
                size={generalsEnums.fontSizes.size12.size}
                lineHeight={generalsEnums.fontSizes.size12.lineHeight}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddressForm;
