import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ItemImage from '../../../Components/Generals/Figma/Atoms/ItemImage';
import Font from '../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../utils/Generals';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import { useStyles, colors } from './ProductCard.styles';

const ProductCard = ({ product }) => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const classes = useStyles();

  const [name, setName] = React.useState('');
  const [codes, setCodes] = React.useState('');
  const [image, setImage] = React.useState('');

  const handleProductDetail = (e) => {
    e.preventDefault();
    navigate(`/product-detail/${product._id}`);
  };

  React.useEffect(() => {
    setName(product.name);
    if (Array.isArray(product.codes)) {
      setCodes(product.codes[0]);
    } else {
      setCodes(product.codes);
    }

    if (product.image) setImage(product.image);

    return () => {
      setName('');
      setCodes('');
      setImage('');
    };
  }, [product]);

  return (
    <Box component="div" className={classes.root}>
      <ItemImage image={image} name={name} size="120px" handleClick={handleProductDetail} />

      <Box component="div" className={classes.ProductInfo}>
        <Font
          text={name}
          color={colors.TEXT_GRAY_666}
          size={generalsEnums.fontSizes.size14.size}
          lineHeight={generalsEnums.fontSizes.size14.lineHeight}
          isHead={false}
        />

        <Font
          text={codes}
          color={colors.TEXT_GRAY_34}
          size={generalsEnums.fontSizes.size14.size}
          lineHeight={generalsEnums.fontSizes.size14.lineHeight}
          isHead={true}
        />

        <Box
          component="div"
          sx={{
            width: '110px',
          }}
        >
          <TsiButton
            type={generalsEnums.buttonTypes.black}
            handleAction={handleProductDetail}
            text={t('ProductCard.viewdetails')}
            isDisabled={generalsEnums.falseBoolean}
            size={generalsEnums.fontSizes.size12.size}
            lineHeight={generalsEnums.fontSizes.size12.lineHeight}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCard;
