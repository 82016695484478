import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Box, Button, IconButton, Tabs, Tab } from '@mui/material';
import * as yup from 'yup';

import generalsEnums from '../../utils/Generals';
import Font from '../../Components/Generals/Fonts/Font';
import Info from './Info';
import Users from '../../entities/Users';
import MyAddresses from '../../Screens/Client/MyAddresses/MyAddresses';
import { useStyles } from './ClientInfoModal.styles';
import { setUserIdAddress } from '../../Redux/Slices/Portal.Slices';
import {
  selectClientInfoModalState,
  setClientInfoModalState,
  setClientInfo,
  selectClientInfo,
  selectClientInfoOnlyView,
  setUpdateData,
  setSnackBar,
  setClientInfoOnlyView,
  setNewClientId,
} from '../../Redux/Slices/Navigation.Slices';
import * as colors from '../../assets/GlobalColors';

const BlurryDialog = styled(Dialog)(({}) => ({
  backdropFilter: 'blur(5px)',
}));

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box component="div" sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ClientInfoModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('global');
  const usersApi = new Users();

  const clientInfoModalState = useSelector(selectClientInfoModalState);
  const clientInfo = useSelector(selectClientInfo);
  const clientInfoOnlyView = useSelector(selectClientInfoOnlyView);
  const language = localStorage.getItem('language');

  const [action, setAction] = React.useState('new');
  const [value, setValue] = React.useState(0);
  const [userIdClient, setUserIdClient] = React.useState(generalsEnums.emptyString);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModal = () => {
    dispatch(setUserIdAddress(generalsEnums.emptyString));
    dispatch(setClientInfo(generalsEnums.emptyObject));
    dispatch(setClientInfoModalState(generalsEnums.falseBoolean));
    dispatch(setClientInfoOnlyView(generalsEnums.falseBoolean));
    setAction('new');
    setValue(0);
    setUserIdClient(generalsEnums.emptyString);
    dispatch(setClientInfo(generalsEnums.emptyString));
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string().required(),
    workEnterprise: yup.string(),
    taxCode: yup.string(),
    credit: yup.number(),
    group: yup.string(),
    password: action === 'new' ? yup.string().required() : yup.string(),
    confirmPassword:
      action === 'new'
        ? yup
            .string()
            .oneOf([yup.ref('password'), null])
            .required()
        : yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: generalsEnums.emptyString,
      lastName: generalsEnums.emptyString,
      email: generalsEnums.emptyString,
      phoneNumber: generalsEnums.emptyString,
      workEnterprise: generalsEnums.emptyString,
      taxCode: generalsEnums.emptyString,
      credit: generalsEnums.zeroNumber,
      group: generalsEnums.emptyString,
      allowMarketingEmails: generalsEnums.falseBoolean,
      userNotes: generalsEnums.emptyString,
      password: generalsEnums.emptyString,
      confirmPassword: generalsEnums.emptyString,
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      if (action === 'new') {
      } else if (action === 'edit') {
        const body = {
          userId: clientInfo.userId,
          name: values.name,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          workEnterprise: values.workEnterprise,
          allowMarketingEmails: values.allowMarketingEmails,
          emailHasChanged: values.email !== clientInfo.email ? true : false,
          sub: clientInfo.sub,
          taxCode: values.taxCode,
          creditId: values.credit === 0 ? null : clientInfo._id,
          credit: values.credit === 0 ? null : parseFloat(values.credit),
          group: values.group,
          userNotes: values.userNotes,
        };

        const response = await usersApi.editClientUser(body);

        const { statusCode, messages } = response;

        if (statusCode === 200) {
          dispatch(
            setSnackBar({
              snackBarStatus: !generalsEnums.falseBoolean,
              snackBarSeverity: 'success',
              snackBarMessage: language === 'es' ? messages.es : messages.en,
            })
          );
          setTimeout(() => {
            dispatch(setUpdateData(!generalsEnums.falseBoolean));
            handleCloseModal();
          }, generalsEnums.mainTimeOut);
        } else {
          dispatch(
            setSnackBar({
              snackBarStatus: !generalsEnums.falseBoolean,
              snackBarSeverity: 'error',
              snackBarMessage: language === 'es' ? messages.es : messages.en,
            })
          );
        }
      }
    },
  });

  React.useEffect(() => {
    if (clientInfoModalState === true) {
      if (Object.keys(clientInfo).length > 0) {
        formik.setValues({
          name: clientInfo.name,
          lastName: clientInfo.lastName,
          email: clientInfo.email,
          phoneNumber: clientInfo.phoneNumber,
          workEnterprise: clientInfo.workEnterprise,
          taxCode: clientInfo.taxCode,
          credit: clientInfo.credit,
          group: clientInfo.group,
          allowMarketingEmails: clientInfo.allowMarketingEmails,
          userNotes: clientInfo.userNotes,
        });
        setAction('edit');
        setUserIdClient(clientInfo._id);
      } else {
        const uniqueId = uuidv4();
        setAction('new');
        setUserIdClient(uniqueId);
        dispatch(setNewClientId(uniqueId));
      }
    }
  }, [clientInfoModalState, clientInfo]);

  return (
    <BlurryDialog maxWidth="xl" onClose={handleCloseModal} open={clientInfoModalState}>
      <Box component="div" className={classes.root}>
        <Box component="div" className={classes.titleBox}>
          <Font
            text={action === 'new' ? t('Modals.ClientInfoModal.titleNew') : t('Modals.ClientInfoModal.titleEdit')}
            color={colors.BLACK}
            size={20}
            lineHeight="28px"
            isHead={true}
          />

          <IconButton onClick={handleCloseModal}>
            <CloseIcon
              sx={{
                color: colors.BLACK,
              }}
            />
          </IconButton>
        </Box>

        <Box component="div" className={classes.tabsRoot}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={
                <Font
                  text={t('Modals.ClientInfoModal.info')}
                  color={colors.BLACK}
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />
              }
            />

            <Tab
              label={
                <Font
                  text={t('Modals.ClientInfoModal.addresses')}
                  color={colors.BLACK}
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />
              }
            />
          </Tabs>

          <form onSubmit={formik.handleSubmit} className={classes.formRoot}>
            <CustomTabPanel value={value} index={0}>
              <Info t={t} formik={formik} clientInfoOnlyView={clientInfoOnlyView} action={action} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Box
                component="div"
                sx={{
                  width: '800px',
                  height: 'auto',
                }}
              >
                <MyAddresses userIdClient={userIdClient} isAdmin={true} />
              </Box>
            </CustomTabPanel>

            <Box component="div" className={classes.buttonsRoot}>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                className={classes.button}
                sx={{
                  backgroundColor: colors.WHITE,
                  border: `1px solid ${colors.BLACK}`,

                  '&:hover': {
                    backgroundColor: colors.WHITE,
                    border: `1px solid ${colors.BLACK}`,
                    opacity: 0.8,
                  },
                }}
              >
                <Font
                  text={t('Generals.cancelAction')}
                  color={colors.BLACK}
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />
              </Button>

              {clientInfoOnlyView === false ? (
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  sx={{
                    backgroundColor: colors.GREEN_MAIN,
                    border: `1px solid ${colors.GREEN_MAIN}`,

                    '&:hover': {
                      backgroundColor: colors.GREEN_MAIN,
                      border: `1px solid ${colors.GREEN_MAIN}`,
                      opacity: 0.8,
                    },
                  }}
                >
                  <Font
                    text={
                      action === 'new' ? t('Modals.ClientInfoModal.btnCreate') : t('Modals.ClientInfoModal.btnUpdate')
                    }
                    color={colors.BLACK}
                    size={14}
                    lineHeight="22px"
                    isHead={true}
                  />
                </Button>
              ) : null}
            </Box>
          </form>
        </Box>
      </Box>
    </BlurryDialog>
  );
};

export default ClientInfoModal;
