import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Countries from '../../entities/Countries';
import { getTaxByCounty } from '../../utils/FormatData';

const TAXE = 16;
const countries = new Countries();

const initialState = {
  products: [],
  subTotal: 0.0,
  delivery: 0.0,
  taxes: 0.0,
  total: 0.0,
  deliveryAddress: {},
  billingAddress: {},
  countries: [],
  userData: {},
  clientSecret: '',
  paymentIntentId: '',
  freight: null,
};

export const fetchCountriesFromApi = createAsyncThunk('Countries/fetchCountriesFromApi', async () => {
  const response = await countries.getAllCountries();
  return response;
});

export const ShoppingCartSlices = createSlice({
  name: 'ShoppingCart',
  initialState,
  reducers: {
    updateTaxes: (state, action) => {
      const { country: deliveryAddressCountry, state: deliveryAddressState } = state.deliveryAddress;
      const tax = getTaxByCounty(deliveryAddressCountry, deliveryAddressState);
      const taxes = state.subTotal * (tax / 100);
      state.taxes = taxes;
      state.total = state.subTotal + state.freight + state.taxes;
      state.total = parseFloat(state.total.toFixed(2));
    },
    AddQuantityInput: (state, action) => {
      const { _id, quantity } = action.payload;

      const productInCart = state.products.find((productInCart) => productInCart._id === _id);

      if (productInCart) {
        state.subTotal -= parseFloat(productInCart.quantityPrice);
        productInCart.quantitySelected = quantity;
        productInCart.quantityPrice = productInCart.quantitySelected * productInCart.price;
        state.subTotal += parseFloat(productInCart.quantityPrice);
        state.taxes = (state.subTotal * TAXE) / 100;
        state.total = state.subTotal + state.taxes;
        state.total = parseFloat(state.total.toFixed(2));
      }
    },
    addFreight: (state, action) => {
      const { freight } = action.payload;
      state.freight = freight;

      if (freight !== null && typeof freight !== 'string') {
        const newTotal = state.subTotal + freight + state.taxes;
        state.total = newTotal;
      } else {
        const newTotal = state.subTotal + state.taxes;
        state.total = newTotal;
      }
    },
    addProduct: (state, action) => {
      const { product } = action.payload;
      product.quantityPrice = parseInt(product.quantitySelected) * parseFloat(product.price);

      state.products.push(product);
      state.subTotal += parseFloat(product.quantityPrice);

      if (Object.keys(state.deliveryAddress).length > 0) {
        const { country: deliveryAddressCountry, state: deliveryAddressState } = state.deliveryAddress;
        const tax = getTaxByCounty(deliveryAddressCountry, deliveryAddressState);
        const taxes = state.subTotal * (tax / 100);
        state.taxes = taxes;
      }

      state.total = state.subTotal + state.delivery + state.taxes;
      state.total = parseFloat(state.total.toFixed(2));
    },
    AddQuantity: (state, action) => {
      const { _id } = action.payload;

      const productInCart = state.products.find((productInCart) => productInCart._id === _id);

      if (productInCart) {
        productInCart.quantitySelected += 1;
        productInCart.quantityPrice = productInCart.quantitySelected * productInCart.price;
        state.subTotal += parseFloat(productInCart.price);
        if (Object.keys(state.deliveryAddress).length > 0) {
          const { country: deliveryAddressCountry, state: deliveryAddressState } = state.deliveryAddress;
          const tax = getTaxByCounty(deliveryAddressCountry, deliveryAddressState);
          const taxes = state.subTotal * (tax / 100);
          state.taxes = taxes;
        }
        state.total = state.subTotal + state.taxes;
        state.total = parseFloat(state.total.toFixed(2));
      }
    },
    SubQuantity: (state, action) => {
      const { _id } = action.payload;

      const productInCart = state.products.find((productInCart) => productInCart._id === _id);

      if (productInCart) {
        if (productInCart.quantitySelected > 1) {
          productInCart.quantitySelected -= 1;
          productInCart.quantityPrice = productInCart.quantitySelected * productInCart.price;
          state.subTotal -= parseFloat(productInCart.price);
          if (Object.keys(state.deliveryAddress).length > 0) {
            const { country: deliveryAddressCountry, state: deliveryAddressState } = state.deliveryAddress;
            const tax = getTaxByCounty(deliveryAddressCountry, deliveryAddressState);
            const taxes = state.subTotal * (tax / 100);
            state.taxes = taxes;
          }
          state.total = state.subTotal + state.taxes;
          state.total = parseFloat(state.total.toFixed(2));
        }
      }
    },
    deleteProduct: (state, action) => {
      const { _id } = action.payload;
      const productInCart = state.products.find((productInCart) => productInCart._id === _id);

      if (productInCart) {
        state.products = state.products.filter((product) => product._id !== _id);

        state.subTotal = state.products.reduce((acc, product) => acc + product.quantityPrice, 0);
        if (Object.keys(state.deliveryAddress).length > 0) {
          const { country: deliveryAddressCountry, state: deliveryAddressState } = state.deliveryAddress;
          const tax = getTaxByCounty(deliveryAddressCountry, deliveryAddressState);
          const taxes = state.subTotal * (tax / 100);
          state.taxes = taxes;
        }
        state.total = state.subTotal + state.delivery + state.taxes;
        state.total = parseFloat(state.total.toFixed(2));
      }
    },
    clearCart: (state) => {
      state.products = [];
      state.subTotal = 0.0;
      state.taxes = 0.0;
      state.total = 0.0;
      state.deliveryAddress = {};
      state.billingAddress = {};
    },
    addDeliveryAddress: (state, action) => {
      const { deliveryAddress } = action.payload;
      state.deliveryAddress = deliveryAddress;
    },
    addBillingAddress: (state, action) => {
      const { billingAddress } = action.payload;
      state.billingAddress = billingAddress;
    },
    addUserData: (state, action) => {
      const { userData } = action.payload;
      state.userData = userData;
    },
    addClientSecret: (state, action) => {
      const { clientSecret } = action.payload;
      state.clientSecret = clientSecret;
    },
    addPaymentIntentId: (state, action) => {
      const { paymentIntentId } = action.payload;
      state.paymentIntentId = paymentIntentId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountriesFromApi.fulfilled, (state, action) => {
      state.countries = action.payload.data;
    });
  },
});

export const {
  updateTaxes,
  AddQuantityInput,
  addFreight,
  addProduct,
  AddQuantity,
  SubQuantity,
  deleteProduct,
  clearCart,
  addDeliveryAddress,
  addBillingAddress,
  addUserData,
  addClientSecret,
  addPaymentIntentId,
} = ShoppingCartSlices.actions;

export const selectProducts = (state) => state.ShoppingCart.products;
export const selectProductsQuantity = (state) => {
  return state.ShoppingCart.products.length;
};
export const selectSubTotal = (state) => state.ShoppingCart.subTotal;
export const selectDelivery = (state) => state.ShoppingCart.freight;
export const selectTaxes = (state) => state.ShoppingCart.taxes;
export const selectTotal = (state) => state.ShoppingCart.total;
export const selectDeliveryAddress = (state) => state.ShoppingCart.deliveryAddress;
export const selectBillingAddress = (state) => state.ShoppingCart.billingAddress;
export const selectIsCartEmpty = (state) => {
  if (state.ShoppingCart.products.length === 0) {
    return true;
  }
  return false;
};
export const selectAllProductsIds = (state) => {
  const productsIds = state.ShoppingCart.products.map((product) => product._id);
  return productsIds;
};
export const selectTotalForStripe = (state) => {
  return state.ShoppingCart.total.toFixed(2);
};
export const selectCountries = (state) => state.ShoppingCart.countries;
export const selectUserData = (state) => state.ShoppingCart.userData;
export const selectClientSecret = (state) => state.ShoppingCart.clientSecret;
export const selectPaymentIntentId = (state) => state.ShoppingCart.paymentIntentId;
export const selectFreight = (state) => state.ShoppingCart.freight;
export const selectIsProductsEmpty = (state) => {
  if (state.ShoppingCart.products.length === 0) {
    return true;
  }
  return false;
};

export default ShoppingCartSlices.reducer;
