import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class Users extends Axios {
  constructor() {
    super();
    this.signout = API_ROUTES.userAuth.signout;
    this.urlGetStripeCustomerId = API_ROUTES.users.getStripeCustomerId;
    this.urlGetClients = API_ROUTES.users.getClients;
    this.urlRefreshSession = API_ROUTES.userAuth.refreshSession;
    this.urlDeleteClientsByIds = API_ROUTES.users.deleteClientsByIds;
    this.urleditClientUser = API_ROUTES.users.editClientUser;
    this.urleditAdminUser = API_ROUTES.users.editAdminUser;
    this.urlchangeAdminUserPassword = API_ROUTES.users.changeAdminUserPassword;
    this.urlsignin = API_ROUTES.userAuth.signin;
    this.urlget2faQR = API_ROUTES.users.get2faQR;
    this.urlcheckUserMFA = API_ROUTES.users.checkUserMFA;
    this.urlenableUserMFA = API_ROUTES.users.enableUserMFA;
    this.urlvalidateUserMFA = API_ROUTES.users.validateUserMFA;
    this.urlsignInWithMFA = API_ROUTES.userAuth.signInWithMFA;
    this.urlsignup = API_ROUTES.userAuth.signup;
    this.urlVerifyAccount = API_ROUTES.userAuth.verify;
    this.urlrestoreUserPasswordGenerateCode = API_ROUTES.userAuth.restoreUserPasswordGenerateCode;
    this.urlrestoreUserPassword = API_ROUTES.userAuth.restoreUserPassword;
  }

  async SignOut(email) {
    const response = await this.post(this.signout, {
      email: email,
    });
    return response;
  }

  getStripeCustomerId(email) {
    return this.post(this.urlGetStripeCustomerId, {
      email: email,
    });
  }

  getClients() {
    return this.get(this.urlGetClients);
  }

  refreshSession(email, refreshToken) {
    return this.post(this.urlRefreshSession, {
      email: email,
      refreshToken: refreshToken,
    });
  }

  deleteClientsByIds(body) {
    return this.post(this.urlDeleteClientsByIds, body);
  }

  editClientUser(body) {
    return this.post(this.urleditClientUser, body);
  }

  editAdminUser(body) {
    return this.post(this.urleditAdminUser, body);
  }

  changeAdminUserPassword(body) {
    return this.post(this.urlchangeAdminUserPassword, body);
  }

  async signin(body) {
    const response = await this.post(this.urlsignin, body);
    return response;
  }

  async get2faQR(body) {
    const response = await this.post(this.urlget2faQR, body);
    return response;
  }

  async checkUserMFA(body) {
    const response = await this.post(this.urlcheckUserMFA, body);
    return response;
  }

  async enableUserMFA(body) {
    const response = await this.post(this.urlenableUserMFA, body);
    return response;
  }

  async validateUserMFA(body) {
    const response = await this.post(this.urlvalidateUserMFA, body);
    return response;
  }

  async signInWithMFA(body) {
    const response = await this.post(this.urlsignInWithMFA, body);
    return response;
  }

  async signup(body) {
    const response = await this.post(this.urlsignup, body);
    return response;
  }

  async verifyAccount(body) {
    const response = await this.post(this.urlVerifyAccount, body);
    return response;
  }

  async restoreUserPasswordGenerateCode(body) {
    const response = await this.post(this.urlrestoreUserPasswordGenerateCode, body);
    return response;
  }

  async restoreUserPassword(body) {
    const response = await this.post(this.urlrestoreUserPassword, body);
    return response;
  }
}

export default Users;
