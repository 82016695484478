import axios from 'axios';

import { API_ROUTES } from '../../utils/ApiRoutes';

export function signout(email) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(API_ROUTES.userAuth.signout, {
        email: email,
      })
      .then(() => {
        resolve({
          token: null,
          exp: null,
          user_id: null,
          name: null,
          email: null,
          userType: null,
          emailAccountToVerify: null,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
