import React from 'react';
import { makeStyles } from '@mui/styles';
import { Tabs, Tab, Box } from '@mui/material';

import * as colors from '../../../../assets/GlobalColors';

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        width: '100%',
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            width: '100%',
            marginTop: '22px',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: colors.BLACK,
  },
}));

const TabsAttoms = ({ itemTabs, onHandleChangeTab }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onHandleChangeTab !== undefined) onHandleChangeTab(newValue);
  };

  return (
    <React.StrictMode>
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{
          flexContainer: classes.flexContainer,
          indicator: classes.indicator,
        }}
        sx={{
          width: '100%',
          borderBottom: `1px solid ${colors.BORDER_CCC}`,
        }}
      >
        {itemTabs.map((itemTab) => (
          <Tab
            key={itemTab.id}
            label={itemTab.label}
            style={{
              textTransform: 'none',
              color: colors.BLACK,
            }}
          />
        ))}
      </Tabs>

      {itemTabs.map((itemTab) => {
        return (
          <CustomTabPanel key={itemTab.id} value={value} index={itemTab.value}>
            {itemTab.children}
          </CustomTabPanel>
        );
      })}
    </React.StrictMode>
  );
};

export default TabsAttoms;
