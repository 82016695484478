import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '35px',
    marginTop: '50px',
  },
  button: {
    backgroundColor: colors.GREEN_MAIN,
    color: colors.BLACK,
    width: '250px',
    height: '35px',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      color: colors.BLACK,
      opacity: '0.8',
    },
  },
});
