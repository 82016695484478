import React from 'react';
import generalsEnums from '../../../../utils/Generals';
import FormikTextField from '../../../../Components/Generals/FormikTextField';
import Font from '../../../../Components/Generals/Fonts/Font';
import FocusButton from '../../../../Components/Generals/FocusButton';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { useFormik } from 'formik';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import Orders from '../../../../entities/Orders';
import { selectPortalLanguage } from '../../../../Redux/Slices/Portal.Slices';
import { useStyles, colors } from './styles';
import {
  setBackDropState,
  setIsToastSuccess,
  setToastSuccessMessage,
} from '../../../../Redux/Slices/Navigation.Slices';

const ContactUs = ({ isContactUs }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const OrdersAPI = new Orders();
  const { t } = useTranslation('global');
  const language = useSelector(selectPortalLanguage);

  const schema = yup.object().shape({
    name: yup.string().required(t('yupGenerals.required.name')),
    email: yup.string().email(t('yupGenerals.validate.email')).required(t('yupGenerals.required.email')),
    phone: isContactUs ? yup.string() : yup.string().required(t('yupGenerals.required.phone')),
    subject: yup.string().required(t('yupGenerals.required.subject')),
    message: yup
      .string()
      .required(t('yupGenerals.required.message'))
      .max(generalsEnums.characters.max500, t('yupGenerals.validate.message')),
  });

  const formik = useFormik({
    initialValues: {
      name: generalsEnums.emptyString,
      email: generalsEnums.emptyString,
      phone: generalsEnums.emptyString,
      subject: generalsEnums.emptyString,
      message: generalsEnums.emptyString,
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      dispatch(setBackDropState(true));
      if (isContactUs) {
      } else {
        const createOrderClaimBody = {
          orderNumber: values.phone,
          name: values.name,
          email: values.email,
          subject: values.subject,
          message: values.message,
        };

        try {
          const createOrderClaimRes = await OrdersAPI.createOrderClaim(createOrderClaimBody);
          const { messages } = createOrderClaimRes;
          dispatch(setIsToastSuccess(true));
          dispatch(setToastSuccessMessage(language === 'en' ? messages.en : messages.es));
          dispatch(setBackDropState(false));
          formik.resetForm();
        } catch (error) {
          dispatch(setBackDropState(false));
        }
      }
    },
  });

  const handlePhoneNumberInput = (e) => {
    const { value } = e.target;
    const phone = value.replace(/[^0-9]+/g, '');

    if (phone.length <= 10 && isContactUs === true) {
      formik.setFieldValue('phone', phone);
    } else if (isContactUs === false) {
      formik.setFieldValue('phone', phone);
    }
  };

  const paragraphs = [
    {
      text: t('client.ContactUs.ReturnsAndClaims.p1'),
      bold: true,
    },
    {
      text: t('client.ContactUs.ReturnsAndClaims.p2'),
      bold: false,
    },
    {
      text: t('client.ContactUs.ReturnsAndClaims.p3'),
      bold: false,
    },
    {
      text: t('client.ContactUs.ReturnsAndClaims.p4'),
      bold: false,
    },
  ];

  return (
    <Container maxWidth="lg">
      <Box component="div" className={isContactUs ? classes.Container : classes.ContainerIsNotContactUs}>
        {!isContactUs ? (
          <Box
            component="div"
            className={classes.root}
            sx={{
              width: '600px',
              gap: '15px',
            }}
          >
            <Font
              text={t('client.ContactUs.ReturnsAndClaims.title')}
              color={colors.BLACK}
              size={28}
              lineHeight="32px"
              isHead={true}
              isCenter={false}
            />

            {paragraphs.map((p, idx) => {
              return (
                <Font
                  key={idx}
                  text={p.text}
                  color={colors.BLACK}
                  size={18}
                  lineHeight="28px"
                  isHead={p.bold}
                  isCenter={false}
                />
              );
            })}
          </Box>
        ) : null}

        <form onSubmit={formik.handleSubmit}>
          <Box
            component="div"
            className={classes.root}
            sx={{
              width: isContactUs ? '100%' : '500px',
              gap: '5px',
            }}
          >
            {isContactUs ? (
              <Font text={t('client.ContactUs.title')} color={colors.BLACK} size={28} lineHeight="32px" isHead={true} />
            ) : null}

            <Box component="div" className={classes.formRow}>
              <Box
                component="div"
                className={classes.formRowContent}
                sx={{
                  width: '100%',
                }}
              >
                <Font
                  text={t('client.ContactUs.form.name.label')}
                  color={colors.BLACK_06}
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />

                <FormikTextField
                  itemValue={formik.values.name}
                  itemSize="medium"
                  itemId="name"
                  itemName="name"
                  itemVariant="outlined"
                  itemOnChange={(e) => {
                    formik.handleChange(e);
                  }}
                  itemStyle={{
                    border: formik.errors.name ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                    borderRadius: '5px',
                    width: '100%',
                    backgroundColor: colors.WHITE,
                  }}
                  itemType="text"
                  itemPlaceHolderColor={colors.BLACK}
                  itemPlaceHolderSize="13px"
                  itemPlaceholder={t('client.ContactUs.form.name.placeholder')}
                />
              </Box>
            </Box>

            <Box component="div" className={classes.formRow}>
              <Box
                component="div"
                className={classes.formRowContent}
                sx={{
                  width: '48%',
                }}
              >
                <Font
                  text={t('client.ContactUs.form.email.label')}
                  color={colors.BLACK_06}
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />

                <FormikTextField
                  itemValue={formik.values.email}
                  itemSize="medium"
                  itemId="email"
                  itemName="email"
                  itemVariant="outlined"
                  itemOnChange={(e) => {
                    formik.handleChange(e);
                  }}
                  itemStyle={{
                    border: formik.errors.email ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                    borderRadius: '5px',
                    width: '100%',
                    backgroundColor: colors.WHITE,
                  }}
                  itemType="text"
                  itemPlaceHolderColor={colors.BLACK}
                  itemPlaceHolderSize="13px"
                  itemPlaceholder={t('client.ContactUs.form.email.placeholder')}
                />
              </Box>

              <Box
                component="div"
                className={classes.formRowContent}
                sx={{
                  width: '48%',
                }}
              >
                <Font
                  text={t(`client.ContactUs.form.${isContactUs ? 'phone' : 'orderNumber'}.label`)}
                  color={colors.BLACK_06}
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />

                <FormikTextField
                  itemValue={formik.values.phone}
                  itemSize="medium"
                  itemId="phone"
                  itemName="phone"
                  itemVariant="outlined"
                  itemOnChange={(e) => {
                    handlePhoneNumberInput(e);
                  }}
                  itemStyle={{
                    border: formik.errors.phone ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                    borderRadius: '5px',
                    width: '100%',
                    backgroundColor: colors.WHITE,
                  }}
                  itemType="text"
                  itemPlaceHolderColor={colors.BLACK}
                  itemPlaceHolderSize="13px"
                  itemPlaceholder={t(`client.ContactUs.form.${isContactUs ? 'phone' : 'orderNumber'}.placeholder`)}
                />
              </Box>
            </Box>

            <Box component="div" className={classes.formRow}>
              <Box
                component="div"
                className={classes.formRowContent}
                sx={{
                  width: '100%',
                }}
              >
                <Font
                  text={t('client.ContactUs.form.subject.label')}
                  color={colors.BLACK_06}
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />

                <FormikTextField
                  itemValue={formik.values.subject}
                  itemSize="medium"
                  itemId="subject"
                  itemName="subject"
                  itemVariant="outlined"
                  itemOnChange={(e) => {
                    formik.handleChange(e);
                  }}
                  itemStyle={{
                    border: formik.errors.subject ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                    borderRadius: '5px',
                    width: '100%',
                    backgroundColor: colors.WHITE,
                  }}
                  itemType="text"
                  itemPlaceHolderColor={colors.BLACK}
                  itemPlaceHolderSize="13px"
                  itemPlaceholder={t('client.ContactUs.form.subject.placeholder')}
                />
              </Box>
            </Box>

            <Box component="div" className={classes.formRow}>
              <Box
                component="div"
                className={classes.formRowContent}
                sx={{
                  width: '100%',
                }}
              >
                <Font
                  text={t('client.ContactUs.form.message.label')}
                  color={colors.BLACK_06}
                  size={14}
                  lineHeight="22px"
                  isHead={true}
                />

                <FormikTextField
                  itemValue={formik.values.message}
                  itemSize="medium"
                  itemId="message"
                  itemName="message"
                  itemVariant="outlined"
                  itemOnChange={(e) => {
                    formik.handleChange(e);
                  }}
                  itemStyle={{
                    border: formik.errors.message ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                    borderRadius: '5px',
                    width: '100%',
                    backgroundColor: colors.WHITE,
                  }}
                  itemType="text"
                  itemPlaceHolderColor={colors.BLACK}
                  itemPlaceHolderSize="13px"
                  itemPlaceholder={t('client.ContactUs.form.message.placeholder')}
                  itemMultiline={true}
                  itemRows={5}
                />
              </Box>
            </Box>

            <Box
              component="div"
              sx={{
                marginTop: '20px',
              }}
            >
              <FocusButton
                handleAction={formik.handleSubmit}
                normalColor={colors.GREEN_MAIN}
                focusedColor={colors.BLACK}
                text={t('Generals.submit')}
                w="250px"
              />
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default ContactUs;
