import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material/styles';
import { Box, FormControl, Select, MenuItem, IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import generalsEnums from '../../utils/Generals';
import Font from './Fonts/Font';
import * as colors from '../../assets/GlobalColors';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.TOOLTIP,
      border: `1px solid ${colors.TOOLTIP}`,
    },
  })
);

const NativeComboBox = ({ valueForm, label, labelColor, data, helperText, w, change, labelHead, disableCB, error }) => {
  const [value, setValue] = React.useState(generalsEnums.emptyString);
  const [isDisabled, setIsDisabled] = React.useState(generalsEnums.falseBoolean);
  const [isError, setIsError] = React.useState(generalsEnums.falseBoolean);

  const handleChange = (event) => {
    setValue(event.target.value);
    change(event);
  };

  React.useEffect(() => {
    setValue(valueForm);
  }, [valueForm]);

  React.useEffect(() => {
    if (disableCB !== generalsEnums.undefinedData) {
      setIsDisabled(disableCB);
    }
  }, [disableCB]);

  React.useEffect(() => {
    if (error !== generalsEnums.undefinedData) {
      setIsError(error);
    }
  }, [error]);

  return (
    <Box
      component="div"
      sx={{
        minWidth: w !== generalsEnums.undefinedData ? w : '280px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      {label !== generalsEnums.emptyString ? (
        <Font
          text={label}
          color={labelColor}
          size={generalsEnums.fontSizes.size16.size}
          lineHeight={generalsEnums.fontSizes.size16.lineHeight}
          isHead={labelHead !== generalsEnums.undefinedData ? labelHead : generalsEnums.trueBoolean}
        />
      ) : null}

      <Box
        component="div"
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '1px',
        }}
      >
        <FormControl fullWidth>
          <Select
            value={value}
            defaultValue={value}
            onChange={handleChange}
            disabled={isDisabled}
            sx={{
              height: '40px',
              border: `1px solid ${isError ? colors.TSADMIN_RED_DELETE : colors.GREEN_MAIN}`,
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          >
            {data.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  value={item.value}
                  sx={{
                    minWidth: '120px',
                    width: '100%',
                    '&.MuiMenuItem-root': {
                      backgroundColor: colors.WHITE,
                    },
                    '&.Mui-selected': {
                      backgroundColor: colors.GREEN_MAIN,
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: colors.GREEN_MAIN,
                    },
                  }}
                >
                  <Font
                    text={item.label}
                    color={colors.BLACK}
                    size={generalsEnums.fontSizes.size14.size}
                    lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                    isHead={generalsEnums.falseBoolean}
                    ml="0px"
                    mt="0px"
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {helperText !== generalsEnums.undefinedData ? (
          <HtmlTooltip
            placement="right"
            title={
              <React.Fragment>
                <Font text={helperText} color={colors.WHITE} size={14} lineHeight="22px" isHead={false} />
              </React.Fragment>
            }
          >
            <IconButton>
              <HelpIcon
                sx={{
                  color: colors.BLACK_06,
                }}
              />
            </IconButton>
          </HtmlTooltip>
        ) : null}
      </Box>
    </Box>
  );
};

export default NativeComboBox;
