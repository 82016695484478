export const mxStatesCodes = [
  {
    Aguascalientes: 'AGS',
  },
  {
    'Baja California': 'BC',
  },
  {
    'Baja California Sur': 'BCS',
  },
  {
    Campeche: 'CAMP',
  },
  {
    Chiapas: 'CHIS',
  },
  {
    Chihuahua: 'CHIH',
  },
  {
    Coahuila: 'COAH',
  },
  {
    Colima: 'COL',
  },
  {
    Durango: 'DGO',
  },
  {
    'Estado de México': 'MEX',
  },
  {
    Guanajuato: 'GTO',
  },
  {
    Guerrero: 'GRO',
  },
  {
    Hidalgo: 'HGO',
  },
  {
    Jalisco: 'JAL',
  },
  {
    Michoacán: 'MICH',
  },
  {
    Morelos: 'MOR',
  },
  {
    Nayarit: 'NAY',
  },
  {
    'Nuevo León': 'NL',
  },
  {
    Oaxaca: 'OAX',
  },
  {
    Puebla: 'PUE',
  },
  {
    Querétaro: 'QRO',
  },
  {
    'Quintana Roo': 'QROO',
  },
  {
    'San Luis Potosí': 'SLP',
  },
  {
    Sinaloa: 'SIN',
  },
  {
    Sonora: 'SON',
  },
  {
    Tabasco: 'TAB',
  },
  {
    Tamaulipas: 'TMPS',
  },
  {
    Tlaxcala: 'TLAX',
  },
  {
    Veracruz: 'VER',
  },
  {
    Yucatán: 'YUC',
  },
  {
    Zacatecas: 'ZAC',
  },
];

export const usStatesCodes = [
  {
    Alabama: 'AL',
  },
  {
    Alaska: 'AK',
  },
  {
    Arizona: 'AZ',
  },
  {
    Arkansas: 'AR',
  },
  {
    California: 'CA',
  },
  {
    Colorado: 'CO',
  },
  {
    Connecticut: 'CT',
  },
  {
    Delaware: 'DE',
  },
  {
    Florida: 'FL',
  },
  {
    Georgia: 'GA',
  },
  {
    Hawaii: 'HI',
  },
  {
    Idaho: 'ID',
  },
  {
    Illinois: 'IL',
  },
  {
    Indiana: 'IN',
  },
  {
    Iowa: 'IA',
  },
  {
    Kansas: 'KS',
  },
  {
    Kentucky: 'KY',
  },
  {
    Louisiana: 'LA',
  },
  {
    Maine: 'ME',
  },
  {
    Maryland: 'MD',
  },
  {
    Massachusetts: 'MA',
  },
  {
    Michigan: 'MI',
  },
  {
    Minnesota: 'MN',
  },
  {
    Mississippi: 'MS',
  },
  {
    Missouri: 'MO',
  },
  {
    Montana: 'MT',
  },
  {
    Nebraska: 'NE',
  },
  {
    Nevada: 'NV',
  },
  {
    'New Hampshire': 'NH',
  },
  {
    'New Jersey': 'NJ',
  },
  {
    'New Mexico': 'NM',
  },
  {
    'New York': 'NY',
  },
  {
    'North Carolina': 'NC',
  },
  {
    'North Dakota': 'ND',
  },
  {
    Ohio: 'OH',
  },
  {
    Oklahoma: 'OK',
  },
  {
    Oregon: 'OR',
  },
  {
    Pennsylvania: 'PA',
  },
  {
    'Rhode Island': 'RI',
  },
  {
    'South Carolina': 'SC',
  },
  {
    'South Dakota': 'SD',
  },
  {
    Tennessee: 'TN',
  },
  {
    Texas: 'TX',
  },
  {
    Utah: 'UT',
  },
  {
    Vermont: 'VT',
  },
  {
    Virginia: 'VA',
  },
  {
    Washington: 'WA',
  },
  {
    'West Virginia': 'WV',
  },
  {
    Wisconsin: 'WI',
  },
  {
    Wyoming: 'WY',
  },
];

export const mxStatesComboBox = [
  {
    value: 'Aguascalientes',
    label: 'Aguascalientes',
  },
  {
    value: 'Baja California',
    label: 'Baja California',
  },
  {
    value: 'Baja California Sur',
    label: 'Baja California Sur',
  },
  {
    value: 'Campeche',
    label: 'Campeche',
  },
  {
    value: 'Chiapas',
    label: 'Chiapas',
  },
  {
    value: 'Chihuahua',
    label: 'Chihuahua',
  },
  {
    value: 'Coahuila',
    label: 'Coahuila',
  },
  {
    value: 'Colima',
    label: 'Colima',
  },
  {
    value: 'Durango',
    label: 'Durango',
  },
  {
    value: 'Estado de México',
    label: 'Estado de México',
  },
  {
    value: 'Guanajuato',
    label: 'Guanajuato',
  },
  {
    value: 'Guerrero',
    label: 'Guerrero',
  },
  {
    value: 'Hidalgo',
    label: 'Hidalgo',
  },
  {
    value: 'Jalisco',
    label: 'Jalisco',
  },
  {
    value: 'Michoacán',
    label: 'Michoacán',
  },
  {
    value: 'Morelos',
    label: 'Morelos',
  },
  {
    value: 'Nayarit',
    label: 'Nayarit',
  },
  {
    value: 'Nuevo León',
    label: 'Nuevo León',
  },
  {
    value: 'Oaxaca',
    label: 'Oaxaca',
  },
  {
    value: 'Puebla',
    label: 'Puebla',
  },
  {
    value: 'Querétaro',
    label: 'Querétaro',
  },
  {
    value: 'Quintana Roo',
    label: 'Quintana Roo',
  },
  {
    value: 'San Luis Potosí',
    label: 'San Luis Potosí',
  },
  {
    value: 'Sinaloa',
    label: 'Sinaloa',
  },
  {
    value: 'Sonora',
    label: 'Sonora',
  },
  {
    value: 'Tabasco',
    label: 'Tabasco',
  },
  {
    value: 'Tamaulipas',
    label: 'Tamaulipas',
  },
  {
    value: 'Tlaxcala',
    label: 'Tlaxcala',
  },
  {
    value: 'Veracruz',
    label: 'Veracruz',
  },
  {
    value: 'Yucatán',
    label: 'Yucatán',
  },
  {
    value: 'Zacatecas',
    label: 'Zacatecas',
  },
];

export const usStatesComboBox = [
  {
    value: 'Alabama',
    label: 'Alabama',
  },
  {
    value: 'Alaska',
    label: 'Alaska',
  },
  {
    value: 'Arizona',
    label: 'Arizona',
  },
  {
    value: 'Arkansas',
    label: 'Arkansas',
  },
  {
    value: 'California',
    label: 'California',
  },
  {
    value: 'Colorado',
    label: 'Colorado',
  },
  {
    value: 'Connecticut',
    label: 'Connecticut',
  },
  {
    value: 'Delaware',
    label: 'Delaware',
  },
  {
    value: 'Florida',
    label: 'Florida',
  },
  {
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Hawaii',
    label: 'Hawaii',
  },
  {
    value: 'Idaho',
    label: 'Idaho',
  },
  {
    value: 'Illinois',
    label: 'Illinois',
  },
  {
    value: 'Indiana',
    label: 'Indiana',
  },
  {
    value: 'Iowa',
    label: 'Iowa',
  },
  {
    value: 'Kansas',
    label: 'Kansas',
  },
  {
    value: 'Kentucky',
    label: 'Kentucky',
  },
  {
    value: 'Louisiana',
    label: 'Louisiana',
  },
  {
    value: 'Maine',
    label: 'Maine',
  },
  {
    value: 'Maryland',
    label: 'Maryland',
  },
  {
    value: 'Massachusetts',
    label: 'Massachusetts',
  },
  {
    value: 'Michigan',
    label: 'Michigan',
  },
  {
    value: 'Minnesota',
    label: 'Minnesota',
  },
  {
    value: 'Mississippi',
    label: 'Mississippi',
  },
  {
    value: 'Missouri',
    label: 'Missouri',
  },
  {
    value: 'Montana',
    label: 'Montana',
  },
  {
    value: 'Nebraska',
    label: 'Nebraska',
  },
  {
    value: 'Nevada',
    label: 'Nevada',
  },
  {
    value: 'New Hampshire',
    label: 'New Hampshire',
  },
  {
    value: 'New Jersey',
    label: 'New Jersey',
  },
  {
    value: 'New Mexico',
    label: 'New Mexico',
  },
  {
    value: 'New York',
    label: 'New York',
  },
  {
    value: 'North Carolina',
    label: 'North Carolina',
  },
  {
    value: 'North Dakota',
    label: 'North Dakota',
  },
  {
    value: 'Ohio',
    label: 'Ohio',
  },
  {
    value: 'Oklahoma',
    label: 'Oklahoma',
  },
  {
    value: 'Oregon',
    label: 'Oregon',
  },
  {
    value: 'Pennsylvania',
    label: 'Pennsylvania',
  },
  {
    value: 'Rhode Island',
    label: 'Rhode Island',
  },
  {
    value: 'South Carolina',
    label: 'South Carolina',
  },
  {
    value: 'South Dakota',
    label: 'South Dakota',
  },
  {
    value: 'Tennessee',
    label: 'Tennessee',
  },
  {
    value: 'Texas',
    label: 'Texas',
  },
  {
    value: 'Utah',
    label: 'Utah',
  },
  {
    value: 'Vermont',
    label: 'Vermont',
  },
  {
    value: 'Virginia',
    label: 'Virginia',
  },
  {
    value: 'Washington',
    label: 'Washington',
  },
  {
    value: 'West Virginia',
    label: 'West Virginia',
  },
  {
    value: 'Wisconsin',
    label: 'Wisconsin',
  },
  {
    value: 'Wyoming',
    label: 'Wyoming',
  },
];
