import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import generalsEnums from '../../utils/Generals';
import UserAvatar from '../../Components/Client/UserAvatar/UserAvatar';
import GoBackButton from '../../Components/Generals/GoBackButton';
import ResponsiveComponent from '../../Hooks/ResponsiveComponent';
import { getResponsiveValues } from '../../utils/FormatData';
import { useStyles } from './ClientProfileRoutes.styles';
import { selectFullName, selectEmail } from '../../Redux/Slices/User.Slices';

const ClientProfileRoutes = ({ containerSize }) => {
  const classes = useStyles();

  const fullName = useSelector(selectFullName);
  const email = useSelector(selectEmail);

  return (
    <Box component={generalsEnums.BoxDiv} className={classes.root}>
      <Box
        component={generalsEnums.BoxDiv}
        className={classes.container}
        sx={{
          width: containerSize,
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          className={classes.userSection}
          style={{
            flexDirection: 'row',
          }}
        >
          <UserAvatar />

          <Box component={generalsEnums.BoxDiv} className={classes.userData}>
            <Box component={generalsEnums.BoxDiv} className={classes.userName}>
              {fullName}
            </Box>

            <Box component={generalsEnums.BoxDiv} className={classes.userName}>
              {email}
            </Box>
          </Box>
        </Box>

        <GoBackButton hasText={true} />

        <Box component={generalsEnums.BoxDiv} className={classes.Outlet}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

const Main = () => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [['95%', '95%', '60%', '60%', '60%']];

  const [containerSize] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  return <ClientProfileRoutes containerSize={containerSize} />;
};

export default Main;
