import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import portalSettings from '../../../../entities/portalSettings';
import generalsEnums from '../../../../utils/Generals';
import Font from '../../../../Components/Generals/Fonts/Font';
import AdminScreenTemplate from '../../../../utils/templates/AdminScreen.Template';
import ResponsiveComponent from '../../../../Hooks/ResponsiveComponent';
import EmptyMarkup from './EmptyMarkup';
import ListMarkup from './ListMarkup';
import { useStyles } from './styles';
import { getResponsiveValues } from '../../../../utils/FormatData';
import { selectPortalLanguage } from '../../../../Redux/Slices/Portal.Slices';
import { getMessageByPortalLanguage, getMarkupPercentage, getMarkupRule } from '../../../../utils/FormatData';
import {
  setIsToastSuccess,
  setToastSuccessMessage,
  setBackDropState,
} from '../../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../../assets/GlobalColors';

const Markup = () => {
  const dispatch = useDispatch();
  const portalSettingsAPI = new portalSettings();
  const classes = useStyles();
  const { t } = useTranslation('global');
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const [markupArray, setMarkupArray] = React.useState(generalsEnums.emptyArray);

  const portalLanguage = useSelector(selectPortalLanguage);
  const [isEditable, setIsEditable] = React.useState(false);
  const [editedValues, setEditedValues] = React.useState({});

  const handleAddNewMarkup = (e) => {
    e.preventDefault();
  };

  const handleEditMarkups = async(e) => {
    e.preventDefault();
    if(isEditable &&  Object.keys(editedValues).length !== 0){
      dispatch(setBackDropState(generalsEnums.trueBoolean));
      const result = await portalSettingsAPI.editActiveMarkup(editedValues);
      const { data } = result;
      const { statusCode } = data;
      if (statusCode === generalsEnums.statusCodes.ok) {
        const { messages: resMessages } = data;
        const message = getMessageByPortalLanguage(resMessages, portalLanguage);
        dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
        dispatch(setToastSuccessMessage(message));
        dispatch(setBackDropState(generalsEnums.falseBoolean));
        setEditedValues({});
      } else {
        dispatch(setIsToastSuccess(generalsEnums.falseBoolean));
      }
    }
    setIsEditable(!isEditable);
  }

  const handleChangeValue = (e, id, key, value) => {
    e.preventDefault();
    const regex =  /^(\d+(\.\d*)?)?$/;
    if (!regex.test(value)) {
      return
    }
    setMarkupArray([...markupArray]);
    setEditedValues(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [key]: value
      }
    }));
  }
  
  const responsiveArray = [
    [
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size22.size,
      generalsEnums.fontSizes.size24.size,
      generalsEnums.fontSizes.size24.size,
    ],
    [
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size22.lineHeight,
      generalsEnums.fontSizes.size24.lineHeight,
      generalsEnums.fontSizes.size24.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];

  const [
    titleSize,
    titleLineHeight,
    titleEmptySize,
    titleEmptyLineHeight,
    descEmptySize,
    descEmptyLineHeight,
    listCurrencySize,
    listCurrencyLineHeight,
    listTableSize,
    listTableLineHeight,
  ] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  const isMarkupArrayEmpty = markupArray.length === generalsEnums.zeroNumber;

  React.useEffect(() => {
    const fetchMarkup = async () => {
      dispatch(setBackDropState(generalsEnums.trueBoolean));
      const getAllActiveMarkupRes = await portalSettingsAPI.getAllActiveMarkup();
      const { data } = getAllActiveMarkupRes;
      const { statusCode } = data;

      if (statusCode === generalsEnums.statusCodes.ok) {
        const { data: markupData, messages: resMessages } = data;
        setMarkupArray(markupData);
        const message = getMessageByPortalLanguage(resMessages, portalLanguage);
        dispatch(setIsToastSuccess(generalsEnums.trueBoolean));
        dispatch(setToastSuccessMessage(message));
        dispatch(setBackDropState(generalsEnums.falseBoolean));
      } else {
        dispatch(setIsToastSuccess(generalsEnums.falseBoolean));
      }
    };

    fetchMarkup();
  }, []);

  return (
    <AdminScreenTemplate>
      <Font
        text={t('Markup.title')}
        color={colors.BLACK}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.columnBox}
        sx={{
          marginTop: '28px',
        }}
      >
        {isMarkupArrayEmpty ? (
          <EmptyMarkup
            t={t}
            colors={colors}
            generalsEnums={generalsEnums}
            classes={classes}
            titleEmptySize={titleEmptySize}
            titleEmptyLineHeight={titleEmptyLineHeight}
            descEmptySize={descEmptySize}
            descEmptyLineHeight={descEmptyLineHeight}
            handleAddNewMarkup={handleAddNewMarkup}
          />
        ) : (
          <ListMarkup
            t={t}
            colors={colors}
            generalsEnums={generalsEnums}
            classes={classes}
            handleAddNewMarkup={handleAddNewMarkup}
            descEmptySize={descEmptySize}
            descEmptyLineHeight={descEmptyLineHeight}
            listCurrencySize={listCurrencySize}
            listCurrencyLineHeight={listCurrencyLineHeight}
            markupArray={markupArray}
            listTableSize={listTableSize}
            listTableLineHeight={listTableLineHeight}
            getMarkupPercentage={getMarkupPercentage}
            getMarkupRule={getMarkupRule}
            handleEditMarkups={handleEditMarkups}
            isEditable={isEditable}
            handleChangeValue={handleChangeValue}
            listMarkupKey={generalsEnums.listMarkupKey}
          />
        )}
      </Box>
    </AdminScreenTemplate>
  );
};

export default Markup;
