import React from 'react';
import uuid from 'react-uuid';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Button, Collapse, Checkbox } from '@mui/material';

import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import HeaderButton from '../../../../Components/TsAdmin/HeaderButton';
import FilterButton from '../../../../Components/TsAdmin/FilterButton';
import TableListProducts from '../../../../Components/TsAdmin/TableListProducts';
import Products from '../../../../entities/Products';
import ResponsiveComponent from '../../../../Hooks/ResponsiveComponent';
import { getResponsiveValues } from '../../../../utils/FormatData';
import { setBackDropState } from '../../../../Redux/Slices/Navigation.Slices';
import { useStyles } from './ViewProducts.styles';
import {
  selectAllProducts,
  selectAllActiveProducts,
  selectAllInactiveProducts,
  setProductToEdit,
  setProductVariantsFromApi,
  setClearProductData,
} from '../../../../Redux/Slices/Products.Slices';
import * as colors from '../../../../assets/GlobalColors';

const ViewProducts = ({ titleSize, titleLineHeight, tableTextSize, tableTextLineHeight }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productsAPI = new Products();
  const { t } = useTranslation('global');

  const [quantity, setQuantity] = React.useState(0);
  const [isVisibled, setIsVisibled] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState(0);
  const [rowsData, setRowsData] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [_idSelected, setIdSelected] = React.useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);

  const allProducts = useSelector(selectAllProducts);
  const allActiveProducts = useSelector(selectAllActiveProducts);
  const allInactiveProducts = useSelector(selectAllInactiveProducts);

  const handleCreateProduct = () => {
    navigate('/ts-admin/products/add');
  };

  const handleEditProducts = () => {
    const product = allProducts.find((product) => product._id === _idSelected[0]);
    dispatch(setProductVariantsFromApi(product.productVariants));
    dispatch(setProductToEdit(product));
    navigate('/ts-admin/product/edit');
  };

  const handleDeleteProducts = () => {
    _idSelected.map(async (id) => {
      await productsAPI.deleteProduct(id);
    });

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const handleFilterButton = (value) => {
    setFilterValue(value);
  };

  const handleCheckboxProduct = (_id) => {
    setIsVisibled(true);
    if (_idSelected.includes(_id)) {
      const newIdSelected = _idSelected.filter((id) => id !== _id);
      setIdSelected(newIdSelected);
      setQuantity(newIdSelected.length);
      if (newIdSelected.length === 0) {
        setIsVisibled(false);
      }
    } else {
      setIdSelected([..._idSelected, _id]);
      setQuantity(_idSelected.length + 1);
    }
  };

  const handleSelectAllProducts = (event) => {
    setSelectedAll(event.target.checked);
    if (event.target.checked) {
      const newIdSelected = allProducts.map((product) => product._id);
      setIdSelected(newIdSelected);
      setQuantity(newIdSelected.length);
      setIsVisibled(true);
    } else {
      setIdSelected([]);
      setQuantity(0);
      setIsVisibled(false);
    }
  };

  const handleClearSelected = () => {
    setIdSelected([]);
    setQuantity(0);
    setIsVisibled(false);
    setSelectedAll(false);
  };

  const filters = [
    {
      text: t('admin.AdminProducts.all-products'),
      value: 0,
    },
    {
      text: t('admin.AdminProducts.active-products'),
      value: 1,
    },
    {
      text: t('admin.AdminProducts.inactive-products'),
      value: 2,
    },
  ];

  const tableHeaders = [
    {
      text: t('admin.AdminProducts.header-image'),
      padding: 'normal',
    },
    {
      text: t('admin.AdminProducts.header-name'),
      padding: 'normal',
    },
    {
      text: t('admin.AdminProducts.header-codes'),
      padding: 'normal',
    },
    {
      text: t('admin.AdminProducts.header-category'),
      padding: 'normal',
    },
  ];

  React.useEffect(() => {
    switch (filterValue) {
      case 0:
        setRows(rowsData);
        break;
      case 1:
        setRows(allActiveProducts);
        break;
      case 2:
        setRows(allInactiveProducts);
        break;
      default:
        setRows(rowsData);
        break;
    }
  }, [filterValue, allProducts, allActiveProducts, allInactiveProducts]);

  React.useEffect(async () => {
    dispatch(setBackDropState(true));

    const productsPaginationBody = {
      offset: null,
      limit: 500,
    };

    const productsPaginationBodyRes = await productsAPI.productsPagination(productsPaginationBody);
    const { data: resData } = productsPaginationBodyRes;
    const { products, LastEvaluatedKey } = resData;
    setRows(products);
    setRowsData(products);

    dispatch(setBackDropState(false));
  }, [dispatch]);

  return (
    <Box component={generalsEnums.BoxDiv} className={classes.root}>
      <Box component={generalsEnums.BoxDiv} className={classes.headerRoot}>
        <Collapse
          in={!isVisibled}
          style={{
            width: '95%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box component={generalsEnums.BoxDiv} className={classes.header}>
            <Font
              text={t('admin.AdminProducts.text-welcome')}
              color={colors.BLACK}
              size={titleSize}
              lineHeight={titleLineHeight}
              isHead={false}
              ml="0px"
            />

            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '200px',
                height: 'auto',
              }}
            >
              <TsiButton
                type={generalsEnums.buttonTypes.save}
                handleAction={handleCreateProduct}
                text={t('admin.AdminProducts.button-create-product')}
                isDisabled={false}
                size={generalsEnums.emptyString}
                lineHeight={generalsEnums.zeroNumber}
              />
            </Box>
          </Box>
        </Collapse>

        <Collapse
          in={isVisibled}
          style={{
            width: '95%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box component="div" className={classes.header}>
            <Box component="div" className={classes.headerTitle}>
              <IconButton onClick={handleClearSelected}>
                <ClearIcon className={classes.closeButton} />
              </IconButton>

              <Box component="div">{quantity}</Box>

              <Box component="div">{t('admin.AdminProducts.text-selected-products')}</Box>
            </Box>

            <Box component="div" className={classes.headerButtons}>
              <Button
                onClick={handleDeleteProducts}
                variant="contained"
                style={{
                  height: '40px',
                  borderRadius: '8px',
                  backgroundColor: colors.WHITE,
                  border: 'none',
                  boxShadow: 'none',
                  textTransform: 'none',
                  color: colors.TSADMIN_RED_DELETE,
                  fontSize: '14px',
                  gap: '10px',
                  border: `1px solid ${colors.GRAY_214_1}`,
                }}
              >
                {t('admin.AdminProducts.button-delete-products')}
              </Button>

              {_idSelected.length === 1 ? (
                <HeaderButton text={t('admin.AdminProducts.button-edit-products')} handleOnClick={handleEditProducts} />
              ) : null}
            </Box>
          </Box>
        </Collapse>
      </Box>

      <Box component={generalsEnums.BoxDiv} className={classes.filterRoot}>
        <Box component={generalsEnums.BoxDiv} className={classes.filterBox}>
          {filters.map((filter) => {
            return (
              <FilterButton
                key={uuid()}
                text={filter.text}
                value={filter.value}
                handleOnClick={handleFilterButton}
                filterValue={filterValue}
              />
            );
          })}
        </Box>
      </Box>

      <Box component={generalsEnums.BoxDiv} className={classes.TableListProducts}>
        {rows.length !== 0 ? (
          <TableListProducts
            tableHeaders={tableHeaders}
            rows={rows}
            classes={classes}
            t={t}
            handleCheckboxProduct={handleCheckboxProduct}
            _idSelected={_idSelected}
            tableTextSize={tableTextSize}
            tableTextLineHeight={tableTextLineHeight}
            colors={colors}
          />
        ) : (
          <Box component={generalsEnums.BoxDiv}>
            <Font
              text={t('admin.AdminProducts.text-no-products')}
              color={colors.BLACK}
              size={titleSize}
              lineHeight={titleLineHeight}
              isHead={false}
              ml="0px"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const Main = () => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size20.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];

  const [titleSize, titleLineHeight, tableTextSize, tableTextLineHeight] = getResponsiveValues(
    responsiveArray,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl
  );

  return (
    <ViewProducts
      titleSize={titleSize}
      titleLineHeight={titleLineHeight}
      tableTextSize={tableTextSize}
      tableTextLineHeight={tableTextLineHeight}
    />
  );
};

export default Main;
