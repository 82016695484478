import { createSlice } from '@reduxjs/toolkit';

import generalsEnums from '../../utils/Generals';
import { getMinDate30DaysAgo, getMaxDate } from '../../utils/FormatData';

const initialState = {
  totalAPI: '0',
  totalMx: '0',
  totalUs: '0',
  totalBlockIps: '0',
  totalBlockBots: '0',
  minDate: getMinDate30DaysAgo(),
  maxDate: getMaxDate(),
  allRequests: generalsEnums.emptyArray,
  allMxRequests: generalsEnums.emptyArray,
  allUsRequests: generalsEnums.emptyArray,
  allBlockedRequests: generalsEnums.emptyArray,
  allBlockedBots: generalsEnums.emptyArray,
};

export const SecurityDashboard = createSlice({
  name: 'SecurityDashboard',
  initialState,
  reducers: {
    setTotals(state, action) {
      const {
        totalAPI,
        totalMx,
        totalUs,
        totalBlockIps,
        totalBlockBots,
        allRequests,
        allMxRequests,
        allUsRequests,
        allBlockedRequests,
        allBlockedBots,
      } = action.payload;

      state.totalAPI = totalAPI;
      state.totalMx = totalMx;
      state.totalUs = totalUs;
      state.totalBlockIps = totalBlockIps;
      state.totalBlockBots = totalBlockBots;
      state.allRequests = allRequests;
      state.allMxRequests = allMxRequests;
      state.allUsRequests = allUsRequests;
      state.allBlockedRequests = allBlockedRequests;
      state.allBlockedBots = allBlockedBots;
    },
    setMinDate(state, action) {
      state.minDate = action.payload;
    },
    setMaxDate(state, action) {
      state.maxDate = action.payload;
    },
  },
});

export const { setTotals, setMinDate, setMaxDate } = SecurityDashboard.actions;

export const selectTotalAPI = (state) => state.SecurityDashboard.totalAPI;
export const selectTotalMx = (state) => state.SecurityDashboard.totalMx;
export const selectTotalUs = (state) => state.SecurityDashboard.totalUs;
export const selectTotalBlockIps = (state) => state.SecurityDashboard.totalBlockIps;
export const selectTotalBlockBots = (state) => state.SecurityDashboard.totalBlockBots;
export const selectMinDate = (state) => state.SecurityDashboard.minDate;
export const selectMaxDate = (state) => state.SecurityDashboard.maxDate;
export const selectAllRequests = (state) => state.SecurityDashboard.allRequests;
export const selectAllMxRequests = (state) => state.SecurityDashboard.allMxRequests;
export const selectAllUsRequests = (state) => state.SecurityDashboard.allUsRequests;
export const selectAllBlockedRequests = (state) => state.SecurityDashboard.allBlockedRequests;
export const selectAllBlockedBots = (state) => state.SecurityDashboard.allBlockedBots;

export default SecurityDashboard.reducer;
