import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, IconButton, Popover, Checkbox, Dialog } from '@mui/material';

import ClientsTable from './ClientsTable';
import Users from '../../../../entities/Users';
import Font from '../../../../Components/Generals/Fonts/Font';
import { useStyles } from './ClientsView.styles';
import {
  setBackDropState,
  setSnackBar,
  setUpdateData,
  selectUpdateData,
  setClientInfoModalState,
  setClientInfoOnlyView,
} from '../../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../../assets/GlobalColors';

const users = new Users();

const ClientsView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [clients, setClients] = React.useState([]);
  const [clientsSelected, setClientsSelected] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [message, setMessage] = React.useState('');
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const update = useSelector(selectUpdateData);

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleAproveDelete = async (e) => {
    e.preventDefault();

    dispatch(setBackDropState(true));

    const body = {
      clientsIds: clientsSelected,
    };

    const res = await users.deleteClientsByIds(body);

    if (res.statusCode === 200) {
      dispatch(setBackDropState(false));
      dispatch(setUpdateData(true));
      setDeleteModal(false);
    } else {
      dispatch(setBackDropState(false));
      setDeleteModal(false);
    }
  };

  const handleAddButton = (e) => {
    e.preventDefault();
    dispatch(setClientInfoOnlyView(false));
    dispatch(setClientInfoModalState(true));
  };

  const handleDeleteButton = async (e) => {
    e.preventDefault();
    if (clients.length > 0) {
      if (clientsSelected.length === 0) {
        setMessage(t('admin.ClientsView.noSelected'));
        setAnchorEl(e.currentTarget);
      } else {
        setDeleteModal(true);
      }
    } else if (clients.length === 0) {
      setMessage(t('admin.ClientsView.noClients'));
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckOneClient = (e, id) => {
    if (e.target.checked) {
      setClientsSelected([...clientsSelected, id]);
    } else {
      const newClientsSelected = clientsSelected.filter((clientId) => clientId !== id);
      setClientsSelected(newClientsSelected);
    }
  };

  const handleSelectAllClients = () => {
    if (!selectedAll) {
      const clientsIds = clients.map((client) => client._id);
      setClientsSelected(clientsIds);
    } else {
      setClientsSelected([]);
    }
    setSelectedAll(!selectedAll);
  };

  const getClientsFromApi = async () => {
    dispatch(setBackDropState(true));
    const response = await users.getClients();

    if (response.statusCode === 200) {
      dispatch(setBackDropState(false));

      if (response.clients.length > 0) {
        setClients(response.clients);
      } else {
        setClients([]);
        setSelectedAll(false);
        setClientsSelected(false);
      }
      dispatch(setUpdateData(false));
    } else {
      dispatch(setBackDropState(false));
      dispatch(setUpdateData(false));
    }
  };

  React.useEffect(async () => {
    if (update === true) {
      getClientsFromApi();
    }
  }, [update]);

  React.useEffect(() => {
    getClientsFromApi();
  }, []);

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.headerBox}>
        <Font text={t('admin.ClientsView.title')} color="#000000" size={24} lineHeight="32px" isHead={true} />

        <Box component="div" className={classes.actionsBox}>
          <IconButton
            onClick={handleDeleteButton}
            aria-describedby={id}
            aria-label="delete"
            className={classes.deleteButton}
          >
            <DeleteIcon />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography variant="string" className="text-16" sx={{ p: 4 }}>
              {message}
            </Typography>
          </Popover>

          <Button onClick={handleAddButton} variant="contained" className={classes.addButton}>
            <Font text={t('admin.ClientsView.add')} color={colors.BLACK} size={16} lineHeight="26px" isHead={false} />
          </Button>
        </Box>
      </Box>

      <ClientsTable
        rows={clients}
        t={t}
        classes={classes}
        handleCheckOneClient={handleCheckOneClient}
        clientsSelected={clientsSelected}
        handleSelectAllClients={handleSelectAllClients}
        selectedAll={selectedAll}
      />

      <Dialog
        PaperProps={{
          style: {
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            padding: '10px 15px',
            borderRadius: '8px',
          },
        }}
        onClose={handleCloseDeleteModal}
        open={deleteModal}
        id="delete-modal"
      >
        <Box component="div" className={classes.deleteBox}>
          <Font
            text={t('admin.ClientsView.delete')}
            color={colors.BLACK_06}
            size={16}
            lineHeight="26px"
            isHead={false}
          />

          <Box component="div" className={classes.deleteButtonsBox}>
            <Button onClick={handleCloseDeleteModal} variant="contained" className={classes.cancelButton}>
              <Font
                text={t('Generals.cancelAction')}
                color={colors.BLACK_06}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>

            <Button onClick={handleAproveDelete} variant="contained" className={classes.deleteButtonDialog}>
              <Font text={t('Generals.ok')} color={colors.WHITE} size={14} lineHeight="22px" isHead={false} />
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ClientsView;
