import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

const useStyles = makeStyles({
  root: {
    width: '400px',
    height: '122px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: colors.WHITE,
    gap: '4px',
    marginBottom: '6px',

    '&:hover': {
      backgroundColor: colors.WHITE,
      opacity: '0.8',
    },
  },
  BoxImage: {
    width: '120px',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.GRAY_240_1,
  },
  Image: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
  },
  ProductInfo: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
});

export { useStyles, colors };
