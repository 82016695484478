import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

const useStyles = makeStyles({
  AccordionSummary: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  AccordionSummaryBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  AccordionSummaryInfoBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
  },
  AccordionSummaryInfoItemBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2px',
  },
});

export { useStyles, colors };
