import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Font from '../../../../Components/Generals/Fonts/Font';
import GreenButton from '../../../../Components/Generals/GreenButton';
import WhiteButton from '../../../../Components/Generals/WhiteButton';
import OrderStatusTable from './OrderStatusTable';
import OrderStatus from '../../../../entities/OrderStatus';
import { useStyles } from './OrdersStatus.styles';
import { setBackDropState } from '../../../../Redux/Slices/Navigation.Slices';
import {
  fetchOrderStatusFromApi,
  selectOrderStatus,
  setStatusToEdit,
  selectStatusToEdit,
  selectAvailableSabe,
} from '../../../../Redux/Slices/OrderStatus.Slices';
import * as colors from '../../../../assets/GlobalColors';

const orderStatusAPI = new OrderStatus();

const OrdersStatusPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [data, setData] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const orderStatus = useSelector(selectOrderStatus);
  const statusToEdit = useSelector(selectStatusToEdit);
  const availableSave = useSelector(selectAvailableSabe);

  const handleEditStatus = (e) => {
    e.preventDefault();
    setEdit(!edit);
  };

  const handleSaveStatus = async (e) => {
    e.preventDefault();
    dispatch(setBackDropState(true));
    const statusToSave = statusToEdit.filter((status) => status.isEditing === true);

    const res = await orderStatusAPI.editOrderStatus({
      statusToEdit: statusToSave,
    });

    if (res.statusCode === 200) {
      dispatch(fetchOrderStatusFromApi());
      setEdit(false);
      dispatch(setBackDropState(false));
    } else dispatch(setBackDropState(false));
  };

  React.useEffect(async () => {
    dispatch(setBackDropState(true));
    try {
      dispatch(fetchOrderStatusFromApi());
      dispatch(setBackDropState(false));
    } catch (error) {
      dispatch(setBackDropState(false));
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (orderStatus) {
      setData(orderStatus);
    }
  }, [orderStatus]);

  React.useEffect(() => {
    dispatch(
      setStatusToEdit({
        edit: edit,
      })
    );
  }, [edit]);

  return (
    <Container maxWidth="xl">
      <Box component="div" className={classes.root}>
        <Font text={t('admin.OrdersStatus.title')} color={colors.BLACK} size={22} lineHeight="28px" isHead={true} />

        <Font
          text={t('admin.OrdersStatus.description')}
          color={colors.BLACK_06}
          size={20}
          lineHeight="26px"
          isHead={false}
        />

        {edit === false ? (
          <GreenButton
            icon={<EditIcon className={classes.EditIcon} />}
            text={t('Generals.edit')}
            handle={handleEditStatus}
            width={100}
            disabled={false}
          />
        ) : (
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <WhiteButton icon={<></>} text={t('Generals.cancelAction')} handle={handleEditStatus} width={100} />

            <GreenButton
              icon={<></>}
              text={t('Generals.save')}
              handle={handleSaveStatus}
              width={100}
              disabled={availableSave}
            />
          </Box>
        )}

        <OrderStatusTable data={edit === true ? statusToEdit : data} colors={colors} edit={edit} />
      </Box>
    </Container>
  );
};

export default OrdersStatusPage;
