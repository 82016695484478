import { makeStyles } from '@mui/styles';

import * as colors from '../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '20px',
  },
  userSection: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    padding: '10px 10px 10px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '20px',
    border: `1px solid ${colors.GRAY_214}`,
  },
  userData: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '5px',
  },
  buttonSignOut: {
    width: '250px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: colors.TSADMIN_RED_DELETE,
    color: colors.WHITE,
    border: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colors.TSADMIN_RED_DELETE,
      boxShadow: 'none',
      border: 'none',
      color: colors.WHITE,
      opacity: 0.8,
      transition: 'all 0.3s ease',
    },
  },
  Outlet: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
