import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { signout } from '../../axios/lambdas/userAuth';

const initialState = {
  token: null,
  exp: null,
  user_id: null,
  name: null,
  email: null,
  userType: 'GUEST',
  emailAccountToVerify: null,
  sub: null,
  lastName: null,
  workEnterprise: null,
  phoneNumber: null,
  allowMarketingEmails: null,
  language: 'es',
  logoId: '',
  logo: {},
  size: 0,
  height: '',
  faviconId: '',
  faviconImage: '',
  storeName: '',
};

export const fetchSignout = createAsyncThunk('User/fetchSignout', async (email) => {
  const response = await signout(email);
  return response;
});

export const UserSlices = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setSignout: (state, action) => {
      state.token = null;
      state.exp = null;
      state.user_id = null;
      state.name = null;
      state.email = null;
      state.userType = 'GUEST';
      state.emailAccountToVerify = null;
      state.sub = null;
      state.lastName = null;
      state.workEnterprise = null;
      state.phoneNumber = null;
      state.allowMarketingEmails = null;
      state.language = 'es';
    },
    setValue: (state, action) => {
      state.token = action.payload;
    },
    setEmailAccountToVerify: (state, action) => {
      state.emailAccountToVerify = action.payload;
    },
    setUserInformation: (state, action) => {
      state.token = action.payload.token;
      state.exp = action.payload.exp;
      state.user_id = action.payload.user_id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.userType = action.payload.userType;
      state.sub = action.payload.sub;
      state.lastName = action.payload.lastName;
      state.workEnterprise = action.payload.workEnterprise;
      state.phoneNumber = action.payload.phoneNumber;
      state.allowMarketingEmails = action.payload.allowMarketingEmails;
      state.language = action.payload.language;
    },
    setUserToken: (state, action) => {
      state.token = action.payload.token;
      state.exp = action.payload.exp;
    },
    setUserUpdated: (state, action) => {
      state.name = action.payload.name;
      state.lastName = action.payload.lastName;
      state.phoneNumber = action.payload.phoneNumber;
      state.workEnterprise = action.payload.workEnterprise;
      state.allowMarketingEmails = action.payload.allowMarketingEmails;
    },
    setUpdateAdminUser: (state, action) => {
      state.name = action.payload.name;
      state.lastName = action.payload.lastName;
      state.language = action.payload.language;
    },
    setLogoId: (state, action) => {
      state.logoId = action.payload;
    },
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
    setLogoSize: (state, action) => {
      state.size = action.payload;
    },
    setLogoHeight: (state, action) => {
      state.height = action.payload;
    },
    setFaviconId: (state, action) => {
      state.faviconId = action.payload;
    },
    setFaviconImage: (state, action) => {
      state.faviconImage = action.payload;
    },
    setStoreName: (state, action) => {
      state.storeName = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSignout.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.exp = action.payload.exp;
      state.user_id = action.payload.user_id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.userType = action.payload.userType;
      state.emailAccountToVerify = action.payload.emailAccountToVerify;
    });
  },
});

export const {
  setSignout,
  setValue,
  setEmailAccountToVerify,
  setUserInformation,
  setUserToken,
  setUserUpdated,
  setUpdateAdminUser,
  setLogoId,
  setLogo,
  setLogoSize,
  setLogoHeight,
  setFaviconId,
  setFaviconImage,
  setStoreName,
  setLanguage,
} = UserSlices.actions;

export const selectToken = (state) => state.User.token;
export const selectEmailAccountToVerify = (state) => state.User.emailAccountToVerify;
export const selectUserType = (state) => state.User.userType;
export const selectIsUserSigned = (state) => {
  return state.User.token === null ? false : true;
};
export const selectFirstName = (state) => {
  if (state.User.name !== null) {
    const nameArray = state.User.name.split(' ');
    return nameArray[0];
  }
};

export const isLogged = (state) => {
  return state.User.token !== null ? true : false;
};
export const selectUserId = (state) => state.User.user_id;
export const selectExp = (state) => state.User.exp;
export const selectName = (state) => state.User.name;
export const selectLastName = (state) => state.User.lastName;
export const selectEmail = (state) => state.User.email;
export const selectPhoneNumber = (state) => state.User.phoneNumber;
export const selectWorkEnterprise = (state) => state.User.workEnterprise;
export const selectAllowMarketingEmails = (state) => state.User.allowMarketingEmails;
export const selectSub = (state) => state.User.sub;
export const selectLanguage = (state) => state.User.language;
export const selectLogoId = (state) => state.User.logoId;
export const selectLogo = (state) => state.User.logo;
export const selectLogoSize = (state) => state.User.size;
export const selectLogoHeight = (state) => state.User.height;
export const selectFaviconId = (state) => state.User.faviconId;
export const selectFaviconImage = (state) => state.User.faviconImage;
export const selectStoreName = (state) => state.User.storeName;
export const selectLanguageStore = (state) => state.User.language;
export const selectFullName = (state) => `${state.User.name} ${state.User.lastName}`;
export default UserSlices.reducer;
