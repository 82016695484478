import React from 'react';
import Image from 'react-bootstrap/esm/Image';
import { FileUploader } from 'react-drag-drop-files';
import { Box, IconButton, Button } from '@mui/material';

import close from '../../../../assets/Icons/close.svg';
import Font from '../../../../Components/Generals/Fonts/Font';

const Favicon = ({ classes, colors, t, generalsEnums, faviconObject, handleSetFaviconImage }) => {
  const [image, setImage] = React.useState(generalsEnums.emptyString);

  const handleRemoveImage = () => {
    setImage(generalsEnums.emptyString);
  };

  const handleChangeImage = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result);
      handleSetFaviconImage(reader.result);
    };
  };

  React.useEffect(() => {
    if (faviconObject !== generalsEnums.emptyObject) {
      setImage(faviconObject.image);
    }
  }, [faviconObject]);

  return (
    <Box
      component="div"
      className={classes.logoRoot}
      sx={{
        border: `1px solid ${colors.BLACK}`,
        backgroundColor: colors.WHITE,
      }}
    >
      <Font
        text={t('admin.LogoAndFavicon.favicon.title')}
        color={colors.BLACK_06}
        size={generalsEnums.fontSizes.size16.size}
        lineHeight={generalsEnums.fontSizes.size16.lineHeight}
        isHead={true}
      />

      <Box
        component="div"
        className={classes.ItemBox}
        sx={{
          gap: '24px',
        }}
      >
        <Box
          component="div"
          className={classes.ItemBox}
          sx={{
            borderRadius: '8px 8px 0px 0px',
            padding: '9px 13px',
            backgroundColor: colors.GRAY_204_1,
            border: `1px solid ${colors.GRAY_204_1}`,
            gap: '21px',
          }}
        >
          <Image src={image} alt={faviconObject.text} />

          <Font
            text={faviconObject.text}
            color={colors.BLACK_06}
            size={generalsEnums.fontSizes.size16.size}
            lineHeight={generalsEnums.fontSizes.size16.lineHeight}
            isHead={true}
          />

          {image !== generalsEnums.emptyString ? (
            <IconButton
              onClick={handleRemoveImage}
              sx={{
                padding: '0px',
              }}
            >
              <Image src={close} alt="close" />
            </IconButton>
          ) : null}
        </Box>

        {image !== generalsEnums.emptyString ? (
          <Button
            onClick={handleRemoveImage}
            variant="text"
            sx={{
              padding: '0px',
              borderBottom: `1px solid ${colors.BLACK_06}`,
              borderRadius: '0px',
            }}
          >
            <Font
              text={t('admin.LogoAndFavicon.favicon.replaceImage')}
              color={colors.BLACK_06}
              size={generalsEnums.fontSizes.size14.size}
              lineHeight={generalsEnums.fontSizes.size14.lineHeight}
              isHead={true}
            />
          </Button>
        ) : null}
      </Box>

      {image === generalsEnums.emptyString ? (
        <FileUploader
          handleChange={handleChangeImage}
          name="file"
          types={['png', 'jpeg', 'jpg']}
          label={''}
          children={
            <Box
              component="div"
              style={{
                width: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
                border: `1px dashed ${colors.GRAY_204_1}`,
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              <Font
                text={t('admin.LogoAndFavicon.logo.fileUploader.title')}
                color={colors.BLACK_06}
                size={generalsEnums.fontSizes.size16.size}
                lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                isHead={true}
              />
              <Font
                text={t('admin.LogoAndFavicon.logo.fileUploader.or')}
                color={colors.BLACK_06}
                size={generalsEnums.fontSizes.size16.size}
                lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                isHead={false}
              />
              <Font
                text={t('admin.LogoAndFavicon.logo.fileUploader.browse')}
                color={colors.BLACK_06}
                size={generalsEnums.fontSizes.size16.size}
                lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                isHead={false}
              />
            </Box>
          }
        />
      ) : null}

      <Font
        text={t('admin.LogoAndFavicon.favicon.imageSize')}
        color={colors.BLACK_06}
        size={generalsEnums.fontSizes.size12.size}
        lineHeight={generalsEnums.fontSizes.size12.lineHeight}
        isHead={true}
      />

      <Font
        text={t('admin.LogoAndFavicon.favicon.imageFormats')}
        color={colors.BLACK_06}
        size={generalsEnums.fontSizes.size12.size}
        lineHeight={generalsEnums.fontSizes.size12.lineHeight}
        isHead={true}
      />
    </Box>
  );
};

export default Favicon;
