import { makeStyles } from '@mui/styles';

import * as colors from '../../assets/GlobalColors';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginBottom: '10px',
  },
  navContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    height: '80px',
    padding: '10px 0px 10px 0px',
  },
  logo: {
    width: '160px',
    height: '70px',
  },
  userNav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: 'auto',
    gap: '10px',
  },
  shoppingCartButton: {
    width: 'auto',
    height: '40px',
    backgroundColor: 'transparent',
    border: 'none',
    gap: '10px',
  },
  Icon: {
    width: '19px',
    height: '19px',
  },
  userButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '160px',
    height: '40px',
    textTransform: 'none',
    gap: '10px',
    border: 'none',
    backgroundColor: 'transparent',
  },
  userSignedButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '160px',
    height: '40px',
    textTransform: 'none',
    gap: '10px',
    border: 'none',
    backgroundColor: 'transparent',
  },
  categoriesNav: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.GREEN_MAIN,
    gap: '64px',
    padding: '12px',
  },
  mainCategoriesFilter: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backButton: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    color: colors.BLACK,

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      opacity: '0.8',
      color: colors.WHITE,
    },
  },
  EmptyCart: {
    width: '50px',
    height: '50px',
  },
});
