import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '50px',
  },
  infoBox: {
    width: '650px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '10px 10px 10px 10px',
  },
  infoRowBox: {
    gap: '5px',
  },
  keepShoppingButton: {
    width: '200px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: colors.GREEN_MAIN,
    border: `1px solid ${colors.GREEN_MAIN}`,
    marginTop: '25px',

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      border: `1px solid ${colors.GREEN_MAIN}`,
      opacity: '0.8',
    },
  },
});
