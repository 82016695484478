import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  addressRoot: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '10px',
    borderBottom: `1px solid ${colors.GRAY_CCC}`,
  },
  addressInfoBox: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '10px',
  },
  addressButtonsBox: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '10px',
  },
  optionButtonRoot: {
    width: '100px',
    height: '40px',
    borderRadius: '5px',
    padding: '12px 25px 12px 25px',
    gap: '10px',
  },
  emptyAddressesRoot: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '20px',
  },
  addAddressButton: {
    width: '200px',
    height: '40px',
    borderRadius: '6px',
    backgroundColor: colors.GREEN_MAIN,
    boxShadow: 'none',
    border: `1px solid ${colors.GREEN_MAIN}`,

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      boxShadow: 'none',
      opacity: '0.8',
    },
  },
});
