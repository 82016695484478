import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class OrderStatus extends Axios {
  constructor() {
    super();
    this.urlGetAllOrderStatus = API_ROUTES.status.getAllOrderStatus;
    this.urleditOrderStatus = API_ROUTES.status.editOrderStatus;
  }

  getAllOrderStatus() {
    return this.get(this.urlGetAllOrderStatus);
  }

  editOrderStatus(body) {
    return this.post(this.urleditOrderStatus, body);
  }
}

export default OrderStatus;
