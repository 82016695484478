import React from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInMonths } from 'date-fns';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import LineCharts from './LineChart';
import generalsEnums from '../../../utils/Generals';
import AdminScreenTemplate from '../../../utils/templates/AdminScreen.Template.jsx';
import ResponsiveComponent from '../../../Hooks/ResponsiveComponent';
import Font from '../../../Components/Generals/Fonts/Font';
import HeaderItem from './HeaderItem.jsx';
import Orders from '../../../entities/Orders';
import DatePicker from '../../../Components/Generals/Figma/Molecules/DatePicker';
import NativeComboBox from '../../../Components/Generals/NativeComboBox';
import { useStyles } from './styles.js';
import { selectName } from '../../../Redux/Slices/User.Slices.js';
import { getResponsiveValues } from '../../../utils/FormatData';
import {
  selectTotalRevenue,
  selectAverageOrderCost,
  selectProfitMargin,
  selectMinDate,
  selectMaxDate,
  setTotalRevenue,
  setAverageOrderCost,
  setMinDate,
  setMaxDate,
  setProfitMargin,
} from '../../../Redux/Slices/Dashboard.Slices';
import * as colors from '../../../assets/GlobalColors.js';

const AdminPortalHome = ({
  titleSize,
  titleLineHeight,
  headerItemTitleSize,
  headerItemTitleLineHeight,
  subtitleSize,
  subtitleLineHeight,
}) => {
  const { t } = useTranslation('global');
  const classes = useStyles();
  const ordersAPI = new Orders();
  const dispatch = useDispatch();

  const [months, setMonths] = React.useState(generalsEnums.zeroNumber);
  const [currentMaxDate, setCurrentMaxDate] = React.useState();
  const [dataArray, setDataArray] = React.useState(generalsEnums.emptyArray);
  const [dayiliReport, setDayiliReport] = React.useState(generalsEnums.emptyArray);
  const [monthlyReport, setMonthlyReport] = React.useState(generalsEnums.emptyArray);
  const [report, setReport] = React.useState(0);

  const userName = useSelector(selectName);
  const totalRevenue = useSelector(selectTotalRevenue);
  const averageOrderCost = useSelector(selectAverageOrderCost);
  const profitMargin = useSelector(selectProfitMargin);
  const minDate = useSelector(selectMinDate);
  const maxDate = useSelector(selectMaxDate);

  const comboBoxArr = [
    {
      value: 0,
      label: t('AdminPortalHome.chart.dayiliReport'),
    },
    {
      value: 1,
      label: t('AdminPortalHome.chart.monthlyReport'),
    },
  ];

  const handleChangeMinDate = (newValue) => {
    const date = new Date(newValue);
    date.setHours(0, 0, 0, 0);
    dispatch(setMinDate(date.toISOString()));
  };

  const handleChangeMaxDate = (newValue) => {
    const date = new Date(newValue);
    date.setHours(23, 59, 59, 999);
    dispatch(setMaxDate(date.toISOString()));
  };

  const handleChangeReport = (e) => {
    const { value } = e.target;
    setReport(value);
  };

  React.useEffect(async () => {
    const getDashboardOrdersRes = await ordersAPI.getDashboardOrders(minDate, maxDate);
    const { data } = getDashboardOrdersRes;
    const { totalRevenue, averageOrderCost, profitPercentage, reports } = data;
    const { dayiliReport: dayliReportData, monthlyReport: monthlyReportData } = reports;
    dispatch(setTotalRevenue(totalRevenue));
    dispatch(setAverageOrderCost(averageOrderCost));
    dispatch(setProfitMargin(profitPercentage));
    const monthsDifference = differenceInMonths(new Date(maxDate), new Date(minDate));
    setMonths(monthsDifference);
    setCurrentMaxDate(new Date(maxDate));
    setDayiliReport(dayliReportData);
    setMonthlyReport(monthlyReportData);
  }, [minDate, maxDate]);

  React.useEffect(() => {
    if (report === 0) {
      setDataArray(dayiliReport);
    } else if (report === 1) {
      setDataArray(monthlyReport);
    }
  }, [report, dayiliReport, monthlyReport]);

  return (
    <AdminScreenTemplate gap={'12px'}>
      <Font
        text={`${t('AdminPortalHome.title')}${userName}`}
        color={colors.BLACK}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.header}
        sx={{
          backgroundColor: colors.GREEN_MAIN_3,
          border: `1px solid ${colors.GREEN_MAIN_3}`,
        }}
      >
        <HeaderItem
          headerItemTitleSize={headerItemTitleSize}
          headerItemTitleLineHeight={headerItemTitleLineHeight}
          title={t('AdminPortalHome.HeaderItem.totalRevenuetitle')}
          subtitle={`${t('AdminPortalHome.HeaderItem.last')} ${months || 1} ${t('AdminPortalHome.HeaderItem.months')}`}
          value={totalRevenue}
        />

        <HeaderItem
          headerItemTitleSize={headerItemTitleSize}
          headerItemTitleLineHeight={headerItemTitleLineHeight}
          title={t('AdminPortalHome.HeaderItem.averageOrderCosttitle')}
          subtitle={generalsEnums.currencies.usd}
          value={averageOrderCost}
        />

        <HeaderItem
          headerItemTitleSize={headerItemTitleSize}
          headerItemTitleLineHeight={headerItemTitleLineHeight}
          title={t('AdminPortalHome.HeaderItem.profitMargintitle')}
          subtitle={t('AdminPortalHome.HeaderItem.onavg')}
          value={profitMargin}
        />
      </Box>

      <Font
        text={`${t('AdminPortalHome.chart.title1')} ${months || 1} ${t('AdminPortalHome.chart.title2')}`}
        color={colors.GREEN_MAIN}
        size={subtitleSize}
        lineHeight={subtitleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Box component={generalsEnums.BoxDiv} className={classes.header}>
        <LineCharts dataArray={dataArray} />

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
          }}
        >
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
            }}
          >
            <DatePicker
              initialValue={minDate}
              currentMaxDate={currentMaxDate}
              handleChangeDate={handleChangeMinDate}
              text={
                <Font
                  text={t('AdminPortalHome.chart.minDate')}
                  color={colors.BLACK_06}
                  size={headerItemTitleSize}
                  lineHeight={headerItemTitleLineHeight}
                  isHead={generalsEnums.trueBoolean}
                  ml="0px"
                />
              }
            />

            <DatePicker
              initialValue={maxDate}
              currentMaxDate={new Date()}
              handleChangeDate={handleChangeMaxDate}
              text={
                <Font
                  text={t('AdminPortalHome.chart.maxDate')}
                  color={colors.BLACK_06}
                  size={headerItemTitleSize}
                  lineHeight={headerItemTitleLineHeight}
                  isHead={generalsEnums.trueBoolean}
                  ml="0px"
                />
              }
            />
          </Box>

          <NativeComboBox
            w="100%"
            valueForm={report}
            label={t('AdminPortalHome.chart.cbtitle')}
            labelHead={generalsEnums.trueBoolean}
            labelColor={colors.TEXT_GRAY_102}
            data={comboBoxArr}
            helperText={generalsEnums.undefinedData}
            change={(e) => {
              handleChangeReport(e);
            }}
          />
        </Box>
      </Box>
    </AdminScreenTemplate>
  );
};

const Main = () => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size30.size,
      generalsEnums.fontSizes.size34.size,
      generalsEnums.fontSizes.size36.size,
      generalsEnums.fontSizes.size36.size,
    ],
    [
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size30.lineHeight,
      generalsEnums.fontSizes.size34.lineHeight,
      generalsEnums.fontSizes.size36.lineHeight,
      generalsEnums.fontSizes.size36.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size8.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size18.size,
    ],
    [
      generalsEnums.fontSizes.size8.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size20.size,
      generalsEnums.fontSizes.size22.size,
      generalsEnums.fontSizes.size24.size,
      generalsEnums.fontSizes.size24.size,
    ],
    [
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size20.lineHeight,
      generalsEnums.fontSizes.size22.lineHeight,
      generalsEnums.fontSizes.size24.lineHeight,
      generalsEnums.fontSizes.size24.lineHeight,
    ],
  ];

  const [titleSize, titleLineHeight, headerItemTitleSize, headerItemTitleLineHeight, subtitleSize, subtitleLineHeight] =
    getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);
  return (
    <AdminPortalHome
      titleSize={titleSize}
      titleLineHeight={titleLineHeight}
      headerItemTitleSize={headerItemTitleSize}
      headerItemTitleLineHeight={headerItemTitleLineHeight}
      subtitleSize={subtitleSize}
      subtitleLineHeight={subtitleLineHeight}
    />
  );
};

export default Main;
