import React from 'react';
import { Box, TextField, Button, IconButton, Radio, FormControl, Select } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDispatch, useSelector } from 'react-redux';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';

import { useStyles } from './NewVariants.styles';
import Font from '../../../../Components/Generals/Fonts/Font';
import {
  setNameNewVariant,
  setTypesNewVariant,
  setDeleteNewVariant,
  setValuesNewVariantValue,
  setDeleteValuesNewVariantValue,
  setAddValuesNewVariantValue,
  setUpdatePositionsNewVariantValue,
  setDefaultNewVariantValue,
} from '../../../../Redux/Slices/Products.Slices';
import * as colors from '../../../../assets/GlobalColors';

const Variant = ({ draggableId, index, item, t }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [items, setItems] = React.useState([]);
  const [selected, setSelected] = React.useState('');

  const handleClickDelete = (e) => {
    e.preventDefault();
    dispatch(setDeleteNewVariant({ sku: item.sku }));
  };

  const handleChangeName = (e) => {
    dispatch(setNameNewVariant({ sku: item.sku, name: e.target.value }));
  };

  const handleChangeType = (e) => {
    setSelected(e.target.value);
    dispatch(setTypesNewVariant({ sku: item.sku, type: e.target.value }));
  };

  const handleChangeValue = (e, value) => {
    dispatch(setValuesNewVariantValue({ sku: item.sku, value: e.target.value, valueSku: value.valueSku }));
  };

  const handleClickDeleteValue = (e, value) => {
    e.preventDefault();
    dispatch(setDeleteValuesNewVariantValue({ sku: item.sku, valueSku: value.valueSku }));
  };

  const handleClickAddValue = (e) => {
    e.preventDefault();
    dispatch(setAddValuesNewVariantValue({ sku: item.sku }));
  };

  const handleClickDefault = (e, index) => {
    e.preventDefault();
    dispatch(setDefaultNewVariantValue({ sku: item.sku, index: index }));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === 0) {
      dispatch(setDefaultNewVariantValue({ sku: item.sku, index: result.source.index }));
    } else {
      const updatedItems = reorder(items, result.source.index, result.destination.index);

      setItems(updatedItems);

      dispatch(setUpdatePositionsNewVariantValue({ sku: item.sku, updatedItems: updatedItems }));
    }
  };

  React.useEffect(() => {
    setItems(item.values);
  }, [item]);

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div className={classes.Variant} ref={provided.innerRef} {...provided.draggableProps}>
          <Box component="div" {...provided.dragHandleProps}>
            <DragIndicatorIcon sx={{ color: colors.BLACK_06 }} />
          </Box>

          <Box component="div" className={classes.textFieldItem}>
            <Font
              text={t('admin.NewVariants.name')}
              color={colors.BLACK_06}
              size={14}
              lineHeight="22px"
              isHead={false}
            />

            <TextField
              value={item.name}
              type="text"
              size="small"
              placeholder={t('admin.NewVariants.name')}
              onChange={(e) => {
                handleChangeName(e);
              }}
              id="name-variant"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: 200,
                color: colors.BLACK,
                '& label.Mui-focused': {
                  color: colors.BLACK_06,
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: colors.BLACK_06,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                },
              }}
            />

            <Button variant="text" onClick={handleClickDelete}>
              <Font
                text={t('admin.NewVariants.delete')}
                color={colors.TSADMIN_RED_DELETE}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </Button>
          </Box>

          <Box component="div" className={classes.textFieldItem}>
            <Font
              text={t('admin.NewVariants.type')}
              color={colors.BLACK_06}
              size={14}
              lineHeight="22px"
              isHead={false}
            />

            <Box
              component="div"
              sx={{
                width: 200,
              }}
            >
              <FormControl fullWidth>
                <Select
                  name={t('admin.NewVariants.type')}
                  value={selected}
                  onChange={(e) => {
                    handleChangeType(e);
                  }}
                  fullWidth
                  size="small"
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <Font
                          text={t('admin.NewVariants.type')}
                          color={colors.BLACK}
                          size={14}
                          lineHeight="22px"
                          isHead={false}
                        />
                      );
                    } else {
                      return <Font text={selected} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />;
                    }
                  }}
                  displayEmpty
                  sx={{
                    color: colors.BLACK,
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.BLACK_06,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.BLACK_06,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: colors.BLACK_06,
                    },
                    '.MuiSvgIcon-root ': {
                      fill: colors.BLACK_06,
                    },
                  }}
                >
                  <option value={'Menú desplegable'}>Menú desplegable</option>
                </Select>
              </FormControl>
            </Box>

            {/* <TextField
              value={item.type}
              type="text"
              size="small"
              placeholder={t('admin.NewVariants.type')}
              onChange={(e) => {
                handleChangeType(e);
              }}
              id="name-variant"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: 200,
                color: colors.BLACK,
                '& label.Mui-focused': {
                  color: colors.BLACK_06,
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: colors.BLACK_06,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                  '&:hover fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.BLACK_06,
                  },
                },
              }}
            /> */}
          </Box>

          <Box component="div" className={classes.textFieldItem}>
            <Font
              text={t('admin.NewVariants.valores')}
              color={colors.BLACK_06}
              size={14}
              lineHeight="22px"
              isHead={false}
            />

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    component="div"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      backgroundColor: colors.WHITE,
                      gap: '20px',
                    }}
                  >
                    {items.map((valueItem, index) => {
                      return (
                        <Draggable key={valueItem.id} draggableId={valueItem.id} index={index}>
                          {(provided, snapshot) => (
                            <div className={classes.valueItem} ref={provided.innerRef} {...provided.draggableProps}>
                              <Box component="div" {...provided.dragHandleProps}>
                                <DragIndicatorIcon sx={{ color: colors.BLACK_06 }} />
                              </Box>

                              <TextField
                                value={valueItem.name}
                                type="text"
                                size="small"
                                placeholder={t('admin.NewVariants.valores')}
                                onChange={(e) => {
                                  handleChangeValue(e, valueItem);
                                }}
                                id="name-variant"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{
                                  width: 300,
                                  color: colors.BLACK,
                                  backgroundColor: colors.WHITE,
                                  '& label.Mui-focused': {
                                    color: colors.BLACK_06,
                                  },
                                  '& .MuiInput-underline:after': {
                                    borderBottomColor: colors.BLACK_06,
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: colors.BLACK_06,
                                    },
                                    '&:hover fieldset': {
                                      borderColor: colors.BLACK_06,
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: colors.BLACK_06,
                                    },
                                  },
                                }}
                              />

                              <Radio
                                checked={valueItem.isDefault}
                                onChange={(e) => {
                                  handleClickDefault(e, index);
                                }}
                              />

                              <Font
                                text={t('admin.NewVariants.default')}
                                color={colors.BLACK_06}
                                size={14}
                                lineHeight="22px"
                                isHead={false}
                              />

                              <IconButton
                                onClick={(e) => {
                                  handleClickDeleteValue(e, valueItem);
                                }}
                              >
                                <RemoveCircleIcon
                                  sx={{
                                    color: colors.TSADMIN_RED_DELETE,
                                  }}
                                />
                              </IconButton>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}

                    <Button onClick={handleClickAddValue} variant="text" className={classes.addValueButton}>
                      <AddIcon
                        sx={{
                          color: colors.BLACK,
                        }}
                      />

                      <Font
                        text={t('admin.NewVariants.addValue')}
                        color={colors.BLACK}
                        size={14}
                        lineHeight="22px"
                        isHead={false}
                      />
                    </Button>
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </div>
      )}
    </Draggable>
  );
};

export default Variant;
