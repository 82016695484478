import React, { useState } from 'react';
import { Box, TextField, Autocomplete, MenuItem, ThemeProvider, createTheme } from '@mui/material';
import * as colors1 from '../../assets/GlobalColors';

const theme = createTheme({
  palette: {
    secondary: {
      main: colors1.GREEN_MAIN,
    },
  },
});

const SearchBrands = ({
  id,
  data,
  isBrands,
  value,
  onChange,
  label,
  labelColor,
  helperText,
  w,
  labelHead,
  disableCB,
  error,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filtra los datos basados en el término de búsqueda
  const filteredData = data.filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Maneja el cambio en el campo de búsqueda
  const handleSearchChange = (event, newValue) => {
    setSearchTerm(newValue);
  };

  // Maneja el cambio de selección en el Autocomplete
  const handleSelectChange = (event, newValue) => {
    setSearchTerm('');
    onChange({ target: { value: newValue ? newValue.value : '' } });
  };

  return (
    <ThemeProvider theme={theme}> 
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
          width: w !== undefined ? w : '100%',
          "& .MuiInputBase-root": { height: "40px" },
        }}
      >
        <Autocomplete
          id={`autocomplete-${id}`}
          options={filteredData}
          getOptionLabel={(option) => option.label}
          value={filteredData.find((item) => item.value === value) || null}
          onChange={handleSelectChange}
          inputValue={searchTerm}
          onInputChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              color="secondary"
              label="Type Brand"
              variant="outlined"
              size="small"
              sx={{
                marginBottom: '6px',
              }}
            />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.value} value={option.value}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{option.label}</div>
                <div 
                  style={{ color: colors1.TEXT_GRAY_102, fontSize: '0.95rem'}}
                >
                  {option.value}
                </div>
              </div>
            </MenuItem>
          )}
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              display: 'none', 
            },
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default SearchBrands;
