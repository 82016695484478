import React from 'react';
import { Button } from '@mui/material';

import Font from './Fonts/Font';
import * as colors from '../../assets/GlobalColors';

const WhiteButton = ({ icon, text, handle, disabled, buttonWidth, buttonBorder, isHead }) => {
  return (
    <Button
      disabled={disabled}
      onClick={handle}
      variant="contained"
      sx={{
        width: buttonWidth ? buttonWidth : 180,
        height: 40,
        borderRadius: '8px',
        border: `1px solid ${buttonBorder ? buttonBorder : colors.WHITE}`,
        backgroundColor: colors.WHITE,
        boxShadow: 'none',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        textAlign: 'left',

        '&:hover': {
          border: `1px solid ${buttonBorder ? buttonBorder : colors.WHITE}`,
          backgroundColor: colors.WHITE,
          boxShadow: 'none',
          opacity: 0.8,
        },
      }}
    >
      {icon}
      <Font text={text} color={colors.BLACK} size={14} lineHeight="20px" isHead={isHead ? isHead : false} ml="0px" />
    </Button>
  );
};

export default WhiteButton;
