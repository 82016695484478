import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class Addresses extends Axios {
  constructor() {
    super();
    this.urlGetAllAddressesByUserId = API_ROUTES.addresses.getAllAddressesByUserId;
    this.urlCreateUsersAddressesHandler = API_ROUTES.addresses.createUsersAddressesHandler;
    this.urldeleteUserAddressHandler = API_ROUTES.addresses.deleteUserAddressHandler;
    this.urleditAddressHandler = API_ROUTES.addresses.editAddressHandler;
  }

  getAllAddressesByUserId(userId) {
    return this.get(this.urlGetAllAddressesByUserId.replace(':userId', userId));
  }

  createUsersAddressesHandler(body) {
    return this.post(this.urlCreateUsersAddressesHandler, body);
  }

  deleteUserAddressHandler(body) {
    return this.post(this.urldeleteUserAddressHandler, body);
  }

  editAddressHandler(body) {
    return this.post(this.urleditAddressHandler, body);
  }
}

export default Addresses;
