import React from 'react';
import uuid from 'react-uuid';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Box, IconButton } from '@mui/material';

import Font from '../../Components/Generals/Fonts/Font';
import generalsEnums from '../../utils/Generals';
import { selectTemplateByName, selectPortalLanguage } from '../../Redux/Slices/Portal.Slices';
import {
  selectViewMailTemplateModalState,
  setViewMailTemplateModalState,
  setViewMailTemplateModalObject,
  selectViewMailTemplateModalObject,
} from '../../Redux/Slices/Navigation.Slices';
import * as colors from '../../assets/GlobalColors';

const useStyles = makeStyles({
  row: { width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
});

const BlurryDialog = styled(Dialog)(({}) => ({
  backdropFilter: 'blur(5px)',
}));

const ViewMailTemplateModal = ({ font20Size, font20LineHeight, font16Size, font16LineHeight }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');
  const classes = useStyles();

  const [logEmailTo, setLogEmailTo] = React.useState(generalsEnums.emptyString);
  const [logDate, setLogDate] = React.useState(generalsEnums.emptyString);
  const [logTemplateName, setLogTemplateName] = React.useState(generalsEnums.emptyString);
  const [mailToDisplay, setMailToDisplay] = React.useState(generalsEnums.emptyArray);
  const [logMailDataBody, setLogMailDataBody] = React.useState(generalsEnums.emptyObject);

  const viewMailTemplateModalState = useSelector(selectViewMailTemplateModalState);
  const viewMailTemplateModalObject = useSelector(selectViewMailTemplateModalObject);
  const mailTemplate = useSelector(selectTemplateByName(logTemplateName));
  const portalLanguage = useSelector(selectPortalLanguage);
  const hasMailToDisplay = mailToDisplay.length > generalsEnums.zeroNumber;

  const handleCloseModal = () => {
    dispatch(setViewMailTemplateModalState(generalsEnums.falseBoolean));
    dispatch(setViewMailTemplateModalObject(generalsEnums.emptyObject));
  };

  const handleFillData = (mailToDisplayArrFiltered) => {
    const {
      name,
      order,
      orderDate,
      total,
      address,
      shippingDate,
      shippingProvider,
      trackingNumber,
      deliveryDate,
      whoReceives,
      deliveryAddress,
      partialShipmentDate,
    } = logMailDataBody;

    const replacements = {
      '%name%': name,
      '%order%': order,
      '%orderDate%': orderDate,
      '%total%': total,
      '%address%': address,
      '%shippingDate%': shippingDate,
      '%shippingProvider%': shippingProvider,
      '%trackingNumber%': trackingNumber,
      '%href%': generalsEnums.emptyString,
      '%deliveryDate%': deliveryDate,
      '%whoReceives%': whoReceives,
      '%deliveryAddress%': deliveryAddress,
      '%partialShipmentDate%': partialShipmentDate,
      '%employeeName%': generalsEnums.emptyString,
    };

    const mailToDisplayArrFilteredFilled = mailToDisplayArrFiltered.map((item) => {
      let value = item.value;
      for (let key in replacements) {
        if (value.includes(key)) {
          value = value.replace(key, replacements[key]);
        }
      }
      return { ...item, value };
    });

    setMailToDisplay(mailToDisplayArrFilteredFilled);
  };

  const handleCleanMailTemplate = (mailTemplateData) => {
    const mailToDisplayArr = [];
    Object.keys(mailTemplateData).forEach((key) => {
      const cleanKey = key.replace(/<[^>]*>?/gm, '');
      const cleanValue = mailTemplateData[key].replace(/<[^>]*>?/gm, '');
      const isBold = mailTemplateData[key].includes('<strong>')
        ? generalsEnums.trueBoolean
        : generalsEnums.falseBoolean;
      const isBreakLine = mailTemplateData[key].includes('<br>')
        ? generalsEnums.trueBoolean
        : generalsEnums.falseBoolean;
      mailToDisplayArr.push({ key: cleanKey, value: cleanValue, isBold, isBreakLine });
    });
    const mailToDisplayArrFiltered = mailToDisplayArr.filter((item) => item.key !== 'subject');

    handleFillData(mailToDisplayArrFiltered);
  };

  React.useEffect(() => {
    if (viewMailTemplateModalState) {
      const { mailDataBody, date } = viewMailTemplateModalObject;
      const { emailTo } = mailDataBody;
      const { templateName } = viewMailTemplateModalObject;
      setLogEmailTo(emailTo);
      setLogDate(date);
      setLogTemplateName(templateName);
      setLogMailDataBody(mailDataBody);
    }
  }, [viewMailTemplateModalState]);

  React.useEffect(() => {
    if (mailTemplate != generalsEnums.undefinedData) {
      if (portalLanguage === generalsEnums.portalLanguages.es.value) {
        handleCleanMailTemplate(mailTemplate.spanishData);
      } else {
        handleCleanMailTemplate(mailTemplate.englishData);
      }
    }
  }, [mailTemplate, portalLanguage]);

  return (
    <React.Fragment>
      {viewMailTemplateModalState && (
        <BlurryDialog onClose={handleCloseModal} open={viewMailTemplateModalState} id="cart-modal">
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              width: '600px',
              height: '600px',
              borderRadius: '12px',
              padding: '32px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '16px',
            }}
          >
            <Box component={generalsEnums.BoxDiv} className={classes.row}>
              <Font
                text={t('ViewMailTemplateModal.title')}
                color={colors.BLACK}
                size={font20Size}
                lineHeight={font20LineHeight}
                isHead={generalsEnums.trueBoolean}
                isCenter={generalsEnums.falseBoolean}
                ml="0px"
              />

              <IconButton
                onClick={handleCloseModal}
                sx={{
                  padding: '0px',
                }}
              >
                <CloseIcon
                  sx={{
                    color: colors.BLACK,
                    width: '24px',
                    height: '24px',
                  }}
                />
              </IconButton>
            </Box>

            <Box component={generalsEnums.BoxDiv} className={classes.row}>
              <Box
                component={generalsEnums.BoxDiv}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                }}
              >
                <Font
                  text={t('ViewMailTemplateModal.to')}
                  color={colors.BLACK}
                  size={font16Size}
                  lineHeight={font16LineHeight}
                  isHead={generalsEnums.falseBoolean}
                  isCenter={generalsEnums.falseBoolean}
                  ml="0px"
                />

                <Font
                  text={logEmailTo}
                  color={colors.BLACK}
                  size={font16Size}
                  lineHeight={font16LineHeight}
                  isHead={generalsEnums.trueBoolean}
                  isCenter={generalsEnums.falseBoolean}
                  ml="0px"
                />
              </Box>

              <Font
                text={logDate}
                color={colors.BLACK_06}
                size={font16Size}
                lineHeight={font16LineHeight}
                isHead={generalsEnums.falseBoolean}
                isCenter={generalsEnums.falseBoolean}
                ml="0px"
              />
            </Box>

            {hasMailToDisplay && (
              <Box
                component={generalsEnums.BoxDiv}
                sx={{
                  width: '100%',
                  height: '100%',
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  padding: '4px',
                }}
              >
                {mailToDisplay.map((item) => {
                  return (
                    <Font
                      key={uuid()}
                      text={item.value}
                      color={colors.BLACK}
                      size={font16Size}
                      lineHeight={font16LineHeight}
                      isHead={item.isBold}
                      isCenter={generalsEnums.falseBoolean}
                      ml="0px"
                    />
                  );
                })}
              </Box>
            )}
          </Box>
        </BlurryDialog>
      )}
    </React.Fragment>
  );
};

export default ViewMailTemplateModal;
