import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '25px',
    marginBottom: '25px',
    padding: '10px 10px 10px 10px',
    gap: '50px',
  },
  stickyBox: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
    position: 'sticky',
    top: '0',
  },
  infoBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    gap: '40px',
  },
  infoDescriptionsBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '16px',
  },
  indexBox: {
    width: '400px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    gap: '24px',
    padding: '20px 20px 20px 20px',
    borderRadius: '6px',
    backgroundColor: colors.GRAY_BG,
    border: `1px solid ${colors.GRAY_BG}`,
  },
});
