import React from 'react';
import { Box, IconButton, OutlinedInput } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import Font from '../../../../Components/Generals/Fonts/Font';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import CurrencyFont from '../../../../Components/Generals/Fonts/CurrencyFont';
import Item from './Item';

const ListMarkup = ({
  t,
  colors,
  generalsEnums,
  classes,
  handleAddNewMarkup,
  descEmptySize,
  descEmptyLineHeight,
  listCurrencySize,
  listCurrencyLineHeight,
  markupArray,
  listTableSize,
  listTableLineHeight,
  getMarkupPercentage,
  getMarkupRule,
  handleEditMarkups,
  isEditable,
  handleChangeValue,
  listMarkupKey
}) => {
  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.columnBox}
      sx={{
        gap: '12px',
      }}
      key={listMarkupKey}
    >
      <Box
        component={generalsEnums.BoxDiv}
        className={classes.rowBox}
      >
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: 'auto',
            minWidth: '100px',
            height: 'auto',
          }}
        >
          <Box
            component={generalsEnums.BoxDiv}
            className={classes.columnBox}
            sx={{
              padding: '16px',
              borderRadius: '8px 8px 0px 0px',
              gap: '8px',
              border: `1px solid ${colors.GREEN_MAIN}`,
              backgroundColor: colors.GREEN_MAIN_1,
            }}
          >
            <Font
              text={t('Markup.list.usd')}
              color={colors.BLACK}
              size={listCurrencySize}
              lineHeight={listCurrencyLineHeight}
              isHead={generalsEnums.trueBoolean}
              ml="0px"
            />

            <Font
              text={t('Markup.list.usdText')}
              color={colors.BLACK}
              size={descEmptySize}
              lineHeight={descEmptyLineHeight}
              isHead={generalsEnums.falseBoolean}
              ml="0px"
            />
          </Box>

          <Item
            generalsEnums={generalsEnums}
            classes={classes}
            colors={colors}
            isWhite={generalsEnums.falseBoolean}
            ruleText={
              <Box
                component={generalsEnums.BoxDiv}
                className={classes.listRuleBox}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '8px',
                }}
              >
                <Font
                  text={t('Markup.list.table.rule')}
                  color={colors.BLACK}
                  size={listTableSize}
                  lineHeight={listTableLineHeight}
                  isHead={generalsEnums.trueBoolean}
                  ml="0px"
                />
              </Box>
            }
            priceText={
              <Font
                text={t('Markup.list.table.price')}
                color={colors.BLACK}
                size={listTableSize}
                lineHeight={listTableLineHeight}
                isHead={generalsEnums.trueBoolean}
                ml="0px"
              />
            }
            markupText={
              <Font
                text={t('Markup.list.table.markup')}
                color={colors.BLACK}
                size={listTableSize}
                lineHeight={listTableLineHeight}
                isHead={generalsEnums.trueBoolean}
                ml="0px"
              />
            }
          />

          {markupArray.map((markup, index) => {
            return (
              <Item
                key={index}
                generalsEnums={generalsEnums}
                classes={classes}
                colors={colors}
                isWhite={generalsEnums.trueBoolean}
                ruleText={
                  <Box
                    component={generalsEnums.BoxDiv}
                    className={classes.listRuleBox}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '8px',
                    }}
                  >
                    <Font
                      text={getMarkupRule(
                        markup.minPrice,
                        markup.maxPrice,
                        t('Markup.list.table.smallerThan'),
                        t('Markup.list.table.biggerThan'),
                        t('Markup.list.table.between')
                      )}
                      color={colors.BLACK}
                      size={listTableSize}
                      lineHeight={listTableLineHeight}
                      isHead={generalsEnums.falseBoolean}
                      ml="0px"
                    />
                  </Box>
                }
                priceText={
                  <Box
                    component={generalsEnums.BoxDiv}
                    className={classes.listPriceBox}
                    key={index + 'priceText'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '8px',
                    }}
                  >
                  {!isEditable ?  
                    <CurrencyFont
                        price={markup.minPrice}
                        color={colors.BLACK}
                        size={listTableSize}
                        lineHeight={listTableLineHeight}
                        isHead={generalsEnums.trueBoolean}
                        displayPrefix={generalsEnums.falseBoolean}
                        key={index + 'minPrice'}
                    /> : 
                    <OutlinedInput
                      value={markup.minPrice}
                      index={index}
                      style={{
                        height: '20px',
                        lineHeight: listTableLineHeight,
                        width: '35%'
                      }}
                      onChange={(e) => {
                        markup.minPrice = e.target.value
                        handleChangeValue(e, markup._id, 'minPrice', e.target.value);
                      }}
                      key={index + 'minPrice'}
                    />
                  }

                    <Font
                      text={t('Markup.list.table.and')}
                      color={colors.BLACK}
                      size={listTableSize}
                      lineHeight={listTableLineHeight}
                      isHead={generalsEnums.trueBoolean}
                      ml="0px"
                    />

                    {!isEditable ? 
                      <CurrencyFont
                        price={markup.maxPrice}
                        color={colors.BLACK}
                        size={listTableSize}
                        lineHeight={listTableLineHeight}
                        isHead={generalsEnums.trueBoolean}
                        displayPrefix={generalsEnums.falseBoolean}
                      /> :
                      <OutlinedInput
                        value={markup.maxPrice}
                        key={index}
                        index={index}
                        style={{
                          height: '20px',
                          lineHeight: listTableLineHeight,
                          width: '35%'
                        }}
                        onChange={(e) => {
                          markup.maxPrice = e.target.value
                          handleChangeValue(e, markup._id, 'maxPrice', e.target.value);
                        }}
                      />
                    }
                  </Box>
                }
                markupText={ !isEditable ? 
                    <Font
                      text={getMarkupPercentage(markup.markup)}
                      color={colors.BLACK}
                      size={listTableSize}
                      lineHeight={listTableLineHeight}
                      isHead={generalsEnums.falseBoolean}
                      ml="0px"
                    /> :
                    <OutlinedInput
                      value={markup.markup}
                      key={index}
                      index={index}
                      style={{
                        height: '20px',
                        lineHeight: listTableLineHeight
                      }}
                      onChange={(e) => {
                        markup.markup = e.target.value
                        handleChangeValue(e, markup._id, 'markup', e.target.value);
                      }}
                    />
                }
              />
            );
          })}
        </Box>
        <Box
          component={generalsEnums.BoxDiv}
        >
          <IconButton 
            onClick={async (e)=> await handleEditMarkups(e)}
            sx={{
              marginLeft: '22px',
              borderRadius: '5px',
              backgroundColor: colors.GREEN_MAIN,
              '&:hover': {
                backgroundColor: colors.GREEN_MAINHOVER,
              },
              color: colors.BLACK,
              transition: 'background-color 0.3s ease-in-out'
            }}
          >
            {isEditable ? <SaveIcon className={classes.Icon}/> :
              <EditIcon className={classes.Icon} />
            }
          </IconButton>
      </Box>
      </Box>
      <Box
        component={generalsEnums.BoxDiv}
        className={classes.buttonAddNewMarkup}
        sx={{
          marginTop: '22px',
        }}
      >
        <TsiButton
          type={generalsEnums.buttonTypes.save}
          handleAction={handleAddNewMarkup}
          text={t('Markup.empty.add')}
          isDisabled={generalsEnums.falseBoolean}
          size={descEmptySize}
          lineHeight={descEmptyLineHeight}
        />
      </Box>
    </Box>
  );
};

export default ListMarkup;
