import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Stepper, Step, StepLabel } from '@mui/material';

import generalsEnums from '../../../../utils/Generals';
import Font from '../../../../Components/Generals/Fonts/Font';
import ResponsiveComponent from '../../../../Hooks/ResponsiveComponent';
import { getResponsiveValues, getStatusColor } from '../../../../utils/FormatData';
import * as colors from '../../../../assets/GlobalColors';

const ColorlibConnector = styled(StepConnector)(({ theme, color }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${color} 0%,${color} 50%,${color}100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient( 95deg,${color}0%,${color} 50%,${color} 100%)`,
      margin: '0px',
      width: '100%',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: 6,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
    width: '100%',
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState, color }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  margin: '0px',

  ...(ownerState.active && {
    backgroundImage: `linear-gradient( 136deg, ${color} 0%, ${color} 50%, ${color} 100%)`,
  }),
  ...(ownerState.completed && {
    backgroundImage: `linear-gradient( 136deg, ${color} 0%, ${color} 50%, ${color} 100%)`,
  }),
}));

const ColorlibStepIcon = (props) => {
  const { active, completed, className, color } = props;

  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);
  const size = IpadAir || IpadMini ? '20px' : '36px';
  const display = IpadAir || IpadMini ? false : true;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      color={color}
      className={className}
      sx={{
        width: size,
        height: size,
      }}
    >
      {display ? (
        <Box
          sx={{
            marginLeft: '-4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Font
            text={props.icon}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size16.size}
            lineHeight={generalsEnums.fontSizes.size16.lineHeight}
            isHead={active ? true : false}
            isCenter={true}
          />
        </Box>
      ) : null}
    </ColorlibStepIconRoot>
  );
};

const StepperAttoms = ({ w, orderStatusData }) => {
  const [statusText, setStatusText] = React.useState(generalsEnums.emptyString);
  const [statusCode, setStatusCode] = React.useState(generalsEnums.emptyString);
  const [statusStep, setStatusStep] = React.useState(generalsEnums.zeroNumber);

  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size10.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size10.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];
  const [text20Size, text20LineHeight] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);
  const top = IpadAir || IpadMini ? 8 : 16;
  const height = IpadAir || IpadMini ? 4 : 8;
  const marginLeft = IpadAir || IpadMini ? '-26px' : '-20px';

  React.useEffect(() => {
    if (orderStatusData !== generalsEnums.undefinedData) {
      const { name, activeStep } = orderStatusData;
      setStatusText(name);
      setStatusCode(orderStatusData.statusCode);
      setStatusStep(activeStep);
    }
  }, [orderStatusData]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: w,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: w,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Font
          text={statusText}
          color={colors.BLACK}
          size={text20Size}
          lineHeight={text20LineHeight}
          isHead={true}
          isCenter={true}
          ml="0px"
        />
      </Box>

      <Stepper
        activeStep={statusStep}
        alternativeLabel
        connector={
          <ColorlibConnector
            color={getStatusColor(statusCode)}
            sx={{
              [`&.${stepConnectorClasses.alternativeLabel}`]: {
                top: top,
              },
              [`& .${stepConnectorClasses.line}`]: {
                height: height,
              },
            }}
          />
        }
        sx={{
          marginLeft: marginLeft,
        }}
      >
        <Step>
          <StepLabel
            StepIconComponent={(props) => <ColorlibStepIcon {...props} color={getStatusColor(statusCode)} />}
          ></StepLabel>
        </Step>

        <Step>
          <StepLabel
            StepIconComponent={(props) => <ColorlibStepIcon {...props} color={getStatusColor(statusCode)} />}
          ></StepLabel>
        </Step>

        <Step>
          <StepLabel
            StepIconComponent={(props) => <ColorlibStepIcon {...props} color={getStatusColor(statusCode)} />}
          ></StepLabel>
        </Step>

        <Step>
          <StepLabel
            StepIconComponent={(props) => <ColorlibStepIcon {...props} color={getStatusColor(statusCode)} />}
          ></StepLabel>
        </Step>

        <Step>
          <StepLabel
            StepIconComponent={(props) => <ColorlibStepIcon {...props} color={getStatusColor(statusCode)} />}
          ></StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};

export default StepperAttoms;
