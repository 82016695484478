import Axios from './axios.class';
import { API_ROUTES } from '../utils/ApiRoutes';

class portalSettings extends Axios {
  constructor() {
    super();
    this.urlgetPortalLogos = API_ROUTES.portalSettings.getPortalLogos;
    this.urleditPortalLogo = API_ROUTES.portalSettings.editPortalLogo;
    this.urlgetPortalDefaultLogo = API_ROUTES.portalSettings.getPortalDefaultLogo;
    this.urlgetPortalFavicon = API_ROUTES.portalSettings.getPortalFavicon;
    this.urlgetProfile = API_ROUTES.portalSettings.getProfile;
    this.urleditProfile = API_ROUTES.portalSettings.editProfile;
    this.urlgetProfileForClient = API_ROUTES.portalSettings.getProfileForClient;
    this.urleditLanguageProfile = API_ROUTES.portalSettings.editLanguageProfile;
    this.urlgetExchangeRate = API_ROUTES.portalSettings.getExchangeRate;
    this.urlgetStoreProfileAddress = API_ROUTES.portalSettings.getStoreProfileAddress;
    this.urlgetAllActiveMarkup = API_ROUTES.portalSettings.getAllActiveMarkup;
    this.urleditActiveMarkup = API_ROUTES.portalSettings.editActiveMarkup;
    this.urlgetAllActiveFreight = API_ROUTES.portalSettings.getAllActiveFreight;
    this.urlgetAllActiveWeight = API_ROUTES.portalSettings.getAllActiveWeight;
    this.urlgetSecurityDashboard = API_ROUTES.portalSettings.getSecurityDashboard;
    this.urlgetApiCalls = API_ROUTES.portalSettings.getApiCalls;
    this.urlblockApiCalls = API_ROUTES.portalSettings.blockApiCalls;
  }

  async getPortalLogos() {
    const response = await this.axios.get(this.urlgetPortalLogos);
    return response;
  }

  async editPortalLogo(data) {
    const response = await this.axios.put(this.urleditPortalLogo, data);
    return response;
  }

  async getPortalDefaultLogo() {
    const response = await this.axios.get(this.urlgetPortalDefaultLogo);
    return response;
  }

  async getPortalFavicon() {
    const response = await this.axios.get(this.urlgetPortalFavicon);
    return response;
  }

  async getProfile() {
    const response = await this.axios.get(this.urlgetProfile);
    return response;
  }

  async editProfile(body) {
    const response = await this.axios.post(this.urleditProfile, body);
    return response;
  }

  async getProfileForClient() {
    const response = await this.axios.get(this.urlgetProfileForClient);
    return response;
  }

  async editLanguageProfile(body) {
    const response = await this.axios.post(this.urleditLanguageProfile, body);
    return response;
  }

  async getExchangeRate() {
    const response = await this.axios.get(this.urlgetExchangeRate);
    return response;
  }

  async getStoreProfileAddress() {
    const response = await this.axios.get(this.urlgetStoreProfileAddress);
    return response;
  }

  async getAllActiveMarkup() {
    const response = await this.axios.get(this.urlgetAllActiveMarkup);
    return response;
  }

  async editActiveMarkup(body) {
    const response = await this.axios.post(this.urleditActiveMarkup, body);
    return response;
  }

  async getAllActiveFreight() {
    const response = await this.axios.get(this.urlgetAllActiveFreight);
    return response;
  }

  async getAllActiveWeight() {
    const response = await this.axios.get(this.urlgetAllActiveWeight);
    return response;
  }

  async getSecurityDashboard(body) {
    const response = await this.axios.post(this.urlgetSecurityDashboard, body);
    return response;
  }

  async getApiCalls() {
    const response = await this.axios.get(this.urlgetApiCalls);
    return response;
  }

  async blockApiCalls(body) {
    const response = await this.axios.post(this.urlblockApiCalls, body);
    return response;
  }
}

export default portalSettings;
