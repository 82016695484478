import React from 'react';
import uuid from 'react-uuid';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import generalsEnums from '../../../utils/Generals';
import Orders from '../../../entities/Orders';
import OrderAccordion from '../../../Components/Client/OrderAccordion/OrderAccordion';
import Font from '../../../Components/Generals/Fonts/Font';
import { useStyles } from './MyOrders.styles';
import { selectUserId } from '../../../Redux/Slices/User.Slices';
import { setBackDropState } from '../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../assets/GlobalColors';

const MyOrders = () => {
  const dispatch = useDispatch();
  const ordersApi = new Orders();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const userId = useSelector(selectUserId);

  const [ordersToDisplay, setOrdersToDisplay] = React.useState([]);

  React.useEffect(async () => {
    dispatch(setBackDropState(true));
    const response = await ordersApi.getAllOrderByUserId(userId);
    const { orders, statusCode } = response;
    if (statusCode === generalsEnums.statusCodes.ok) {
      setOrdersToDisplay(orders);
      dispatch(setBackDropState(false));
    }
  }, []);

  return (
    <Box component={generalsEnums.BoxDiv} className={classes.root}>
      <Font text={t('client.MyOrders.text-welcome')} color={colors.BLACK} size={24} lineHeight="32px" isHead={true} />

      <Box component={generalsEnums.BoxDiv} className={classes.ordersBox}>
        {ordersToDisplay.map((order) => {
          return <OrderAccordion key={uuid()} order={order.order} />;
        })}
      </Box>
    </Box>
  );
};

export default MyOrders;
