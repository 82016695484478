const generalsEnums = {
  emptyString: '',
  undefinedData: undefined,
  nullData: null,
  zeroNumber: 0,
  oneNumber: 1,
  falseBoolean: false,
  trueBoolean: true,
  emptyObject: {},
  mainTimeOut: 1500,
  emptyArray: [],
  snackBar: {
    success: 'success',
    error: 'error',
    warning: 'warning',
  },
  variants: {
    text: 'text',
    image: 'image',
    favicon: 'favicon',
  },
  languages: {
    spanish: 'es',
    english: 'en',
  },
  timeOut: {
    time1500: 1500,
    short: 3000,
  },
  characters: {
    max500: 500,
  },
  paddings: {
    containerRoot: '20px',
    root: {
      desktop: '20px',
      tablet: '10px',
    },
  },
  responseStatusCode: {
    success: 200,
  },
  TableRowsPerPage: {
    ten: 10,
    fiveTen: 50,
  },
  statusCodes: {
    ok: 200,
    created: 201,
    noContent: 204,
    badRequest: 400,
    unauthorized: 401,
    notFound: 404,
    internalServerError: 500,
  },
  fontSize: {
    small: 12,
    medium: 14,
    large: 16,
    xLarge: 22,
    xxLarge: 22,
    xxxLarge: 22,
    xxxxLarge: 24,
  },
  fontSizes: {
    size8: {
      size: 8,
      lineHeight: '12px',
    },
    size10: {
      size: 10,
      lineHeight: '16px',
    },
    size12: {
      size: 12,
      lineHeight: '18px',
    },
    size14: {
      size: 14,
      lineHeight: '22px',
    },
    size16: {
      size: 16,
      lineHeight: '24px',
    },
    size18: {
      size: 18,
      lineHeight: '28px',
    },
    size20: {
      size: 20,
      lineHeight: '28px',
    },
    size22: {
      size: 22,
      lineHeight: '28px',
    },
    size24: {
      size: 24,
      lineHeight: '32px',
    },
    size26: {
      size: 26,
      lineHeight: '32px',
    },
    size28: {
      size: 28,
      lineHeight: '36px',
    },
    size30: {
      size: 30,
      lineHeight: '36px',
    },
    size32: {
      size: 32,
      lineHeight: '42px',
    },
    size34: {
      size: 34,
      lineHeight: '42px',
    },
    size36: {
      size: 36,
      lineHeight: '46px',
    },
    size38: {
      size: 38,
      lineHeight: '46px',
    },
    size40: {
      size: 40,
      lineHeight: '48px',
    },
  },
  icons: {
    fontSize: {
      small: 'small',
    },
    kind: {
      profile: 'profile',
      store: 'store',
      shopTwo: 'shopTwo',
      ArrowBack: 'ArrowBack',
      ForwardToInbox: 'ForwardToInbox',
      Error: 'Error',
      NotificationsActive: 'NotificationsActive',
      Search: 'Search',
      Package: 'Package',
      Delete: 'Delete',
      Add: 'Add',
      Security: 'Security',
    },
  },
  storeCodes: {
    main: 'mx-1',
  },
  buttonTypes: {
    save: 'save',
    black: 'black',
    transparent: 'transparent',
  },
  boderRadius: {
    b4: '4px',
    b8: '8px',
  },
  mediaQueries: {
    IpadAir: '(max-width:1180px)',
    IpadMini: '(max-width:1024px)',
    isXs: '(max-width:600px)',
    isSm: '(max-width:960px)',
    isMd: '(max-width:1280px)',
    isLg: '(max-width:1920px)',
  },
  userTypes: {
    guest: 'Invitado',
  },
  badge: {
    green: 'green',
    gray: 'gray',
    red: 'red',
    greenSoft: 'greenSoft',
  },
  ipSample: 'xxx.xxx.xxx.xxx',
  logHistoryKind: {
    mail: 'mail',
    change: 'change',
    notification: 'notification',
  },
  s3Keys: {
    tvhFiles: 'tvhfiles',
  },
  currencies: {
    mxn: 'mxn',
    usd: 'usd',
  },
  itemNoImageAlt: 'No image',
  BoxDiv: 'div',
  countries: {
    mexico: 'mx',
    unitedStates: 'us',
  },
  UserManagement: {
    global: 'signin',
  },
  portalLanguages: {
    es: {
      value: 'es',
      label: 'Español',
    },
    en: {
      value: 'en',
      label: 'English',
    },
  },
  SelectAddress: {
    address: 'address',
    billing: 'billing',
  },
  weightConditions: {
    greaterOrEqual: '>=',
    lessOrEqual: '<=',
  },
  logsKind: {
    mail: 'mail',
    notification: 'notification',
  },
  templatesNames: {
    footer: 'footer',
    purchaseConfirmation: 'purchaseConfirmation',
    shippingConfirmation: 'shippingConfirmation',
    trackingUpdate: 'trackingUpdate',
    deliveryConfirmation: 'deliveryConfirmation',
    claimOnOrder: 'claimOnOrder',
    complaintResponse: 'complaintResponse',
    complaintSolution: 'complaintSolution',
    complaintConfirm: 'complaintConfirm',
    paymentDeclined: 'paymentDeclined',
    shippingUpdate: 'shippingUpdate',
    abandonedCart: 'abandonedCart',
  },
  orderStatus: {
    os1: 'os1',
    os2: 'os2',
    os3: 'os3',
    os4: 'os4',
    os5: 'os5',
    os6: 'os6',
    os7: 'os7',
    os8: 'os8',
    os9: 'os9',
    os10: 'os10',
    os11: 'os11',
    os12: 'os12',
    os13: 'os13',
    os14: 'os14',
  },
  SecurityListDetails: {
    allRequests: 'allRequests',
    allMxRequests: 'allMxRequests',
    allUsRequests: 'allUsRequests',
    allBlockedRequests: 'allBlockedRequests',
    allBlockedBots: 'allBlockedBots',
  },
  phoneCodes: {
    mx: '+52',
    us: '+1',
  },
  listMarkupKey: 'listMarkupKey'
};

export default generalsEnums;
