import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Switch } from '@mui/material';
import { withStyles } from '@mui/styles';

import generalsEnums from '../../../../utils/Generals';
import * as colors from '../../../../assets/GlobalColors';

const CustomSwitch = withStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 1,
    color: colors.WHITE,
    '&$checked': {
      color: colors.WHITE,
      '& + $track': {
        opacity: 1,
        backgroundColor: colors.GREEN_MAIN,
      },
    },
    '&$disabled': {
      color: colors.WHITE,
      '&$checked': {
        color: colors.WHITE,
        '& + $track': {
          opacity: 1,
          backgroundColor: colors.GREEN_MAIN,
        },
      },
    },
  },
  thumb: {
    boxShadow: 'none',
  },
  track: {
    borderRadius: 999,
    opacity: 1,
    backgroundColor: colors.BORDER_CCC,
  },
  checked: {},
  disabled: {},
}))(Switch);

const SwitchAttom = ({ itemChecked, itemChange }) => {
  const [checked, setChecked] = React.useState(false);

  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);

  const switchWidth = IpadAir || IpadMini ? 38 : 50;
  const switchHeight = IpadAir || IpadMini ? 20 : 22;
  const switchThumbWidth = IpadAir || IpadMini ? 18 : 20;
  const switchThumbHeight = IpadAir || IpadMini ? 18 : 20;
  const switchThumbTranslateX = IpadAir || IpadMini ? 'translateX(18px)' : 'translateX(26px)';

  const handleChange = (event) => {
    event.preventDefault();
    setChecked(event.target.checked);
    itemChange();
  };

  React.useEffect(() => {
    setChecked(itemChecked);
  }, []);

  return (
    <CustomSwitch
      checked={checked}
      onChange={handleChange}
      sx={{
        width: switchWidth,
        height: switchHeight,
        '& .MuiSwitch-thumb': {
          width: switchThumbWidth,
          height: switchThumbHeight,
        },
        '& .Mui-checked': {
          transform: switchThumbTranslateX,
        },
      }}
    />
  );
};

export default SwitchAttom;
