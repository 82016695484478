import React from 'react';
import { Box, Button } from '@mui/material';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import Paths from '../../../utils/enums/Paths';
import generalsEnums from '../../../utils/Generals';
import OrderSummaryCard from '../../../Components/Client/Checkout/OrderSummary/OrderSummaryCard';
import Orders from '../../../entities/Orders';
import Font from '../../../Components/Generals/Fonts/Font';
import WhiteButton from '../../../Components/Generals/WhiteButton';
import { useStyles } from './OrderSummary.styles';
import { clearCart } from '../../../Redux/Slices/ShoppingCart.Slices';
import { setBackDropState, selectClearShoppingCart } from '../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../assets/GlobalColors';

const OrderSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const order = new Orders();
  const { t } = useTranslation('global');
  const { orderNumber } = useParams();

  const email = 'ventas@tsi.com';

  const clearShoppingCart = useSelector(selectClearShoppingCart);

  const [data, setData] = React.useState(generalsEnums.nullData);
  const [products, setProducts] = React.useState(generalsEnums.emptyArray);
  const [orderSubTotal, setOrderSubTotal] = React.useState(generalsEnums.zeroNumber);
  const [orderDelivery, setOrderDelivery] = React.useState(generalsEnums.zeroNumber);
  const [orderTaxes, setOrderTaxes] = React.useState(generalsEnums.zeroNumber);
  const [orderTotal, setOrderTotal] = React.useState(generalsEnums.zeroNumber);
  const [customerName, setCustomerName] = React.useState(generalsEnums.emptyString);

  const handlePrintOrderTicket = async (e) => {
    e.preventDefault();
    dispatch(setBackDropState(generalsEnums.trueBoolean));

    const response = await order.createOrderTicket(orderNumber);

    if (response.statusCode === 200) {
      dispatch(setBackDropState(false));
      window.open(response.res, '_blank');
    } else dispatch(setBackDropState(generalsEnums.falseBoolean));
  };

  const handleGoToHome = (e) => {
    e.preventDefault();
    navigate(Paths.client.Home2);
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    window.open(`mailto:${email}`, '_blank');
  };

  React.useEffect(async () => {
    dispatch(setBackDropState(true));
    const response = await order.getOrder(orderNumber);
    const { statusCode, order: data } = response;

    if (statusCode === 200) {
      setOrderSubTotal(data.order.subTotal);
      setOrderDelivery(data.order.orderDelivery);
      setOrderTaxes(data.order.orderTaxes);
      setOrderTotal(data.order.orderTotal);
      setProducts(data.order.orderProducts);
      setCustomerName(`${data.user.name} ${data.user.lastName}`);
      setData(data);
      dispatch(setBackDropState(false));
    } else dispatch(setBackDropState(false));
  }, []);

  React.useEffect(() => {
    if (clearShoppingCart) dispatch(clearCart());
  }, [clearShoppingCart]);

  if (data !== null) {
    return (
      <Container maxWidth="lg">
        <Box className={classes.root}>
          <OrderSummaryCard
            isSummary={true}
            handlePrintOrderTicket={handlePrintOrderTicket}
            productsOrder={products}
            orderSubTotal={orderSubTotal}
            orderDelivery={orderDelivery}
            orderTaxes={orderTaxes}
            orderTotal={orderTotal}
            canEdit={null}
            orderSummary={true}
          />

          <Box component="div" className={classes.infoBox}>
            <Font
              text={`${t('client.OrderSummary.title')} ${customerName}!`}
              color={colors.BLACK}
              size={24}
              lineHeight="32px"
              isHead={true}
            />

            <Box
              component="div"
              className={classes.infoRowBox}
              sx={{
                marginTop: '20px',
                marginBottom: '50px',
              }}
            >
              <Font
                text={t('client.OrderSummary.orderNumber')}
                color={colors.BLACK}
                size={18}
                lineHeight="28px"
                isHead={false}
              />

              <Font text={orderNumber} color={colors.BLACK} size={18} lineHeight="28px" isHead={true} />
            </Box>

            <Font
              text={`${t('client.OrderSummary.info')}`}
              color={colors.BLACK}
              size={14}
              lineHeight="28px"
              isHead={false}
            />

            <Button onClick={handleGoToHome} variant="contained" className={classes.keepShoppingButton}>
              <Font
                text={`${t('client.OrderSummary.keepShopping')}`}
                color={colors.BLACK}
                size={14}
                lineHeight="28px"
                isHead={false}
              />
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }

  return null;
};

export default OrderSummary;
