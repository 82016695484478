import { makeStyles } from '@mui/styles';

import * as colors from '../../../../assets/GlobalColors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
  },
  responsiveBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxOne: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxOneInfo: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '16px',
  },
  boxGreen: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  boxGreenTitle: {
    width: '100%',
    height: '60px',
  },
  boxGreenText: {
    width: '100%',
    height: 'auto',
  },
  boxInfoDown: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  boxWorkers: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
  },
  boxValues: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '24px',
    marginTop: '30px',
  },
  boxValue: { display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', flexShrink: '0' },
  workerPhoto: {
    width: '95%',
    height: '95%',
  },
});

export { useStyles, colors };
