import React from 'react';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton.jsx';
import Paths from '../../../utils/enums/Paths';
import portalSettings from '../../../entities/portalSettings';
import LineCharts from './LineChart.jsx';
import AdminScreenTemplate from '../../../utils/templates/AdminScreen.Template.jsx';
import generalsEnums from '../../../utils/Generals';
import ResponsiveComponent from '../../../Hooks/ResponsiveComponent';
import Font from '../../../Components/Generals/Fonts/Font';
import HeaderItem from '../Home/HeaderItem.jsx';
import DatePicker from '../../../Components/Generals/Figma/Molecules/DatePicker';
import { getResponsiveValues } from '../../../utils/FormatData';
import { useStyles } from '../Home/styles.js';
import { setBackDropState } from '../../../Redux/Slices/Navigation.Slices.js';
import {
  selectTotalAPI,
  selectTotalMx,
  selectTotalUs,
  selectTotalBlockIps,
  selectTotalBlockBots,
  selectMinDate,
  selectMaxDate,
  setMinDate,
  setMaxDate,
  setTotals,
} from '../../../Redux/Slices/SecurityDashboard.Slices.js';
import * as colors from '../../../assets/GlobalColors.js';

const SecurityDashboard = ({
  titleSize,
  titleLineHeight,
  headerItemTitleSize,
  headerItemTitleLineHeight,
  counterTitleSize,
  counterTitleLineHeight,
}) => {
  const { t } = useTranslation('global');
  const classes = useStyles();
  const dispatch = useDispatch();
  const portalSettingsAPI = new portalSettings();
  const navigate = useNavigate();

  const [currentMaxDate, setCurrentMaxDate] = React.useState();
  const [dataArray, setDataArray] = React.useState(generalsEnums.emptyArray);
  const [mxCounter, setMxCounter] = React.useState(generalsEnums.zeroNumber);
  const [mxIsBlocked, setMxIsBlocked] = React.useState(generalsEnums.falseBoolean);
  const [usCounter, setUsCounter] = React.useState(generalsEnums.zeroNumber);
  const [usIsBlocked, setUsIsBlocked] = React.useState(generalsEnums.falseBoolean);

  const totalAPI = useSelector(selectTotalAPI);
  const totalMx = useSelector(selectTotalMx);
  const totalUs = useSelector(selectTotalUs);
  const totalBlockIps = useSelector(selectTotalBlockIps);
  const totalBlockBots = useSelector(selectTotalBlockBots);
  const minDate = useSelector(selectMinDate);
  const maxDate = useSelector(selectMaxDate);

  const tabs = [
    {
      id: uuid(),
      label: t('SecurityDashboard.totalAPI'),
      value: totalAPI,
      type: generalsEnums.SecurityListDetails.allRequests,
    },
    {
      id: uuid(),
      label: t('SecurityDashboard.totalMx'),
      value: totalMx,
      type: generalsEnums.SecurityListDetails.allMxRequests,
    },
    {
      id: uuid(),
      label: t('SecurityDashboard.totalUs'),
      value: totalUs,
      type: generalsEnums.SecurityListDetails.allUsRequests,
    },
    {
      id: uuid(),
      label: t('SecurityDashboard.totalBlockIps'),
      value: totalBlockIps,
      type: generalsEnums.SecurityListDetails.allBlockedRequests,
    },
    {
      id: uuid(),
      label: t('SecurityDashboard.totalBlockBots'),
      value: totalBlockBots,
      type: generalsEnums.SecurityListDetails.allBlockedBots,
    },
  ];

  const handleChangeMinDate = (newValue) => {
    const date = new Date(newValue);
    date.setHours(0, 0, 0, 0);
    dispatch(setMinDate(date.toISOString()));
  };

  const handleChangeMaxDate = (newValue) => {
    const date = new Date(newValue);
    date.setHours(23, 59, 59, 999);
    dispatch(setMaxDate(date.toISOString()));
  };

  const handleClickTab = (type) => {
    const path = Paths.admin.Security.ListDetails.replace(':type', type);
    navigate(path);
  };

  const handleUpdateCountryAPIStatus = async (country) => {
    dispatch(setBackDropState(generalsEnums.trueBoolean));
    await portalSettingsAPI.blockApiCalls({
      country: country,
      newStatus: country === generalsEnums.countries.mexico ? !mxIsBlocked : !usIsBlocked,
    });
    if (country === generalsEnums.countries.mexico) {
      setMxIsBlocked(!mxIsBlocked);
    } else {
      setUsIsBlocked(!usIsBlocked);
    }
    dispatch(setBackDropState(generalsEnums.falseBoolean));
  };

  React.useEffect(async () => {
    dispatch(setBackDropState(true));
    const getSecurityDashboardResponse = await portalSettingsAPI.getSecurityDashboard({
      endTime: maxDate,
      startTime: minDate,
    });
    const { data } = getSecurityDashboardResponse;
    const {
      allRequests,
      totalAPI,
      allMxRequests,
      totalMx,
      allUsRequests,
      totalUs,
      allBlockedRequests,
      totalBlockIps,
      allBlockedBots,
      totalBlockBots,
    } = data.data;
    setDataArray(allRequests);
    dispatch(
      setTotals({
        totalAPI: totalAPI,
        totalMx: totalMx,
        totalUs: totalUs,
        totalBlockIps: totalBlockIps,
        totalBlockBots: totalBlockBots,
        allRequests: allRequests,
        allMxRequests: allMxRequests,
        allUsRequests: allUsRequests,
        allBlockedRequests: allBlockedRequests,
        allBlockedBots: allBlockedBots,
      })
    );
    setCurrentMaxDate(new Date(maxDate));

    dispatch(setBackDropState(false));
  }, [minDate, maxDate]);

  React.useEffect(async () => {
    const getApiCallsResponse = await portalSettingsAPI.getApiCalls();
    const { data: apiCallsData } = getApiCallsResponse;
    const { isMxApiBlocked, isUsApiBlocked } = apiCallsData.data;
    const { isBlocked: mxisBlocked, counter: mxcounter } = isMxApiBlocked;
    const { isBlocked: usisBlocked, counter: uscounter } = isUsApiBlocked;
    setMxCounter(mxcounter);
    setMxIsBlocked(mxisBlocked);
    setUsCounter(uscounter);
    setUsIsBlocked(usisBlocked);
  }, []);

  return (
    <AdminScreenTemplate gap={'12px'}>
      <Font
        text={t('SecurityDashboard.title')}
        color={colors.BLACK}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.header}
        sx={{
          backgroundColor: colors.GREEN_MAIN_3,
          border: `1px solid ${colors.GREEN_MAIN_3}`,
        }}
      >
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            variant="text"
            sx={{
              padding: '0px',
            }}
            onClick={() => handleClickTab(tab.type)}
          >
            <HeaderItem
              key={tab.id}
              headerItemTitleSize={headerItemTitleSize}
              headerItemTitleLineHeight={headerItemTitleLineHeight}
              title={tab.label}
              subtitle={generalsEnums.emptyString}
              value={tab.value}
            />
          </Button>
        ))}
      </Box>

      <Box component={generalsEnums.BoxDiv} className={classes.header}>
        <LineCharts dataArray={dataArray} />

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
            }}
          >
            <DatePicker
              initialValue={minDate}
              currentMaxDate={currentMaxDate}
              handleChangeDate={handleChangeMinDate}
              text={
                <Font
                  text={t('AdminPortalHome.chart.minDate')}
                  color={colors.BLACK_06}
                  size={headerItemTitleSize}
                  lineHeight={headerItemTitleLineHeight}
                  isHead={generalsEnums.trueBoolean}
                  ml="0px"
                />
              }
            />

            <DatePicker
              initialValue={maxDate}
              currentMaxDate={new Date()}
              handleChangeDate={handleChangeMaxDate}
              text={
                <Font
                  text={t('AdminPortalHome.chart.maxDate')}
                  color={colors.BLACK_06}
                  size={headerItemTitleSize}
                  lineHeight={headerItemTitleLineHeight}
                  isHead={generalsEnums.trueBoolean}
                  ml="0px"
                />
              }
            />
          </Box>

          <Font
            text={t('SecurityDashboard.apiCalls.title')}
            color={colors.BLACK}
            size={headerItemTitleSize}
            lineHeight={headerItemTitleLineHeight}
            isHead={generalsEnums.trueBoolean}
            ml="0px"
          />

          <Box
            component={generalsEnums.BoxDiv}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
            }}
          >
            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '230px',
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
              }}
            >
              <Font
                text={`${t('SecurityDashboard.apiCalls.mxCounter')} ${mxCounter}`}
                color={colors.BLACK}
                size={counterTitleSize}
                lineHeight={counterTitleLineHeight}
                isHead={generalsEnums.falseBoolean}
                ml="0px"
              />

              <TsiButton
                type={generalsEnums.buttonTypes.save}
                handleAction={(e) => {
                  e.preventDefault();
                  handleUpdateCountryAPIStatus(generalsEnums.countries.mexico);
                }}
                text={
                  mxIsBlocked ? t('SecurityDashboard.apiCalls.mx.unblock') : t('SecurityDashboard.apiCalls.mx.block')
                }
                isDisabled={false}
                size={counterTitleSize}
                lineHeight={counterTitleLineHeight}
                hasCenter={generalsEnums.trueBoolean}
              />
            </Box>

            <Box
              component={generalsEnums.BoxDiv}
              sx={{
                width: '230px',
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
              }}
            >
              <Font
                text={`${t('SecurityDashboard.apiCalls.usCounter')} ${usCounter}`}
                color={colors.BLACK}
                size={counterTitleSize}
                lineHeight={counterTitleLineHeight}
                isHead={generalsEnums.falseBoolean}
                ml="0px"
              />

              <TsiButton
                type={generalsEnums.buttonTypes.save}
                handleAction={(e) => {
                  e.preventDefault();
                  handleUpdateCountryAPIStatus(generalsEnums.countries.unitedStates);
                }}
                text={
                  usIsBlocked ? t('SecurityDashboard.apiCalls.us.unblock') : t('SecurityDashboard.apiCalls.us.block')
                }
                isDisabled={false}
                size={counterTitleSize}
                lineHeight={counterTitleLineHeight}
                hasCenter={generalsEnums.trueBoolean}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </AdminScreenTemplate>
  );
};

const Main = () => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const responsiveArray = [
    [
      generalsEnums.fontSizes.size28.size,
      generalsEnums.fontSizes.size30.size,
      generalsEnums.fontSizes.size34.size,
      generalsEnums.fontSizes.size36.size,
      generalsEnums.fontSizes.size36.size,
    ],
    [
      generalsEnums.fontSizes.size28.lineHeight,
      generalsEnums.fontSizes.size30.lineHeight,
      generalsEnums.fontSizes.size34.lineHeight,
      generalsEnums.fontSizes.size36.lineHeight,
      generalsEnums.fontSizes.size36.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size8.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size18.size,
      generalsEnums.fontSizes.size18.size,
    ],
    [
      generalsEnums.fontSizes.size8.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
      generalsEnums.fontSizes.size18.lineHeight,
    ],
    [
      generalsEnums.fontSizes.size8.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size14.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size8.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size14.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];

  const [
    titleSize,
    titleLineHeight,
    headerItemTitleSize,
    headerItemTitleLineHeight,
    counterTitleSize,
    counterTitleLineHeight,
  ] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  return (
    <SecurityDashboard
      titleSize={titleSize}
      titleLineHeight={titleLineHeight}
      headerItemTitleSize={headerItemTitleSize}
      headerItemTitleLineHeight={headerItemTitleLineHeight}
      counterTitleSize={counterTitleSize}
      counterTitleLineHeight={counterTitleLineHeight}
    />
  );
};

export default Main;
