import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage/session';

import UserSlices from './Slices/User.Slices';
import ProductsSlices from './Slices/Products.Slices';
import NavigationSlices from './Slices/Navigation.Slices';
import ShoppingCartSlices from './Slices/ShoppingCart.Slices';
import OrderStatusSlices from './Slices/OrderStatus.Slices';
import PortalSlices from './Slices/Portal.Slices';
import DashboardSlices from './Slices/Dashboard.Slices';
import SecurityDashboard from './Slices/SecurityDashboard.Slices';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['User', 'Products', 'ShoppingCart', 'OrderStatus', 'PortalSlices', 'SecurityDashboard'],
  blacklist: [],
};

const rootReducer = combineReducers({
  User: UserSlices,
  Products: ProductsSlices,
  Navigation: NavigationSlices,
  ShoppingCart: ShoppingCartSlices,
  OrderStatus: OrderStatusSlices,
  PortalSlices: PortalSlices,
  DashboardSlices: DashboardSlices,
  SecurityDashboard: SecurityDashboard,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
