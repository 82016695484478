import React from 'react';
import { Box } from '@mui/material';

import generalsEnums from '../../../utils/Generals';
import Font from '../../../Components/Generals/Fonts/Font';
import CurrencyFont from '../../../Components/Generals/Fonts/CurrencyFont';
import { useStyles } from './styles.js';
import * as colors from '../../../assets/GlobalColors.js';

const HeaderItem = ({ headerItemTitleSize, headerItemTitleLineHeight, title, subtitle, value }) => {
  const classes = useStyles();

  return (
    <Box component={generalsEnums.BoxDiv} className={classes.HeaderItemRoot}>
      <Box
        component={generalsEnums.BoxDiv}
        className={classes.HeaderItemText}
        sx={{
          padding: '12px 24px',
          borderRadius: '8px 8px 0px 0px',
          backgroundColor: colors.GREEN_MAIN,
        }}
      >
        <Font
          text={title}
          color={colors.BLACK}
          size={headerItemTitleSize}
          lineHeight={headerItemTitleLineHeight}
          isHead={generalsEnums.trueBoolean}
          ml="0px"
        />
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.HeaderItemText}
        sx={{
          padding: '4px 12px',
          backgroundColor: colors.WHITE,
        }}
      >
        <Font
          text={subtitle}
          color={colors.BLACK_06}
          size={headerItemTitleSize}
          lineHeight={headerItemTitleLineHeight}
          isHead={generalsEnums.falseBoolean}
          ml="0px"
        />

        {typeof value === 'string' ? (
          <Font
            text={value}
            color={colors.GREEN_MAIN}
            size={headerItemTitleSize}
            lineHeight={headerItemTitleLineHeight}
            isHead={generalsEnums.trueBoolean}
            ml="0px"
          />
        ) : (
          <CurrencyFont
            price={value}
            color={colors.GREEN_MAIN}
            size={headerItemTitleSize}
            lineHeight={headerItemTitleLineHeight}
            isHead={generalsEnums.trueBoolean}
          />
        )}
      </Box>
    </Box>
  );
};

export default HeaderItem;
