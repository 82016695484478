import React from 'react';
import { Box, Switch } from '@mui/material';

import Font from '../../../../Components/Generals/Fonts/Font';
import TsiLogo from '../../../../Components/Generals/TsiLogo';
import ResponsiveComponent from '../../../../Hooks/ResponsiveComponent';
import { getResponsiveValues } from '../../../../utils/FormatData';

const Template = ({ generalsEnums, colors, templateData, tamplateLanguage, setTamplateLanguage }) => {
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();

  const [dataToRender, setDataToRender] = React.useState(<></>);
  const [isTemplateAvailable, setIsTemplateAvailable] = React.useState(generalsEnums.falseBoolean);

  const responsiveArray = [
    ['100%', '100%', '500px', '800px', '1000px'],
    ['column-reverse', 'column-reverse', 'row', 'row', 'row'],
  ];

  const [templateWidth, templateDirection] = getResponsiveValues(responsiveArray, isXs, isSm, isMd, isLg, isXl);

  React.useEffect(() => {
    const data =
      tamplateLanguage === generalsEnums.languages.english ? templateData.englishData : templateData.spanishData;

    if (data !== undefined) {
      setDataToRender(<></>);

      const renderedData = [];

      Object.keys(data).forEach((key) => {
        if (key !== 'subject') {
          const value = data[key];

          let textValue = value;

          if (value.includes('%name%')) {
            textValue = value.replace(
              '%name%',
              tamplateLanguage === generalsEnums.languages.spanish ? 'NOMBRE DEL USUARIO' : 'USER NAME'
            );
          } else if (value.includes('%employeeName%')) {
            textValue = value.replace(
              '%employeeName%',
              tamplateLanguage === generalsEnums.languages.spanish ? 'NOMBRE DEL EMPLEADO' : 'EMPLOYEE NAME'
            );
          } else if (textValue.includes('<strong>')) {
            textValue = textValue.replace('<strong>', '');
          } else if (textValue.includes(':</strong>')) {
            textValue = textValue.replace(':</strong>', '');
          }

          renderedData.push(
            <Font
              key={key}
              text={textValue}
              color={colors.BLACK}
              size={generalsEnums.fontSizes.size14.size}
              lineHeight={generalsEnums.fontSizes.size14.lineHeight}
              isHead={false}
              isCenter={false}
            />
          );
        }
      });

      setDataToRender((prevState) => (
        <>
          {prevState}
          {renderedData}
        </>
      ));
    }
  }, [templateData, tamplateLanguage]);

  React.useEffect(() => {
    if (!isTemplateAvailable) {
      setTamplateLanguage(generalsEnums.languages.spanish);
    } else {
      setTamplateLanguage(generalsEnums.languages.english);
    }
  }, [isTemplateAvailable]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: '100%',
        height: '100%',
        padding: '4px',
        display: 'flex',
        flexDirection: templateDirection,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      }}
    >
      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: templateWidth,
          height: '100%',
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',

          backgroundColor: colors.WHITE,
          border: `1px solid ${colors.BORDER_GRAY_1}`,
        }}
      >
        <TsiLogo />

        {dataToRender}
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: 'auto',
          height: 'auto',
          padding: '4px',
        }}
      >
        <Font
          text={'Lenguaje'}
          color={colors.BLACK}
          size={generalsEnums.fontSizes.size14.size}
          lineHeight={generalsEnums.fontSizes.size14.lineHeight}
          isHead={false}
          isCenter={false}
        />

        <Box
          sx={{
            width: 'auto',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Font
            text={'Español'}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size14.size}
            lineHeight={generalsEnums.fontSizes.size14.lineHeight}
            isHead={false}
            isCenter={false}
          />

          <Switch checked={isTemplateAvailable} onChange={() => setIsTemplateAvailable(!isTemplateAvailable)} />

          <Font
            text={'English'}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size14.size}
            lineHeight={generalsEnums.fontSizes.size14.lineHeight}
            isHead={false}
            isCenter={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Template;
