import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider } from '@mui/material';

import generalsEnums from '../../../../../utils/Generals';
import Icons from '../../../../../Components/Generals/Figma/Icons/Icons';
import * as colors from '../../../../../assets/GlobalColors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '24px',
  },
  summary: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '24px',
    overflow: 'auto',
  },
  details: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: '8px',
    padding: '16px',
    gap: '16px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.BORDER_CCC}`,
  },
  detailsTitle: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsAddress: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '4px',
  },
  logHistoryCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    padding: '8px',
  },
  logHistoryText: {
    width: '100%',
    padding: '16px',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: '8px',
    backgroundColor: colors.WHITE,
  },
  logHistoryTextTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    width: '18px',
    height: '18px',
  },
});

const LogBadge = ({ kind }) => {
  const classes = useStyles();

  const [iconKind, setIconKind] = React.useState(generalsEnums.nullData);

  React.useEffect(() => {
    switch (kind) {
      case generalsEnums.logHistoryKind.mail:
        setIconKind(generalsEnums.icons.kind.ForwardToInbox);
        break;
      case generalsEnums.logHistoryKind.change:
        setIconKind(generalsEnums.icons.kind.Error);
        break;
      case generalsEnums.logHistoryKind.notification:
        setIconKind(generalsEnums.icons.kind.NotificationsActive);
        break;
      default:
        setIconKind(generalsEnums.nullData);
    }
  }, []);

  return (
    <Box
      component="div"
      sx={{
        padding: '11px',
        borderRadius: '5px',
        backgroundColor: colors.GREEN_MAIN,
      }}
    >
      <Icons kind={iconKind} c={classes.icon} />
    </Box>
  );
};

export default LogBadge;
