import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectToken, selectUserType } from '../Redux/Slices/User.Slices';

const AdminPortalPrivateRoutes = () => {
  const token = useSelector(selectToken);
  const userType = useSelector(selectUserType);

  return (token != null) & (userType == 'TSADMIN') ? <Outlet /> : <Navigate to="/ts-admin/signin" />;
};

export default AdminPortalPrivateRoutes;
