import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '20px',
  },
  userSection: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    padding: '10px 10px 10px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '20px',
    border: `1px solid ${colors.GRAY_214}`,
    backgroundColor: colors.WHITE,
  },
  sectionButton: {
    width: '100%',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    textTransform: 'none',
    color: colors.BLACK_1,
    borderBottom: `1px solid ${colors.GRAY_214}`,
    backgroundColor: colors.WHITE,
    boxShadow: 'none',
    borderRadius: '6px',

    '&:hover': {
      opacity: '0.8',
      backgroundColor: colors.WHITE,
    },
  },
});
