import { makeStyles } from '@mui/styles';

import * as colors from '../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '1200px',
    height: '800px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: colors.WHITE,
    borderRadius: '6px',
    padding: '10px',
    gap: '10px',
  },
  titleBox: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabsRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '5px',
  },
  formRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  InfoRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '25px',
  },
  InfoRow: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  InfoInputsRow: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  buttonsRoot: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  },
  button: {
    width: '180px',
    height: '40px',
    borderRadius: '6px',
    display: 'flex',
    padding: '12px 25px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
});
