import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Container } from '@material-ui/core';

import FocusButton from '../../../../Components/Generals/FocusButton';
import Font from '../../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../../utils/Generals';
import CustomTabs from '../../../../Components/Generals/CustomTabs';
import Logo from './Logo';
import Favicon from './Favicon';
import portalSettings from '../../../../entities/portalSettings';
import {
  setTextLogoObject,
  setImageLogoObject,
  selectTextLogoObject,
  setTextLogoFontSize,
  setTextLogoColor,
  setTextLogoIsUpdated,
  setTextLogoText,
  setBackDropState,
  selectImageLogoObject,
  setImageLogoImage,
  selectLogoIsUpdated,
  setImageLogoIsDefault,
  setTextLogoIsDefault,
  setFaviconObject,
  selectFaviconObject,
  setFaviconImage,
} from '../../../../Redux/Slices/Navigation.Slices';
import { useStyles, colors } from './styles';

const LogoAndFavicon = () => {
  const dispatch = useDispatch();
  const portalSettingsAPI = new portalSettings();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [textLogoData, setTextLogoData] = React.useState(generalsEnums.emptyObject);
  const [imageLogoData, setImageLogoData] = React.useState(generalsEnums.emptyObject);
  const [faviconData, setFaviconData] = React.useState(generalsEnums.emptyObject);

  const textLogoObject = useSelector(selectTextLogoObject);
  const imageLogoObject = useSelector(selectImageLogoObject);
  const logoIsUpdated = useSelector(selectLogoIsUpdated);
  const faviconObject = useSelector(selectFaviconObject);

  const handleSetFontSize = (fontSize) => {
    dispatch(setTextLogoFontSize(fontSize));
    dispatch(setTextLogoIsUpdated(true));
  };

  const handleSetColor = (color) => {
    dispatch(setTextLogoColor(color));
    dispatch(setTextLogoIsUpdated(true));
  };

  const handleSetText = (text) => {
    dispatch(setTextLogoText(text));
    dispatch(setTextLogoIsUpdated(true));
  };

  const handleSetImage = (image) => {
    dispatch(setImageLogoImage(image));
    dispatch(setTextLogoIsUpdated(true));
  };

  const handleCancelAction = () => {
    dispatch(setTextLogoObject(textLogoData));
    dispatch(setImageLogoObject(imageLogoData));
    dispatch(setFaviconObject(faviconData));
    dispatch(setTextLogoIsUpdated(false));
  };

  const handleSaveChanges = () => {
    dispatch(setBackDropState(true));

    const objectsToSave = [textLogoObject, imageLogoObject, faviconObject];

    objectsToSave.forEach(async (object) => {
      const response = await portalSettingsAPI.editPortalLogo(object);
      if (response !== generalsEnums.undefinedData) {
        dispatch(setTextLogoIsUpdated(false));
        dispatch(setBackDropState(false));
      } else dispatch(setBackDropState(false));
    });
  };

  const handleSetIsDefault = (type) => {
    const isText = type === generalsEnums.variants.text;

    dispatch(setTextLogoIsDefault(isText));
    dispatch(setImageLogoIsDefault(!isText));
    dispatch(setTextLogoIsUpdated(true));
  };

  const handleSetFaviconImage = (image) => {
    dispatch(setFaviconImage(image));
    dispatch(setTextLogoIsUpdated(true));
  };

  const tabs = [
    {
      label: t('admin.LogoAndFavicon.tabs.logo'),
      component: (
        <Logo
          classes={classes}
          colors={colors}
          t={t}
          generalsEnums={generalsEnums}
          textLogoObject={textLogoObject}
          handleSetFontSize={handleSetFontSize}
          handleSetColor={handleSetColor}
          handleSetText={handleSetText}
          imageLogoObject={imageLogoObject}
          handleSetImage={handleSetImage}
          handleSetIsDefault={handleSetIsDefault}
        />
      ),
      value: generalsEnums.zeroNumber,
    },
    {
      label: t('admin.LogoAndFavicon.tabs.favicon'),
      component: (
        <Favicon
          classes={classes}
          colors={colors}
          t={t}
          generalsEnums={generalsEnums}
          faviconObject={faviconObject}
          handleSetFaviconImage={handleSetFaviconImage}
        />
      ),
      value: generalsEnums.oneNumber,
    },
  ];

  React.useEffect(async () => {
    dispatch(setBackDropState(true));
    const response = await portalSettingsAPI.getPortalLogos();

    if (response !== generalsEnums.undefinedData) {
      dispatch(setTextLogoIsUpdated(false));
      const { data } = response.data;

      const textLogo = data.find((logo) => logo.type === generalsEnums.variants.text);
      const imageLogo = data.find((logo) => logo.type === generalsEnums.variants.image);
      const favicon = data.find((logo) => logo.type === generalsEnums.variants.favicon);

      if (textLogo !== generalsEnums.undefinedData) {
        setTextLogoData(textLogo);
        dispatch(setTextLogoObject(textLogo));
      }
      if (imageLogo !== generalsEnums.undefinedData) {
        setImageLogoData(imageLogo);
        dispatch(setImageLogoObject(imageLogo));
      }
      if (favicon !== generalsEnums.undefinedData) {
        setFaviconData(favicon);
        dispatch(setFaviconObject(favicon));
      }

      dispatch(setBackDropState(false));
    } else dispatch(setBackDropState(false));
  }, []);

  return (
    <Container maxWidth="xl">
      <Box
        component="div"
        className={classes.root}
        sx={{
          width: '100%',
          padding: generalsEnums.paddings.containerRoot,
        }}
      >
        <Box
          component="div"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Font
            text={t('admin.LogoAndFavicon.title')}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size20.size}
            lineHeight={generalsEnums.fontSizes.size20.lineHeight}
            isHead={true}
          />

          {logoIsUpdated ? (
            <Box
              component="div"
              sx={{
                width: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '10px  ',
              }}
            >
              <FocusButton
                handleAction={handleCancelAction}
                normalColor={colors.WHITE}
                focusedColor={colors.BLACK}
                text={t('Generals.cancelAction')}
              />

              <FocusButton
                handleAction={handleSaveChanges}
                normalColor={colors.GREEN_MAIN}
                focusedColor={colors.BLACK}
                text={t('Generals.saveChanges')}
              />
            </Box>
          ) : null}
        </Box>

        <CustomTabs bColor="transparent" border="none" textColor={colors.BLACK} tabs={tabs} />
      </Box>
    </Container>
  );
};

export default LogoAndFavicon;
