import React from 'react';
import { Box, Select, MenuItem, FormControl, Typography } from '@mui/material';

import { useStyles } from './AddressForm.styles';
import AddressTextField from '../../Generals/AddressTextField';
import { mexicoStates } from '../../../utils/States';
import FormikTextField from '../../Generals/FormikTextField';
import * as colors from '../../../assets/GlobalColors';

const AddressForm = ({
  phStreet,
  fStreet,
  fStreetValue,
  streetError,
  phOptionalApartment,
  fApartment,
  fApartmentValue,
  phCountry,
  fCountry,
  fCountryValue,
  countryError,
  phZipCode,
  fZipCode,
  fZipCodeValue,
  zipCodeError,
  phCity,
  fCity,
  fCityValue,
  cityError,
  phState,
  fState,
  fStateValue,
  stateError,
  change,
  formik,
  onlyView,
}) => {
  const classes = useStyles();
  const COMMON_WIDTH = '300px';

  const [state, setState] = React.useState('');

  const handleZipCodeInput = (e) => {
    const { value } = e.target;
    const zipCode = value.replace(/[^0-9]+/g, '');

    if (zipCode.length <= 5) {
      formik.setFieldValue(fZipCode, zipCode);
    }
  };

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.row}>
        <FormikTextField
          itemValue={fStreetValue}
          itemSize="medium"
          itemId={fStreet}
          itemName={fStreet}
          itemVariant="outlined"
          itemOnChange={(e) => {
            formik.handleChange(e);
          }}
          itemStyle={{
            border: streetError ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
            borderRadius: '5px',
            width: COMMON_WIDTH,
            backgroundColor: colors.WHITE,
          }}
          itemType="text"
          itemPlaceHolderColor={colors.BLACK}
          itemPlaceHolderSize="13px"
          itemPlaceholder={phStreet}
          itemDisabled={onlyView}
        />

        <FormikTextField
          itemValue={fApartmentValue}
          itemSize="medium"
          itemId={fApartment}
          itemName={fApartment}
          itemVariant="outlined"
          itemOnChange={(e) => {
            formik.handleChange(e);
          }}
          itemStyle={{
            border: `0.4px solid ${colors.GRAY_214_1}`,
            borderRadius: '5px',
            width: COMMON_WIDTH,
            backgroundColor: colors.WHITE,
          }}
          itemType="text"
          itemPlaceHolderColor={colors.BLACK}
          itemPlaceHolderSize="13px"
          itemPlaceholder={phOptionalApartment}
          itemDisabled={onlyView}
        />

        <FormikTextField
          itemValue={fCountryValue}
          itemSize="medium"
          itemId={fCountry}
          itemName={fCountry}
          itemVariant="outlined"
          itemOnChange={(e) => {
            formik.handleChange(e);
          }}
          itemStyle={{
            border: countryError ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
            borderRadius: '5px',
            width: COMMON_WIDTH,
            backgroundColor: colors.WHITE,
          }}
          itemType="text"
          itemPlaceHolderColor={colors.BLACK}
          itemPlaceHolderSize="13px"
          itemPlaceholder={phCountry}
          itemDisabled={onlyView}
        />
      </Box>

      <Box component="div" className={classes.row}>
        <FormikTextField
          itemValue={fZipCodeValue}
          itemSize="medium"
          itemId={fZipCode}
          itemName={fZipCode}
          itemVariant="outlined"
          itemOnChange={(e) => {
            handleZipCodeInput(e);
          }}
          itemStyle={{
            border: zipCodeError ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
            borderRadius: '5px',
            width: COMMON_WIDTH,
            backgroundColor: colors.WHITE,
          }}
          itemType="text"
          itemPlaceHolderColor={colors.BLACK}
          itemPlaceHolderSize="13px"
          itemPlaceholder={phZipCode}
          itemDisabled={onlyView}
        />

        <FormikTextField
          itemValue={fCityValue}
          itemSize="medium"
          itemId={fCity}
          itemName={fCity}
          itemVariant="outlined"
          itemOnChange={(e) => {
            formik.handleChange(e);
          }}
          itemStyle={{
            border: cityError ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
            borderRadius: '5px',
            width: COMMON_WIDTH,
            backgroundColor: colors.WHITE,
          }}
          itemType="text"
          itemPlaceHolderColor={colors.BLACK}
          itemPlaceHolderSize="13px"
          itemPlaceholder={phCity}
          itemDisabled={onlyView}
        />

        <Box
          component="div"
          style={{
            width: COMMON_WIDTH,
            height: '100%',
          }}
        >
          <FormControl fullWidth>
            {onlyView === true ? (
              <FormikTextField
                itemValue={fStateValue}
                itemSize="medium"
                itemId={fState}
                itemName={fState}
                itemVariant="outlined"
                itemOnChange={(e) => {
                  formik.handleChange(e);
                }}
                itemStyle={{
                  border: cityError ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                  borderRadius: '5px',
                  width: COMMON_WIDTH,
                  backgroundColor: colors.WHITE,
                }}
                itemType="text"
                itemPlaceHolderColor={colors.BLACK}
                itemPlaceHolderSize="13px"
                itemPlaceholder={phState}
                itemDisabled={onlyView}
              />
            ) : (
              <Select
                name={fState}
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  formik.setFieldValue(fState, e.target.value);
                }}
                fullWidth
                placeholder={phState}
                style={{
                  backgroundColor: 'white',
                  border: stateError ? `1.5px solid ${colors.ORANGE}` : `1.5px solid ${colors.GRAY_229_1}`,
                  boxShadow: 'none',
                  color: colors.BLACK_06,
                  height: '54px',
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Typography
                        variant="string"
                        className="text-14"
                        style={{
                          color: colors.BLACK_06,
                        }}
                      >
                        {phState}
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        variant="string"
                        className="text-14"
                        style={{
                          color: colors.BLACK_06,
                        }}
                      >
                        {selected}
                      </Typography>
                    );
                  }
                }}
                displayEmpty
              >
                {mexicoStates.map((state, idx) => (
                  <MenuItem key={idx} value={state.label}>
                    {state.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default AddressForm;
