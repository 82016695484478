import React from 'react';
import { Box } from '@mui/material';

const Item = ({ generalsEnums, classes, colors, isWhite, ruleText, priceText, markupText }) => {
  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.rowBox}
      sx={{
        padding: '8px 16px',
        gap: '8px',
        border: `1px solid ${colors.GREEN_MAIN}`,
        backgroundColor: isWhite ? colors.WHITE : colors.GREEN_MAIN_2,
      }}
    >
      <Box component={generalsEnums.BoxDiv} className={classes.listRuleBox}>
        {ruleText}
      </Box>

      <Box component={generalsEnums.BoxDiv} className={classes.listPriceBox}>
        {priceText}
      </Box>

      <Box component={generalsEnums.BoxDiv} className={classes.listMarkupBox}>
        {markupText}
      </Box>
    </Box>
  );
};

export default Item;
