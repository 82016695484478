import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import generalsEnums from '../../../../utils/Generals';
import Icons from '../Icons/Icons';
import TsiButton from '../Atoms/TsiButton';

const GoBackButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);

  const textTemplateSize =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size10.size : generalsEnums.fontSizes.size12.size;
  const textTemplateLineHeight =
    IpadAir || IpadMini ? generalsEnums.fontSizes.size10.lineHeight : generalsEnums.fontSizes.size12.lineHeight;
  const iconWidth = IpadAir || IpadMini ? '12px' : '16px';
  const iconHeight = IpadAir || IpadMini ? '12px' : '16px';

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <TsiButton
      type={generalsEnums.buttonTypes.transparent}
      handleAction={handleGoBack}
      text={t('Generals.goBack')}
      isDisabled={false}
      size={textTemplateSize}
      lineHeight={textTemplateLineHeight}
      icon={<Icons kind={generalsEnums.icons.kind.ArrowBack} c={null} w={iconWidth} h={iconHeight} />}
    />
  );
};

export default GoBackButton;
