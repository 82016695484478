import { makeStyles } from '@mui/styles';

import * as colors from '../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    padding: '10px 10px 10px 10px',
    marginTop: '25px',
    marginBottom: '25px',
  },
  container: {
    width: 'auto',
    minWidth: '100px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '20px',
    backgroundColor: colors.WHITE,
    padding: '12px',
    borderRadius: '6px',
    border: `1px solid ${colors.GRAY_204_1}`,
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: colors.BLACK_06,
    color: colors.BLACK_06,
    marginTop: '5px',
    marginBottom: '5px',
  },
  formItem: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formItemInputBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  formItemCondition: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  FreeShiping: {
    width: '100%',
    height: 'auto',
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.GRAY_214_1}`,
    borderRadius: '6px',
  },
  FreeShipingTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  continueButton: {
    width: '170px',
    height: '40px',
    backgroundColor: colors.GREEN_MAIN,
    border: `1px solid ${colors.GREEN_MAIN}`,
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: colors.GREEN_MAIN,
      border: `1px solid ${colors.GREEN_MAIN}`,
      borderRadius: '6px',
      opacity: '0.8',
    },
  },
  cancelButton: {
    width: '170px',
    height: '40px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.WHITE}`,
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: colors.WHITE,
      border: `1px solid ${colors.WHITE}`,
      borderRadius: '6px',
      opacity: '0.8',
    },
  },
});
