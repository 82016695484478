import React from 'react';
import Image from 'react-bootstrap/Image';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import * as yup from 'yup';

import InputText from '../../../../Components/Generals/InputText';
import TsiButton from '../../../../Components/Generals/Figma/Atoms/TsiButton';
import generalsEnums from '../../../../utils/Generals';
import { setBackDropState, setSnackBar } from '../../../../Redux/Slices/Navigation.Slices';

const EnableQrMfa = ({ qrImage, t, colors, handleCancelQr, sub, AccessToken, usersAPI, handleCloseQrSuccess }) => {
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    UserCode: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      UserCode: generalsEnums.emptyString,
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      dispatch(setBackDropState(generalsEnums.trueBoolean));

      const enableBody = {
        sub: sub,
      };

      const enableResponse = await usersAPI.enableUserMFA(enableBody);
      const { statusCode } = enableResponse;

      if (statusCode === generalsEnums.statusCodes.ok) {
        const validateMfaBody = {
          AccessToken: AccessToken,
          UserCode: values.UserCode,
        };

        const validateMfaResponse = await usersAPI.validateUserMFA(validateMfaBody);
        const { statusCode, messages } = validateMfaResponse;

        if (statusCode === generalsEnums.statusCodes.ok) {
          formik.resetForm();
          handleCloseQrSuccess();
          const language = localStorage.getItem('language');
          const message = messages[language];
          dispatch(setBackDropState(generalsEnums.falseBoolean));
          dispatch(
            setSnackBar({
              snackBarStatus: true,
              snackBarSeverity: generalsEnums.snackBar.success,
              snackBarMessage: message,
            })
          );
        } else dispatch(setBackDropState(generalsEnums.falseBoolean));
      } else dispatch(setBackDropState(generalsEnums.falseBoolean));
    },
  });

  const handleNumberInput = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value.length <= 6) {
        formik.setFieldValue('UserCode', e.target.value);
      }
    }
  };

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '12px',
      }}
    >
      <Image src={qrImage} />

      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
        }}
      >
        <Box
          component="div"
          sx={{
            width: '400px',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '12px',
          }}
        >
          <InputText
            value={formik.values.UserCode}
            name="UserCode"
            onChange={(e) => handleNumberInput(e)}
            label={t('admin.MyProfile.MFA.qr.UserCode.label')}
            labelColor={colors.BLACK_06}
            ph={t('admin.MyProfile.MFA.qr.UserCode.placeholder')}
            error={formik.errors.UserCode}
            isDisabled={generalsEnums.falseBoolean}
            w="100%"
            isPassword={generalsEnums.falseBoolean}
            extraLabel={generalsEnums.undefinedData}
            rows={generalsEnums.undefinedData}
            helperText={generalsEnums.undefinedData}
            hasRows={generalsEnums.falseBoolean}
            hasErrorMessage={generalsEnums.falseBoolean}
            errorMessage={generalsEnums.emptyString}
          />

          <TsiButton
            type={generalsEnums.buttonTypes.save}
            handleAction={() => formik.handleSubmit()}
            text={t('Generals.save')}
            isDisabled={generalsEnums.falseBoolean}
          />

          <TsiButton
            type={generalsEnums.buttonTypes.black}
            handleAction={handleCancelQr}
            text={t('Generals.cancelAction')}
            isDisabled={generalsEnums.falseBoolean}
          />
        </Box>
      </form>
    </Box>
  );
};

export default EnableQrMfa;
