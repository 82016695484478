import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import * as yup from 'yup';

import Font from '../../../Components/Generals/Fonts/Font';
import FormikTextField from '../../../Components/Generals/FormikTextField';
import { setBackDropState, setToastError, setToastSuccessMessage } from '../../../Redux/Slices/Navigation.Slices';
import { addUserData, selectUserData } from '../../../Redux/Slices/ShoppingCart.Slices';
import * as colors from '../../../assets/GlobalColors';

const UserForm = ({ classes, setShowUserForm }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const [isValidateOnChange, setIsValidateOnChange] = React.useState(false);

  const COMMON_WIDTH = '320px';
  const userData = useSelector(selectUserData);

  const placeHolders = {
    phName: t('client.ConfirmOrder.phName'),
    phEmail: t('client.ConfirmOrder.phUserEmail'),
  };

  const schema = yup.object().shape({
    userName: yup.string().required(),
    userEmail: yup.string().email().required(),
  });

  const formik = useFormik({
    initialValues: {
      userName: '',
      userEmail: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: isValidateOnChange,
    onSubmit: async (values) => {
      const { userName, userEmail } = values;
      dispatch(setBackDropState(true));

      const userData = {
        name: userName,
        email: userEmail,
      };

      dispatch(
        addUserData({
          userData: userData,
        })
      );

      dispatch(setBackDropState(false));

      setShowUserForm(false);
    },
  });

  React.useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      formik.setFieldValue('userName', userData.name);
      formik.setFieldValue('userEmail', userData.email);
    }
  }, [userData]);

  React.useEffect(() => {
    if (Object.keys(formik.errors).length !== 0) {
      dispatch(setToastError(true));
      dispatch(setToastSuccessMessage(t('Errors.incompleteForm')));
      setIsValidateOnChange(true);    
    }
  }, [formik.errors]);

  return (
    <Box
      component="div"
      className={classes.formItem}
      sx={{
        flexDirection: 'column',
        padding: '15px 15px 15px 15px',
        backgroundColor: colors.WHITE,
        borderRadius: '5px',
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Font text={t('client.ConfirmOrder.UserForm')} color={colors.BLACK} size={20} lineHeight="26px" isHead={true} />

        <Box
          component="div"
          className={classes.formItem}
          sx={{
            flexDirection: 'row',
            marginTop: '10px',
            gap: '10px',
          }}
        >
          <Box component="div" className={classes.formItemInputBox}>
            <Font text={`${placeHolders.phName} *`} color={colors.BLACK} size={14} lineHeight="22px" isHead={true} />

            <FormikTextField
              itemValue={formik.values.userName}
              itemSize="medium"
              itemId="userName"
              itemName="userName"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.userName ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: COMMON_WIDTH,
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={placeHolders.phName}
            />
          </Box>

          <Box component="div" className={classes.formItemInputBox}>
            <Font text={`${placeHolders.phEmail} *`} color={colors.BLACK} size={14} lineHeight="22px" isHead={true} />

            <FormikTextField
              itemValue={formik.values.userEmail}
              itemSize="medium"
              itemId="userEmail"
              itemName="userEmail"
              itemVariant="outlined"
              itemOnChange={(e) => {
                formik.handleChange(e);
              }}
              itemStyle={{
                border: formik.errors.userEmail ? '1px solid red' : `0.4px solid ${colors.GRAY_214_1}`,
                borderRadius: '5px',
                width: COMMON_WIDTH,
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={colors.BLACK}
              itemPlaceHolderSize="13px"
              itemPlaceholder={placeHolders.phEmail}
            />
          </Box>
        </Box>

        <Box
          component="div"
          className={classes.formItem}
          sx={{
            marginTop: '10px',
          }}
        >
          <Button onClick={formik.handleSubmit} variant="contained" className={classes.continueButton}>
            <Font text={t('Generals.continue')} color={colors.BLACK} size={14} lineHeight="26px" isHead={true} />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UserForm;
