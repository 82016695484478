import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './DataForm.styles';
import AddressForm from '../../../Components/Client/AddressForm/AddressForm';
import PersonalForm from '../../../Components/Client/PersonalForm/PersonalForm';
import { selectUserId } from '../../../Redux/Slices/User.Slices';
import Addresses from '../../../entities/Addresses';
import { setBackDropState } from '../../../Redux/Slices/Navigation.Slices';
import Font from '../../../Components/Generals/Fonts/Font';

const addresses = new Addresses();

const DataForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { t } = useTranslation('global');

  const userId = useSelector(selectUserId);

  let { type } = useParams();

  const placeHolders = {
    phStreet: t('client.ConfirmOrder.phStreet'),
    phOptionalApartment: t('client.ConfirmOrder.phOptionalApartment'),
    phCountry: t('client.ConfirmOrder.phCountry'),
    phZipCode: t('client.ConfirmOrder.phZipCode'),
    phCity: t('client.ConfirmOrder.phCity'),
    phState: t('client.ConfirmOrder.phState'),
    phName: t('client.ConfirmOrder.phName'),
    phLastName: t('client.ConfirmOrder.phLastName'),
    phWorkEnterprise: t('client.ConfirmOrder.phWorkEnterprise'),
    phPhone: t('client.ConfirmOrder.phPhone'),
    phCardNumber: t('client.ConfirmOrder.phCardNumber'),
    phCardName: t('client.ConfirmOrder.phCardName'),
    phCardExpiration: t('client.ConfirmOrder.phCardExpiration'),
    phCardCVC: t('client.ConfirmOrder.phCardCVC'),
  };

  const schema = yup.object().shape({
    customerName: yup.string().required(),
    customerLastName: yup.string().required(),
    customerWorkEnterprise: yup.string(),
    customerPhone: yup.string().required(),
    customerStreet: yup.string().required(),
    customerApartment: yup.string(),
    customerCity: yup.string().required(),
    customerCountry: yup.string().required(),
    customerState: yup.string().required(),
    customerZipCode: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      customerName: '',
      customerLastName: '',
      customerWorkEnterprise: '',
      customerPhone: '',
      customerStreet: '',
      customerApartment: '',
      customerCity: '',
      customerCountry: '',
      customerState: '',
      customerZipCode: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (type === 'userAddress') {
        const body = {
          userId: userId,
          name: values.customerName,
          lastName: values.customerLastName,
          workEnterprise: values.customerWorkEnterprise,
          phone: values.customerPhone,
          street: values.customerStreet,
          apartment: values.customerApartment,
          city: values.customerCity,
          country: values.customerCountry,
          state: values.customerState,
          zipCode: values.customerZipCode,
        };

        dispatch(setBackDropState(true));

        const response = await addresses.createUsersAddressesHandler(body);

        if (response.statusCode === 200) {
          formik.resetForm();
          dispatch(setBackDropState(false));
          navigate('/checkout');
        } else {
          dispatch(setBackDropState(false));
        }
      }
    },
  });

  return (
    <Container maxWidth="md">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" className={classes.root}>
          <Font text={t('client.DataForm.title')} color="#000000" size={22} lineHeight="28px" isHead={true} />

          <PersonalForm
            phName={placeHolders.phName}
            customerName="customerName"
            customerNameValue={formik.values.customerName}
            nameError={formik.errors.customerName}
            phLastName={placeHolders.phLastName}
            customerLastName="customerLastName"
            customerLastNameValue={formik.values.customerLastName}
            lastNameError={formik.errors.customerLastName}
            phWorkEnterprise={placeHolders.phWorkEnterprise}
            customerWorkEnterprise="customerWorkEnterprise"
            customerWorkEnterpriseValue={formik.values.customerWorkEnterprise}
            phPhone={placeHolders.phPhone}
            customerPhone="customerPhone"
            customerPhoneValue={formik.values.customerPhone}
            change={formik.handleChange}
            formik={formik}
          />

          <AddressForm
            phStreet={placeHolders.phStreet}
            fStreet="customerStreet"
            fStreetValue={formik.values.customerStreet}
            streetError={formik.errors.customerStreet}
            phOptionalApartment={placeHolders.phOptionalApartment}
            fApartment="customerApartment"
            fApartmentValue={formik.values.customerApartment}
            phCountry={placeHolders.phCountry}
            fCountry="customerCountry"
            fCountryValue={formik.values.customerCountry}
            countryError={formik.errors.customerCountry}
            phZipCode={placeHolders.phZipCode}
            fZipCode="customerZipCode"
            fZipCodeValue={formik.values.customerZipCode}
            zipCodeError={formik.errors.customerZipCode}
            phCity={placeHolders.phCity}
            fCity="customerCity"
            fCityValue={formik.values.customerCity}
            cityError={formik.errors.customerCity}
            phState={placeHolders.phState}
            fState="customerState"
            fStateValue={formik.values.customerState}
            stateError={formik.errors.customerState}
            change={formik.handleChange}
            formik={formik}
          />

          <Button type="submit" variant="contained" className={classes.button}>
            <Typography variant="string" className="text-16">
              {t('client.DataForm.btnContinue')}
            </Typography>
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default DataForm;
