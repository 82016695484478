import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';

import generalsEnums from '../../../../utils/Generals';
import Font from '../../../../Components/Generals/Fonts/Font';
import * as colors from '../../../../assets/GlobalColors';

const BadgeAttom = ({ kind, text }) => {
  const [badgeBackgroundColor, setBadgeBackgroundColor] = React.useState(generalsEnums.emptyString);
  const [badgeTextColor, setBadgeTextColor] = React.useState(generalsEnums.emptyString);

  const IpadAir = useMediaQuery(generalsEnums.mediaQueries.IpadAir);
  const IpadMini = useMediaQuery(generalsEnums.mediaQueries.IpadMini);
  const isXs = useMediaQuery(generalsEnums.mediaQueries.isXs);
  const isSm = useMediaQuery(generalsEnums.mediaQueries.isSm);
  const isMd = useMediaQuery(generalsEnums.mediaQueries.isMd);
  const isLg = useMediaQuery(generalsEnums.mediaQueries.isLg);
  const rootPadding = IpadAir || IpadMini ? '4px 12px' : '5px 12px';

  const getResponsiveValue = (values) => {
    return isXs ? values[0] : isSm ? values[1] : isMd ? values[2] : isLg ? values[3] : values[4];
  };

  const responsiveSize = getResponsiveValue([
    generalsEnums.fontSizes.size12.size,
    generalsEnums.fontSizes.size12.size,
    generalsEnums.fontSizes.size14.size,
    generalsEnums.fontSizes.size14.size,
    generalsEnums.fontSizes.size16.size,
  ]);
  const responsiveLineHeight = getResponsiveValue([
    generalsEnums.fontSizes.size12.lineHeight,
    generalsEnums.fontSizes.size12.lineHeight,
    generalsEnums.fontSizes.size14.lineHeight,
    generalsEnums.fontSizes.size14.lineHeight,
    generalsEnums.fontSizes.size16.lineHeight,
  ]);

  React.useEffect(() => {
    switch (kind) {
      case generalsEnums.badge.green:
        setBadgeBackgroundColor(colors.BADGE_GREEN_BKC);
        setBadgeTextColor(colors.BADGE_GREEN_TEXT);
        break;
      case generalsEnums.badge.gray:
        setBadgeBackgroundColor(colors.BADGE_GRAY_BKC);
        setBadgeTextColor(colors.BADGE_GRAY_TEXT);
        break;
      case generalsEnums.badge.red:
        setBadgeBackgroundColor(colors.BADGE_RED_BKC);
        setBadgeTextColor(colors.BADGE_RED_TEXT);
        break;
      case generalsEnums.badge.greenSoft:
        setBadgeBackgroundColor(colors.BADGE_GREEN_SOFT);
        setBadgeTextColor(colors.BLACK);
        break;
      default:
        setBadgeBackgroundColor(colors.BADGE_GREEN_BKC);
        setBadgeTextColor(colors.BADGE_GREEN_TEXT);
        break;
    }
  }, [kind]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: 'fit-content',
        borderRadius: '100px',
        padding: rootPadding,
        backgroundColor: badgeBackgroundColor,
      }}
    >
      <Font
        text={text}
        color={badgeTextColor}
        size={responsiveSize}
        lineHeight={responsiveLineHeight}
        isHead={generalsEnums.trueBoolean}
        isCenter={generalsEnums.falseBoolean}
      />
    </Box>
  );
};

export default BadgeAttom;
