import { makeStyles } from '@mui/styles';

import * as colors from '../../../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: '25px',
    marginBottom: '50px',
    gap: '30px',
    textAlign: 'left',
  },
  EditIcon: {
    color: colors.BLACK,
  },
});
