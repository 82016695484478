import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Image from 'react-bootstrap/Image';
import CancelIcon from '@mui/icons-material/Cancel';
import { FileUploader } from 'react-drag-drop-files';
import { SketchPicker } from 'react-color';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Divider,
  Button,
  Popover,
  FormControlLabel,
  Radio,
} from '@mui/material';

import FormikTextField from '../../../../Components/Generals/FormikTextField';
import Font from '../../../../Components/Generals/Fonts/Font';

const Logo = ({
  classes,
  colors,
  t,
  generalsEnums,
  textLogoObject,
  handleSetFontSize,
  handleSetColor,
  handleSetText,
  imageLogoObject,
  handleSetImage,
  handleSetIsDefault,
}) => {
  const [expanded, setExpanded] = React.useState(generalsEnums.emptyString);
  const [fontSize, setFontSize] = React.useState(generalsEnums.zeroNumber);
  const [fontColor, setFontColor] = React.useState(generalsEnums.emptyString);
  const [anchorEl, setAnchorEl] = React.useState(generalsEnums.nullData);
  const [text, setText] = React.useState(generalsEnums.emptyString);
  const [image, setImage] = React.useState(generalsEnums.emptyString);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    handleSetIsDefault(panel);
  };

  const handleSubFontSize = (e) => {
    e.preventDefault();
    if (fontSize > 1) {
      setFontSize(fontSize - 1);
      handleSetFontSize(fontSize - 1);
    } else {
      setFontSize(1);
      handleSetFontSize(1);
    }
  };

  const handleAddFontSize = (e) => {
    e.preventDefault();
    setFontSize(fontSize + 1);
    handleSetFontSize(fontSize + 1);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color) => {
    setFontColor(color);
    handleSetColor(color);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    handleSetText(e.target.value);
  };

  const handleChangeImage = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result);
      handleSetImage(reader.result);
    };
  };

  const handleRemoveImage = () => {
    setImage(generalsEnums.emptyString);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  React.useEffect(() => {
    setFontSize(textLogoObject.fontSize);
    setFontColor(textLogoObject.fontColor);
    setText(textLogoObject.text);

    if (imageLogoObject.image !== generalsEnums.emptyString) setImage(imageLogoObject.image);

    if (textLogoObject.isDefault) {
      setExpanded(generalsEnums.variants.text);
    } else if (imageLogoObject.isDefault) {
      setExpanded(generalsEnums.variants.image);
    }
  }, [textLogoObject, imageLogoObject]);

  return (
    <Box
      component="div"
      className={classes.logoRoot}
      sx={{
        width: '100%',
        border: `1px solid ${colors.BLACK}`,
        backgroundColor: colors.WHITE,
      }}
    >
      <Accordion
        expanded={expanded === 'text'}
        onChange={handleChange('text')}
        sx={{
          width: '100%',
          border: `1px solid ${expanded === 'text' ? colors.GREEN_MAIN : colors.BLACK}`,
          backgroundColor: colors.WHITE,
          boxShadow: 'none',
          borderRadius: '6px',
          padding: '5px',
        }}
      >
        <AccordionSummary
          aria-controls="textd-content"
          id="textd-header"
          sx={{
            border: 'none',
            backgroundColor: colors.WHITE,
            boxShadow: 'none',
          }}
        >
          <Box
            component="div"
            className={classes.ItemBox}
            sx={{
              width: 'auto',
            }}
          >
            <FormControlLabel
              checked={expanded === 'text'}
              value="other"
              control={
                <Radio
                  sx={{
                    width: '10px',
                    height: '10px',
                  }}
                />
              }
              label=""
              sx={{
                width: 'auto',
                padding: '0px',
                '& .MuiSvgIcon-root': {
                  color: expanded === 'text' ? colors.GREEN_MAIN : colors.BLACK,
                },
              }}
            />

            <Font
              text={t('admin.LogoAndFavicon.logo.title')}
              color={colors.BLACK_06}
              size={18}
              lineHeight="28px"
              isHead={true}
            />
          </Box>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            border: 'none',
            backgroundColor: colors.WHITE,
            boxShadow: 'none',
          }}
        >
          <Box
            component="div"
            className={classes.LogoAccordionDetails}
            sx={{
              backgroundColor: colors.WHITE,
              border: `1px solid ${colors.GRAY_204_1}`,
              marginLeft: '30px',
            }}
          >
            <Font
              text={t('admin.LogoAndFavicon.logo.indication')}
              color={colors.BLACK_06}
              size={generalsEnums.fontSizes.size14.size}
              lineHeight={generalsEnums.fontSizes.size14.lineHeight}
              isHead={true}
            />

            <Box component="div" className={classes.LogoAccordionDetailsItem}>
              <Box
                component="div"
                className={classes.ItemColumnBox}
                sx={{
                  width: '150px',
                  backgroundColor: colors.WHITE,
                }}
              >
                <Font
                  text={t('admin.LogoAndFavicon.logo.label')}
                  color={colors.BLACK_06}
                  size={generalsEnums.fontSizes.size14.size}
                  lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                  isHead={true}
                />

                <Box
                  component="div"
                  className={classes.ItemBox}
                  sx={{
                    width: '100%',
                    backgroundColor: colors.WHITE,
                    border: `1px solid ${colors.GRAY_204_1}`,
                    borderRadius: '8px',
                    padding: '0px',
                  }}
                >
                  <Font
                    text={fontSize}
                    color={colors.BLACK_06}
                    size={generalsEnums.fontSizes.size14.size}
                    lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                    isHead={true}
                    ml="16px"
                  />

                  <Box
                    component="div"
                    className={classes.ItemBox}
                    sx={{
                      width: 'auto',
                      backgroundColor: colors.GRAY_204_1,
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '8px',
                    }}
                  >
                    <IconButton onClick={handleSubFontSize}>
                      <RemoveIcon fontSize={generalsEnums.icons.fontSize.small} />
                    </IconButton>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        width: '2px',
                        backgroundColor: colors.BLACK,
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                    />

                    <IconButton onClick={handleAddFontSize}>
                      <AddIcon fontSize={generalsEnums.icons.fontSize.small} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <Box
                component="div"
                aria-describedby={id}
                className={classes.ItemColumnBox}
                sx={{
                  width: '150px',
                  backgroundColor: colors.WHITE,
                }}
              >
                <Font
                  text={t('admin.LogoAndFavicon.logo.colorPicker')}
                  color={colors.BLACK_06}
                  size={generalsEnums.fontSizes.size14.size}
                  lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                  isHead={true}
                />

                <Box
                  component="div"
                  className={classes.ItemBox}
                  sx={{
                    width: '100%',
                    backgroundColor: colors.WHITE,
                    border: `1px solid ${colors.GRAY_204_1}`,
                    borderRadius: '8px',
                    padding: '0px',
                  }}
                >
                  <Button
                    variant="text"
                    onClick={handleClick}
                    sx={{
                      width: 'auto',
                      padding: '0px',
                      backgroundColor: colors.WHITE,

                      '&:hover': {
                        backgroundColor: colors.WHITE,
                        opacity: '0.8',
                      },
                    }}
                  >
                    <Font
                      text={fontColor}
                      color={colors.BLACK_06}
                      size={generalsEnums.fontSizes.size14.size}
                      lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                      isHead={true}
                    />
                  </Button>

                  <Box
                    component="div"
                    onClick={handleClick}
                    sx={{
                      width: '50px',
                      height: '35px',
                      backgroundColor: fontColor,
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '8px',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
              </Box>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                <Box component="div">
                  <SketchPicker
                    color={fontColor}
                    onChange={({ hex }) => {
                      handleColorChange(hex);
                    }}
                  />
                </Box>
              </Popover>
            </Box>

            <FormikTextField
              itemValue={text}
              itemSize="medium"
              itemId="text"
              itemName="text"
              itemVariant="outlined"
              itemOnChange={(e) => {
                handleTextChange(e);
              }}
              itemStyle={{
                border: `0.4px solid ${colors.GREEN_MAIN}`,
                borderRadius: '8px',
                width: '100%',
                backgroundColor: colors.WHITE,
              }}
              itemType="text"
              itemPlaceHolderColor={fontColor}
              itemPlaceHolderSize={fontSize}
              itemPlaceholder={t('admin.LogoAndFavicon.logo.placeholder')}
              itemMultiline={true}
              itemRows={4}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'image'}
        onChange={handleChange('image')}
        sx={{
          width: '100%',
          border: `1px solid ${expanded === 'image' ? colors.GREEN_MAIN : colors.BLACK}`,
          backgroundColor: colors.WHITE,
          boxShadow: 'none',
          borderRadius: '6px',
          padding: '5px',
        }}
      >
        <AccordionSummary
          aria-controls="textd-content"
          id="textd-header"
          sx={{
            border: 'none',
            backgroundColor: colors.WHITE,
            boxShadow: 'none',
          }}
        >
          <Box
            component="div"
            className={classes.ItemBox}
            sx={{
              width: 'auto',
            }}
          >
            <FormControlLabel
              checked={expanded === 'image'}
              value="other"
              control={
                <Radio
                  sx={{
                    width: '10px',
                    height: '10px',
                  }}
                />
              }
              label=""
              sx={{
                width: 'auto',
                padding: '0px',
                '& .MuiSvgIcon-root': {
                  color: expanded === 'image' ? colors.GREEN_MAIN : colors.BLACK,
                },
              }}
            />

            <Font
              text={t('admin.LogoAndFavicon.logo.titleImage')}
              color={colors.BLACK_06}
              size={18}
              lineHeight="28px"
              isHead={true}
            />
          </Box>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            border: 'none',
            backgroundColor: colors.WHITE,
            boxShadow: 'none',
          }}
        >
          <Box
            component="div"
            className={classes.LogoAccordionDetails}
            sx={{
              backgroundColor: colors.WHITE,
              marginLeft: '10px',
            }}
          >
            {image === generalsEnums.emptyString ? (
              <FileUploader
                handleChange={handleChangeImage}
                name="file"
                types={['png', 'jpeg', 'jpg']}
                label={''}
                children={
                  <Box
                    component="div"
                    style={{
                      width: '400px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '16px',
                      border: `1px dashed ${colors.GRAY_204_1}`,
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Font
                      text={t('admin.LogoAndFavicon.logo.fileUploader.title')}
                      color={colors.BLACK_06}
                      size={generalsEnums.fontSizes.size16.size}
                      lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                      isHead={true}
                    />
                    <Font
                      text={t('admin.LogoAndFavicon.logo.fileUploader.or')}
                      color={colors.BLACK_06}
                      size={generalsEnums.fontSizes.size16.size}
                      lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                      isHead={false}
                    />
                    <Font
                      text={t('admin.LogoAndFavicon.logo.fileUploader.browse')}
                      color={colors.BLACK_06}
                      size={generalsEnums.fontSizes.size16.size}
                      lineHeight={generalsEnums.fontSizes.size16.lineHeight}
                      isHead={false}
                    />
                  </Box>
                }
              />
            ) : (
              <Box
                component="div"
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                }}
              >
                <IconButton
                  onClick={handleRemoveImage}
                  sx={{
                    padding: '0px',
                    boxShadow: 'none',
                    border: 'none',
                    backgroundColor: colors.WHITE,

                    '&:hover': {
                      backgroundColor: colors.WHITE,
                      opacity: '0.8',
                    },
                  }}
                >
                  <CancelIcon
                    fontSize={generalsEnums.icons.fontSize.small}
                    sx={{
                      color: colors.TSADMIN_RED_DELETE,
                    }}
                  />
                </IconButton>

                <Image
                  src={image}
                  alt={t('admin.CreateProduct.label-image')}
                  style={{
                    width: 'auto',
                    height: 'auto',
                  }}
                />
              </Box>
            )}

            <Font
              text={t('admin.LogoAndFavicon.logo.fileUploader.formats')}
              color={colors.BLACK_06}
              size={generalsEnums.fontSizes.size16.size}
              lineHeight={generalsEnums.fontSizes.size16.lineHeight}
              isHead={false}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Logo;
