import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import * as yup from 'yup';

import Users from '../../../entities/Users';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import Font from '../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../utils/Generals';
import InputText from '../../../Components/Generals/InputText';
import { setUserInformation } from '../../../Redux/Slices/User.Slices';
import { setBackDropState, selectErrorId, selectErrorMessage } from '../../../Redux/Slices/Navigation.Slices';

const SignIn = ({ t, isCenter, colors, titleSize, titleLineHeight, textSize, textLineHeight, Paths, signInWidth }) => {
  const UsersAPI = new Users();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disableButton, setDisableButton] = React.useState(generalsEnums.falseBoolean);

  const errorId = useSelector(selectErrorId);
  const errorMessage = useSelector(selectErrorMessage);

  const handleSignUpNavigate = (e) => {
    e.preventDefault();
    navigate(Paths.UserManagement.signup);
  };

  const handleForgotPasswordNavigate = (e) => {
    e.preventDefault();
    navigate(Paths.UserManagement.forgotPassword);
  }

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      email: generalsEnums.emptyString,
      password: generalsEnums.emptyString,
    },
    validationSchema: schema,
    validateOnBlur: generalsEnums.falseBoolean,
    validateOnChange: generalsEnums.falseBoolean,
    validateOnMount: generalsEnums.falseBoolean,
    validationSchema: schema,
    onSubmit: async (values) => {
      dispatch(setBackDropState(generalsEnums.trueBoolean));
      setDisableButton(generalsEnums.trueBoolean);

      const body = {
        email: values.email,
        password: values.password,
      };

      try {
        const response = await UsersAPI.signin(body);

        dispatch(
          setUserInformation({
            token: response.token,
            exp: response.exp,
            user_id: response.user_id,
            name: response.name,
            email: response.email,
            userType: response.userType,
            sub: response.sub,
            lastName: response.lastName,
            workEnterprise: response.workEnterprise,
            phoneNumber: response.phoneNumber,
            allowMarketingEmails: response.allowMarketingEmails,
          })
        );

        dispatch(setBackDropState(generalsEnums.falseBoolean));
        setDisableButton(generalsEnums.falseBoolean);

        formik.resetForm();
        navigate(Paths.client.Home);
      } catch (error) {
        dispatch(setBackDropState(generalsEnums.falseBoolean));
        setDisableButton(generalsEnums.falseBoolean);
      }
    },
  });

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: signInWidth,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: isCenter,
      }}
    >
      <Font
        text={t('UserManagement.SignIn.title')}
        color={colors.WHITE}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Font
        text={t('UserManagement.SignIn.subtitle')}
        color={colors.GRAY_BG_6}
        size={textSize}
        lineHeight={textLineHeight}
        isHead={generalsEnums.falseBoolean}
        ml="0px"
        mt="10px"
      />

      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
        }}
      >
        <InputText
          value={formik.values.email}
          name="email"
          onChange={formik.handleChange}
          label={t('client.SignIn.label-email')}
          labelColor={colors.GRAY_BG_6}
          ph={t('client.SignIn.placeholder-email')}
          error={formik.errors.email}
          isDisabled={false}
          w="100%"
          isPassword={false}
          extraLabel={generalsEnums.undefinedData}
          rows={generalsEnums.undefinedData}
          helperText={generalsEnums.undefinedData}
          hasRows={generalsEnums.falseBoolean}
          hasErrorMessage={false}
          errorMessage={generalsEnums.emptyString}
          mt="20px"
        />

        <InputText
          value={formik.values.password}
          name="password"
          onChange={formik.handleChange}
          label={t('client.SignIn.label-password')}
          labelColor={colors.GRAY_BG_6}
          ph={t('client.SignIn.placeholder-password')}
          error={formik.errors.password}
          isDisabled={false}
          w="100%"
          isPassword={true}
          extraLabel={
            <Button
              variant="text"
              onClick={handleForgotPasswordNavigate}
              style={{
                textTransform: 'none',
              }}
            >
              <Font
                text={t('client.SignIn.label-forgot-password')}
                color={colors.GREEN_MAIN}
                size={textSize}
                lineHeight={textLineHeight}
                isHead={generalsEnums.falseBoolean}
                ml="0px"
              />
            </Button>
          }
          rows={generalsEnums.undefinedData}
          helperText={generalsEnums.undefinedData}
          hasRows={generalsEnums.falseBoolean}
          hasErrorMessage={errorId === '2' ? true : false}
          errorMessage={errorMessage}
          mt="20px"
        />

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            marginTop: '20px',
          }}
        >
          <TsiButton
            type={generalsEnums.buttonTypes.save}
            handleAction={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            text={t('client.SignIn.signin-button')}
            isDisabled={disableButton}
          />
        </Box>
      </form>

      <Box
        component="div"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginTop: '20px',
        }}
      >
        <Font
          text={t('client.SignIn.text-dont-have-account')}
          color={colors.GRAY_BG_6}
          size={textSize}
          lineHeight={textLineHeight}
          isHead={generalsEnums.falseBoolean}
          ml="0px"
        />

        <Button
          variant="text"
          type="submit"
          style={{
            textTransform: 'none',
            border: 'none',
            backgroundColor: 'transparent',
            shadow: 'none',
            marginLeft: '5px',
          }}
          onClick={handleSignUpNavigate}
        >
          <Font
            text={t('client.SignIn.signup-button')}
            color={colors.GREEN_MAIN}
            size={textSize}
            lineHeight={textLineHeight}
            isHead={generalsEnums.falseBoolean}
            ml="0px"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default SignIn;
