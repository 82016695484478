import React from 'react';
import uuid from 'react-uuid';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import Font from '../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../utils/Generals';
import RadioButtonAttom from '../../../Components/Generals/Figma/Atoms/RadioButtonAttom';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import { getAddressText } from '../../../utils/FormatData';
import { setToastError, setToastSuccessMessage } from '../../../Redux/Slices/Navigation.Slices';
import * as colors from '../../../assets/GlobalColors';

const AddressComponent = ({ text, color, size, lineHeight }) => (
  <Font text={text} color={color} size={size} lineHeight={lineHeight} isHead={generalsEnums.falseBoolean} ml="0px" />
);

const AddressItem = ({ address, addressSize, addressLineHeight, addressSelected, handleSelectAddress }) => {
  const [addressUserData, setAddressUserData] = React.useState(generalsEnums.emptyString);
  const [addressStreet, setAddressStreet] = React.useState(generalsEnums.emptyString);
  const [addressCity, setAddressCity] = React.useState(generalsEnums.emptyString);
  const [isSelect, setIsSelect] = React.useState(generalsEnums.falseBoolean);

  const setState = (stateToUpdate, value) => {
    switch (stateToUpdate) {
      case 'addressUserData':
        setAddressUserData(value);
        break;
      case 'addressStreet':
        setAddressStreet(value);
        break;
      case 'addressCity':
        setAddressCity(value);
        break;
      default:
        break;
    }
  };

  const handleSelect = (e) => {
    e.preventDefault();
    handleSelectAddress(address);
  };

  React.useEffect(() => {
    if (Object.keys(address).length > 0) {
      const addressTextFormates = getAddressText(address);

      const addressStatesToUpdate = {
        addressUserData: 'addressUserData',
        addressStreet: 'addressStreet',
        addressCity: 'addressCity',
      };

      Object.keys(addressStatesToUpdate).forEach((key) => {
        if (addressTextFormates[key]) {
          const stateToUpdate = addressStatesToUpdate[key];
          setState(stateToUpdate, addressTextFormates[key]);
        }
      });
    }
  }, [address]);

  React.useEffect(() => {
    const { _id } = address;
    const { _id: _idAddressSelected } = addressSelected;

    if (_id === _idAddressSelected) {
      setIsSelect(generalsEnums.trueBoolean);
    }
  }, [addressSelected, address]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '14px 18px 14px 18px',
        borderRadius: '10px',
        gap: '4px',
        border: `1px solid ${colors.GREEN_MAIN}`,
      }}
    >
      <RadioButtonAttom isChecked={isSelect} isDisabled={generalsEnums.falseBoolean} handleChange={handleSelect} />

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: 'auto',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <AddressComponent
          text={addressUserData}
          color={colors.BLACK}
          size={addressSize}
          lineHeight={addressLineHeight}
        />
        <AddressComponent text={addressStreet} color={colors.BLACK} size={addressSize} lineHeight={addressLineHeight} />
        <AddressComponent
          text={addressCity}
          color={colors.TEXT_GRAY_102}
          size={addressSize}
          lineHeight={addressLineHeight}
        />
      </Box>
    </Box>
  );
};

const AddressBook = ({
  t,
  userLoggedAddress,
  addressSize,
  addressLineHeight,
  handleOpenAddressFormFromAddressBook,
  addressObject,
  handleSaveAddress,
}) => {
  const dispatch = useDispatch();
  const [addressSelected, setAddressSelected] = React.useState(generalsEnums.emptyObject);
  const [userLoggedAddressLocal, setUserLoggedAddressLocal] = React.useState(generalsEnums.emptyArray);

  const handleSelectAddress = (NewAddressSelected) => {
    const { _id } = NewAddressSelected;
    const { _id: _idAddressSelected } = addressSelected;

    if (_id === _idAddressSelected) {
      setAddressSelected(generalsEnums.emptyObject);
    } else {
      setAddressSelected(NewAddressSelected);
    }
  };

  const handleUseThisAddress = (e) => {
    e.preventDefault();
    if (Object.keys(addressSelected).length === 0) {
      dispatch(setToastError(generalsEnums.trueBoolean));
      dispatch(setToastSuccessMessage(t('AddressBook.addressNotSelected')));
    } else {
      handleSaveAddress(addressSelected, generalsEnums.falseBoolean, generalsEnums.falseBoolean);
    }
  };

  React.useEffect(() => {
    if (Object.keys(addressObject).length > 0) {
      const { _id } = addressObject;
      const address = userLoggedAddressLocal.find((address) => address._id === _id);
      setAddressSelected(address);
    }
  }, [addressObject]);

  React.useEffect(() => {
    if (userLoggedAddress !== generalsEnums.nullData) {
      setUserLoggedAddressLocal(userLoggedAddress);
    }
  }, [userLoggedAddress]);

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '8px',
        padding: '0px 0px 8px 0px',
      }}
    >
      <Font
        text={t('AddressBook.title')}
        color={colors.BLACK}
        size={addressSize}
        lineHeight={addressLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      {userLoggedAddressLocal.map((address) => {
        return (
          <AddressItem
            key={uuid()}
            address={address}
            addressSize={addressSize}
            addressLineHeight={addressLineHeight}
            addressSelected={addressSelected}
            handleSelectAddress={handleSelectAddress}
          />
        );
      })}

      <Box
        component={generalsEnums.BoxDiv}
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '200px',
          }}
        >
          <TsiButton
            type={generalsEnums.buttonTypes.black}
            handleAction={handleOpenAddressFormFromAddressBook}
            text={t('AddressBook.buttons.addNewAddress')}
            isDisabled={generalsEnums.falseBoolean}
            size={generalsEnums.fontSizes.size12.size}
            lineHeight={generalsEnums.fontSizes.size12.lineHeight}
          />
        </Box>

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '200px',
          }}
        >
          <TsiButton
            type={generalsEnums.buttonTypes.save}
            handleAction={handleUseThisAddress}
            text={t('AddressBook.buttons.useThisAddress')}
            isDisabled={generalsEnums.falseBoolean}
            size={generalsEnums.fontSizes.size12.size}
            lineHeight={generalsEnums.fontSizes.size12.lineHeight}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddressBook;
