import React from 'react';
import { Button } from '@mui/material';

import Font from './Fonts/Font';
import * as colors from '../../assets/GlobalColors';

const GreenButton = ({ icon, text, handle, width, disabled }) => {
  return (
    <Button
      onClick={handle}
      variant="contained"
      sx={{
        width: width ? width : 180,
        height: 40,
        borderRadius: '8px',
        border: `2px solid ${colors.GREEN_MAIN}`,
        backgroundColor: colors.GREEN_MAIN,
        boxShadow: 'none',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        textAlign: 'left',

        '&:hover': {
          border: `2px solid ${colors.GREEN_MAIN}`,
          backgroundColor: colors.GREEN_MAIN,
          boxShadow: 'none',
          opacity: 0.8,
        },

        '&:disabled': {
          border: `2px solid ${colors.GREEN_MAIN}`,
          backgroundColor: colors.GREEN_MAIN,
          boxShadow: 'none',
          opacity: 0.2,
        },
      }}
      disabled={disabled}
    >
      {icon}
      <Font text={text} color={colors.BLACK} size={14} lineHeight="20px" isHead={true} />
    </Button>
  );
};

export default GreenButton;
