import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Button,
  Popover,
} from '@mui/material';

import Font from '../../../../Components/Generals/Fonts/Font';
import FormikTextField from '../../../../Components/Generals/FormikTextField';
import WhiteButton from '../../../../Components/Generals/WhiteButton';
import {
  setStatusToEditNameById,
  setStatusToEditDescriptionById,
  setRestoreStatusToEditById,
} from '../../../../Redux/Slices/OrderStatus.Slices';
import * as colors from '../../../../assets/GlobalColors';

const OrderPopover = ({ t, handleRestoreStatus, row }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box variant="div" aria-describedby={id}>
      <IconButton variant="contained" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          variant="div"
          sx={{
            padding: '10px',
          }}
        >
          <Button
            onClick={(e) => handleRestoreStatus(e, row)}
            variant="text"
            sx={{
              width: 180,
              height: 40,
              borderRadius: '8px',
              border: `1px solid ${colors.WHITE}`,
              backgroundColor: colors.WHITE,
              boxShadow: 'none',

              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px',
              textAlign: 'left',

              '&:hover': {
                border: `1px solid ${colors.WHITE}`,
                backgroundColor: colors.WHITE,
                boxShadow: 'none',
                opacity: 0.8,
              },
            }}
          >
            <Font
              text={t('admin.OrdersStatus.button')}
              color={colors.BLACK}
              size={14}
              lineHeight="20px"
              isHead={false}
              ml="0px"
            />
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

const OrderStatusTable = ({ colors, data, edit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const handleEditStatusName = (e, row) => {
    dispatch(
      setStatusToEditNameById({
        _id: row._id,
        name: e.target.value,
      })
    );
  };

  const handleEditStatusDescription = (e, row) => {
    dispatch(
      setStatusToEditDescriptionById({
        _id: row._id,
        description: e.target.value,
      })
    );
  };

  const handleRestoreStatus = (e, row) => {
    e.preventDefault();
    dispatch(
      setRestoreStatusToEditById({
        _id: row._id,
      })
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        border: 'none',
        boxShadow: 'none',
      }}
    >
      <Table
        sx={{
          border: 'none',
          boxShadow: 'none',
        }}
      >
        <TableHead
          sx={{
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <TableRow>
            <TableCell>
              <Font
                text={t('admin.OrdersStatus.tableHeaders.state')}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </TableCell>

            <TableCell>
              <Font
                text={t('admin.OrdersStatus.tableHeaders.description')}
                color={colors.BLACK}
                size={14}
                lineHeight="22px"
                isHead={false}
              />
            </TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            border: 'none',
            boxShadow: 'none',
          }}
        >
          {data.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell component="th" scope="row" padding="normal">
                {edit === false ? (
                  <Font text={row.name} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />
                ) : (
                  <FormikTextField
                    itemValue={row.name}
                    itemSize="small"
                    itemId="name"
                    itemName="name"
                    itemVariant="outlined"
                    itemOnChange={(e) => {
                      handleEditStatusName(e, row);
                    }}
                    itemStyle={{
                      border: `1px solid ${colors.GRAY_214_1}`,
                      borderRadius: '5px',
                      width: '250px',
                      backgroundColor: colors.WHITE,
                    }}
                    itemType="text"
                    itemPlaceHolderColor={colors.BLACK}
                    itemPlaceHolderSize="13px"
                    itemPlaceholder="name"
                    itemRows={2}
                  />
                )}
              </TableCell>

              <TableCell padding="normal">
                {edit === false ? (
                  <Font text={row.description} color={colors.BLACK} size={14} lineHeight="22px" isHead={false} />
                ) : (
                  <FormikTextField
                    itemValue={row.description}
                    itemSize="small"
                    itemId="description"
                    itemName="description"
                    itemVariant="outlined"
                    itemOnChange={(e) => {
                      handleEditStatusDescription(e, row);
                    }}
                    itemStyle={{
                      border: `1px solid ${colors.GRAY_214_1}`,
                      borderRadius: '5px',
                      width: '600px',
                      backgroundColor: colors.WHITE,
                    }}
                    itemType="text"
                    itemPlaceHolderColor={colors.BLACK}
                    itemPlaceHolderSize="13px"
                    itemPlaceholder="name"
                    itemRows={4}
                  />
                )}
              </TableCell>

              <TableCell padding="normal">
                {edit === false ? null : <OrderPopover t={t} handleRestoreStatus={handleRestoreStatus} row={row} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderStatusTable;
