import { makeStyles } from '@mui/styles';

import * as colors from '../../../../../assets/GlobalColors';

const useStyles = makeStyles({
  logHistoryCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    padding: '8px',
  },
  logHistoryText: {
    width: '100%',
    padding: '16px',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: '8px',
    backgroundColor: colors.WHITE,
  },
  logHistoryTextTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default useStyles;
