import { makeStyles } from '@mui/styles';

import * as colors from './../../assets/GlobalColors';

export const useStyles = makeStyles({
  root: {
    width: '400px',
    height: 'auto',
    padding: '20px',
    borderRadius: '6px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.WHITE}`,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
  },
  buttonsBox: {
    width: '100%',
    height: 'auto',
    backgroundColor: colors.WHITE,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
  },
  optionButton: {
    width: '150px',
    height: '40px',
    borderRadius: '6px',
  },
});
