import React from 'react';
import { Box } from '@mui/material';

import { useStyles } from './UserAvatar.styles';
import UserAvatarIcon from '../../../assets/Icons/UserAvatar.png';

const UserAvatar = () => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <img src={UserAvatarIcon} alt="User Avatar" className={classes.avatar} />
    </Box>
  );
};

export default UserAvatar;
