import React from 'react';
import { Box } from '@mui/material';

import Font from '../../../../../Components/Generals/Fonts/Font';

const InfoBox = ({
  generalsEnums,
  classes,
  title,
  text,
  colors,
  responsiveTitleSize,
  responsiveLineHeightSize,
  responsiveTextSize,
  responsiveTextLineHeightSize,
  w,
  isGreen,
}) => {
  return (
    <Box
      component={generalsEnums.BoxDiv}
      className={classes.boxGreen}
      sx={{
        width: w,
      }}
    >
      <Box
        component={generalsEnums.BoxDiv}
        className={classes.boxGreenTitle}
        sx={{
          padding: isGreen ? '12px 24px' : '0px',
          backgroundColor: isGreen ? colors.GREEN_MAIN : colors.WHITE,
          border: isGreen ? `1px solid ${colors.GREEN_MAIN}` : 'none',
        }}
      >
        <Font
          text={title}
          color={colors.BLACK}
          size={responsiveTitleSize}
          lineHeight={responsiveLineHeightSize}
          isHead={generalsEnums.trueBoolean}
        />
      </Box>

      <Box
        component={generalsEnums.BoxDiv}
        className={classes.boxGreenText}
        sx={{
          padding: isGreen ? '16px 24px' : '0px',
          backgroundColor: isGreen ? colors.WHITE : colors.WHITE,
          border: isGreen ? `1px solid ${colors.GREEN_MAIN}` : 'none',
        }}
      >
        <Font
          text={text}
          color={colors.BLACK}
          size={responsiveTextSize}
          lineHeight={responsiveTextLineHeightSize}
          isHead={generalsEnums.falseBoolean}
        />
      </Box>
    </Box>
  );
};

export default InfoBox;
