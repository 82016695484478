import { createSlice } from '@reduxjs/toolkit';

import generalsEnums from '../../utils/Generals';
import { getMinDate, getMaxDate } from '../../utils/FormatData';

const initialState = {
  totalRevenue: 0,
  averageOrderCost: 0,
  profitMargin: '',
  minDate: getMinDate(),
  maxDate: getMaxDate(),
};

export const DashboardSlices = createSlice({
  name: 'DashboardSlices',
  initialState,
  reducers: {
    setTotalRevenue: (state, action) => {
      state.totalRevenue = action.payload;
    },
    setAverageOrderCost: (state, action) => {
      state.averageOrderCost = action.payload;
    },
    setProfitMargin: (state, action) => {
      state.profitMargin = action.payload;
    },
    setMinDate: (state, action) => {
      state.minDate = action.payload;
    },
    setMaxDate: (state, action) => {
      state.maxDate = action.payload;
    },
  },
});

export const { setTotalRevenue, setAverageOrderCost, setProfitMargin, setMinDate, setMaxDate } =
  DashboardSlices.actions;

export const selectTotalRevenue = (state) => state.DashboardSlices.totalRevenue;
export const selectAverageOrderCost = (state) => state.DashboardSlices.averageOrderCost;
export const selectProfitMargin = (state) => state.DashboardSlices.profitMargin;
export const selectMinDate = (state) => state.DashboardSlices.minDate;
export const selectMaxDate = (state) => state.DashboardSlices.maxDate;

export default DashboardSlices.reducer;
