import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import * as yup from 'yup';

import Users from '../../../entities/Users';
import TsiButton from '../../../Components/Generals/Figma/Atoms/TsiButton';
import Font from '../../../Components/Generals/Fonts/Font';
import generalsEnums from '../../../utils/Generals';
import InputText from '../../../Components/Generals/InputText';
import { setEmailAccountToVerify } from '../../../Redux/Slices/User.Slices';
import { setBackDropState } from '../../../Redux/Slices/Navigation.Slices';

const ForgotPassword = ({
  t,
  isCenter,
  colors,
  titleSize,
  titleLineHeight,
  textSize,
  textLineHeight,
  Paths,
  signInWidth,
}) => {
  const UsersAPI = new Users();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disableButton, setDisableButton] = React.useState(generalsEnums.falseBoolean);

  const handleBackToSignIn = (e) => {
    e.preventDefault();
    navigate(Paths.UserManagement.signin);
  };

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      dispatch(setBackDropState(generalsEnums.trueBoolean));
      setDisableButton(generalsEnums.trueBoolean);

      try {
        const restoreUserPasswordGenerateCodeBody = {
          email: values.email,
        };

        await UsersAPI.restoreUserPasswordGenerateCode(restoreUserPasswordGenerateCodeBody);

        dispatch(setBackDropState(generalsEnums.falseBoolean));
        dispatch(setEmailAccountToVerify(values.email));

        navigate(Paths.UserManagement.resetPassword);
        setDisableButton(generalsEnums.falseBoolean);
        formik.resetForm();
      } catch (error) {
        setDisableButton(generalsEnums.falseBoolean);
        dispatch(setBackDropState(generalsEnums.falseBoolean));
      }
    },
  });

  return (
    <Box
      component={generalsEnums.BoxDiv}
      sx={{
        width: signInWidth,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: isCenter,
      }}
    >
      <Font
        text={t('UserManagement.ForgotPassword.title')}
        color={colors.WHITE}
        size={titleSize}
        lineHeight={titleLineHeight}
        isHead={generalsEnums.trueBoolean}
        ml="0px"
      />

      <Font
        text={t('UserManagement.ForgotPassword.subtitle')}
        color={colors.GRAY_BG_6}
        size={textSize}
        lineHeight={textLineHeight}
        isHead={generalsEnums.falseBoolean}
        ml="0px"
        mt="10px"
      />

      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
        }}
      >
        <InputText
          value={formik.values.email}
          name="email"
          onChange={formik.handleChange}
          label={t('UserManagement.ForgotPassword.email')}
          labelColor={colors.GRAY_BG_6}
          ph={t('UserManagement.ForgotPassword.emailPlaceholder')}
          error={formik.errors.email}
          isDisabled={false}
          w="100%"
          isPassword={false}
          extraLabel={generalsEnums.undefinedData}
          rows={generalsEnums.undefinedData}
          helperText={generalsEnums.undefinedData}
          hasRows={generalsEnums.falseBoolean}
          hasErrorMessage={false}
          errorMessage={generalsEnums.emptyString}
          mt="20px"
        />

        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: '100%',
            marginTop: '20px',
          }}
        >
          <TsiButton
            type={generalsEnums.buttonTypes.save}
            handleAction={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            text={t('Generals.submit')}
            isDisabled={disableButton}
          />
        </Box>
      </form>

      <Box
        component="div"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginTop: '20px',
        }}
      >
        <Button
          variant="text"
          type="submit"
          style={{
            textTransform: 'none',
            border: 'none',
            backgroundColor: 'transparent',
            shadow: 'none',
          }}
          onClick={handleBackToSignIn}
        >
          <Font
            text={t('UserManagement.ForgotPassword.backToSignIn')}
            color={colors.GREEN_MAIN}
            size={textSize}
            lineHeight={textLineHeight}
            isHead={generalsEnums.falseBoolean}
            ml="0px"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
