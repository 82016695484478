import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import generalsEnums from '../../utils/Generals';
import Font from '../../Components/Generals/Fonts/Font';
import InputText from '../../Components/Generals/InputText';
import TsiButton from '../../Components/Generals/Figma/Atoms/TsiButton';
import NativeComboBox from '../../Components/Generals/NativeComboBox';
import { useStyles, colors } from './styles';

const Profile = ({
  isDisabled,
  profile,
  countries,
  languages,
  portalSettingsAPI,
  language,
  handleOpenSnackBar,
  handleCloseStoreProfilesModal,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [disableButton, setDisableButton] = React.useState(generalsEnums.falseBoolean);

  const handleInputPhone = (e) => {
    const { value } = e.target;
    const regex = /^[0-9\b]+$/;

    if (value === '' || regex.test(value)) {
      formik.setFieldValue('phone', value);
    }
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    country: yup.string().required(),
    language: yup.string().required(),
    phone: yup.string().required(),
    address: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: generalsEnums.emptyString,
      email: generalsEnums.emptyString,
      country: generalsEnums.emptyString,
      language: generalsEnums.emptyString,
      phone: generalsEnums.emptyString,
      address: generalsEnums.emptyString,
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const body = {
        profile: {
          _id: profile._id,
          name: values.name,
          email: values.email,
          country: values.country,
          language: values.language,
          storeCode: profile.storeCode,
          phone: values.phone,
          address: values.address,
        },
      };

      await portalSettingsAPI.editProfile(body);

      handleCloseStoreProfilesModal();
    },
  });

  React.useEffect(() => {
    if (profile && Object.keys(profile).length > 0) {
      const {
        name: nameProfile,
        email: emailProfile,
        country: countryProfile,
        language: languageProfile,
        phone: phoneProfile,
        address: addressProfile,
      } = profile;

      formik.setValues({
        name: nameProfile,
        email: emailProfile,
        country: countryProfile,
        language: languageProfile,
        phone: phoneProfile,
        address: addressProfile,
      });
    }
  }, [profile]);

  React.useEffect(() => {
    setDisableButton(isDisabled);
  }, [isDisabled]);

  return (
    <Box component="div" className={classes.profileRoot}>
      <Font
        text={t('Modals.StoreProfilesModal.Profile.title')}
        color={colors.BLACK}
        size={generalsEnums.fontSizes.size22.size}
        lineHeight={generalsEnums.fontSizes.size22.lineHeight}
        isHead={true}
      />

      <form onSubmit={formik.handleSubmit}>
        <Box component="div" className={classes.profileRoot}>
          <Font
            text={t('Modals.StoreProfilesModal.Profile.subtitle')}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size18.size}
            lineHeight={generalsEnums.fontSizes.size18.lineHeight}
            isHead={true}
          />

          <Box component="div" className={classes.profileRow}>
            <Box component="div" className={classes.profileColumn}>
              <InputText
                value={formik.values.name}
                name="name"
                onChange={formik.handleChange}
                label={t('Modals.StoreProfilesModal.Profile.name.label')}
                labelColor={colors.BLACK_06}
                ph={t('Modals.StoreProfilesModal.Profile.name.placeholder')}
                error={formik.errors.name}
                isDisabled={isDisabled}
                helperText={t('Modals.StoreProfilesModal.Profile.name.helperText')}
              />

              <InputText
                value={formik.values.email}
                name="email"
                onChange={formik.handleChange}
                label={t('Modals.StoreProfilesModal.Profile.email.label')}
                labelColor={colors.BLACK_06}
                ph={t('Modals.StoreProfilesModal.Profile.email.placeholder')}
                error={formik.errors.email}
                isDisabled={isDisabled}
                helperText={t('Modals.StoreProfilesModal.Profile.email.helperText')}
              />

              <InputText
                value={formik.values.phone}
                name="phone"
                onChange={(e) => {
                  handleInputPhone(e);
                }}
                label={t('Modals.StoreProfilesModal.Profile.phone.label')}
                labelColor={colors.BLACK_06}
                ph={t('Modals.StoreProfilesModal.Profile.phone.placeholder')}
                error={formik.errors.phone}
                isDisabled={isDisabled}
                helperText={t('Modals.StoreProfilesModal.Profile.phone.helperText')}
              />
            </Box>

            <InputText
              value={formik.values.address}
              name="address"
              onChange={formik.handleChange}
              label={t('Modals.StoreProfilesModal.Profile.address.label')}
              labelColor={colors.BLACK_06}
              ph={t('Modals.StoreProfilesModal.Profile.address.placeholder')}
              error={formik.errors.address}
              isDisabled={isDisabled}
              w="200px"
              isPassword={false}
              extraLabel={generalsEnums.undefinedData}
              rows={6}
              helperText={t('Modals.StoreProfilesModal.Profile.address.helperText')}
              hasRows={generalsEnums.trueBoolean}
              hasErrorMessage={generalsEnums.falseBoolean}
              errorMessage={generalsEnums.emptyString}
            />
          </Box>

          <Font
            text={t('Modals.StoreProfilesModal.Profile.env')}
            color={colors.BLACK}
            size={generalsEnums.fontSizes.size18.size}
            lineHeight={generalsEnums.fontSizes.size18.lineHeight}
            isHead={true}
          />

          <Box component="div" className={classes.profileRow}>
            <NativeComboBox
              valueForm={formik.values.country}
              label={t('Modals.StoreProfilesModal.Profile.country.label')}
              labelColor={colors.BLACK_06}
              data={countries}
              helperText={t('Modals.StoreProfilesModal.Profile.country.helperText')}
              change={(e) => {
                formik.setFieldValue('country', e.target.value);
              }}
            />

            <NativeComboBox
              valueForm={formik.values.language}
              label={t('Modals.StoreProfilesModal.Profile.language.label')}
              labelColor={colors.BLACK_06}
              data={languages}
              helperText={t('Modals.StoreProfilesModal.Profile.language.helperText')}
              change={(e) => {
                formik.setFieldValue('language', e.target.value);
              }}
            />
          </Box>

          <TsiButton
            type={generalsEnums.buttonTypes.save}
            handleAction={() => {
              formik.handleSubmit();
            }}
            text={t('Generals.saveChanges')}
            isDisabled={disableButton}
          />
        </Box>
      </form>
    </Box>
  );
};

export default Profile;
